import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const ProfileReport = ({ successors, user, companyLogo }) => {
  const profileHeading = [
    {
      name: "Years of Senior Management Experience",
    },
    {
      name: "Years with CU or Company",
    },
    {
      name: "Education",
    },
    {
      name: "Performance",
    },
    {
      name: "Loyalty",
    },
    {
      name: "Desire to Advance",
    },
    {
      name: "Retirement Window",
    },
    {
      name: "Additional Comments",
    },
  ];

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Page
      size="A4"
      orientation="landscape"
      style={{
        padding: 20,
        fontFamily: "RobotoRegular",
        lineHeight: 1.2,
        fontSize: 9,
        color: "#334155",
        backgroundColor: "#f1f5f9",
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ position: "absolute", right: 30, top: 30 }}>
        <Image
          style={{ width: 120, height: 24, objectFit: "contain" }}
          src={logo}
        />
      </View>
      <View
        style={{
          paddingBottom: 20,
          width: "100%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 16,
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <Image
            style={{
              width: 360,
              height: 120,
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            src={companyLogo}
          />
        </View>
        <View
          style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}
        >
          <Text
            style={[
              {
                fontFamily: "RobotoBold",
                fontSize: 20,
                textTransform: "capitalize",
              },
            ]}
          >
            Company Name: {user?.company_name}
          </Text>
        </View>
        <View
          style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}
        >
          <Text
            style={[
              {
                fontFamily: "RobotoBold",
                fontSize: 12,
                textTransform: "uppercase",
                color: "#14b8a6",
                letterSpacing: 3,
                textAlign: "center",
              },
            ]}
          >
            Succession Plan for {user?.title}
          </Text>
        </View>
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              textAlign: "center",
              justifyContent: "center",
            },
          ]}
        >
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                textAlign: "center",
                justifyContent: "center",
                marginRight: 12,
              },
            ]}
          >
            <Text
              style={[
                {
                  fontFamily: "RobotoMedium",
                  fontSize: 10,
                  textAlign: "center",
                },
              ]}
            >
              Created by:
            </Text>
            <Text
              style={{
                fontFamily: "RobotoRegular",
                fontSize: 10,
                marginLeft: 2,
              }}
            >
              {user?.name}({user?.title})
            </Text>
          </View>
          <View
            style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}
          >
            <Text style={[{ fontFamily: "RobotoMedium", fontSize: 10 }]}>
              Date:
            </Text>
            <Text
              style={{
                fontFamily: "RobotoRegular",
                fontSize: 10,
                marginLeft: 2,
              }}
            >
              {moment(new Date()).format("MM/DD/YYYY")}
            </Text>
          </View>
        </View>
      </View>
      {successors &&
        successors.length > 0 &&
        successors.map((row, key) => {
          return (
            <View style={{ width: "100%" }} wrap={false} key={key}>
              {row.successors_details && row.successors_details.length > 0 && (
                <View
                  style={[
                    {
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 20,
                      marginTop: 5,
                      marginBottom: 5,
                    },
                  ]}
                >
                  <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                    {row.name}{" "}
                    <Text
                      style={[{ fontFamily: "RobotoRegular", fontSize: 10 }]}
                    >
                      {" (" + row.position + ")"}
                    </Text>
                  </Text>
                  <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                    Retirement Window :{" "}
                    {row?.retirement
                      ? row?.retirement
                          ?.replace(/(Eliminates this candidate)/gi, "")
                          .replace(/[{()}]/g, "")
                      : ""}
                  </Text>
                </View>
              )}
              {row.successors_details && row.successors_details.length > 0 && (
                <View
                  style={{
                    border: "1px solid #e2e8f0",
                    borderRadius: 16,
                    overflow: "hidden",
                    marginBottom: 20,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                    }}
                  >
                    <Text
                      style={[
                        {
                          fontFamily: "RobotoBold",
                          fontSize: 12,
                          color: "#ffffff",
                          width: "20%",
                          maxWidth: "100%",
                          paddingHorizontal: 6,
                          paddingVertical: 6,
                        },
                      ]}
                    >
                      &nbsp;
                    </Text>
                    {row.successors_details.map((item, index) => (
                      <View
                        key={index}
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                          },
                        ]}
                      >
                        <Text
                          style={{
                            width: "100%",
                            fontFamily: "RobotoBold",
                            fontSize: 10,
                          }}
                        >
                          {item.name}
                        </Text>
                        <Text style={{ fontSize: 8, marginTop: 2 }}>
                          {item?.position_details
                            ? item?.position_details[0]?.short_name
                            : "N/A"}
                        </Text>
                      </View>
                    ))}
                  </View>
                  <View
                    style={{
                      width: "100%",
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      backgroundColor: "white",
                    }}
                  >
                    {profileHeading.map((items, index) => (
                      <View
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderTop: "1px solid #e2e8f0",
                        }}
                      >
                        <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "wrap",
                              fontSize: 10,
                              width: "20%",
                              maxWidth: "100%",
                              paddingHorizontal: 12,
                              paddingVertical: 12,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontFamily: "RobotoMedium",
                                width: "100%",
                                fontSize: 10,
                                lineHeight: 1,
                              },
                            ]}
                          >
                            {items.name}
                          </Text>
                        </View>

                        {row.successors_details.map((item, index2) => (
                          <View
                            key={index + "_" + index2}
                            style={[
                              {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                flexWrap: "wrap",
                                fontSize: 10,
                                width: "20%",
                                maxWidth: "100%",
                                paddingHorizontal: 12,
                                paddingVertical: 12,
                              },
                            ]}
                          >
                            {index === 0 && (
                              <Text style={{ fontSize: 9 }}>
                                {item?.experience_details &&
                                item?.experience_details.length > 0
                                  ? item?.experience_details[0]?.name
                                  : "N/A"}
                              </Text>
                            )}
                            {index === 1 && (
                              <Text style={{ fontSize: 9 }}>
                                {item?.cu_details && item?.cu_details.length > 0
                                  ? item?.cu_details[0]?.name
                                  : "N/A"}
                              </Text>
                            )}
                            {index === 2 && (
                              <Text style={{ fontSize: 9 }}>
                                {item?.education_details &&
                                item?.education_details.length > 0
                                  ? item?.education_details[0]?.name
                                  : "N/A"}
                              </Text>
                            )}
                            {index === 3 && (
                              <Text style={{ fontSize: 9 }}>
                                {item?.performance_details &&
                                item?.performance_details.length > 0
                                  ? (item?.performance_alt_details.length > 0
                                      ? item?.performance_alt_details[0]?.name
                                      : item?.performance_details[0]?.name) +
                                    " " +
                                    (item?.performance_alt_details.length > 0 &&
                                    item?.performance_alt_details[0]?.number
                                      ? "(" +
                                        item?.performance_alt_details[0]
                                          ?.number +
                                        ")"
                                      : "(" +
                                        item?.performance_details[0]?.number +
                                        ")")
                                  : "N/A"}
                              </Text>
                            )}
                            {index === 4 &&
                              (item?.loyalty_details.length > 0 &&
                              item?.loyalty_details[0].isPass ? (
                                <Text style={{ fontSize: 9 }}>
                                  {item?.performance_details &&
                                  item?.performance_details.length > 0
                                    ? item?.performance_details[0]?.name +
                                      " " +
                                      (item?.performance_details[0].number
                                        ? "(" +
                                          item?.performance_details[0].number +
                                          ")"
                                        : "")
                                    : "N/A"}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {Array.isArray(item?.loyalty_details) &&
                                  item?.loyalty_details.length > 0
                                    ? item?.loyalty_details[0]?.isPass
                                      ? "Pass: "
                                      : "Fail: "
                                    : ""}
                                  {item?.loyalty_details &&
                                  item?.loyalty_details.length > 0
                                    ? item?.loyalty_details[0]?.name
                                    : "N/A"}
                                </Text>
                              ))}
                            {index === 5 &&
                              (item.desire_details.length > 0 &&
                              item?.desire_details[0].isPass ? (
                                <Text style={{ fontSize: 9 }}>
                                  {Array.isArray(item?.desire_details) &&
                                  item?.desire_details.length > 0
                                    ? item?.desire_details[0]?.isPass
                                      ? "Pass: "
                                      : "Fail: "
                                    : ""}
                                  {item?.desire_details &&
                                  item?.desire_details.length > 0
                                    ? item?.desire_details[0]?.name
                                    : "N/A"}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {Array.isArray(item?.desire_details) &&
                                  item?.desire_details.length > 0
                                    ? item?.desire_details[0]?.isPass
                                      ? "Pass: "
                                      : "Fail: "
                                    : ""}
                                  {item?.desire_details &&
                                  item?.desire_details.length > 0
                                    ? item?.desire_details[0]?.name
                                    : "N/A"}
                                </Text>
                              ))}
                            {index === 6 &&
                              (item.retirement_details.length > 0 &&
                              item?.retirement_details[0].isPass ? (
                                <Text style={{ fontSize: 9 }}>
                                  {Array.isArray(item?.retirement_details) &&
                                  item?.retirement_details.length > 0
                                    ? item?.retirement_details[0]?.isPass
                                      ? "Pass: "
                                      : "Fail: "
                                    : ""}
                                  {item?.retirement_details &&
                                  item?.retirement_details.length > 0
                                    ? item?.retirement_details[0]?.name
                                    : "N/A"}
                                </Text>
                              ) : (
                                <Text style={{ fontSize: 9, color: "red" }}>
                                  {Array.isArray(item?.retirement_details) &&
                                  item?.retirement_details.length > 0
                                    ? item?.retirement_details[0]?.isPass
                                      ? "Pass: "
                                      : "Fail: "
                                    : ""}
                                  {item?.retirement_details &&
                                  item?.retirement_details.length > 0
                                    ? item?.retirement_details[0]?.name
                                    : "N/A"}
                                </Text>
                              ))}
                            {index === 7 && (
                              <Text style={{ fontSize: 9 }}>
                                {item?.additional_comments
                                  ? item?.additional_comments
                                  : ""}
                              </Text>
                            )}
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>
              )}
            </View>
          );
        })}
    </Page>
  );
};

export default ProfileReport;
