import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Textarea from "../form/Textarea";

import { postData } from "../../services/api";
import toastr from "toastr";

import { useTranslation } from "react-i18next";
import TierSelectDropdown from "../elements/TierSelectDropdown";

const HeadingManagePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  type,
  loadPredictorsList = () => {},
  loadConcernList = () => {},
  setData = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [weight, setWeight] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const isEdit = !!data?._id;
      const endpoint = isEdit ? "company-heading/update" : "heading/create";
      const payload = isEdit
        ? {
            type,
            heading_id: data?._id,
            heading_name: name,
            heading_description: description,
            weight: data?.weight,
            // tierId: selectedTier,
          }
        : {
            type,
            name,
            description,
            weight,
            // tierId: selectedTier,
          };

      const result = await postData(endpoint, payload);

      if (result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: isEdit ? data?._id : result?.data?._id,
          current_status: "",
        });

        type === "concern" ? loadConcernList() : loadPredictorsList();

        setData({});
        setIsOpen(false);
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setName(data?.heading_name ? data?.heading_name : data?.name);
    setDescription(
      data?.heading_description ? data?.heading_description : data?.description
    );
    // setSelectedTier(data?.tierId);
    setWeight(data?.weight);
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {type && type === "concern"
                      ? t("manage_concern")
                      : t("manage_predictors")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen(false);
                        setName(null);
                        setDescription(null);
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4">
                    <div className="relative mb-3">
                      <Input
                        label={t("factor_name")}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t("enter_factor_name")}
                        inputValue={name}
                        setInput={setName}
                        requiredDisplay={true}
                      />
                    </div>
                    {/* {type && type === "predictors" && (
                      <div className="relative col-span-12">
                        <Input
                          label={t("weight")}
                          labelClasses={"!text-xs"}
                          inputType={"number"}
                          inputPlaceholder={t("enter_weight")}
                          inputValue={weight}
                          setInput={setWeight}
                          requiredDisplay={true}
                        />
                      </div>
                    )} */}
                    {/* <div className="relative mb-3">
                      <TierSelectDropdown
                        selectedTier={selectedTier}
                        setSelectedTier={setSelectedTier}
                        className={"!w-full"}
                      />
                    </div> */}
                    <div className="relative mb-3">
                      <Textarea
                        label={t("description")}
                        labelClasses={"!text-xs"}
                        inputValue={description}
                        inputPlaceholder={t("enter_description")}
                        setTextarea={setDescription}
                      />
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("confirm")}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default HeadingManagePopup;
