import React, { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import FreeAuthRoute from "./routes/FreeAuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import AuthLayout from "./layouts/AuthLayout";

// Front
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import UpdatePassword from "./pages/UpdatePassword";
import AboutUsPage from "./pages/AboutUsPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import Privacy from "./pages/Privacy";
import Freetrial from "./pages/Freetrial";
import Demo2 from "./pages/Demo2";
import Intro from "./pages/Intro";

import LoginAs from "./pages/LoginAs";

import DefaultLayout from "./layouts/DefaultLayout";
import AdminLogin from "./pages/AdminLogin";
import Dashboard from "./pages/Dashboard";
import Concern from "./pages/Concern";
//import Successor from "./pages/Successor";
import Policy from "./pages/Policy";
import Competencies from "./pages/Competencies";
import Profiles from "./pages/Profiles";
import AddSuccessor from "./pages/AddSuccessor";
import Predictors from "./pages/Predictors";
import Scorecard from "./pages/Scorecard";
import AdvancementReadiness from "./pages/AdvancementReadiness";
import Conclusion from "./pages/Conclusion";
//import PlanSummary from "./pages/PlanSummary";
import DevelopmentPlan from "./pages/DevelopmentPlan";
import AdminProfile from "./pages/AdminProfile";
import Settings from "./pages/Settings";

//CMS
import Positions from "./pages/Positions";
import AddPosition from "./pages/AddPosition";
import Performance from "./pages/Performance";
import Experience from "./pages/Experience";
import Educations from "./pages/Educations";
import Desires from "./pages/Desires";
import Loyalties from "./pages/Loyalties";
import Returements from "./pages/Returements";
import EmailTemplates from "./pages/EmailTemplates";
import ManageEmailTemplate from "./pages/ManageEmailTemplate";
import Industries from "./pages/Industries";
import ManageIndustry from "./pages/ManageIndustry";
import ConcernHeadings from "./pages/ConcernHeadings";
import PredictorHeadings from "./pages/PredictorHeadings";
import Options from "./pages/Options";
import ManageOption from "./pages/ManageOption";

import ManagePolicy from "./pages/ManagePolicy";
import Instructions from "./pages/Instructions";
import ManageInstruction from "./pages/ManageInstruction";
import ActivationCode from "./pages/ActivationCode";
import CommissionPay from "./pages/CommissionPay";

import SuccessionPlan from "./pages/admin/SuccessionPlan";
import ManageSuccessionPlan from "./pages/ManageSuccessionPlan";
import Leaderships from "./pages/admin/Leaderships";
import ManageLeadership from "./pages/admin/ManageLeadership";

//CRM
import Assign from "./pages/Assign";
import Users from "./pages/Users";
import CompanyEmployees from "./pages/CompanyEmployees";
//import BoardMamber from "./pages/BoardMamber";
import Boardroom from "./pages/Boardroom";
import BoardMamberAdministrator from "./pages/BoardMamberAdministrator";
import ManageBoardMamberAdministrator from "./pages/ManageBoardMamberAdministrator";
//import BoardMamberPlaning from "./pages/BoardMamberPlaning";
//import BoardMamberReadiness from "./pages/BoardMamberReadiness";
import BoardStrength from "./pages/BoardStrength";
import Salesteam from "./pages/Salesteam";
import Consultants from "./pages/Consultants";
import ManageConsultant from "./pages/ManageConsultant";
import ConsultantAdmin from "./pages/ConsultantAdmin";
import Staff from "./pages/Staff";

import Plans from "./pages/Plans";
import BuyPlan from "./pages/BuyPlan";

import PasswordCreate from "./pages/PasswordCreate";
import Permission from "./pages/Permission";
import Notification from "./pages/Notification";

// Super Admin
import DashboardStatistics from "./pages/admin/DashboardStatistics";
import NewSale from "./pages/admin/NewSale";
import LeadTracking from "./pages/admin/LeadTracking";
import LeadTrackings from "./pages/admin/LeadTrackings";
import ManageSalesTracking from "./pages/admin/ManageSalesTracking";
import ManageLeadTracking from "./pages/admin/ManageLeadTracking";
import Reports from "./pages/admin/Reports";
import CompanyDetails from "./pages/CompanyDetails";
import Admins from "./pages/admin/Admins";
import Pricing from "./pages/admin/Pricing";
import ManagePricing from "./pages/admin/ManagePricing";
import Companies from "./pages/admin/Companies";
import Transactions from "./pages/admin/Transactions";
import Teams from "./pages/admin/Teams";
import ManageTeam from "./pages/admin/ManageTeam";
import Testimonials from "./pages/admin/Testimonials";
import ManageTestimonial from "./pages/admin/ManageTestimonial";
import Banners from "./pages/admin/Banner";
import ManageBanner from "./pages/admin/ManageBanner";
//import ManageBenefit from "./pages/admin/ManageBenefit.js-not-used";
import SiteSettings from "./pages/admin/SiteSettings";
import Enquiry from "./pages/admin/Enquiry";
import FrontLayout from "./layouts/FrontLayout";
import Subscriptions from "./pages/admin/Subscriptions";
import Blogs from "./pages/admin/Blogs";
import ManageBlog from "./pages/admin/ManageBlog";
//import Helps from "./pages/admin/Helps";
//import ManageHelp from "./pages/admin/ManageHelp";
import Clients from "./pages/admin/Clients";
import ManageClients from "./pages/admin/ManageClients";
import ManageAbout from "./pages/admin/ManageAbout";
import Coupons from "./pages/admin/Coupons";
import ManageCoupon from "./pages/admin/ManageCoupon";
import ManageTerms from "./pages/admin/ManageTerms";
import ManageCompany from "./pages/admin/ManageCompany";
import AccountCreation from "./pages/admin/AccountCreation";
import PlanFeatures from "./pages/admin/PlanFeatures";
import Payments from "./pages/admin/Payments";

import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
//import Contact from "./pages/Contact";
import PerformanceAlt from "./pages/PerformanceAlt";
import PredictorHeadingAlt from "./pages/PredictorHeadingAlt";
import ConcernSettings from "./pages/ConcernSettings";
import PositionAlt from "./pages/PositionAlt";
import ScorecardAlt from "./pages/ScorecardAlt";
import CompetenciesAlt from "./pages/CompetenciesAlt";
import NonRatedEmployees from "./pages/NonRatedEmployees";
import Resources from "./pages/Resources";
import ResourceDetails from "./pages/ResourceDetails";
import RequestDemo from "./pages/RequestDemo";
import ManageContact from "./pages/admin/ManageContact";
import ManageAccessDemo from "./pages/admin/ManageAccessDemo";
import IndividualProfile from "./pages/IndividualProfile";
import IndividualScorecard from "./pages/IndividualScorecard";
import IndividualDevelopment from "./pages/IndividualDevelopment";
import IndividualAdvancementReadiness from "./pages/IndividualAdvancementReadiness";
import IndividualConclusion from "./pages/IndividualConclusion";
import IndividualRetirementReport from "./pages/IndividualRetirementReport";
import IndividualCompanyReport from "./pages/IndividualCompanyReport";
import EmergencyReplacementPlan from "./pages/EmergencyReplacementPlan";
import ManageEmergencyReplacementPlan from "./pages/ManageEmergencyReplacementPlan";
import Leads from "./pages/admin/Leads";
import ManageLeads from "./pages/admin/ManageLeads";
import BoardEffectiveness from "./pages/admin/BoardEffectiveness";
import ManageBoardEffectiveness from "./pages/admin/ManageBoardEffectiveness";

import Executives from "./pages/Executives";
import ManageExecutive from "./pages/ManageExecutive";
import ExecutiveAudit from "./pages/admin/ExecutiveAudit";
import ExecutiveStrength from "./pages/ExecutiveStrength";
import ExecutiveDetails from "./pages/ExecutiveDetails";
import ExecutiveAuditHistory from "./pages/ExecutiveAuditHistory";
import BoardStrengthHistory from "./pages/BoardStrengthHistory";

import Help from "./pages/pdf/Help";
import Faq from "./pages/Faq";
import ManageFaq from "./pages/ManageFaq";
//import FaqFront from "./pages/FaqFront";
import History from "./pages/History";

import Page404 from "./pages/Page404";
import AccessDenied from "./pages/AccessDenied";

//v2
import HomeV2 from "./pages/HomeV2";
import ContactV2 from "./pages/ContactV2";
import FaqV2 from "./pages/v2/FaqV2";
import PricingContactV2 from "./pages/v2/PricingContactV2";
import FreeAuthLayout from "./layouts/v2/FreeAuthLayout";
//import FreeLandingLayout from "./layouts/v2/FreeLandingLayout";
//import LandingPage from "./pages/v2/LandingPage";
import NewsLetters from "./pages/admin/NewsLetters";
import Invoices from "./pages/Invoices";
import Callback from "./pages/Callback";
import BoardMamberNew from "./pages/BoardMamberNew";
import BoardMamberDetails from "./pages/BoardMamberDetails";
//import ManageBoardMamber from "./pages/ManageBoardMamber";
import OurClients from "./pages/OurClients";
import ViewCompanyDetails from "./pages/admin/ViewCompanyDetails";
import AccountCreationV2 from "./pages/admin/AccountCreationV2";
import BuyPlanV2 from "./pages/BuyPlanV2";
import PositionGroup from "./pages/admin/PositionGroup";
import BillingDetails from "./pages/admin/BillingDetails";
import QuestionGroup from "./pages/admin/QuestionGroup";
import PerspectiveBoardMember from "./pages/PerspectiveBoardMember";
import BoardRetirementReport from "./pages/BoardRetirementReport";
import ExecutiveAuditSettings from "./pages/ExecutiveAuditSettings";
import BoardEffectivenessSettings from "./pages/BoardEffectivenessSettings";
import Authentication from "./pages/Authentication";
import IndividualSuccessionCandidate from "./pages/IndividualSuccessionCandidate";
import IndividualCompanyRetirementProfile from "./pages/IndividualCompanyRetirementProfile";
import InternetSpeedChecker from "./pages/InternetSpeedChecker";
import InternetSpeedMonitor from "./pages/InternetSpeedMonitor";
import ProspectiveBoardMemberReport from "./pages/ProspectiveBoardMemberReport";
import DeveloperPage from "./pages/DeveloperPage";
import ManagePositionGroup from "./pages/ManagePositionGroup";
import NewHome from "./pages/newHome/NewHome";
import ChangeEmail from "./pages/ChangeEmail";
import CompanySNSummary from "./pages/CompanySNSummary";
// import { SettingsProvider } from "./Context/SettingsContext";
import QuestionsLibrary from "./pages/QuestionsLibrary";
import CompanyPositionGroup from "./pages/CompanyPositionGroup";
import CandidateSummaryReport from "./pages/CandidateSummaryReport";
import ReadinessSummaryReport from "./pages/ReadinessSummaryReport";

const LinkedinLink = () => {
  window.location.href =
    "https://try.successionnow.com/b2d34cb8-532c-47f6-93fa-d5234ee566a2";
  return null;
};

const FacebookLink = () => {
  window.location.href =
    "https://try.successionnow.com/c6520f1a-5047-4cda-8cbd-bf71e3556ca1/";
  return null;
};

const YoutubeLink = () => {
  window.location.href =
    "https://try.successionnow.com/6751b849-2794-46d8-90c6-53817f69a548/";
  return null;
};

const SpotifyLink = () => {
  window.location.href =
    "https://try.successionnow.com/5dcf4b89-6dc2-4f11-a2aa-1198496007b8/";
  return null;
};

const XLink = () => {
  window.location.href =
    "https://try.successionnow.com/6a8727c3-d37f-4230-bf84-ad6e544e79b6/";
  return null;
};

const ContentLink = () => {
  window.location.href =
    "https://try.successionnow.com/6b65f009-7985-4339-bb94-2d6efb418801/";
  return null;
};

const GrowLink = () => {
  window.location.href =
    "https://try.successionnow.com/404e95c6-8dc7-459f-802a-c649a0d8834f/";
  return null;
};

const PromoLink = () => {
  window.location.href =
    "https://try.successionnow.com/68609384-a395-4308-93a2-f4ea9d0e93a3/";
  return null;
};

const HrperfLink = () => {
  window.location.href =
    "https://try.successionnow.com/59806cb5-193d-4bf6-b905-32218e266d81/";
  return null;
};

const IntroLink = () => {
  window.location.href =
    "https://try.successionnow.com/9a43397f-e45f-4b2e-bab1-1bf98c67e667";
  return null;
};

const RedirectDashboard = () => {
  window.location.href = "/dashboard";
};

const CreateRoutes = () => {
  const [isChange, setIsChange] = useState(false);
  const isTrial = localStorage.getItem("isTrial");
  return (
    <BrowserRouter>
      {/* <SettingsProvider> */}
      <ScrollToTop>
        <Routes>
          <Route path="*" element={<Page404 />}></Route>
          <Route path="/access-denied" element={<AccessDenied />}></Route>
          <Route path="/homev1" element={<FrontLayout />}>
            <Route path="/homev1" exact element={<Home />} />
          </Route>

          {/* v2 */}
          <Route path="/" element={<FreeAuthLayout />}>
            <Route path="/" exact element={<HomeV2 />} />
          </Route>
          <Route path="/new-home" exact element={<NewHome />} />
          <Route path="/speed-checker" element={<FreeAuthLayout />}>
            <Route
              path="/speed-checker"
              exact
              element={<InternetSpeedMonitor />}
            />
          </Route>
          <Route path="/contact-us" element={<FreeAuthLayout />}>
            <Route path="/contact-us" exact element={<ContactV2 />} />
          </Route>
          <Route
            path="/frequently-asked-questions"
            element={<FreeAuthLayout />}
          >
            <Route
              path="/frequently-asked-questions"
              exact
              element={<FaqV2 />}
            />
          </Route>
          <Route path="/homev2-pricing-contact" element={<FreeAuthLayout />}>
            <Route
              path="/homev2-pricing-contact"
              exact
              element={<PricingContactV2 />}
            />
          </Route>
          <Route path="/resources" element={<FreeAuthLayout />}>
            <Route path="/resources" exact element={<Resources />} />
          </Route>
          <Route path="/resource-details/:alias" element={<FreeAuthLayout />}>
            <Route
              path="/resource-details/:alias"
              exact
              element={<ResourceDetails />}
            />
          </Route>
          <Route path="/terms-and-conditions" element={<FreeAuthLayout />}>
            <Route
              path="/terms-and-conditions"
              exact
              element={<TermsAndConditions />}
            />
          </Route>
          <Route path="/privacy" element={<FreeAuthLayout />}>
            <Route path="/privacy" exact element={<Privacy />} />
          </Route>
          {/* v2  End */}
          {/* <Route path="/about-us" element={<FrontLayout />}> */}
          <Route path="/about-us" element={<FreeAuthLayout />}>
            <Route path="/about-us" exact element={<AboutUsPage />} />
          </Route>
          <Route path="/our-clients" element={<FreeAuthLayout />}>
            <Route path="/our-clients" exact element={<OurClients />} />
          </Route>
          {/* <Route path="/terms-and-conditions" element={<FrontLayout />}>
            <Route
              path="/terms-and-conditions"
              exact
              element={
                <TermsAndConditions />
              }
            />
          </Route>
          <Route path="/privacy" element={<FrontLayout />}>
            <Route
              path="/privacy"
              exact
              element={
                <Privacy />
              }
            />
          </Route> */}
          {/* <Route path="/contact-us" element={<FrontLayout />}>
            <Route
              path="/contact-us"
              exact
              element={
                <Contact />
              }
            />
          </Route> */}
          {/* <Route path="/frequently-asked-questions" element={<FrontLayout />}>
            <Route
              path="/frequently-asked-questions"
              exact
              element={
                <FaqFront />
              }
            />
          </Route> */}
          {/* <Route path="/resources" element={<FrontLayout />}>
            <Route
              path="/resources"
              exact
              element={
                <Resources/>
              }
            />
          </Route>
          <Route path="/resource-details/:alias" element={<FrontLayout />}>
            <Route
              path="/resource-details/:alias"
              exact
              element={
                <ResourceDetails/>
              }
            />
          </Route> */}
          <Route path="/request-demo" element={<FrontLayout />}>
            <Route path="/request-demo" exact element={<RequestDemo />} />
          </Route>
          <Route path="/intro" element={<AuthLayout />}>
            <Route
              path="/intro"
              exact
              element={
                <IntroLink />
                // <Freetrial type={1} segment={'intro'}/>
              }
            />
          </Route>
          <Route path="/gartner" element={<AuthLayout />}>
            <Route
              path="/gartner"
              exact
              element={<Freetrial type={1} segment={"gartner"} />}
            />
          </Route>
          <Route path="/linkedin" element={<AuthLayout />}>
            <Route
              path="/linkedin"
              exact
              element={
                <LinkedinLink />
                // <Freetrial type={1} segment={'linkedin'}/>
              }
            />
          </Route>
          <Route path="/facebook" element={<AuthLayout />}>
            <Route
              path="/facebook"
              exact
              element={
                <FacebookLink />
                // <Freetrial type={1} segment={'facebook'}/>
              }
            />
          </Route>
          <Route path="/youtube" element={<AuthLayout />}>
            <Route
              path="/youtube"
              exact
              element={
                <YoutubeLink />
                // <Freetrial type={1} segment={'youtube'}/>
              }
            />
          </Route>
          <Route path="/spotify" element={<AuthLayout />}>
            <Route
              path="/spotify"
              exact
              element={
                <SpotifyLink />
                // <Freetrial type={1} segment={'spotify'}/>
              }
            />
          </Route>
          <Route path="/x" element={<AuthLayout />}>
            <Route
              path="/x"
              exact
              element={
                <XLink />
                // <Freetrial type={1} segment={'x'}/>
              }
            />
          </Route>
          <Route path="/content" element={<AuthLayout />}>
            <Route
              path="/content"
              exact
              element={
                <ContentLink />
                // <Freetrial type={1} segment={'content'}/>
              }
            />
          </Route>
          <Route path="/grow" element={<AuthLayout />}>
            <Route
              path="/grow"
              exact
              element={
                <GrowLink />
                // <Freetrial type={1} segment={'grow'}/>
              }
            />
          </Route>
          <Route path="/promo" element={<AuthLayout />}>
            <Route
              path="/promo"
              exact
              element={
                <PromoLink />
                // <Freetrial type={1} segment={'promo'}/>
              }
            />
          </Route>
          <Route path="/hrperf" element={<AuthLayout />}>
            <Route
              path="/hrperf"
              exact
              element={
                <HrperfLink />
                // <Freetrial type={1} segment={'promo'}/>
              }
            />
          </Route>
          <Route path="/intro/2" element={<AuthLayout />}>
            <Route
              path="/intro/2"
              exact
              element={<Intro type={2} segment={"intro/2"} />}
            />
          </Route>
          <Route path="/intro/3" element={<AuthLayout />}>
            <Route
              path="/intro/3"
              exact
              element={<Intro type={3} segment={"intro/3"} />}
            />
          </Route>
          <Route path="/intro/4" element={<AuthLayout />}>
            <Route
              path="/intro/4"
              exact
              element={<Intro type={4} segment={"intro/4"} />}
            />
          </Route>

          <Route path="/intro/6" element={<AuthLayout />}>
            <Route
              path="/intro/6"
              exact
              element={<Intro type={6} segment={"intro/6"} />}
            />
          </Route>

          <Route path="/demo/2" element={<AuthLayout />}>
            <Route path="/demo/2" exact element={<Demo2 />} />
          </Route>
          <Route path="/signin" element={<AuthLayout />}>
            <Route
              path="/signin"
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/signup" element={<AuthLayout />}>
            <Route
              path="/signup"
              exact
              element={
                <FreeAuthRoute>
                  <Signup />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/forgot-password" element={<AuthLayout />}>
            <Route
              path="/forgot-password"
              exact
              element={
                <FreeAuthRoute>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/update-password" element={<AuthLayout />}>
            <Route
              path="/update-password"
              exact
              element={
                <FreeAuthRoute>
                  <UpdatePassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/change-email" element={<AuthLayout />}>
            <Route
              path="/change-email"
              exact
              element={
                <FreeAuthRoute>
                  <ChangeEmail />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/authentication" element={<AuthLayout />}>
            <Route
              path="/authentication"
              exact
              element={
                <FreeAuthRoute>
                  <Authentication />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/manage" element={<AuthLayout />}>
            <Route
              path="/manage"
              exact
              element={
                <FreeAuthRoute>
                  <PasswordCreate />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/activationcode" element={<AuthLayout />}>
            <Route
              path="/activationcode"
              exact
              element={
                <FreeAuthRoute>
                  <ActivationCode />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/commission-pay" element={<AuthLayout />}>
            <Route
              path="/commission-pay"
              exact
              element={
                <FreeAuthRoute>
                  <CommissionPay />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/admin" element={<AuthLayout />}>
            <Route
              path="/admin"
              exact
              element={
                <FreeAuthRoute>
                  <AdminLogin />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/login-as" element={<AuthLayout />}>
            <Route
              path="/login-as"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                    "sales-admin",
                    "sales-staff",
                    "consultant",
                    "content-manager",
                  ]}
                >
                  <LoginAs />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/plans" element={<AuthLayout />}>
            <Route
              path="/plans"
              exact
              element={
                <Plans />
                // <FreeAuthRoute getAccess={["customer-admin"]}>
                //   <Plans />
                // </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/buy-plan" element={<AuthLayout />}>
            <Route
              path="/buy-plan"
              exact
              element={
                <BuyPlan />
                // <FreeAuthRoute getAccess={["customer-admin"]}>
                //   <Plans />
                // </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/buy-plan-v2" element={<AuthLayout />}>
            <Route
              path="/buy-plan-v2"
              exact
              element={
                <BuyPlanV2 />
                // <FreeAuthRoute getAccess={["customer-admin"]}>
                //   <Plans />
                // </FreeAuthRoute>
              }
            />
          </Route>
          {/* External From */}
          <Route path="/salesteam" element={<AuthLayout />}>
            <Route path="/salesteam" exact element={<Salesteam />} />
          </Route>
          <Route path="/leadinput" element={<AuthLayout />}>
            <Route path="/leadinput" exact element={<LeadTracking />} />
          </Route>
          {/* External From End */}
          <Route path="/dashboard" element={<DefaultLayout />}>
            <Route
              path="/dashboard"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/developerPage" element={<DefaultLayout />}>
            <Route
              path="/developerPage"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <DeveloperPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/dashboard-statistics" element={<DefaultLayout />}>
            <Route
              path="/dashboard-statistics"
              exact
              element={
                <ProtectedRoute getAccess={["basic-administrator"]}>
                  <DashboardStatistics />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/new-sale" element={<DefaultLayout />}>
            <Route
              path="/new-sale"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <NewSale />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/lead-tracking" element={<DefaultLayout />}>
            <Route
              path="/lead-tracking"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <LeadTrackings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/position-groups" element={<DefaultLayout />}>
            <Route
              path="/position-groups"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <PositionGroup />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/question-groups" element={<DefaultLayout />}>
            <Route
              path="/question-groups"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <QuestionGroup />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-lead-tracking/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-lead-tracking/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageLeadTracking />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-sales-tracking/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-sales-tracking/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageSalesTracking />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/reports" element={<DefaultLayout />}>
            <Route
              path="/reports"
              exact
              element={
                <ProtectedRoute getAccess={["basic-administrator"]}>
                  <Reports />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company-details/:id" element={<DefaultLayout />}>
            <Route
              path="/company-details/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <CompanyDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/policy" element={<DefaultLayout />}>
            <Route
              path="/policy"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  {isTrial !== 1 ? <Policy /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/successors" element={<DefaultLayout />}>
            <Route
              path="/successors"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Successor />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/competencies" element={<DefaultLayout />}>
            <Route
              path="/competencies"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Competencies />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/profiles"
            element={<DefaultLayout getIsChange={isChange} />}
          >
            <Route
              path="/profiles"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Profiles getIsChange={(data) => setIsChange(data)} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-successor" element={<DefaultLayout />}>
            <Route
              path="/add-successor"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <AddSuccessor />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/concern"
            element={<DefaultLayout getIsChange={isChange} />}
          >
            <Route
              path="/concern"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Concern getIsChange={(data) => setIsChange(data)} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/predictors"
            element={<DefaultLayout getIsChange={isChange} />}
          >
            <Route
              path="/predictors"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Predictors getIsChange={(data) => setIsChange(data)} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/scorecard" element={<DefaultLayout />}>
            <Route
              path="/scorecard"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Scorecard />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/plan-summary" element={<DefaultLayout />}>
            <Route
              path="/plan-summary"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin","customer-subadmin", "customer-user"]}>
                  <PlanSummary />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/development-plan" element={<DefaultLayout />}>
            <Route
              path="/development-plan"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <DevelopmentPlan />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/callback" element={<DefaultLayout />}>
            <Route
              path="/callback"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Callback />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/advancement-readiness"
            element={<DefaultLayout getIsChange={isChange} />}
          >
            <Route
              path="/advancement-readiness"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <AdvancementReadiness
                    getIsChange={(data) => setIsChange(data)}
                  />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/conclusion" element={<DefaultLayout />}>
            <Route
              path="/conclusion"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Conclusion />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/profile" element={<DefaultLayout />}>
            <Route
              path="/profile"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "basic-administrator",
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                    "sales-admin",
                    "sales-staff",
                    "consultant",
                    "customer-boardmember",
                  ]}
                >
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/change-password" element={<DefaultLayout />}>
            <Route
              path="/change-password"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "basic-administrator",
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                    "sales-admin",
                    "sales-staff",
                    "consultant",
                    "customer-boardmember",
                    "customer-executive",
                  ]}
                >
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/settings" element={<DefaultLayout />}>
            <Route
              path="/settings"
              exact
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/instructions" element={<DefaultLayout />}>
            <Route
              path="/instructions"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Instructions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/manage-instruction" element={<DefaultLayout />}>
            <Route
              path="/manage-instruction"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageInstruction />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CMS Part  */}
          <Route path="/positions" element={<DefaultLayout />}>
            <Route
              path="/positions"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Positions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-position" element={<DefaultLayout />}>
            <Route
              path="/add-position"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <AddPosition /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-position/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-position/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  {isTrial !== 1 ? <AddPosition /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/performances" element={<DefaultLayout />}>
            <Route
              path="/performances"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Performance />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/experiences" element={<DefaultLayout />}>
            <Route
              path="/experiences"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Experience />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/educations" element={<DefaultLayout />}>
            <Route
              path="/educations"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Educations />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/desires" element={<DefaultLayout />}>
            <Route
              path="/desires"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Desires />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/loyalties" element={<DefaultLayout />}>
            <Route
              path="/loyalties"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Loyalties />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/returements" element={<DefaultLayout />}>
            <Route
              path="/returements"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Returements />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/email-templates" element={<DefaultLayout />}>
            <Route
              path="/email-templates"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <EmailTemplates />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-email/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-email/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageEmailTemplate />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/industries" element={<DefaultLayout />}>
            <Route
              path="/industries"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Industries />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-industry" element={<DefaultLayout />}>
            <Route
              path="/add-industry"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageIndustry />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-industry/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-industry/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageIndustry />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/concern-headings" element={<DefaultLayout />}>
            <Route
              path="/concern-headings"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ConcernHeadings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/predictor-headings" element={<DefaultLayout />}>
            <Route
              path="/predictor-headings"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <PredictorHeadings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/options/:heading_id" element={<DefaultLayout />}>
            <Route
              path="/options/:heading_id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Options />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-option/:heading_id" element={<DefaultLayout />}>
            <Route
              path="/add-option/:heading_id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageOption />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/edit-option/:heading_id/:id"
            element={<DefaultLayout />}
          >
            <Route
              path="/edit-option/:heading_id/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageOption />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/manage-policy" element={<DefaultLayout />}>
            <Route
              path="/manage-policy"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ManagePolicy />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/permission" element={<DefaultLayout />}>
            <Route
              path="/permission"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Permission />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CRM Part  */}
          <Route path="/assigns" element={<DefaultLayout />}>
            <Route
              path="/assigns"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <Assign />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/board-members" element={<DefaultLayout />}>
            <Route
              path="/board-members"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <BoardMamberNew />
                  {/* <BoardMamber /> */}
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/board-members-new" element={<DefaultLayout />}>
            <Route
              path="/board-members-new"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin","customer-subadmin","customer-boardmember"]}>
                  <BoardMamberNew />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/boardroom" element={<DefaultLayout />}>
            <Route
              path="/boardroom"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <Boardroom />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/perspective-board-member" element={<DefaultLayout />}>
            <Route
              path="/perspective-board-member"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <PerspectiveBoardMember />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/board-member/edit/:id" element={<DefaultLayout />}>
            <Route
              path="/board-member/edit/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ManageBoardMamber />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/board-member/details/:id" element={<DefaultLayout />}>
            <Route
              path="/board-member/details/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BoardMamberDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/boardmember-administrator" element={<DefaultLayout />}>
            <Route
              path="/boardmember-administrator"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BoardMamberAdministrator />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/edit-boardmember-administrator/:id"
            element={<DefaultLayout />}
          >
            <Route
              path="/edit-boardmember-administrator/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ManageBoardMamberAdministrator />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/board-member/planning" element={<DefaultLayout />}>
            <Route
              path="/board-member/planning"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin","customer-subadmin"]}>
                  <BoardMamberPlaning />
                </ProtectedRoute>
              }
            />
          </Route> */}
          {/* <Route path="/board-member/readiness" element={<DefaultLayout />}>
            <Route
              path="/board-member/readiness"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin","customer-subadmin"]}>
                  <BoardMamberReadiness />
                </ProtectedRoute>
              }
            />
          </Route> */}
          {/* <BoardMamberConclusion /> */}
          <Route path="/boardstrength" element={<DefaultLayout />}>
            <Route
              path="/boardstrength"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BoardStrength />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/board-retirement-report" element={<DefaultLayout />}>
            <Route
              path="/board-retirement-report"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BoardRetirementReport />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/board-strength-history" element={<DefaultLayout />}>
            <Route
              path="/board-strength-history"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BoardStrengthHistory />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/prospective-board-member-report"
            element={<DefaultLayout />}
          >
            <Route
              path="/prospective-board-member-report"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin"]}>
                  <ProspectiveBoardMemberReport />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executives" element={<DefaultLayout />}>
            <Route
              path="/executives"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-executive",
                  ]}
                >
                  <Executives />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive-audit" element={<DefaultLayout />}>
            <Route
              path="/executive-audit"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <ManageExecutive />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive/edit/:id" element={<DefaultLayout />}>
            <Route
              path="/executive/edit/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-boardmember",
                  ]}
                >
                  <ManageExecutive />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive/details/:id" element={<DefaultLayout />}>
            <Route
              path="/executive/details/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ExecutiveDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executivestrength" element={<DefaultLayout />}>
            <Route
              path="/executivestrength"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ExecutiveStrength />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive-audit-history" element={<DefaultLayout />}>
            <Route
              path="/executive-audit-history"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  <ExecutiveAuditHistory />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/users" element={<DefaultLayout />}>
            <Route
              path="/users"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <Users /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-user/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-user/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={[
                    "customer-admin",
                    "customer-subadmin",
                    "customer-user",
                  ]}
                >
                  {isTrial !== 1 ? <Assign /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company-employees" element={<DefaultLayout />}>
            <Route
              path="/company-employees"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <CompanyEmployees /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/consultants" element={<DefaultLayout />}>
            <Route
              path="/consultants"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Consultants />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-consultant" element={<DefaultLayout />}>
            <Route
              path="/add-consultant"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ManageConsultant />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/consultant-admin" element={<DefaultLayout />}>
            <Route
              path="/consultant-admin"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ConsultantAdmin />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Super Admin */}
          <Route path="/salesstaff" element={<DefaultLayout />}>
            <Route
              path="/salesstaff"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Staff />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admins" element={<DefaultLayout />}>
            <Route
              path="/admins"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Admins />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/companies" element={<DefaultLayout />}>
            <Route
              path="/companies"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Companies />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company/:id" element={<DefaultLayout />}>
            <Route
              path="/company/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ViewCompanyDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-company" element={<DefaultLayout />}>
            <Route
              path="/add-company"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageCompany />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/new-account" element={<DefaultLayout />}>
            <Route
              path="/new-account"
              exact
              element={
                <ProtectedRoute getAccess={["sales-admin", "sales-staff"]}>
                  <AccountCreationV2 />
                  {/* <AccountCreation /> */}
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/new-account-v2" element={<DefaultLayout />}>
            <Route
              path="/new-account-v2"
              exact
              element={
                <ProtectedRoute getAccess={["sales-admin", "sales-staff"]}>
                  <AccountCreationV2 />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/payment" element={<DefaultLayout />}>
            <Route
              path="/payment"
              exact
              element={
                <ProtectedRoute getAccess={["sales-admin", "sales-staff"]}>
                  <Payments />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/invoices" element={<DefaultLayout />}>
            <Route
              path="/invoices"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Invoices />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-company/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-company/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <AccountCreation />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/pricing" element={<DefaultLayout />}>
            <Route
              path="/pricing"
              exact
              element={
                <ProtectedRoute getAccess={["sales-admin", "sales-staff"]}>
                  <Pricing />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-plan" element={<DefaultLayout />}>
            <Route
              path="/add-plan"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManagePricing />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-plan/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-plan/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManagePricing />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/plan-features" element={<DefaultLayout />}>
            <Route
              path="/plan-features"
              exact
              element={
                <ProtectedRoute getAccess={["sales-admin", "sales-staff"]}>
                  <PlanFeatures />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/activationcodes" element={<DefaultLayout />}>
            <Route
              path="/activationcodes"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Coupons />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-coupon" element={<DefaultLayout />}>
            <Route
              path="/add-coupon"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageCoupon />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-coupon/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-coupon/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageCoupon />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/transactions" element={<DefaultLayout />}>
            <Route
              path="/transactions"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Transactions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/teams" element={<DefaultLayout />}>
            <Route
              path="/teams"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Teams />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-team" element={<DefaultLayout />}>
            <Route
              path="/add-team"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageTeam />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-team/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-team/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageTeam />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/testimonials" element={<DefaultLayout />}>
            <Route
              path="/testimonials"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <Testimonials />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-testimonial" element={<DefaultLayout />}>
            <Route
              path="/add-testimonial"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <ManageTestimonial />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-testimonial/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-testimonial/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <ManageTestimonial />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/banners" element={<DefaultLayout />}>
            <Route
              path="/banners"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Banners />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-banner" element={<DefaultLayout />}>
            <Route
              path="/add-banner"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBanner />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-banner/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-banner/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBanner />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/benefits" element={<DefaultLayout />}>
            <Route
              path="/benefits"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBenefit />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/site-settings" element={<DefaultLayout />}>
            <Route
              path="/site-settings"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <SiteSettings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/succession-plans" element={<DefaultLayout />}>
            <Route
              path="/succession-plans"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <SuccessionPlan />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-successionplan" element={<DefaultLayout />}>
            <Route
              path="/add-successionplan"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageSuccessionPlan />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-successionplan/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-successionplan/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageSuccessionPlan />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/leaderships" element={<DefaultLayout />}>
            <Route
              path="/leaderships"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Leaderships />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/board-effectiveness" element={<DefaultLayout />}>
            <Route
              path="/board-effectiveness"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <BoardEffectiveness />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-board-effectiveness" element={<DefaultLayout />}>
            <Route
              path="/add-board-effectiveness"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBoardEffectiveness type={"boardmember"} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/edit-board-effectiveness/:id"
            element={<DefaultLayout />}
          >
            <Route
              path="/edit-board-effectiveness/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBoardEffectiveness type={"boardmember"} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive-audit-360" element={<DefaultLayout />}>
            <Route
              path="/executive-audit-360"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ExecutiveAudit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-executive-audit" element={<DefaultLayout />}>
            <Route
              path="/add-executive-audit"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBoardEffectiveness type={"executive"} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-executive-audit/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-executive-audit/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageBoardEffectiveness type={"executive"} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-leadership" element={<DefaultLayout />}>
            <Route
              path="/add-leadership"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageLeadership />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-leadership/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-leadership/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageLeadership />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/enquiries" element={<DefaultLayout />}>
            <Route
              path="/enquiries"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Enquiry />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/newsletters" element={<DefaultLayout />}>
            <Route
              path="/newsletters"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <NewsLetters />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/subscriptions" element={<DefaultLayout />}>
            <Route
              path="/subscriptions"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Subscriptions />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/billing" element={<DefaultLayout />}>
            <Route
              path="/billing"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <BillingDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/articles" element={<DefaultLayout />}>
            <Route
              path="/articles"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <Blogs />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-blog" element={<DefaultLayout />}>
            <Route
              path="/add-blog"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <ManageBlog />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-blog/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-blog/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["sales-admin", "sales-staff", "content-manager"]}
                >
                  <ManageBlog />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/transactions/:id" element={<DefaultLayout />}>
            <Route
              path="/transactions/:id"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Transactions />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/helps" element={<DefaultLayout />}>
            <Route
              path="/helps"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin","customer-subadmin"]}>
                  <Helps />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/faq" element={<DefaultLayout />}>
            <Route
              path="/faq"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Faq />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/planhistory" element={<DefaultLayout />}>
            <Route
              path="/planhistory"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <History />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company-succession-summary" element={<DefaultLayout />}>
            <Route
              path="/company-succession-summary"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <CompanySNSummary />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/candidate-summary-report" element={<DefaultLayout />}>
            <Route
              path="/candidate-summary-report"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <CandidateSummaryReport />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/readiness-summary-report" element={<DefaultLayout />}>
            <Route
              path="/readiness-summary-report"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <ReadinessSummaryReport />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-faq" element={<DefaultLayout />}>
            <Route
              path="/add-faq"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageFaq />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-faq/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-faq/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageFaq />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/add-help" element={<DefaultLayout />}>
            <Route
              path="/add-help"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageHelp />
                </ProtectedRoute>
              }
            />
          </Route> */}
          {/* <Route path="/edit-help/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-help/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageHelp />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/clients" element={<DefaultLayout />}>
            <Route
              path="/clients"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Clients />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/add-client" element={<DefaultLayout />}>
            <Route
              path="/add-client"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageClients />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-client/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-client/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageClients />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/about" element={<DefaultLayout />}>
            <Route
              path="/about"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageAbout />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/leads" element={<DefaultLayout />}>
            <Route
              path="/leads"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <Leads />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/edit-lead/:id" element={<DefaultLayout />}>
            <Route
              path="/edit-lead/:id"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageLeads />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/terms" element={<DefaultLayout />}>
            <Route
              path="/terms"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageTerms />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/contact" element={<DefaultLayout />}>
            <Route
              path="/contact"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageContact />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/access-demo" element={<DefaultLayout />}>
            <Route
              path="/access-demo"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageAccessDemo />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/performance-alt" element={<DefaultLayout />}>
            <Route
              path="/performance-alt"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <PerformanceAlt /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/position-alt" element={<DefaultLayout />}>
            <Route
              path="/position-alt"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <PositionAlt /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/manage-position" element={<DefaultLayout />}>
            <Route
              path="/manage-position"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <ManagePositionGroup />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/concern-settings" element={<DefaultLayout />}>
            <Route
              path="/concern-settings"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <ConcernSettings /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/predictors-heading-alt" element={<DefaultLayout />}>
            <Route
              path="/predictors-heading-alt"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <PredictorHeadingAlt />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/scorecard-alt" element={<DefaultLayout />}>
            <Route
              path="/scorecard-alt"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? <ScorecardAlt /> : <RedirectDashboard />}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/competencies-alt" element={<DefaultLayout />}>
            <Route
              path="/competencies-alt"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <CompetenciesAlt />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/non-rated-employees" element={<DefaultLayout />}>
            <Route
              path="/non-rated-employees"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <NonRatedEmployees />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/executive-audit-settings" element={<DefaultLayout />}>
            <Route
              path="/executive-audit-settings"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <ExecutiveAuditSettings />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/board-effectiveness-settings"
            element={<DefaultLayout />}
          >
            <Route
              path="/board-effectiveness-settings"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <BoardEffectivenessSettings />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/manual" element={<DefaultLayout />}>
            <Route
              path="/manual"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Help />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/profile-reports" element={<DefaultLayout />}>
            <Route
              path="/profile-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualProfile />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/scorecard-reports" element={<DefaultLayout />}>
            <Route
              path="/scorecard-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualScorecard />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/development-reports" element={<DefaultLayout />}>
            <Route
              path="/development-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualDevelopment />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/readiness-reports" element={<DefaultLayout />}>
            <Route
              path="/readiness-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualAdvancementReadiness />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/conclusion-reports" element={<DefaultLayout />}>
            <Route
              path="/conclusion-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualConclusion />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company-retirement-profile" element={<DefaultLayout />}>
            <Route
              path="/company-retirement-profile"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualCompanyRetirementProfile />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/succession-candidate-reports"
            element={<DefaultLayout />}
          >
            <Route
              path="/succession-candidate-reports"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <IndividualSuccessionCandidate />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/retirement-reports" element={<DefaultLayout />}>
            <Route
              path="/retirement-reports"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin"]}>
                  {isTrial !== 1 ? (
                    <IndividualRetirementReport />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/company-reports" element={<DefaultLayout />}>
            <Route
              path="/company-reports"
              exact
              element={
                <ProtectedRoute getAccess={["customer-admin"]}>
                  {isTrial !== 1 ? (
                    <IndividualCompanyReport />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/emergency-replacement-plan" element={<DefaultLayout />}>
            <Route
              path="/emergency-replacement-plan"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  {isTrial !== 1 ? (
                    <EmergencyReplacementPlan />
                  ) : (
                    <RedirectDashboard />
                  )}
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/manage-emergency-replacement-plan"
            element={<DefaultLayout />}
          >
            <Route
              path="/manage-emergency-replacement-plan"
              exact
              element={
                <ProtectedRoute getAccess={[]}>
                  <ManageEmergencyReplacementPlan />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/success" element={<AuthLayout />}>
            <Route
              path="/success"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Success />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/cancel" element={<AuthLayout />}>
            <Route
              path="/cancel"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <Cancel />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/notification" element={<DefaultLayout />}>
            <Route path="/notification" exact element={<Notification />} />
          </Route>
          {/* This Route Use Only Dev Server */}
          <Route path="/company-position-groups" element={<DefaultLayout />}>
            <Route
              path="/company-position-groups"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <CompanyPositionGroup />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/question-library"
            element={<DefaultLayout getIsChange={isChange} />}
          >
            <Route
              path="/question-library"
              exact
              element={
                <ProtectedRoute
                  getAccess={["customer-admin", "customer-subadmin"]}
                >
                  <QuestionsLibrary />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
      {/* </SettingsProvider> */}
    </BrowserRouter>
  );
};

export default CreateRoutes;
