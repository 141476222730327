import React from 'react';
import EvaluationTools from "../../../Items/EvaluationTools";
import Logo from "../../../../assets/image/9bo-logo.png"
import Button from '../../../form/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const IntegratedToolSection = () => {
  const { i18n,t } = useTranslation();

  const EvaluationToolsData = {
    title: "Integrated Evaluation Tools For Better Succession Planning",
    description: "Enhance your succession strategy with our powerful evaluation tools: Board Strength, 9Box Now, and Executive Audit 360. These integrated tools provide data-driven insights that support informed decision-making, reduc- ing subjectivity and enhancing the overall effectiveness of your succession planning process.",
    EvaluationTools:
      [
        {
          id: 1,
          title: "Board Strength",
          description: "<p>Evaluate your current overall board strength and identify future potential board leaders.</p><p>Our tool facilitates enhanced governance and strengthens organizational resilience.</p>"
        },
        {
          id: 2,
          image: Logo,
          description: "<p>Elevate employee performance and potential, streamline succession planning, and make data-driven HR decisions using 9Box.</p><p>Our platform goes beyond simple categorization, offering a nuanced approach to employee evaluations to mitagate bias and customization to adapt to your organization's evolving needs.</p>"
        },
        {
          id: 3,
          title: "Executive Audit 360",
          comingsoon: "(coming soon)",
          description: "<p>Review your Excutive Team to identify successes, strengths, and opportunities for growth.</p><p>Use the audits to improve communication and understanding and align your Executive Team with organizational goals.</p>"
        },

      ]
  }

  return (
    <>
      <section className="relative w-full py-10 bg-white">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
          <div className=" w-full mb-10 xl:mb-20 space-y-5">
            <h2 className="max-w-3xl mx-auto text-center capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{ __html: EvaluationToolsData.title }}></h2>
            <div className="max-w-4xl mx-auto  text-sm xs:text-base 2xl:text-lg text-slate-700 font-extrabold font-PlusJakartaSans text-center sm:text-left" dangerouslySetInnerHTML={{ __html: EvaluationToolsData.description }}></div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-10">
            {EvaluationToolsData.EvaluationTools.map((item, index) => (
              <EvaluationTools key={index} data={item} />
            ))}

          </div>
        </div>
      </section>
      <section className="relative w-full pb-10 px-5 mb-5 sm:mb-0 flex items-center justify-center">
        <Link to={'//calendly.com/cameronbrockbank/succession-now-demo'} target='_blank'>
          <div className="w-full max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl bg-dark-teal-500 mx-auto py-2 cursor-pointer rounded-md px-5" >
                <div className="text-white capitalize text-sm sm:text-base font-bold font-PlusJakartaSans text-center">{t('book_your_demo')}</div>
                {/* <div className="text-white capitalize text-sm sm:text-base font-bold font-PlusJakartaSans text-center">Book Yourb Demo To Learn More About How These Tools Can Work Together For You</div>             */}
          </div>
        </Link>
        {/* <div className='relative w-full'>
          <Button
            buttonLabel={t('book_your_demo')}
            buttonClasses={"!bg-dark-teal-500 w-full"}
            buttonLabelClasses={"text-white !font-bold capitalize !text-lg md:!text-xl"}
            buttonHasLink={true}
            buttonLink={'//calendly.com/cameronbrockbank/succession-now-demo'}
            buttonTarget={"_blank"}
          />
        </div> */}
      </section>
    </>
  );
};

export default IntegratedToolSection