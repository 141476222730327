import React, { useState } from "react";
import Banner from "../../components/section/NewHome/Banner/Banner";
import Video from "../../components/section/NewHome/Video";
import TrustedBy from "../../components/section/NewHome/TrustedBy";
import Offer from "../../components/section/NewHome/Offer";
import StepPlan from "../../components/section/NewHome/StepPlan";
import StepPlanItem from "../../components/section/NewHome/StepPlanItem/StepPlanItem";
import EvaluationTools from "../../components/section/NewHome/EvaluationTools";
import Webinars from "../../components/section/NewHome/Webinars";

const NewHome = () => {
  const [activeTab, setActiveTab] = useState("Generate");

  return (
    <>
      <Banner />
      <Video />
      <TrustedBy />
      <Offer />
      <StepPlan activeTab={activeTab} setActiveTab={setActiveTab} />
      <StepPlanItem activeTab={activeTab} />
      <EvaluationTools />
      <Webinars />
    </>
  );
};

export default NewHome;
