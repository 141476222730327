import React from "react";
import Header from "../../../Header";
import Hero from "../../../Hero";

const Banner = () => {
  return (
    <div className="overflow-hidden relative bg-home-background w-full md:w-[97%] mt-0 md:mt-5 rounded-none md:rounded-3xl h-[510px] md:h-[800px] mx-auto">
      <div className="w-full h-full absolute top-0 left-0 space-y-20 md:space-y-32 p-7 ">
        <Header />
        <Hero />
      </div>
    </div>
  );
};

export default Banner;
