import React from "react";
import { sheduleLink } from "../constant/Constant";

const Hero = () => {
  return (
    <section className="space-y-0 md:space-y-5">
      <div className="font-normal  text-black font-IBM text-[48px] text-center w-full">
        <span className="italic">Succession Planning Simplified</span>
        <br />
        <span className="font-light text-black">Lorem ipsum dolor amet</span>
      </div>
      <div className="top-[324px] left-[477px] w-[486px] h-[52px] text-center mx-auto">
        <span className="font-medium text-base text-black font-Outfit">
          Our user-friendly succession planning software allows you to develop
          your leadership pipeline and be prepared for any staff changes.
        </span>
      </div>

      <div className="w-48 mx-auto">
        <a
          target="_blank"
          href={sheduleLink}
          className={
            "relative z-0 overflow-hidden flex justify-center items-center gap-2 rounded-full h-[52px] py-0 px-3 transition-all duration-200 text-white border bg-black after:absolute after:h-full after:content-[''] after:-z-[1] after:top-0 after:left-0 after:transition-[width] after:duration-300 after:ease-in-out after:w-full hover:after:w-0 mx-auto after:bg-[#000000] border-transparent hover:border-white hover:text-white capitalize font-IBM font-medium text-base mt-5"
          }
        >
          Schedule Your Demo
        </a>
      </div>
    </section>
  );
};

export default Hero;
