import React, { useEffect, useState, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import ButtonFile from "../components/form/ButtonFile";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
//import Textarea from '../components/form/Textarea';
import moment from "moment";
import toastr from "toastr";
//import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { API_URL } from "../config/host";

import { postData } from "../services/api";
import { allowedImageExtensions } from "../helpers";
import { useDispatch } from "react-redux";
import { authUserSetup } from "../store/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import { useTranslation } from "react-i18next";
import InputGroup from "../components/form/InputGroup";
import EmailEditPopup from "../components/popup/EmailEditPopup";

const AdminProfile = () => {
  setTitle("Succession Now | Admin Profile");
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const path = location.pathname.replace(/^\/+/g, "");
  const role = localStorage.getItem("role");
  //const flatPicker = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [position, setPosition] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChanagePassword, setIsChanagePassword] = useState(false);
  const [isCancelChanagePassword, setIsCancelChanagePassword] = useState(false);

  const [profileTitle, setProfileTitle] = useState(null);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  //const [description,setDescription] = useState('');
  const [designation, setDesignation] = useState("");
  const [selectRetirementWindow, setSelectRetirementWindow] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const [image, setImage] = useState("");
  const [showImage, setShowimage] = useState("");
  const [isUpdateImage, setIsUpdateImage] = useState(false);

  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [enabledEmailEditPopup, setEnabledEmailEditPopup] = useState(false);

  useEffect(() => {
    setName(profileData?.name);
    setEmail(profileData?.email);
    setPhone(profileData?.phone);
    //setDescription(profileData?.description);
    setShowimage(API_URL + profileData?.image);
    setDesignation(profileData?.designation);
    setSelectRetirementWindow(profileData?.retirement_window);
    setProfileTitle(profileData?.title);
    setCompanyName(
      profileData?.company_details
        ? profileData?.company_details?.company_name
        : profileData?.company_name
    );
  }, [profileData]);

  useEffect(() => {
    async function profileData() {
      try {
        const result = await postData("profile/details", {});
        if (result.data) {
          setProfileData(result.data);
        } else {
          console.log("Profile Details message", result.message);
        }
      } catch (error) {
        console.log("Error profile details catch", error.message);
      }
    }
    profileData();

    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();
  }, []);

  const onCancel = async (e) => {
    setIsCancel(true);
    setName(profileData?.name);
    setPhone(profileData?.phone);
    //setDescription(profileData?.description);
    setDesignation(profileData?.designation);
    setSelectRetirementWindow(profileData?.retirement_window);
    setProfileTitle(profileData?.title);
    setCompanyName(
      profileData?.company_details
        ? profileData?.company_details?.company_name
        : profileData?.company_name
    );
    setIsCancel(false);
    if (role && (role === "customer-admin" || role === "customer-subadmin")) {
      return navigate("/dashboard");
    } else if (role && role === "customer-user") {
      return navigate("/profiles");
    } else {
      return navigate("/dashboard-statistics");
    }
  };

  const onUpdate = async (e) => {
    setIsUpdate(true);
    try {
      const result = await postData("profile/update", {
        name: name,
        company_name: companyName,
        phone: phone,
        //description:description,
        designation: designation !== "" ? designation : null,
        retirement_window:
          selectRetirementWindow !== "" ? selectRetirementWindow : null,
        title: profileTitle,
        role: role,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        dispatch(authUserSetup(result.data));
        setIsUpdate(false);
        if (
          role &&
          (role === "customer-admin" || role === "customer-subadmin")
        ) {
          return navigate("/dashboard");
        } else if (role && role === "customer-user") {
          return navigate("/profiles");
        } else {
          return navigate("/dashboard-statistics");
        }
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  const onCancelChangePassword = async (e) => {
    setIsCancelChanagePassword(true);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setIsCancelChanagePassword(false);
  };

  const onChangePassword = async (e) => {
    setIsChanagePassword(true);
    try {
      const result = await postData("change-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsChanagePassword(false);
      } else {
        toastr.error(result.message);
        setIsChanagePassword(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsChanagePassword(false);
    }
  };

  const onImgChange = (e) => {
    setIsUpdateImage(true);
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
        setIsUpdateImage(false);
      } else {
        var output = document.getElementById("profileImg");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    async function updateImage(e) {
      const formData = new FormData();
      formData.append("image", image);
      try {
        const result = await postData("profile/update-image", formData);
        if (result.data) {
          dispatch(authUserSetup(result.data));
          document.getElementById("headerImg").src =
            API_URL + result?.data?.image;
          setImage("");
          setIsUpdateImage(false);
        } else {
          console.log("image message", result.message);
          setIsUpdateImage(false);
        }
      } catch (error) {
        console.log("Error profile image catch", error.message);
        setIsUpdateImage(false);
      }
    }
    if (image) {
      updateImage();
    }
  }, [image]);

  useEffect(() => {
    if (actionValue) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">
          {path && path === "profile" ? t("profile") : t("change_password")}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-3 border-r border-slate-200">
          <div className="p-2 2xl:p-4">
            <div className="py-6 px-6 flex flex-col space-y-2 relative">
              <div className="relative group w-full aspect-1 border-2  mx-auto overflow-hidden rounded-full border-dark-teal-500">
                <ButtonFile
                  buttonType={"button"}
                  buttonIcon={
                    isUpdateImage
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-image"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("change_picture")}
                  buttonClasses={
                    "!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"
                  }
                  functions={onImgChange}
                  accepts={"image/*"}
                />
                <Image
                  src={showImage}
                  alt={"Admin"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover cursor-pointer"}
                  id={"profileImg"}
                />
              </div>
              <div className="w-full space-y-1">
                <div className="text-xl font-bold text-slate-900 text-center">
                  {profileData?.name}
                </div>
                {profileData.position_details &&
                  profileData.position_details.length > 0 && (
                    <div className="flex items-center justify-center space-x-2 text-slate-500 text-sm">
                      <i className="fa-solid fa-fw fa-briefcase"></i>
                      <div className="text-[13px] font-light">
                        <span className="font-semibold">
                          {profileData.position_details[0].short_name}
                        </span>{" "}
                        {profileData?.company_name
                          ? t("of") + " " + profileData?.company_name
                          : ""}
                      </div>
                    </div>
                  )}
                <div className="flex items-center justify-center space-x-2 text-slate-500 text-sm">
                  <i className="fa-solid fa-fw fa-clock"></i>
                  <div className="text-[13px] font-light">
                    {t("since")}{" "}
                    <span className="font-semibold">
                      {profileData
                        ? moment(new Date(profileData?.created_at)).format(
                            "MMM YYYY"
                          )
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full text-center">
                <div className="text-xs text-slate-400">
                  {t("email_address")}
                </div>
                <div className="text-sm text-slate-500">
                  {profileData ? profileData?.email : "N/A"}
                </div>
              </div>
              {profileData?.phone && (
                <div className="w-full text-center">
                  <div className="text-xs text-slate-400">{t("phone")}</div>
                  <div className="text-sm text-slate-500">
                    {profileData?.phone}
                  </div>
                </div>
              )}
              <div className="w-full text-center">
                <div className="text-xs text-slate-400">{t("last_login")}</div>
                <div className="text-sm text-slate-500">
                  {profileData && profileData?.last_login
                    ? moment(new Date(profileData?.last_login)).format(
                        "Do MMM YYYY"
                      )
                    : "N/A"}
                </div>
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex items-center justify-center rounded-b border-t border-slate-200 pt-5">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  isUpdateImage
                    ? "fa-light fa-spinner fa-spin"
                    : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("change_picture")}
                functions={onImgChange}
                accepts={"image/*"}
              />
            </div>
          </div>
        </div>
        {path && path === "profile" && (
          <div className="col-span-12 lg:col-span-9 flex flex-col">
            <div className="text-xl font-bold text-slate-900">
              {t("welcome_to_successionNow")}!
            </div>
            <div className="space-y-4 mt-4">
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="relative w-full">
                  <Input
                    label={t("company_name")}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={t("enter_company_name")}
                    inputValue={companyName}
                    setInput={setCompanyName}
                    requiredDisplay={true}
                    isDisabled={
                      role &&
                      (role === "customer-user" || role === "customer-subadmin")
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    label={t("full_name")}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={t("enter_name")}
                    inputValue={name}
                    setInput={setName}
                    isDisabled={role && role === "customer-user" ? true : false}
                    requiredDisplay={true}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="flex w-full items-center space-x-2">
                  <div className="flex-grow">
                    <Input
                      label={t("work_email")}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={t("work_email_address")}
                      inputValue={email}
                      setInput={setEmail}
                      isDisabled={true}
                      requiredDisplay={true}
                    />
                  </div>
                  <Button
                    buttonClasses={
                      "!p-0 !flex !border !mt-4 !border-transparent !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-regular fa-pencil"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledEmailEditPopup(true);
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    label={t("phone")}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={t("enter_phone")}
                    inputValue={phone}
                    setInput={setPhone}
                    requiredDisplay={true}
                  />
                </div>
              </div>

              <div className="grid sm:grid-cols-2 gap-4">
                {role &&
                  (role === "customer-admin" ||
                    role === "customer-subadmin") && (
                    <div className="relative w-full">
                      <Select
                        label={t("position")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={designation}
                        dropdownClass={"!w-60"}
                        search={true}
                        dropdownData={[
                          { _id: "", label: t("select_position"), value: "" },
                          ...position.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?._id,
                          })),
                          { _id: "others", label: t("other"), value: "others" },
                        ].filter((itm) => itm)}
                        getSelectedValue={(e) => {
                          if (e._id === "others") {
                            setEnabledOtherPositionPopup(true);
                          }
                          setDesignation(e._id);
                        }}
                      />
                    </div>
                  )}
                {role && role !== "master-admin" && (
                  <div className="relative w-full">
                    <Select
                      label={t("retirement_window")}
                      labelClasses={"!text-xs"}
                      xPlacement={"left"}
                      selectedValue={selectRetirementWindow}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_retirement_window"),
                          value: "",
                        },
                        ...retirementWindow.map((item) => {
                          return {
                            _id: item?._id,
                            label: item?.name
                              .replace(/(Eliminates this candidate)/gi, "")
                              .replace(/[{()}]/g, ""),
                            value: item?._id,
                          };
                        }),
                      ]}
                      getSelectedValue={(e) => setSelectRetirementWindow(e._id)}
                    />
                  </div>
                )}
              </div>
              {/* <div className="relative w-full">
                    <Textarea 
                    label={t('about_me')} 
                    labelClasses={'!text-xs'} 
                    inputValue={description} 
                    inputPlaceholder={t('tell_something_about_yourself')}
                    setTextarea={setDescription}
                    />
                  </div> */}
            </div>
            <div className="py-3 px-3 mt-auto">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isCancel
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-times"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("cancel")}
                  buttonClasses={
                    "!bg-slate-200 !text-slate-600 border border-slate-300"
                  }
                  functions={onCancel}
                  buttonHasLink={false}
                  buttonDisabled={isCancel}
                />
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isUpdate
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-save"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("save")}
                  functions={onUpdate}
                  buttonHasLink={false}
                  buttonDisabled={isUpdate}
                />
              </div>
            </div>
          </div>
        )}
        {path && path === "change-password" && (
          <div className="col-span-12 lg:col-span-9 flex flex-col">
            <div className="space-y-4">
              <div className="relative w-full">
                <Input
                  label={t("old_password")}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={t("enter_old_password")}
                  inputValue={oldPassword}
                  setInput={setOldPassword}
                  //errorType={'error'}
                  requiredDisplay={true}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("new_password")}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={t("enter_new_password")}
                  inputValue={newPassword}
                  setInput={setNewPassword}
                  //errorType={''}
                  requiredDisplay={true}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("retype_password")}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={t("enter_retype_password")}
                  inputValue={confirmPassword}
                  setInput={setConfirmPassword}
                  //errorType={''}
                  requiredDisplay={true}
                />
              </div>
            </div>
            <div className="py-3 px-3 mt-auto">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isCancelChanagePassword
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-times"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("cancel")}
                  buttonClasses={
                    "!bg-slate-200 !text-slate-600 border border-slate-300"
                  }
                  functions={onCancelChangePassword}
                  buttonHasLink={false}
                  buttonDisabled={isCancelChanagePassword}
                />
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isChanagePassword
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("save")}
                  functions={onChangePassword}
                  buttonHasLink={false}
                  buttonDisabled={isCancelChanagePassword}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherPositionPopup>
      )}
      {enabledEmailEditPopup && (
        <EmailEditPopup
          isOpen={enabledEmailEditPopup}
          setIsOpen={(val) => setEnabledEmailEditPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></EmailEditPopup>
      )}
    </div>
  );
};

export default AdminProfile;
