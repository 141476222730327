import React, { useEffect, useState } from "react";
import Button from "../../../form/Button";
import setpOneImage from "../../../../assets/image/step-1.webp";
import setpTwoImage from "../../../../assets/image/step-2.webp";
import setpThreeImage from "../../../../assets/image/step-3.webp";

const StepPlanItem = ({ activeTab }) => {
  const [stepData, setStepData] = useState([]);

  useEffect(() => {
    if (activeTab === "Generate") {
      const setpData = {
        step: "Step 1",
        title: "Start A New Plan",
        subtitle: "With One Click",
        description:
          "Add the candidates being considered, including basic information on their experience and education.",
        image: setpOneImage,
      };
      setStepData(setpData);
    } else if (activeTab === "Measure") {
      const setpData = {
        step: "Step 2",
        title: "Evaluate",
        description:
          "Rate candidates based on their qualifications, strengths and weaknesses and create custom development plans",
        image: setpTwoImage,
      };
      setStepData(setpData);
    } else if ("Plan") {
      const setpData = {
        step: "Step 3",
        title: "Generate Your Plan",
        description:
          "SuccessionNow scores each candidate and generates a report giving you a clear side-by-side comparison.",
        image: setpThreeImage,
      };
      setStepData(setpData);
    }
  }, [activeTab]);

  return (
    <div className="relative z-10 w-full px-4 md:px-0 -mt-44 max-w-[894px]  max-h-[542px] h-auto mx-auto bg-white border-8 border-[#DCECEC] rounded-[18px] p-4 md:p-6">
      <div className="w-full mt-8">
        <div className="space-y-5 px-8">
          <Button
            buttonClasses={
              "  !bg-[#FAF3FF] !w-[205px] !h-[68px] !rounded-[18px]"
            }
            buttonLabelClasses={
              "!font-Outfit !text-black !font-medium !text-[24px] !text-center "
            }
            buttonType={"button"}
            buttonLabel={stepData?.step}
            buttonHasLink={true}
            buttonLink={"/"}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center max-w-6xl mx-auto space-y-5 px-8 py-8">
          <div className="text-left">
            <h2 className="text-[40px] font-medium font-IBM text-black">
              {stepData?.title} <br /> {stepData?.subtitle}
            </h2>
            <div className="mt-4 text-black text-[16px] font-Outfit font-normal">
              {stepData?.description}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="rounded-[19px] w-[433px] h-[286px]">
              <img
                src={stepData?.image}
                alt="Dashboard Illustration"
                className="w-full h-full rounded-[19px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepPlanItem;
