import React, { useState, useRef, useEffect } from "react";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Textarea from "../../components/form/Textarea";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { useTranslation } from "react-i18next";
import { useDebounce } from "../../helpers/useDebounce";

const ManageSalesTracking = () => {
  const { id } = useParams();
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Sales Tracking"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | New Sales Tracking"
    );
  }
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const [sizes, setSizes] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAdministrator, setCompanyAdministrator] = useState("");
  const [companyAdministratorEmail, setCompanyAdministratorEmail] =
    useState("");
  const [verifyCompanyAdministratorEmail, setVerifyCompanyAdministratorEmail] =
    useState("");
  const [companyAdministratorPhone, setCompanyAdministratorPhone] =
    useState("");
  const [administratorPosition, setAdministratorPosition] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [price, setPrice] = useState("");
  const [priceNote, setPriceNote] = useState("");
  const [salesperson, setSalesperson] = useState("");
  const [contractLength, setContractLength] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [details, setDetails] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [interval, setInterval] = useState("month");
  const [priceId, setPriceId] = useState("");
  const [purchasePlans, setPurchasePlans] = useState([]);
  const [source, setSource] = useState("");
  const [salespersonEmail, setSalespersonEmail] = useState("");
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const search = useDebounce(administratorPosition, 500);

  useEffect(() => {
    const loadCompanySize = async () => {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { ordering: 1 },
          type: "company-size",
        });
        if (result.data) {
          setSizes(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCompanySize();
    // Lead details
    const loadLeadTrackingDetailsPlans = async () => {
      try {
        const result = await postData("salesteam/details", {
          sortQuery: { ordering: 1 },
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadLeadTrackingDetailsPlans();
  }, []);

  console.log(details, "details");

  useEffect(() => {
    setCompanyName(details?.company_name);
    setCompanyAdministrator(details?.comapny_administrator);
    setCompanyAdministratorEmail(details?.company_administrator_email);
    setCompanyAdministratorPhone(details?.company_administrator_phone);
    setCompanySize(details?.company_size);
    setPrice(details?.price);
    setPriceNote(details?.price_note);
    setSalesperson(details?.salesperson);
    setContractLength(details?.contract_length);
    setPaymentTerms(details?.payment_terms);
    setAdditionalNotes(details?.additional_note);
    setAdministratorPosition(details?.administrator_position);
    setInterval(details?.interval);
    setPriceId(details?.plan_id);
    setSource(details?.source);
    setSalespersonEmail(details?.salesperson_email);
  }, [details]);

  const onSubmit = async () => {
    setIsUpdate(true);
    try {
      const result = await postData("salesteam/update", {
        id: id,
        company_name: companyName,
        comapny_administrator: companyAdministrator,
        company_administrator_email: companyAdministratorEmail,
        verify_company_adminstrator_email: verifyCompanyAdministratorEmail,
        company_administrator_phone: companyAdministratorPhone,
        company_size: companySize,
        price: price,
        price_note: priceNote,
        salesperson: salesperson,
        additional_note: additionalNotes,
        administrator_position: administratorPosition,
        contract_length: contractLength,
        payment_terms: paymentTerms,
        salesperson_email: salespersonEmail,
        source: source,
        plan_id: priceId,
        interval: interval,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  useEffect(() => {
    const loadPurchasePlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
          sortQuery: { ordering: 1 },
          isView: 1,
        });
        if (result.data) {
          setPurchasePlans(
            result.data.map((itm) => {
              let price;
              if (itm.pricing?.rates.length > 0) {
                price = itm.pricing?.rates?.filter(
                  (it) => it?.interval === interval
                )[0]?.amount;
              }
              return {
                _id: itm?._id,
                title: itm?.name,
                price: price,
              };
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPurchasePlans();
  }, [interval]);

  useEffect(() => {
    // Position List
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("position/list", {
          keyword: search,
          limit: 1000000000,
          isGlobal: true,
        });
        if (result.data) {
          setPositions(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [search]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("edit_lead_tracking")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
          <div className="space-y-4">
            <div className="grid sm:grid-cols-3 gap-4">
              <div className="relative w-full">
                <Input
                  label={t("company_name")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"input"}
                  inputPlaceholder={t("company_name")}
                  inputValue={companyName}
                  setInput={setCompanyName}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("company_administrator")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"input"}
                  inputPlaceholder={t("enter_company_administrator")}
                  inputValue={companyAdministrator}
                  setInput={setCompanyAdministrator}
                />
              </div>
              <div className="relative w-full">
                {/* <Input
                  label={t("administrator_position")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"input"}
                  inputPlaceholder={t("enter_administrator_position")}
                  inputValue={administratorPosition}
                  setInput={setAdministratorPosition}
                /> */}
                <div className="relative group focus-within:outline-none">
                  <Input
                    label={t("administrator_position")}
                    inputType={"text"}
                    inputValue={administratorPosition}
                    setInput={setAdministratorPosition}
                    isDisabled={false}
                    className="w-full"
                  />
                  {positions &&
                    Array.isArray(positions) &&
                    positions.length > 0 && (
                      <div className="absolute top-full left-0 w-full mt-1 z-10 hidden group-focus-within:block">
                        <div className="relative bg-white border border-slate-100 shadow-md py-1 rounded-lg">
                          <div className="overflow-auto scrollbar max-h-60">
                            {positions.map((item) => (
                              <Button
                                key={item?.name} // Always provide a unique key for lists
                                buttonLabel={
                                  item?.name + " - " + item?.short_name
                                }
                                buttonLabelClasses={"!text-xs"}
                                buttonClasses={
                                  "w-full !h-8 !justify-start !bg-transparent hover:!bg-slate-100 !text-slate-500 hover:!text-600"
                                }
                                functions={() => {
                                  setAdministratorPosition(item?.name);
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="relative w-full">
                <Input
                  label={t("company_administrator_email")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"email"}
                  inputPlaceholder={t("enter_company_administrator_email")}
                  inputValue={companyAdministratorEmail}
                  setInput={setCompanyAdministratorEmail}
                  isDisabled={true}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("company_administrator_phone")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"number"}
                  inputPlaceholder={t("enter_company_administrator_phone")}
                  inputValue={companyAdministratorPhone}
                  setInput={setCompanyAdministratorPhone}
                  //isDisabled={true}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("interval")}
                  requiredDisplay={true}
                  labelClasses={"!text-xs"}
                  xPlacement={"bottomLeft"}
                  selectedValue={interval}
                  dropdownClass={"!w-60"}
                  dropdownData={[
                    { _id: " ", label: t("select_interval"), value: "" },
                    { _id: "month", label: t("month"), value: "month" },
                    { _id: "year", label: t("year"), value: "year" },
                  ]}
                  getSelectedValue={(e) => {
                    setInterval(e._id);
                    setPriceId("");
                    setPrice("");
                  }}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("plans")}
                  requiredDisplay={true}
                  labelClasses={"!text-xs"}
                  xPlacement={"bottomLeft"}
                  selectedValue={priceId}
                  dropdownClass={"!w-60"}
                  dropdownData={[
                    { _id: "", label: t("select_plans"), value: "" },
                    ...purchasePlans.map((item) => ({
                      _id: item?._id,
                      label:
                        item?.title + " - $" + Number(item?.price).toFixed(2),
                      value: item?.price,
                    })),
                  ]}
                  getSelectedValue={(e) => {
                    setPriceId(e._id);
                    setPrice(e.value);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("company_size")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={companySize}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: " ", label: t("select_size"), value: "" },
                    ...sizes.map((boardyear_item) => ({
                      _id: boardyear_item?._id,
                      label: boardyear_item?.name,
                      value: boardyear_item?._id,
                    })),
                  ]}
                  getSelectedValue={(e) => {
                    setCompanySize(e._id);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("price")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"input"}
                  inputPlaceholder={t("enter_price")}
                  inputValue={price}
                  setInput={setPrice}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("Source")}
                  xPlacement={"left"}
                  requiredDisplay={true}
                  selectedValue={source}
                  dropdownClass={"!w-60"}
                  //dropdownButtonLabelClass={"!font-bold"}
                  dropdownData={[
                    { _id: "", label: "Select Source", value: "" },
                    { _id: "linkedin", label: "LinkedIn", value: "linkedin" },
                    { _id: "growbots", label: "GrowBots", value: "growbots" },
                    { _id: "google", label: "Google", value: "google" },
                    { _id: "gartner", label: "Gartner", value: "gartner" },
                    { _id: "g2", label: "G2", value: "g2" },
                    { _id: "youtube", label: "YouTube", value: "youtube" },
                    {
                      _id: "website-organic",
                      label: "Website (Organic)",
                      value: "website-organic",
                    },
                    {
                      _id: "referral_email_nick",
                      label: "Referral, Email (Nick)",
                      value: "referral_email_nick",
                    },
                    {
                      _id: "hr_perfommance_solutions",
                      label: "HR Performance Solutions",
                      value: "hr_perfommance_solutions",
                    },
                    {
                      _id: "gac",
                      label: "GAC",
                      value: "gac",
                    },
                    {
                      _id: "shrm",
                      label: "SHRM",
                      value: "shrm",
                    },
                    { _id: "other", label: "Other", value: "other" },
                  ]}
                  dropdownOptionBold={true}
                  getSelectedValue={(e) => setSource(e._id)}
                />
              </div>

              <div className="relative w-full">
                <Input
                  label={t("salesperson")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"input"}
                  inputPlaceholder={t("enter_salesperson")}
                  inputValue={salesperson}
                  setInput={setSalesperson}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("Salesperson Email")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"email"}
                  inputValue={salespersonEmail}
                  setInput={setSalespersonEmail}
                  isDisabled={false}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={"Proposed Contract Length"}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={contractLength}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: " ", label: t("select_size"), value: "" },
                    ...Array(3)
                      .fill()
                      .map((_, index) => ({
                        _id: Number(index + 1),
                        label:
                          Number(index + 1) > 1
                            ? Number(index + 1) + " Years"
                            : Number(index + 1) + " Year",
                        value: Number(index + 1),
                      })),
                  ]}
                  getSelectedValue={(e) => {
                    setContractLength(e._id);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={"Payment Terms"}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={paymentTerms}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: "", label: "Select Payemnt Terms", value: "" },
                    { _id: "monthly", label: "Monthly", value: "monthly" },
                    {
                      _id: "full-payment",
                      label: "Full Payment",
                      value: "full-payment",
                    },
                  ]}
                  getSelectedValue={(e) => {
                    setPaymentTerms(e._id);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative w-full">
                <Textarea
                  label={t("price_notes")}
                  inputClasses={"!h-32"}
                  labelClasses={"!text-xs"}
                  inputValue={priceNote}
                  setTextarea={setPriceNote}
                />
              </div>
              <div className="relative w-full">
                <Textarea
                  label={t("additional_notes")}
                  inputClasses={"!h-32"}
                  labelClasses={"!text-xs"}
                  inputValue={additionalNotes}
                  setTextarea={setAdditionalNotes}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-5">
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={
                isUpdate
                  ? "fa-light fa-spinner fa-spin"
                  : "fa-regular fa-floppy-disk"
              }
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={onSubmit}
              buttonHasLink={false}
              buttonDisabled={isUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSalesTracking;
