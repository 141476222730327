import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import { useTranslation } from "react-i18next";
import Shape from "../../shape.png";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const BoardStrengthReport = ({
  areaOfExpertise,
  studies,
  educations,
  positions,
  boardMambers,
  reatingOptions,
  totalEffectivenessAvg,
  certifacates,
  user,
  companyLogo,
  retirementWindow,
  totalAvgYearOfBoard,
}) => {
  const { i18n, t } = useTranslation();

  const overallScoreDescription = (avgRating) => {
    if (avgRating && avgRating >= 9 && avgRating <= 10) {
      return "Highly Effective";
    } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
      return "Very Effective";
    } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
      return "Effective";
    } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
      return "Somewhat Effective";
    } else if (avgRating && avgRating < 5) {
      return "Effectiveness Improvement Needed";
    }
  };

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };

  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={{
          padding: 20,
          fontFamily: "RobotoRegular",
          lineHeight: 1.2,
          fontSize: 9,
          color: "#334155",
          backgroundColor: "#f1f5f9",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          fixed
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: 160,
              zIndex: -1,
              opacity: 0.1,
            }}
          >
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -10,
              right: 0,
              height: 160,
              zIndex: -1,
              opacity: 0.075,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View style={{ position: "absolute", right: 30, top: 30 }}>
          <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
        </View>

        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 16,
              position: "relative",
              width: "100%",
            }}
          >
            <Image
              style={{
                width: 360,
                height: 120,
                objectFit: "contain",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              src={companyLogo}
            />
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoBold",
                  fontSize: 20,
                  textTransform: "capitalize",
                },
              ]}
            >
              Company Name: {user?.company_name}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text
              style={[
                {
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  textTransform: "uppercase",
                  color: "#14b8a6",
                  letterSpacing: 3,
                  textAlign: "center",
                },
              ]}
            >
              {t("board_strength_report")}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 20,
            gap: 10,
          }}
          wrap={false}
        >
          <View style={{ width: "33.33%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#0f172a",
                color: "#ffffff",
                overflow: "hidden",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <View
                style={[
                  {
                    fontSize: 12,
                    maxWidth: "100%",
                    padding: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  },
                ]}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoBold",
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  {t("overall_board_effectiveness_score")}
                </Text>
              </View>
            </View>
            <View
              style={{
                margin: "0 auto",
                width: "100%",
                border: "1px solid #e2e8f0",
                backgroundColor: "white",
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                overflow: "hidden",
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoBold",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalEffectivenessAvg}
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoRegular",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {overallScoreDescription(totalEffectivenessAvg)}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "33.33%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#0f172a",
                color: "#ffffff",
                overflow: "hidden",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoBold",
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  {t("average_years_of_board_service")}
                </Text>
              </View>
            </View>
            <View
              style={{
                margin: "0 auto",
                width: "100%",
                border: "1px solid #e2e8f0",
                backgroundColor: "white",
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                overflow: "hidden",
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoBold",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalAvgYearOfBoard}
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoRegular",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalAvgYearOfBoard > 1 ? "Years" : "Year"}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 16,
            overflow: "hidden",
            marginBottom: 20,
          }}
          wrap={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#0f172a",
              color: "#ffffff",
              overflow: "hidden",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              paddingHorizontal: 8,
            }}
          >
            <View
              style={[
                {
                  maxWidth: "100%",
                  paddingVertical: 12,
                  backgroundColor: "#0f172a",
                  color: "#ffffff",
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={{
                  width: "100%",
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  color: "#ffffff",
                }}
              >
                {t("strength_factors")}
              </Text>
            </View>
            <View
              style={[
                {
                  maxWidth: "100%",
                  paddingVertical: 12,
                  backgroundColor: "#0f172a",
                  color: "#ffffff",
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={{
                  width: "100%",
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  color: "#ffffff",
                }}
              >
                {t("combined_year_of_experience")}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              backgroundColor: "white",
            }}
          >
            {areaOfExpertise &&
              areaOfExpertise.length > 0 &&
              areaOfExpertise.map((item, index) => {
                let count = 0;
                let member = 0;
                let rating = 0;
                let score = "Limited";
                let primary = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.primary_expertise === item?._id).map((it) => it?.primary_experience);
                if (primary && primary.length > 0) {
                  count = count + primary.reduce((total, num) => total + num, 0);
                  member = member + primary?.length;
                }
                let secondary = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.secondary_expertise === item?._id).map((it) => it?.secondary_experience);
                if (secondary && secondary.length > 0) {
                  count = count + secondary.reduce((total, num) => total + num, 0);
                  member = member + secondary?.length;
                }
                rating = count && count > 0 ? Number(count) / Number(boardMambers.length) : 0;
                if (rating && rating < 2) {
                  score = "Limited";
                } else if (rating && rating >= 2 && rating <= 4.9) {
                  score = "Well Represented";
                } else if (rating && rating >= 5 && rating <= 9.9) {
                  score = "Strong";
                } else if (rating && rating >= 10) {
                  score = "Very Strong";
                }
                if (Number(count) > 0) {
                  return (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        borderTop: "1px solid #e2e8f0",
                      }}
                      key={index}
                    >
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 8,
                            paddingHorizontal: 8,
                            width: "50%",
                            columnGap: 4,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <Text style={[{ color: "#E11D48", marginRight: 2 }]}>*</Text>
                        <Text
                          style={[
                            {
                              fontFamily: "RobotoRegular",
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              whiteSpace: "normal",
                            },
                          ]}
                        >
                          {item?.name}
                        </Text>
                      </View>
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 8,
                            paddingHorizontal: 8,
                            width: "50%",
                            columnGap: 4,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontFamily: "RobotoRegular",
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              whiteSpace: "normal",
                            },
                          ]}
                        >
                          {count}
                        </Text>
                      </View>
                    </View>
                  );
                }
              })}
          </View>
        </View>

        <View style={{ position: "relative", marginBottom: 20 }} wrap={false}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              gap: 10,
            }}
          >
            <View style={{ width: "25%" }}>
              <View
                style={{
                  backgroundColor: "#0f172a",
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: "RobotoBold",
                    color: "#fff",
                  }}
                >
                  {t("board_education")}
                </Text>
              </View>
              <View
                style={{
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#e2e8f0",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  }}
                >
                  {t("field_of_study")}
                </Text>
              </View>
              <View
                style={{
                  margin: "0 auto",
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderTop: 0,
                  backgroundColor: "white",
                  borderBottomRightRadius: 16,
                  borderBottomLeftRadius: 16,
                  overflow: "hidden",
                  paddingBottom: 8,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {studies &&
                  studies.length > 0 &&
                  studies
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => {
                      let count = 0;
                      let studyCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.study === item?._id).map((it) => it?.study);
                      if (studyCount && studyCount.length > 0) {
                        count = count + studyCount?.length;
                      }
                      if (count > 0)
                        return (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid #e2e8f0",
                            }}
                            key={index}
                          >
                            <View
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "70%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoRegular",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                            </View>
                            <View
                              key={index}
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                  },
                                ]}
                              >
                                {count}
                              </Text>
                            </View>
                          </View>
                        );
                    })}
              </View>
            </View>

            <View style={{ width: "25%" }}>
              <View
                style={{
                  backgroundColor: "#0f172a",
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: "RobotoBold",
                    color: "#fff",
                  }}
                >
                  {t("board_education")}
                </Text>
              </View>
              <View
                style={{
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#e2e8f0",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  }}
                >
                  {t("level_of_education")}
                </Text>
              </View>
              <View
                style={{
                  margin: "0 auto",
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderTop: 0,
                  backgroundColor: "white",
                  borderBottomRightRadius: 16,
                  borderBottomLeftRadius: 16,
                  overflow: "hidden",
                  paddingBottom: 8,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {educations &&
                  educations.length > 0 &&
                  educations
                    ?.sort((a, b) => b.ordering - a.ordering)
                    ?.map((item, index) => {
                      let count = 0;
                      let educationCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.education === item?._id).map((it) => it?.education);
                      if (educationCount && educationCount.length > 0) {
                        count = count + educationCount?.length;
                      }
                      if (count > 0)
                        return (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid #e2e8f0",
                            }}
                            key={index}
                          >
                            <View
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "70%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoRegular",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                            </View>
                            <View
                              key={index}
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                  },
                                ]}
                              >
                                {count}
                              </Text>
                            </View>
                          </View>
                        );
                    })}
              </View>
            </View>

            <View style={{ width: "25%" }}>
              <View
                style={{
                  backgroundColor: "#0f172a",
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: "RobotoBold",
                    color: "#fff",
                  }}
                >
                  {t("board_retirement_profile")}
                </Text>
              </View>
              <View
                style={{
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#e2e8f0",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  }}
                >
                  {t("board_retirement_window")}
                </Text>
              </View>
              <View
                style={{
                  margin: "0 auto",
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderTop: 0,
                  backgroundColor: "white",
                  borderBottomRightRadius: 16,
                  borderBottomLeftRadius: 16,
                  overflow: "hidden",
                  paddingBottom: 8,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {retirementWindow &&
                  retirementWindow.length > 0 &&
                  retirementWindow?.map((item, index) => {
                    const countMap =
                      boardMambers &&
                      Array?.isArray(boardMambers) &&
                      boardMambers?.length > 0 &&
                      boardMambers?.reduce((acc, member) => {
                        const windowId = member?.retirement_window;
                        acc[windowId] = (acc[windowId] || 0) + 1;
                        return acc;
                      }, {});
                    const count = countMap[item._id] || 0;
                    if (countMap && count > 0) {
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            borderTop: "1px solid #e2e8f0",
                          }}
                          key={index}
                        >
                          <View
                            style={[
                              {
                                maxWidth: "100%",
                                paddingHorizontal: 8,
                                paddingVertical: 8,
                                width: "70%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoRegular",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                          </View>
                          <View
                            key={index}
                            style={[
                              {
                                maxWidth: "100%",
                                paddingHorizontal: 8,
                                paddingVertical: 8,
                                width: "30%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoMedium",
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign: "right",
                                },
                              ]}
                            >
                              {count}
                            </Text>
                          </View>
                        </View>
                      );
                    }
                  })}
              </View>
            </View>

            <View style={{ width: "25%" }}>
              <View
                style={{
                  backgroundColor: "#0f172a",
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    fontFamily: "RobotoBold",
                    color: "#fff",
                  }}
                >
                  {t("committee_assignment")}
                </Text>
              </View>
              <View
                style={{
                  paddingVertical: 6,
                  paddingHorizontal: 8,
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#e2e8f0",
                  flexShrink: 0,
                }}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  }}
                >
                  {t("board_committee_experience")}
                </Text>
              </View>
              <View
                style={{
                  margin: "0 auto",
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderTop: 0,
                  backgroundColor: "white",
                  borderBottomRightRadius: 16,
                  borderBottomLeftRadius: 16,
                  overflow: "hidden",
                  paddingBottom: 8,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                {positions &&
                  positions.length > 0 &&
                  positions
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item, index) => {
                      let count = 0;
                      let positionCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.position === item?._id).map((it) => it?.position);
                      let prevPositionCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position === item?._id).map((it) => it?.previous_position);
                      let prevPositionCount2 = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position_two === item?._id).map((it) => it?.previous_position_two);
                      let prevPositionCount3 = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position_three === item?._id).map((it) => it?.previous_position_three);
                      if (positionCount && positionCount.length > 0) {
                        count = count + positionCount?.length;
                      }
                      if (prevPositionCount && prevPositionCount.length > 0) {
                        count = count + prevPositionCount?.length;
                      }
                      if (prevPositionCount2 && prevPositionCount2.length > 0) {
                        count = count + prevPositionCount2?.length;
                      }
                      if (prevPositionCount3 && prevPositionCount3.length > 0) {
                        count = count + prevPositionCount3?.length;
                      }
                      if (count > 0) {
                        return (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              borderTop: "1px solid #e2e8f0",
                            }}
                            key={index}
                          >
                            <View
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "70%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoRegular",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                            </View>
                            <View
                              key={index}
                              style={[
                                {
                                  maxWidth: "100%",
                                  paddingHorizontal: 8,
                                  paddingVertical: 8,
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                  },
                                ]}
                              >
                                {count}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    })}
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 16,
            overflow: "hidden",
            marginBottom: 20,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#0f172a",
              color: "#ffffff",
              overflow: "hidden",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              paddingHorizontal: 8,
            }}
          >
            <View
              style={[
                {
                  maxWidth: "100%",
                  paddingVertical: 12,
                  backgroundColor: "#0f172a",
                  color: "#ffffff",
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={{
                  width: "100%",
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  color: "#ffffff",
                }}
              >
                {t("board_effectiveness")}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              paddingHorizontal: 12,
              paddingVertical: 12,
              backgroundColor: "white",
            }}
          >
            {reatingOptions &&
              reatingOptions.length > 0 &&
              reatingOptions.map((item, index) => {
                let ratingRowAnswers = item?.question?.answers;
                const ratedBoardMember = boardMambers.filter((it) => it?.ratingOptions).map((itm) => itm.ratingOptions.filter((row) => row.questionId === item?.question?._id));
                let totalSum = 0;
                let _comments = [];
                let _ans = [];
                if (ratedBoardMember && ratedBoardMember.length > 0) {
                  ratedBoardMember.forEach((obj) => {
                    obj.forEach((ob) => {
                      totalSum += ob?.rating ? ob?.rating : 0;
                      if (ob?.comments) {
                        _comments.push(ob?.comments);
                      }
                      if (ob?.ids && ob?.ids?.length > 0) {
                        _ans.push(ob?.ids);
                      }
                    });
                  });
                }
                if (ratingRowAnswers && ratingRowAnswers.length > 0) {
                  ratingRowAnswers.forEach((obj) => {
                    if (obj?.company_id === user?._id) {
                      _ans.push(obj?._id);
                    }
                  });
                }
                let totalBoardMember = ratedBoardMember?.length;
                let avgRating = Number(totalSum / totalBoardMember) > 0 ? Number(totalSum / totalBoardMember).toFixed(2) : 0;
                let _score = "";
                if (avgRating && avgRating >= 9 && avgRating <= 10) {
                  _score = "Highly Effective";
                } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                  _score = "Very Effective";
                } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                  _score = "Effective";
                } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                  _score = "Somewhat Effective";
                } else if (avgRating && avgRating < 5) {
                  _score = "Effectiveness Improvement Needed";
                }
                let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
                return (
                  <>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 16,
                      }}
                      key={index}
                    >
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "75%",
                            columnGap: 4,
                            flexDirection: "row",
                          },
                        ]}
                      >
                        <View style={{ width: 16, flexShrink: 0 }}>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                lineHeight: 1,
                                whiteSpace: "nowrap",
                                color: "#334155",
                                textAlign: "right",
                                fontFamily: "RobotoMedium",
                              },
                            ]}
                          >
                            {Number(index + 1) + "."}
                          </Text>
                        </View>
                        <View style={{ flexShrink: 1, flexGrow: 1, paddingRight: 16 }}>
                          <Text
                            style={[
                              {
                                fontFamily: "RobotoRegular",
                                fontSize: 10,
                                lineHeight: 1,
                                color: "#334155",
                                whiteSpace: "normal",
                              },
                            ]}
                          >
                            {item?.question?.name}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "8%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              textAlign: "center",
                              fontFamily: "RobotoBold",
                            },
                          ]}
                        >
                          {avgRating}
                        </Text>
                      </View>
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "17%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              width: "100%",
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              fontFamily: "RobotoRegular",
                            },
                          ]}
                        >
                          {_score}
                        </Text>
                      </View>
                    </View>
                    {_uniqueArrayWithCount && _uniqueArrayWithCount.length > 0 && (
                      <View className="relative">
                        {_uniqueArrayWithCount.map((an, i) => {
                          let rowData = ratingRowAnswers.filter((row) => row._id === an.id);
                          if (rowData && rowData.length > 0) {
                            return (
                              <View
                                style={{
                                  paddingRight: 16,
                                  paddingLeft: 20,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: 4,
                                }}
                              >
                                <View
                                  style={{
                                    backgroundColor: "#000000",
                                    width: 4,
                                    height: 4,
                                    borderRadius: 9999,
                                    overflow: "hidden",
                                    flexShrink: 0,
                                  }}
                                ></View>
                                <View
                                  style={{
                                    flexShrink: 1,
                                    flexGrow: 1,
                                    marginHorizontal: 8,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      color: "#334155",
                                    }}
                                  >
                                    {rowData[0]?.description}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    marginLeft: "auto",
                                    flexShrink: 0,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      color: "#334155",
                                      fontFamily: "RobotoMedium",
                                    }}
                                  >
                                    {an.count}
                                  </Text>
                                </View>
                              </View>
                            );
                          }
                        })}
                      </View>
                    )}
                    {_comments?.length > 0 && (
                      <View
                        style={{
                          paddingHorizontal: 12,
                          paddingVertical: 8,
                          width: "100%",
                          borderTopWidth: 1,
                          borderColor: "#e2e8f0",
                        }}
                      >
                        <Text
                          style={{
                            color: "#000",
                            fontSize: 10,
                            textTransform: "capitalize",
                            fontFamily: "RobotoBold",
                            marginBottom: 5,
                          }}
                        >
                          comments
                        </Text>
                        {_comments?.map((itm, idx) => (
                          <Text
                            style={{
                              fontSize: 10,
                              color: "#334155",
                              paddingHorizontal: 8,
                              textTransform: "capitalize",
                              paddingVertical: 8,
                              fontFamily: "RobotoRegular",
                            }}
                            key={idx}
                          >
                            {itm}
                          </Text>
                        ))}
                      </View>
                    )}
                  </>
                );
              })}
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 20,
            gap: 10,
          }}
          wrap={false}
        >
          <View style={{ width: "33.33%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#0f172a",
                color: "#ffffff",
                overflow: "hidden",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <View
                style={[
                  {
                    fontSize: 12,
                    maxWidth: "100%",
                    padding: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  },
                ]}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoBold",
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  {t("overall_board_effectiveness_score")}
                </Text>
              </View>
            </View>
            <View
              style={{
                margin: "0 auto",
                width: "100%",
                border: "1px solid #e2e8f0",
                backgroundColor: "white",
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                overflow: "hidden",
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoBold",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalEffectivenessAvg}
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoRegular",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {overallScoreDescription(totalEffectivenessAvg)}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "33.33%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#0f172a",
                color: "#ffffff",
                overflow: "hidden",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    width: "100%",
                    fontFamily: "RobotoBold",
                    fontSize: 11,
                    textAlign: "center",
                  }}
                >
                  {t("average_years_of_board_service")}
                </Text>
              </View>
            </View>
            <View
              style={{
                margin: "0 auto",
                width: "100%",
                border: "1px solid #e2e8f0",
                backgroundColor: "white",
                borderBottomRightRadius: 16,
                borderBottomLeftRadius: 16,
                overflow: "hidden",
              }}
            >
              <View
                style={[
                  {
                    maxWidth: "100%",
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoBold",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalAvgYearOfBoard}
                </Text>
                <Text
                  style={[
                    {
                      textAlign: "center",
                      fontFamily: "RobotoRegular",
                      fontSize: 10,
                      lineHeight: 1,
                      whiteSpace: "nowrap",
                    },
                  ]}
                >
                  {totalAvgYearOfBoard > 1 ? "Years" : "Year"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </>
  );
};
export default BoardStrengthReport;
