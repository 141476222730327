import React, { useCallback, useEffect, useState } from "react";
import Select from "../form/Select";
import { postData } from "../../services/api";
import { useSettingsModal } from "../../Context/SettingsContext";
import { useTranslation } from "react-i18next";
import { PositionTier } from "../../constant/Constant";

const TierSelectDropdown = ({
  selectedTier,
  setSelectedTier = () => {},
  isLable = true,
  className,
  isDefaultTier = false,
  isSelect = true,
}) => {
  const { i18n, t } = useTranslation();
  const [tierList, setTierList] = useState("");
  const [loading, setLoading] = useState(false);
  const { tierType, setTierType } = useSettingsModal();

  const loadList = useCallback(async () => {
    if (tierType === PositionTier) {
      try {
        const result = await postData("filter-item/list", {
          limit: 999999,
          type: "position-group",
        });
        if (result.status === 200) {
          setTierList(result.data);
          if (isDefaultTier) {
            const tierData =
              result?.data &&
              Array?.isArray(result.data) &&
              result.data.length > 0 &&
              result.data[0]?._id;
            setSelectedTier(tierData);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    }
  }, [tierType]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <div>
      {tierType === PositionTier && (
        <div className={`relative w-60 ${className}`}>
          <Select
            xPlacement={"left"}
            label={isLable ? t("select_tier") : ""}
            selectedValue={selectedTier}
            dropdownClass={"!w-full"}
            dropdownData={[
              ...(isSelect
                ? [
                    {
                      _id: "",
                      label: t("select_tier"),
                      value: "",
                    },
                  ]
                : []),
              ...(Array.isArray(tierList)
                ? tierList.map((item) => ({
                    _id: item?._id || "",
                    label: item?.name || "",
                    value: item?._id || "",
                  }))
                : []),
            ]}
            getSelectedValue={(e) => {
              setSelectedTier(e._id);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TierSelectDropdown;
