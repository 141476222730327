import React, { useEffect, useState, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import toastr from "toastr";

import LogoutPopup from "../components/LogoutPopup";
import NoDataFound from "../components/NoDataFound";
import Searching from "../components/Searching";
import { postData } from "../services/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authLogout } from "../store/authSlice";
import TermsPopup from "../components/popup/TermsPopup";
import ProfilePopup from "../components/popup/ProfilePopup";

const BuyPlan = ({}) => {
  setTitle("Succession Now | Plans");
  const { i18n, t } = useTranslation();
  const isLogin = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [planId, setPlanId] = useState("");
  const [isPay, setIsPay] = useState(false);
  const [enabledLogoutPopup, setenabledLogoutPopup] = useState(false);
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const loginAs = localStorage.getItem("loginAs");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabledTermsPopup, setEnabledTermsPopup] = useState(false);
  const [enabledProfilePopup, setEnabledProfilePopup] = useState(false);
  const [profileData, setProfileData] = useState({});
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g, "");

  useEffect(() => {
    async function promoUser() {
      setLoading(true);
      try {
        const result = await postData("check-promo-user", {
          sortQuery: { created_at: -1 },
        });
        if (result.data) {
          setPromoCode(result.data.promo_code);
          setPlanId(result.data.plan_id);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    promoUser();
  }, []);

  const onPay = async () => {
    if (isLogin) {
      setIsPay(true);
      try {
        const paymentData = await postData("stripe/plan-create", {
          promoCode: promoCode,
          planId: planId,
        });
        if (paymentData.status && paymentData.status === 200) {
          setIsPay(false);
          window.location.href = paymentData?.data?.redirect_url;
          //return navigate("/dashboard");
        } else {
          toastr.error(paymentData.message);
          setIsPay(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsPay(false);
      }
    } else {
      return navigate("/signin");
    }
  };

  const onCouponPay = async () => {
    if (isLogin) {
      setIsPay(true);
      try {
        const results = await postData("apply-coupon", {
          promo_code: couponCode,
        });
        if (results.data) {
          const paymentData = await postData("stripe/plan-create", {
            promoCode: couponCode,
          });
          if (paymentData.status && paymentData.status === 200) {
            setIsPay(false);
            window.location.href = paymentData?.data?.redirect_url;
          } else {
            toastr.error(paymentData.message);
            setIsPay(false);
          }
        } else {
          toastr.error(results.message);
          setIsPay(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsPay(false);
      }
    } else {
      return navigate("/signin");
    }
  };

  useEffect(() => {
    if (role && (role === "master-admin" || role === "basic-administrator")) {
      return navigate("/dashboard-statistics");
    }
  }, [role]);

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      role !== "master-admin" &&
      role !== "basic-administrator" &&
      (!loginAs || user?.isInvitedEmployee === 2)
    ) {
      dispatch(async () => {
        try {
          const results = await postData("subscription/check", {
            sortQuery: { created_at: -1 },
            status: 3,
          });
          if (results.data && !results.data.trans_details) {
            if (
              role === "customer-admin" &&
              results.data.payment_status &&
              results.data.payment_status === 3
            ) {
              localStorage.setItem("isTrial", 1);
            } else {
              localStorage.removeItem("isTrial");
              const results2 = await postData("check-promo-user", {
                sortQuery: { created_at: -1 },
              });
              if (
                results2.data &&
                results2.data.promo_code &&
                results2.data.isPaid === 1
              ) {
                try {
                  const results3 = await postData("check-promocode", {
                    promo_code: results2.data.promo_code,
                  });
                  if (results3.status && results3.status === 200) {
                    return navigate("/dashboard");
                  } else {
                    return navigate("/buy-plan");
                    //return navigate("/plans");
                  }
                } catch (error) {
                  console.log(error.message);
                }
              } else {
                //return navigate("/plans");
                return navigate("/buy-plan");
              }
            }
          } else if (results.data && results.data.trans_details) {
            if (
              results.data.trans_details.status &&
              results.data.trans_details.status === 1
            ) {
              navigate("/dashboard");
            }
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);

  return (
    <>
      <section className="relative flex items-center w-full min-h-screen bg-slate-50 py-20 overflow-hidden">
        <div className="w-full max-w-7xl mx-auto">
          <Link to={"/"}>
            <div className="h-12 w-full flex justify-center object-contain mb-5">
              <svg
                className="h-full"
                preserveAspectRatio="xMidYMid meet"
                data-bbox="0 0 382.89 71.37"
                viewBox="0 0 382.89 71.37"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="img"
                aria-labelledby="svgcid-iz595q-x7t6i4"
              >
                <title id="svgcid-iz595q-x7t6i4">SuccessionNow Logo</title>
                <g>
                  <path
                    d="m21.02 40.95-6.88-2.98c-3.78-1.61-4.59-3.29-4.59-6.01s1.05-5.15 5.08-5.15c3.41 0 4.84 2.42 4.84 6.57h8.56v-1.24c0-8.49-5.77-11.9-13.7-11.9-8.44 0-13.71 4.4-13.71 13.02 0 7.38 3.66 10.66 11.59 13.39 5.89 2.05 7.94 3.53 7.94 7.56 0 3.72-2.6 5.46-5.33 5.46-4.71 0-5.89-2.67-5.89-6.82v-1.3H0v1.8c0 8.18 3.97 12.9 14.07 12.9s15.38-4.22 15.38-13.58c0-5.39-1.74-8.8-8.43-11.72Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M49.45 54.15c0 3.72-1.61 5.52-4.53 5.52-2.54 0-3.66-1.3-3.66-4.65v-23H32.7v24.74c0 6.57 3.16 9.49 8.49 9.49 3.6 0 6.7-1.49 8.49-4.77h.12v3.91h8.18V32.02h-8.56v22.13Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M75.18 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M103.19 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M131.01 31.15c-11.53 0-13.27 8.31-13.27 17.24 0 8.31.5 17.85 12.9 17.85 8.06 0 11.78-4.34 12.09-12.09h-7.69c-.5 4.09-1.67 6.26-4.53 6.26-3.16 0-4.22-3.84-4.22-7.75v-2.67h16.92v-1.92c0-10.23-2.23-16.93-12.21-16.93Zm-4.71 13.76v-1.3c0-3.1.99-6.63 4.4-6.63s4.15 2.85 4.34 7.94h-8.74Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="m162.72 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="m189.75 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    fill="#3a8078"
                    d="M209.32 20.61v7.32h-8.56v-7.32h8.56z"
                    data-color="1"
                  ></path>
                  <path
                    fill="#3a8078"
                    d="M209.32 32.02v33.35h-8.56V32.02h8.56z"
                    data-color="1"
                  ></path>
                  <path
                    d="M226.36 31.15c-9.8 0-13.14 6.14-13.14 17.54s2.85 17.73 13.14 17.55c9.8 0 13.14-6.14 13.14-17.55s-2.85-17.73-13.14-17.54Zm0 29.26c-4.22 0-4.59-4.9-4.59-11.72s.37-11.72 4.59-11.72c3.47 0 4.59 3.41 4.59 11.72s-1.12 11.72-4.59 11.72Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M260 31.15c-3.6 0-6.7 1.49-8.49 4.77h-.12v-3.91h-8.18v33.35h8.56V43.23c0-3.72 1.61-5.52 4.53-5.52 2.54 0 3.66 1.3 3.66 4.65v23h8.56V40.62c0-6.57-3.16-9.49-8.49-9.49Z"
                    fill="#3a8078"
                    data-color="1"
                  ></path>
                  <path
                    d="M325.74 31.15c-9.8 0-14.45 6.14-16.86 17.54-2.48 11.59-.93 17.73 9.42 17.55 9.79 0 14.45-6.14 16.86-17.55 2.48-11.59.93-17.73-9.42-17.54Zm.87 17.54c-1.74 8.31-3.6 11.72-7.07 11.72-4.21 0-3.53-4.9-2.11-11.72 1.49-6.82 2.85-11.72 7.07-11.72 3.47 0 3.91 3.41 2.11 11.72Z"
                    fill="#a7a9ac"
                    data-color="2"
                  ></path>
                  <path
                    fill="#a7a9ac"
                    d="m374.7 32.02-9.05 23.68h-.12l.62-23.68h-9.3l-9.18 23.68h-.12l1.11-23.68h-8.55l.18 33.35h9.92l9.18-23.68h.12l-.62 23.68h9.74l14.26-33.35h-8.19z"
                    data-color="2"
                  ></path>
                  <path
                    fill="#a7a9ac"
                    d="M321.29 12.82 313.02 0 300.2 8.27l6.29 1.36-11.16 41.79h-.13l-5.34-23.31h-10.29l-9.42 43.26h8.18l6.57-29.99h.13l5.34 23.99h10.16l14.2-53.96 6.56 1.41z"
                    data-color="2"
                  ></path>
                </g>
              </svg>
            </div>
          </Link>
          <div className="text-center mb-10 space-y-4">
            <div className="text-3xl font-bold text-dark-teal-500">
              {t("youare_almost_ready_to_start_planning")}
            </div>
            <div className="text-3xl font-bold text-dark-teal-500">
              {t("welcome_to_succession_now")}
            </div>
          </div>
          <>
            {isLogin ? (
              <div className="flex flex-wrap justify-center -mx-5">
                <div className="w-full flex max-w-[380px] p-5">
                  {/* hover:ring-2 ring-dark-teal-400 transition-all duration-200 group */}
                  <div
                    className={
                      "w-full h-full flex flex-col rounded overflow-hidden relative z-10 " +
                      (promoCode || planId ? "" : "bg-white p-5 shadow")
                    }
                  >
                    {
                      promoCode || planId ? (
                        <div className="relative">
                          <Button
                            buttonLabelClasses={"font-semibold text-sm"}
                            buttonClasses={
                              "w-full h-12 rounded-md group-hover:!bg-dark-teal-400"
                            }
                            buttonType={"button"}
                            buttonIcon={
                              isPay
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-light fa-credit-card-front"
                            }
                            buttonIconPosition={"left"}
                            buttonLabel={t("pay_now")}
                            functions={() => onPay()}
                            buttonHasLink={false}
                            buttonDisabled={isPay}
                          />
                        </div>
                      ) : (
                        <div className="bg-gray-100 ">
                          <div className="bg-white p-6 mx-auto md:mx-auto">
                            <div className="text-teal-600 w-16 h-16 mx-auto my-6 text-6xl flex items-center justify-center">
                              <i className="fa-solid fa-info-circle"></i>
                            </div>
                            <div className="text-center">
                              <h3 className="md:text-xl text-base text-gray-900 font-semibold text-center">
                                Account payment status is pending.
                              </h3>
                            </div>
                          </div>
                        </div>
                      )
                      // <>
                      // <div className="relative">
                      //   <div className="text-sm text-slate-400 text-center font-light">
                      //     If Company provided any coupon. You can apply and pay it
                      //   </div>
                      // </div>
                      // <div className="pt-4 space-y-4">
                      //   <div className="relative">
                      //     <Input
                      //       label={"Activation Code"}
                      //       labelClasses={"!text-xs"}
                      //       inputType={"text"}
                      //       inputPlaceholder={"Enter Activation Code if Available"}
                      //       inputValue={couponCode}
                      //       setInput={setCouponCode}
                      //     />
                      //   </div>
                      //   <div className="relative flex justify-center">
                      //     <Button
                      //       buttonClasses=""
                      //       buttonLabelClasses=""
                      //       buttonType={"button"}
                      //       buttonIcon={(isPay)? "fa-light fa-spinner fa-spin":"fa-light fa-arrow-right-to-arc"}
                      //       buttonIconPosition={"left"}
                      //       buttonLabel={"Pay Now"}
                      //       functions={() => onCouponPay()}
                      //       buttonHasLink={false}
                      //       buttonDisabled={isPay}
                      //     />
                      //   </div>
                      // </div>
                      // </>
                    }
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {
              isLogin ? (
                <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
                  {/* Take me to */}
                  {/* <Button 
                  buttonClasses={'!bg-transparent !text-dark-teal-400 !h-auto !px-0'} 
                  buttonType={''}
                  buttonHasLink={true}
                  buttonLabel={'My Account'} 
                  buttonLabelClasses={'!font-semibold'}
                  buttonLink={"/profiles"}
                /> */}
                  {/* or */}
                  <Button
                    buttonClasses={
                      "!bg-transparent !text-dark-teal-400 !h-auto !px-0"
                    }
                    buttonType={""}
                    buttonHasLink={false}
                    buttonLabel={t("logout")}
                    buttonLabelClasses={"!font-semibold"}
                    functions={() => setenabledLogoutPopup(true)}
                  />
                </div>
              ) : null
              // <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
              //   Already have an account?
              //   <Button
              //     buttonClasses={'!bg-transparent !text-dark-teal-400 !h-auto !px-0'}
              //     buttonType={''}
              //     buttonHasLink={true}
              //     buttonLabel={'Login'}
              //     buttonLabelClasses={'!font-semibold'}
              //     buttonLink={"/signin"}
              //   />
              // </div>
            }
          </>
        </div>
        {enabledLogoutPopup && (
          <LogoutPopup
            isOpen={enabledLogoutPopup}
            setIsOpen={(val) => setenabledLogoutPopup(val)}
          />
        )}
      </section>
    </>
  );
};
export default BuyPlan;
