import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ExecutiveStrengthReport from "./pdf/ExecutiveStrengthReport";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";

const ExecutiveStrength = () => {
  setTitle("Succession Now | Executive Strength");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [executives, setExecutives] = useState([]);

  const [comanyYear, setCompanyYear] = useState([]);
  const [retirementWindow,setRetirementWindow] = useState([]);

  const [reatingOptions, setReatingOptions] = useState([]);
  const [totalEffectivenessAvg, setTotalEffectivenessAvg] = useState(0);

  useEffect(() => {
    if ((role && role !== "customer-admin") || user?.plan_alias === "professional-plan") {
      return navigate("/dashboard");
    }
  }, [role]);

  const heading = [
    {
      name: t("management"),
      option: "",
    },
    {
      name: t("human_resources"),
      option: "",
    },
    {
      name: t("finance_banking_credit_union_finance_companies"),
      option: "",
    },
    {
      name: t("it"),
      option: "",
    },
    {
      name: t("legal"),
      option: "",
    },
    {
      name: t("accounting"),
      option: "",
    },
    {
      name: t("marketing"),
      option: "",
    },
    {
      name: t("complance_and_regulation"),
      option: "",
    },
    {
      name: t("prior_board_experience"),
      option: "",
    },
  ];

  useEffect(() => {
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("executive/list", {
          sortQuery: { created_at: 1 },
          outside_candidate: 2,
        });
        if (result.data) {
          setExecutives(result.data);
          const groupedCounts = result.data.reduce((acc, item) => {
              const key = item.board_year ?? "null";
              acc[key] = (acc[key] || 0) + 1;
              return acc;
          }, {});
        
          // Convert to array format if needed
          const boardResult = Object.entries(groupedCounts).map(([board_year, count]) => ({
              board_year: board_year === "null" ? null : Number(board_year),
              count
          }));
          setCompanyYear(boardResult);
          const _groupedCounts = result.data.reduce((acc, item) => {
              const key = item.retirement_window ?? "null";
              acc[key] = (acc[key] || 0) + 1;
              return acc;
          }, {});
        
          // Convert to array format if needed
          const retirementResult = Object.entries(_groupedCounts).map(([retirement_window, count]) => ({
              retirement_window: retirement_window === "null" ? null : Number(retirement_window),
              count
          }));
          setRetirementWindow(retirementResult)
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();

    async function employeeRatingOptionList() {
      setLoading2(true);
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "executive",
        });
        if (result.data) {
          setReatingOptions(result.data);
          setLoading2(false);
        } else {
          console.log("Employee rating message", result.message);
          setLoading2(false);
        }
      } catch (error) {
        console.log("Error employee rating catch", error.message);
        setLoading2(false);
      }
    }
    employeeRatingOptionList();
  }, []);

  useEffect(() => {
    if (reatingOptions && reatingOptions.length > 0) {
      let checkRatedCount = reatingOptions.filter((it) => it?.question?.fieldType !== "checkbox");
      const totalQuestion = Number(checkRatedCount?.length);
      let totalAvg = 0;
      reatingOptions.map((item, index) => {
        const ratedExecutives = executives.filter((it) => it?.executiveAudits).map((itm) => itm.executiveAudits.filter((row) => row.questionId === item?.question?._id));
        let totalSum = 0;
        if (ratedExecutives && ratedExecutives.length > 0) {
          ratedExecutives.forEach((obj) => {
            obj.forEach((ob) => {
              totalSum += ob?.rating ? ob?.rating : 0;
            });
          });
        }
        let totalExecutives = ratedExecutives?.length;
        let avgRating = Number(totalSum / totalExecutives).toFixed(2);
        totalAvg += Number(avgRating ? avgRating : 0);
      });
      let avg = Number(totalAvg / totalQuestion) ? Number(totalAvg / totalQuestion).toFixed(2) : 0;
      setTotalEffectivenessAvg(avg);
    }
  }, [reatingOptions, executives]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const overallScoreDescription = (avgRating) => {
    if (avgRating && avgRating >= 9 && avgRating <= 10) {
      return "Highly Effective";
    } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
      return "Very Effective";
    } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
      return "Effective";
    } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
      return "Somewhat Effective";
    } else if (avgRating && avgRating < 5) {
      return "Effectiveness Improvement Needed";
    }
  };

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };  


  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("executive_strength_report") + " - " + user?.company_name}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <ExecutiveStrengthReport executives={executives} reatingOptions={reatingOptions} totalEffectivenessAvg={totalEffectivenessAvg} comanyYear={comanyYear} retirementWindow={retirementWindow} user={user} companyLogo={API_URL + user?.image}/>
                }
                fileName="profile_report.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_strength_report"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>

      <div className="w-full max-w-xs bg-white rounded overflow-hidden shadow mt-3 m-auto">
        <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
          <div className="text-white font-bold text-sm leading-none text-center">{t("overall_executive_audit_score")}</div>
        </div>
        <div className="w-full relative">
          <div className={"w-full flex"}>
            <div className={"py-3 px-2 text-left w-[100%]"}>
              <div className="block">
                <div className="text-sm font-bold text-slate-600 text-center">{totalEffectivenessAvg}</div>
                <div className="text-sm font-medium text-slate-500 text-center">{overallScoreDescription(totalEffectivenessAvg)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-5">
        <div className="grid grid-cols-2 gap-2">
          <div className="flex flex-col">
            {/* <div className="text-lg text-slate-800 font-bold flex-shrink-0">{t("board_education")}</div> */}
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%]  z-10 bg-slate-900 rounded-tl-md">{t("years_with_the_company")}</th>
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tr-md">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {comanyYear && comanyYear.length > 0 ? (
                      comanyYear.map((item, index) => {
                          if (item?.count > 0 && item?.board_year !== null) {
                            return (
                              <tr>
                                <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{(item?.board_year > 1)?item?.board_year+' Years':item?.board_year+' Year'}</div>
                                  </div>
                                </td>
                                <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{item?.count}</div>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>          
          <div className="flex flex-col">
            {/* <div className="text-lg text-slate-800 font-bold flex-shrink-0">{t("board_retirement_profile")}</div> */}
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tl-md">{t("retirement_window")}</th>
                      <th className="py-4 px-2 text-right w-full max-w-[50%] z-10 bg-slate-900 rounded-tr-md">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {retirementWindow && retirementWindow.length > 0 ? (
                      retirementWindow.map((item, index) => {                        
                        if (item?.count > 0 && item?.retirement_window !== null) {
                          return (
                            <tr>
                              <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{(item?.retirement_window > 1)?item?.retirement_window+' Years':item?.retirement_window+' Year'}</div>
                                </div>
                              </td>
                              <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{item?.count}</div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>          
        </div>
      </div>

      <div className="w-full bg-slate-50 rounded overflow-hidden shadow mt-3">
        <div className="w-full px-2 py-4 bg-black rounded-t-md sticky">
          <div className="text-white font-bold text-sm leading-none text-center">{t("executive_audit_360")}</div>
        </div>
        <div className="w-full relative divide-y divide-slate-200">
          {reatingOptions && reatingOptions.length > 0 ? (
            reatingOptions.map((item, index) => {
              let ratingRowAnswers = item?.question?.answers;
              const ratedExecutive = executives.filter((it) => it?.executiveAudits).map((itm) => itm.executiveAudits.filter((row) => row.questionId === item?.question?._id));
              let totalSum = 0;
              let _comments = [];
              let _ans = [];
              if (ratedExecutive && ratedExecutive.length > 0) {
                ratedExecutive.forEach((obj) => {
                  obj.forEach((ob) => {
                    totalSum += ob?.rating ? ob?.rating : 0;
                    if (ob?.comments) {
                      _comments.push(ob?.comments);
                    }
                    if (ob?.ids && ob?.ids?.length > 0) {
                      _ans.push(ob?.ids);
                    }
                  });
                });
              }
              let totalExecutive = ratedExecutive?.length;
              let avgRating = Number(totalSum / totalExecutive) > 0 ? Number(totalSum / totalExecutive).toFixed(2) : 0;
              let _score = "";
              if (avgRating && avgRating >= 9 && avgRating <= 10) {
                _score = "Highly Effective";
              } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                _score = "Very Effective";
              } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                _score = "Effective";
              } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                _score = "Somewhat Effective";
              } else if (avgRating && avgRating < 5) {
                _score = "Effectiveness Improvement Needed";
              }
              let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
              return (
                <>
                  <div className="w-full px-3 py-3 bg-white">
                    <div className="flex w-full">
                      <div className={"py-3 px-2 text-left w-[60%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">
                            {Number(index + 1)}.{" " + item?.question?.name}
                          </div>
                          <div className="text-xs text-slate-400">{item?.option}</div>
                        </div>
                      </div>
                      <div className={"py-3 px-2 text-left w-[10%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">{avgRating > 0 ? avgRating : ""}</div>
                        </div>
                      </div>
                      <div className={"py-3 px-2 text-left w-[30%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">{_score}</div>
                        </div>
                      </div>
                    </div>
                    {_uniqueArrayWithCount && _uniqueArrayWithCount.length > 0 && (
                      <div className="relative">
                        {_uniqueArrayWithCount.map((an, i) => {
                          let rowData = ratingRowAnswers.filter((row) => row._id === an.id);
                          if (rowData) {
                            return (
                              <div className="px-4 flex items-center gap-2">
                                <div className="w-2 h-2 rounded-full overflow-hidden bg-slate-600"></div>
                                <div className="text-slate-600 text-sm">{rowData[0]?.description}</div>
                                <div className="text-slate-600 text-sm font-semibold ml-auto">{an.count}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                    {_comments.length > 0 && (
                      <div className="py-3 px-2 w-full ">
                        <div className="flex items-center gap-1 text-slate-700 mb-2">
                          <i className="fa-solid fa-comment mr-1"></i>
                          <div className="text-sm capitalize">comments</div>
                        </div>
                        {_comments.map((itm, idx) => (
                          <div key={idx} className="text-xs text-slate-500 whitespace-pre-line py-1 capitalize px-2 font-semibold">
                            {itm}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <div className="border-b border-slate-200">
              <div className="py-3 px-2 text-left">{loading2 ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}</div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full max-w-xs bg-white rounded overflow-hidden shadow mt-3 m-auto">
        <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
          <div className="text-white font-bold text-sm leading-none text-center">{t("overall_executive_audit_score")}</div>
        </div>
        <div className="w-full relative">
          <div className={"w-full flex"}>
            <div className={"py-3 px-2 text-left w-[100%]"}>
              <div className="block">
                <div className="text-sm font-bold text-slate-600 text-center">{totalEffectivenessAvg}</div>
                <div className="text-sm font-medium text-slate-500 text-center">{overallScoreDescription(totalEffectivenessAvg)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecutiveStrength;
