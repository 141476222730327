
import React from "react";

const Video = () => {
  return (
    <div className="relative z-10 w-full max-w-[1095px] mx-auto px-4 md:px-0 -mt-44 md:-mt-[232px]">
      <div className="relative w-full h-0 pb-[59.5%] md:pb-[652.0493774414062px] mt-[560.58px] md:mt-0">
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-[14.88px] border-8"
          src="https://www.youtube.com/embed/RTQt3OEBF_0?enablejsapi=1&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </div>
    </div>
  );
};

export default Video;

