import React, { useCallback, useEffect, useState } from "react";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import Button from "../components/form/Button";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import CompanySNReport from "./pdf/CompanySNReport";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";
import NoDataFound from "../components/NoDataFound";
import TableSkeleton from "../components/loader/TableSkeleton";
import DropdownCheckbox from "../components/form/DropdownCheckbox";

const CompanySNSummary = () => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  const user = useSelector((state) => state.auth?.user);
  const [positions, setPositions] = useState([]);

  //   Summary List Api Call
  const loadList = useCallback(async (SelectedPosition = []) => {
    setLoading(true);
    try {
      const result = await postData("/highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        // isReport: true,
        // is_interim: 2,
      });

      if (result.data) {
        const sortedData = result.data.sort((a, b) => {
          if (a.position_code === "ceo" && b.position_code !== "ceo") return -1;
          if (b.position_code === "ceo" && a.position_code !== "ceo") return 1;
          return a.position.localeCompare(b.position);
        });
        setList(sortedData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: true,
              };
            })
          );
          //setPositions(result.data);
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  useEffect(() => {
    let SelectedPosition = [];

    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0) {
      loadList(SelectedPosition);
    }
  }, [positions]);

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 gap-4">
        <div className="text-2xl font-bold">
          {t("company_succession_summary")}
        </div>
        <div
          className="sm:ml-auto flex justify-end items-center"
          data-tooltip-id={`buttonReport${19644}`}
          data-tooltip-place="bottom"
        >
          <Button
            buttonType={"button"}
            isCustomLabel={true}
            customData={
              <>
                <i className={"fa-fw fa-solid fa-file-pdf"}></i>
                <div
                  className={"text-xs sm:text-sm font-medium whitespace-nowrap"}
                >
                  <PDFDownloadLink
                    document={
                      <Document>
                        <CompanySNReport
                          user={user}
                          companyLogo={API_URL + user?.image}
                          list={list}
                        />
                      </Document>
                    }
                    fileName="company_succession_summary_report.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? t("Loading Report") : "Download Report"
                    }
                  </PDFDownloadLink>
                </div>
              </>
            }
            buttonLabel={"Download Report"}
            buttonClasses={" !w-full"}
            buttonHasLink={false}
            functions={(e) => getDownload(e)}
          />
          <Tooltip
            id={`buttonReport${19644}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to Download Company Succession Summary Report
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
      </div>
      <div className="overflow-x-auto mt-4">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-slate-900 text-slate-100 border border-slate-400 text-[13px]">
              <th className="border border-slate-400 p-2">Position</th>
              <th className="border border-slate-400 p-2">
                Current Position Holder
              </th>
              <th className="border border-slate-400 p-2">Candidates</th>
              <th className="border border-slate-400 p-2">
                Readiness Timeframe
              </th>
              <th className="border border-slate-400 p-2 font-bold">
                Plan Selections
              </th>
              <th className="border border-slate-400 p-2">0-12 Month Plan</th>
              <th className="border border-slate-400 p-2">1-3 Year Plan</th>
              <th className="border border-slate-400 p-2">3-5 Year Plan</th>
              <th className="bg-red-700 border border-slate-400 p-2">
                Emergency or Interim Plan
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading && list && Array?.isArray(list) && list?.length > 0 ? (
              list.map((position, index) => {
                const successors = position?.successors_details || [];
                const conclusions =
                  position?.conclusion?.filter((c) => !c?.isComment) || [];
                const totalRows = Math.max(
                  successors.length,
                  conclusions.length,
                  4
                );
                const selectionLabels = [
                  "Selection 1",
                  "Selection 2",
                  "Selection 3",
                  "Selection 4",
                ];

                return (
                  <React.Fragment key={index}>
                    {Array.from({ length: totalRows }).map((_, rowIdx) => {
                      const candidate = successors[rowIdx] || {};
                      const conclusion = conclusions[rowIdx] || {};

                      return (
                        <tr
                          key={`row-${index}-${rowIdx}`}
                          className="border border-slate-400 text-[13px]"
                        >
                          {rowIdx === 0 && (
                            <>
                              <td
                                className="border border-slate-400 p-2 font-bold"
                                rowSpan={totalRows}
                              >
                                <div className="text-center flex justify-center items-center">
                                  {position?.position || "-"}
                                </div>
                              </td>
                              <td
                                className="border border-slate-400 p-2 text-teal-600 font-bold"
                                rowSpan={totalRows}
                              >
                                <div className="text-center flex justify-center items-center">
                                  {position?.name || "-"}
                                </div>
                              </td>
                            </>
                          )}
                          <td className="border border-slate-400 p-2">
                            {candidate?.name || "-"}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {candidate["timeframe_details"] &&
                            candidate["timeframe_details"].length > 0
                              ? candidate["timeframe_details"]
                                  .map((item) => item?.name || "-")
                                  .join(", ")
                              : "-"}
                          </td>
                          <td className="border border-slate-400 font-bold p-2 text-center text-pink-600">
                            {selectionLabels[rowIdx] || "-"}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {conclusion?.time_horizon_zero_to_one_text || "-"}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {conclusion?.time_horizon_one_to_three_text || "-"}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {conclusion?.time_horizon_three_to_five_text || "-"}
                          </td>
                          <td className="border border-slate-400 p-2">
                            {conclusion?.replacement_text || "-"}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="h-2 bg-teal-300">
                      <td colSpan={9}></td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <tr className="border-b border-slate-200 w-full">
                <td className="py-3 px-2 text-left" colSpan={9}>
                  {loading ? (
                    <TableSkeleton />
                  ) : (
                    <NoDataFound label={t("no_data_found")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanySNSummary;
