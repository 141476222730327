import { useState, useEffect } from "react";
import CreateRoutes from "./routes";
import { useTranslation } from "react-i18next";
import { NetworkStatusProvider } from "./Context/NetworkStatusContext";
import moment from "moment-timezone";
import { SyncDataModalProvider } from "./Context/SyncDataModalContext";
import { PositionManageProvider } from "./Context/PositionManageContext";
import { SettingsProvider } from "./Context/SettingsContext";

moment.tz.setDefault("America/Denver");

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = sessionStorage.getItem("sessionLang");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <>
      <NetworkStatusProvider>
        <SettingsProvider>
          <SyncDataModalProvider>
            <PositionManageProvider>
              <CreateRoutes />
            </PositionManageProvider>
          </SyncDataModalProvider>
        </SettingsProvider>
      </NetworkStatusProvider>
    </>
  );
}

export default App;
