import React, { useState } from "react";

const StepPlan = ({ activeTab, setActiveTab = () => {} }) => {
  const tabs = ["Generate", "Measure", "Plan"];
  return (
    <section
      className="relative w-full py-10 bg-home-background  h-[548px] rounded-[20px] mt-16"
      id="company-section"
    >
      <div className="w-full mt-8">
        <div className="space-y-5 px-5">
          <div className="font-normal  text-black font-IBM text-[48px] text-center w-full">
            <span className="italic">How to Build Your</span>
            <br />
            <span className="font-light text-black">Succession Plan</span>
          </div>
          <div className="text-center font-medium text-black font-Outfit text-[16px] max-w-[434px] mx-auto">
            Follow a simple 3-Step process to create your Succession Plan
          </div>
        </div>
        <div className="w-full flex justify-center items-center mt-8">
          <div className="w-[434px] h-[52px] flex items-center bg-[#DCECEC] rounded-full gap-1">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`flex-1 h-full text-center rounded-full transition-all relative ${
                  activeTab === tab ? "bg-black text-white" : "text-black"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepPlan;
