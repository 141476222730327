import React, { useState,useEffect } from 'react';
import PriceingPlanItem from "../Items/PriceingPlanItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { postData } from '../../services/api';

const MonthlyPlan = ({plans,buttonLink=true}) => {  
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('accessToken');
  const { i18n,t } = useTranslation();
  const [loading,setLoading] = useState(false);

  const createPayment = async (id,price,interval) => {
    try {
      if(isLogin){
        const result = await postData('stripe/subscription-create', {
          planId : id,
          price : price,
          interval : interval
        });
        if (result.data) {
          window.location.href = result?.data?.redirect_url;
          setLoading(false);
        } else {
          setLoading(false);
        }
      }else{
        return navigate("/signin");
      }      
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <>
      {plans.map((item, index) => {
        let price = 0;
        let features = [];
        if(item.pricing?.rates.length>0){
          price = item.pricing?.rates.filter((it) => it?.interval === 'month')[0]?.amount;
        }
        if(item?.features?.length>0){
          features = item.features.filter((_it) => _it?.headingType === 'number-of-plans');
        }
        if(buttonLink){
          return (
            <PriceingPlanItem 
              key={index} 
              data={{
                _id: item?._id,
                title : item?.title,
                price: price,
                recurring: "mo*",
                btnname: t('buy_plan'),
                buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
                buttonHasLink:true,
                link: '//calendly.com/cameronbrockbank/succession-now-demo',
                features: features.length>0 && features.map((row,key) => {
                  return {
                    _id: Number(key+1), name: ((row?.inputValue>1000)?'Unlimited':row?.inputValue)+' '+row?.name
                  }
                })
              }} 
            />
          )
        }else{
          return (
            <PriceingPlanItem 
              key={index} 
              data={{
                _id: item?._id,
                title : item?.title,
                price: price,
                recurring: "mo*",
                btnname: t('buy_plan'),
                buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
                buttonHasLink:false,
                features: features.length>0 && features.map((row,key) => {
                  return {
                    _id: Number(key+1), name: ((row?.inputValue>1000)?'Unlimited':row?.inputValue)+' '+row?.name
                  }
                })
              }} 
              getFunction={(id,price) => createPayment(id,price,'month')}
            />
          )
        }
      })}
    </>
    
  );
};

export default MonthlyPlan;
