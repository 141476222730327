import React, {useState, useEffect} from "react";
import Image from "../../components/elements/Image";
import { Link, NavLink } from "react-router-dom";

const FreeAuthFooter = ({ settings }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []); 

  const companymenu = [
    { _id: 1, name: "How It Works", sectionId:"successionbuild-section"},
  ]
  const productmenu = [
    { _id: 1, name: "Pricing", sectionId:"pricingPlan-section" },   
    { _id: 3, name: "Schedule Demo", link: "//calendly.com/cameronbrockbank/succession-now-demo", type: "link" },
  ]
  const resourcesmenu = [
    // { _id: 1, name: "Help Center", link: "/", type: "link" },
    { _id: 2, name: "Blog", link: "/resources", type: "link" },
  ]
  const supportmenu = [
    { _id: 1, name: "Contact Us", link: "/contact-us", type: "link" },
  ]
  
  const aboutsuccessionmenu = [
    { _id: 1, name: "Terms", link: "/terms-and-conditions", type: "link" },
    { _id: 2, name: "Privacy", link: "/privacy", type: "link" },
  ]

  const socialIconsData = [  
    {
      name: "Linked In",
      icon: "linkedin-in",
      url: "https://www.linkedin.com/company/successionnow/"
    },
  ]

  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");      
      const scrollPosition = window.scrollY;      
      sections.forEach(section => {
        const sectionTop = section.offsetTop - 50; 
        const sectionHeight = section.sectionTop;
        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 


  const scrollToSection = id => {
    const section = document.getElementById(id);    
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 160,
        behavior: 'smooth'
      });
    } else {
      window.location.href = `/#${id}`;    
    }
  };

window.addEventListener('load', () => {
  const hash = window.location.hash.substring(1);
  if (hash) {
      const section = document.getElementById(hash);
      if (section) {
          window.scrollTo({
              top: section.offsetTop - 160,
              behavior: 'smooth'
          });
          // Remove the hash from the URL after scrolling
          window.history.replaceState({}, document.title, window.location.pathname);
      }
  }
});

  return (
    <>
      <footer className="relative bg-slate-950 flex items-center py-10 lg:py-20">
        <div className="w-full lg:max-w-5xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-5">
          <div className="block xl:flex flex-wrap justify-between -mx-5 space-y-10 xl:space-y-0">
            <div className="xl:w-1/2 w-full px-5 flex flex-col">
              <div className="relative mb-5 lg:mb-8 block xs:flex space-y-2 xs:space-y-0 items-end gap-2">
                <div className="h-10 relative">
                  <Link to="/" className="block w-full h-full cursor-pointer transition-all duration-200">
                    <Image 
                      src={'logo-succession-now.svg'} 
                      alt={''} 
                      width={'100%'}
                      height={'100%'} 
                      effect={'blur'} 
                      classes={'object-contain object-left'}
                      id={""}
                    />
                  </Link>
                </div>
                <div className="text-base text-white">{settings?.copyright}</div>
              </div>
              <div className="mt-auto block xs:flex items-center gap-x-10">
                  <div className="relative flex items-center gap-x-2">
                      {aboutsuccessionmenu.map.length > 0 && (
                        <div className="relative">
                            <ul className="flex items-center gap-x-2">
                              {aboutsuccessionmenu.map((item) => (
                                <li key={item?._id}>
                                  {
                                    item.sectionId ?
                                    <NavLink
                                      key={item?._id}
                                      to={item.sectionId ? `//#${item.sectionId}` : item.link}
                                      className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"                                      
                                    >
                                      {item.name}
                                    </NavLink>:
                                    <NavLink
                                      key={item?._id}
                                      to={item.sectionId ? `//#${item.sectionId}` : item.link}
                                      className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"                                      
                                    >
                                      {item.name}
                                    </NavLink>
                                  }
                                  
                                </li>
                              ))}
                            </ul>
                      </div> 
                      )}
                  </div>
                  <div className="relative flex items-center gap-x-5">
                      {socialIconsData.map((icon, index) => (
                          <a key={index} href={icon.url} target="_blank" className="block text-xl cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white">
                            <i className={`fa-brands fa-${icon.icon}`}></i>
                          </a>
                      ))}
                  </div>
              </div>
              
            </div>
            <div className="xl:w-1/2 w-full px-5 block xs:flex justify-between gap-2">
              <div className="relative">
                  <div className="font-semibold text-white py-3 px-0 capitalize mb-4">Company</div>
                  {companymenu.map.length > 0 && (
                     <div className="relative">
                        <ul className="flex flex-col gap-y-4">
                          {companymenu.map((item) => (
                            <li key={item?._id}>
                              {
                                item.sectionId?
                                <NavLink
                                  key={item?._id}
                                  // to={`/homev2/${item.sectionId}`}
                                  to={`//#${item.sectionId}`}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                  onClick={() => {
                                    scrollToSection(item.sectionId);
                                  }}
                                >
                                  {item.name}
                                </NavLink>:
                                <NavLink
                                  key={item?._id}
                                  // to={`/homev2/${item.sectionId}`}
                                  to={item.link}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                >
                                  {item.name}                                  
                                </NavLink>
                              }                              
                            </li>
                          ))}
                        </ul>
                   </div> 
                  )}
              </div>
              <div className="relative">
                  <div className="font-semibold text-white py-3 px-0 capitalize mb-4">Product</div>
                  {productmenu.map.length > 0 && (
                     <div className="relative">
                        <ul className="flex flex-col gap-y-4">
                          {productmenu.map((item) => (
                            <li key={item?._id}>
                              {
                                item.sectionId?
                                <NavLink
                                  key={item?._id}
                                  to={`//#${item.sectionId}`}
                                  target={item.type === "link" ? "_blank" : null}
                                  rel={item.type === "link" ? "noopener noreferrer" : null}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                  onClick={() => {
                                    scrollToSection(item.sectionId);
                                  }}                                
                                >
                                  {item.name}
                                </NavLink>:
                                <NavLink
                                  key={item?._id}
                                  to={item.link}
                                  target={item.type === "link" ? "_blank" : null}
                                  rel={item.type === "link" ? "noopener noreferrer" : null}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"                     
                                >
                                  {item.name}
                                </NavLink>
                              }
                            </li>
                          ))}
                        </ul>
                   </div> 
                  )}
              </div>
              <div className="relative">
                  <div className="font-semibold text-white py-3 px-0 capitalize mb-4">Resources</div>
                  {resourcesmenu.map.length > 0 && (
                     <div className="relative">
                        <ul className="flex flex-col gap-y-4">
                          {resourcesmenu.map((item) => (
                            <li key={item?._id}>
                              {
                                item.sectionId ?
                                <NavLink
                                  key={item?._id}
                                  to={ `//#${item.sectionId}`}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                  onClick={() => {
                                    scrollToSection(item.sectionId);
                                  }}
                                >
                                  {item.name}
                                </NavLink>:
                                <NavLink
                                  key={item?._id}
                                  to={item.link}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"                               
                                >
                                {item.name}
                              </NavLink>
                              }                              
                            </li>
                          ))}
                        </ul>
                   </div> 
                  )}
              </div>
              <div className="relative">
                  <div className="font-semibold text-white py-3 px-0 capitalize mb-4">Support</div>
                  {supportmenu.map.length > 0 && (
                     <div className="relative">
                        <ul className="flex flex-col gap-y-4">
                          {supportmenu.map((item) => (
                            <li key={item?._id}>
                              {
                                item.sectionId ?
                                <NavLink
                                  key={item?._id}
                                  to={`//#${item.sectionId}`}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                  onClick={() => {
                                    scrollToSection(item.sectionId);
                                  }}
                                >
                                  {item.name}
                                </NavLink>:
                                <NavLink
                                  key={item?._id}
                                  to={item.link}
                                  className="block text-base cursor-pointer py-2 lg:px-0 lg:py-0  transition-all duration-200 text-white"
                                >
                                  {item.name}
                                </NavLink>
                              }
                              
                            </li>
                          ))}
                        </ul>
                   </div> 
                  )}
              </div>
             
            </div>
         
          </div>
         
        </div>
      </footer>
    </>
  );
};

export default FreeAuthFooter;
