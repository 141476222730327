import React,{useState,useEffect} from 'react';
import PriceingPlanItem from "../../../Items/PriceingPlanItem";
import Searching from '../../../Searching';
import NoDataFound from '../../../NoDataFound';

import { postData } from '../../../../services/api';
import { useTranslation } from "react-i18next";
import Tabs from "../../../elements/Tab";
import MonthlyPlan from "../../../TabContent/MonthlyPlan";
import AnnualPlan from "../../../TabContent/AnnualPlan";

const PricingPlanSection = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const { i18n,t } = useTranslation();

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true);
      try {
        const result = await postData("plans/list",{
          limit: 4,
          offset: 0,
          sortQuery:{'ordering':1},
          isView : 1
        });
        if(result.data) {
              const rowLen = result?.data?.length;
              setPlans(
                result.data.map((value, key) => {
                  if (rowLen === key + 1) {
                    return {
                      title:value?.name,
                      talk:"let's talk",
                      btnname:"contact us",
                      buttonClasses: '!bg-[#F4F7FA] !text-dark-teal-500 xl:!text-lg lg:h-10 xl:h-14 !px-6',
                      link: '//calendly.com/cameronbrockbank/succession-now-demo',
                      pricing : value?.pricing,
                      features : value?.features
                    }
                  } else {
                    return {
                      title:value?.name,
                      price:value?.price,
                      btnname:"get started",
                      buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
                      link: '//calendly.com/cameronbrockbank/succession-now-demo',
                      pricing : value?.pricing,
                      features : value?.features
                    }
                  }                  
                })
              )
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPlans();    
}, []);

  const pricingList = [
    { _id: 1, name:t('includes_unlimited_succession_plans')},
  ]
  const PriceingPlanData = {
    title: t('pricing_&_plans'),  
    description: t('plans_are_priced_to_fit_the_size_of_your_business'),
    //PriceingPlan: plans
    // PriceingPlan: 
    // [
    //   {
    //     title:"essentials",
    //     price:"275",
    //     recurring:"mo*",
    //     btnname:"get started",
    //     buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
    //     link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
    //   },
    //   {
    //     title:"professionals",
    //     price:"525",
    //     recurring:"mo*",
    //     btnname:"get started",
    //     buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
    //     link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
    //   },
    //   {
    //     title:"enterprise",
    //     price:"850",
    //     recurring:"mo*",
    //     btnname:"get started",
    //     buttonClasses: '!bg-dark-teal-500 !text- xl:!text-lg lg:h-10 xl:h-14 !px-6',
    //     link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
    //   },
      
    // ]
  }
  const tabs = [
    {
      _id: 'tab1',
      name: 'Monthly',
      component: <MonthlyPlan plans={plans}/>
    },
    {
      _id: 'tab2',
      name: 'Annual',
      component: <AnnualPlan plans={plans}/>
    },
   
  ];
  return (
    <section className="relative w-full py-10 bg-white" id="pricingPlan-section">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
        
          <div className="max-w-3xl w-full mx-auto text-center mb-10  space-y-5">
              <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:PriceingPlanData.title}}></h2>
              <div className="text-sm xs:text-base text-slate-700" dangerouslySetInnerHTML={{__html:PriceingPlanData.description}}></div>
              {/* <div className="max-w-[272px] m-auto">
                  {pricingList.map.length > 0 && (
                      <ul className="list-disc text-left">
                        {pricingList.map((item) => (
                          <li key={item?._id}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                  )}
                
              </div> */}                
          </div>
          {/* <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-5 lg:gap-10">
                  {(PriceingPlanData.PriceingPlan && PriceingPlanData.PriceingPlan.length>0)?
                  PriceingPlanData.PriceingPlan.map((item, index) => (
                      <PriceingPlanItem key={index} data={item}/>
                  )):loading ? <Searching label={'Searching'} /> : <NoDataFound label={'No Data Found'}/>}
          </div> */}
          <div className="relative">
              <Tabs tabs={tabs} navTabClass="max-w-[768px] w-full m-auto" divClass="w-full bg-transparent shadow-none gap-5" buttonClass="w-full rounded-md !text-dark-teal-500 border border-dark-teal-500" tabContentClass="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-5 xl:gap-14"/>
          </div>
          <div className="flex justify-center lg:justify-end items-center text-slate-600 uppercase text-xs font-bold font-PlusJakartaSans tracking-wider mt-5 xs:mt-0">*annual contract required</div>
      </div>
  </section>
  );
};

export default PricingPlanSection;