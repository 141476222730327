import React, { useState } from "react";
import toastr from "toastr";
import Button from "../../form/Button";
import { useTranslation } from "react-i18next";
import Select from "../../form/Select";
import { postData } from "../../../services/api";
import { useSelector } from "react-redux";

const MultipleButtonAction = ({
  selectedRow,
  setSelectedRow = () => {},
  setList = () => {},
  list,
  API_URL_STATUS = "",
  API_URL_DELETE = "",
  API_DEVELOPER_URL = "",
  loadList = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth?.user);

  const handleStatusToggle = async (data) => {
    try {
      setLoading(true);
      const response = await postData(API_URL_STATUS, {
        ids: selectedRow,
        status: data,
      });

      if (response?.status === 200) {
        setList(
          list.map((item) =>
            selectedRow?.includes(item._id) ? { ...item, status: data } : item
          )
        );
        setSelectedRow([]);
        setSelectedValue("");
        toastr.success(
          response?.data?.message || "Status updated successfully."
        );
      } else {
        toastr.error(response?.data?.message || "Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastr.error(
        error?.response?.data?.message ||
          "An unexpected error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      setLoading(true);
      const response = await postData(API_URL_DELETE, {
        ids: selectedRow,
      });

      if (response?.status === 200) {
        loadList();
        setSelectedRow([]);
        setSelectedValue("");
        toastr.success(response?.data?.message || "Data deleted successfully.");
      } else {
        toastr.error(response?.data?.message || "Failed to delete data.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastr.error(
        error?.response?.data?.message ||
          "An unexpected error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeveloperDelete = async () => {
    try {
      setLoading(true);
      const response = await postData(API_DEVELOPER_URL, {
        ids: selectedRow,
      });

      if (response?.status === 200) {
        loadList();
        setSelectedRow([]);
        setSelectedValue("");
        toastr.success(response?.data?.message || "Data deleted successfully.");
      } else {
        toastr.error(response?.data?.message || "Failed to delete data.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastr.error(
        error?.response?.data?.message ||
          "An unexpected error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {selectedRow && Array.isArray(selectedRow) && selectedRow?.length > 0 && (
        <div className="flex gap-2">
          <div className="relative w-48">
            <Select
              xPlacement={"right"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-48"}
              transitionClass={"!w-auto"}
              selectedValue={selectedValue}
              dropdownData={[
                { _id: "", label: t("Choose a value"), value: "" },
                {
                  _id: "deleteAll",
                  label: "Delete All",
                  value: "deleteAll",
                },
                {
                  _id: "activeAll",
                  label: "Active All",
                  value: "activeAll",
                },
                {
                  _id: "deactivateAll",
                  label: "Deactivate All",
                  value: "deactivateAll",
                },
                {
                  _id: "parmanentDeveloperDelete",
                  label: "Delete Developer Account",
                  value: "parmanentDeveloperDelete",
                },
              ]}
              getSelectedValue={(e) => {
                setSelectedValue(e.value);
              }}
            />
          </div>
          {selectedValue === "deleteAll" && (
            <div className="flex gap-2 animate-fade-in">
              <Button
                buttonType={"button"}
                buttonIcon={"fa-solid fa-trash"}
                buttonIconPosition={"left"}
                buttonLabel={t("Apply For Delete")}
                buttonHasLink={false}
                functions={() => {
                  handleMultipleDelete();
                }}
                buttonClasses={
                  " !bg-rose-600 !border-rose-500 !text-white ml-2"
                }
                buttonLabelClasses="text-white"
              />
            </div>
          )}
          {selectedValue === "activeAll" && (
            <div className="flex gap-2 animate-fade-in">
              <Button
                buttonType={"button"}
                buttonIcon={
                  loading ? "fa-light fa-spinner fa-spin" : "fa-solid fa-check"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("Apply For Active")}
                buttonHasLink={false}
                functions={() => {
                  handleStatusToggle(1);
                }}
                buttonClasses={
                  " !bg-green-600 !border-green-500 !text-white ml-2"
                }
                buttonLabelClasses="text-white"
              />
            </div>
          )}
          {selectedValue === "deactivateAll" && (
            <div className="flex gap-2 animate-fade-in">
              <Button
                buttonType={"button"}
                buttonIcon={"fa-solid fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={t("Apply For Deactivate")}
                buttonHasLink={false}
                functions={() => {
                  handleStatusToggle(2);
                }}
                buttonClasses={
                  " !bg-transparent !border-2 !border-rose-600 !text-rose-600 ml-2"
                }
                buttonLabelClasses=" !text-rose-600"
              />
            </div>
          )}
          {user &&
            user?.email === "prokashmalo981@gmail.com" &&
            selectedValue === "parmanentDeveloperDelete" && (
              <div className="flex gap-2 animate-fade-in">
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-solid fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("Delete Developer Account")}
                  buttonHasLink={false}
                  functions={() => {
                    handleDeveloperDelete();
                  }}
                  buttonClasses={
                    " !bg-transparent !border-2 !border-rose-600 !text-rose-600 ml-2"
                  }
                  buttonLabelClasses=" !text-rose-600"
                />
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default MultipleButtonAction;
