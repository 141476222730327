import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { postData } from "../../../services/api";
import Button from "../../form/Button";
import { Tooltip } from "react-tooltip";
import { ManageDefaultConcernAndPredictorsSkleton } from "../../loader/ManageDefaultConcernAndPredictorsSkleton";
import NoDataFound from "../../NoDataFound";
import Checkbox from "../../form/Checkbox";
import AddEditConcernHeadingsPopup from "../../popup/AddEditConcernHeadingsPopup";
import toastr from "toastr";
import SelectedQuestionItems from "./SelectedQuestionItems";
import { sumTotal } from "../../../helpers";

const GlobalQuestionList = ({ selectedTierType, selectedTier }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [processing, setProcesing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [concerns, setConcerns] = useState([]);
  const [predictors, setPredictors] = useState([]);
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
    type: "",
  });
  const [isUpdateConcern, setIsUpdateConcern] = useState(false);
  const [isUpdatePredictor, setIsUpdatePredictor] = useState(false);
  const [selectedDefaultQuestion, setSelectedDefaultQuestion] = useState({
    concern: [],
    predictor: [],
  });
  const [weightTotalValue, setWeightTotalValue] = useState(0);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("heading/list", {
        sortQuery: { ordering: 1 },
        isGlobal: true,
        tierId: selectedTier,
        tierType: selectedTierType,
      });
      if (result.data) {
        let _concerns = [];
        let _predictors = [];
        let _selectedConcern = [];
        let _selectedPredictor = [];
        const getPredictorValue = result.data?.filter(
          (item) => item?.isDefault && item?.type === "predictors"
        );
        setWeightTotalValue(sumTotal(getPredictorValue, "weight"));

        result.data.map((item) => {
          if (item?.type === "concern") {
            _concerns.push({
              _id: item?._id,
              name: item?.name,
              description: item?.description,
              alias: item?.alias,
              weight: item?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
            });
          } else if (item?.type === "predictors") {
            _predictors.push({
              _id: item?._id,
              name: item?.name,
              description: item?.description,
              alias: item?.alias,
              weight: item?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
            });
          }
        });
        const selectedData = result.data?.filter((item) => item?.isDefault);

        selectedData?.map((item) => {
          if (item?.type === "concern") {
            _selectedConcern.push({
              _id: item?._id,
              name: item?.name,
              description: item?.description,
              alias: item?.alias,
              weight: item?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
            });
          } else if (item?.type === "predictors") {
            _selectedPredictor.push({
              _id: item?._id,
              name: item?.name,
              description: item?.description,
              alias: item?.alias,
              weight: item?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
            });
          }
        });
        setSelectedDefaultQuestion({
          concern: _selectedConcern,
          predictor: _selectedPredictor,
        });
        setConcerns(_concerns);
        setPredictors(_predictors);
        setLoading(false);
        setIsUpdateConcern(true);
        setIsUpdatePredictor(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  }, [selectedTier, selectedTierType]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const onSubmit = async (type) => {
    try {
      let payload;
      if (type === "concern") {
        payload = {
          ids: concerns,
          ...(selectedTierType === "position-wide" && { tierId: selectedTier }),
          tierType: selectedTierType,
        };
      } else if (type === "predictors") {
        payload = {
          ids: predictors,
          ...(selectedTierType === "position-wide" && { tierId: selectedTier }),
          tierType: selectedTierType,
        };
      }

      const result = await postData("heading/set-default", payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setLoading(false);
        loadList();
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const updateHandler = (heading_id, event, type) => {
    if (event) {
      if (type === "concern") {
        let enabledConcerns =
          concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;
        if (Number(enabledConcerns + 1) === 8) {
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
        } else {
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                return item;
              }
            })
          );
        }
        setIsUpdateConcern(true);
      } else if (type === "predictors") {
        let enabledPredictors =
          predictors.length > 0 &&
          predictors.filter((it) => it?.isDefault)?.length;

        if (Number(enabledPredictors + 1) === 8) {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
        } else {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                return item;
              }
            })
          );
        }
        setIsUpdatePredictor(true);
      }
    } else {
      if (type === "concern") {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdateConcern(true);
      } else if (type === "predictors") {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdatePredictor(true);
      }
    }
  };

  useEffect(() => {
    let enabledConcerns =
      concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;

    if (enabledConcerns > 0) {
      if (enabledConcerns === 8) {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      } else {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      }
    }
  }, [isUpdateConcern]);

  useEffect(() => {
    let enabledPredictors =
      predictors.length > 0 && predictors.filter((it) => it?.isDefault)?.length;
    // const getPredictorValue = predictors?.filter((item) => item?.isDefault);
    // setWeightTotalValue(sumTotal(getPredictorValue, "weight"));
    if (enabledPredictors > 0) {
      if (enabledPredictors === 8) {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      } else {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      }
    }
  }, [isUpdatePredictor]);

  useEffect(() => {
    if (Array.isArray(predictors)) {
      const getPredictorValue = predictors.filter((item) => item?.isDefault);
      setWeightTotalValue(sumTotal(getPredictorValue, "weight"));
    }
  }, [predictors]);

  return (
    <div>
      <div className="relative mt-6 p-4 bg-white shadow-md rounded-lg border border-gray-200">
        <div className="text-xl font-semibold text-teal-500">
          Selected Questions
        </div>
        <div className="relative flex flex-col gap-4 mb-3">
          <div className="grid grid-cols-2 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
            <div className="relative">
              {!loading && (
                <SelectedQuestionItems
                  data={selectedDefaultQuestion?.concern}
                  type="concern"
                />
              )}
            </div>
            <div className="relative">
              {!loading && (
                <SelectedQuestionItems
                  data={selectedDefaultQuestion?.predictor}
                  type="predictors"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="relative p-2 rounded-lg shadow-lg">
        <div className="relative">
          <div className="relative flex flex-col gap-4 mb-3">
            <div className="grid grid-cols-2 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
              <div className="relative p-2 rounded-lg border border-slate-400 shadow-sm">
                <div className="text-xl font-semibold text-slate-500">
                  Manage Quesions(Concerns)
                </div>

                <div className="sm:ml-auto flex justify-end items-center mt-2 gap-4">
                  <div
                    data-tooltip-id={`buttonDefaultQuesition${1236}`}
                    data-tooltip-place="bottom"
                  >
                    <Button
                      buttonType={"button"}
                      buttonClasses={"!bg-slate-500"}
                      buttonIcon={"fa-regular fa-floppy-disk"}
                      buttonIconPosition={"left"}
                      buttonLabel={"Save For Default"}
                      buttonHasLink={false}
                      functions={() => onSubmit("concern")}
                    />
                    <Tooltip
                      id={`buttonDefaultQuesition${1236}`}
                      className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                      render={() => (
                        <div className="gap-x-1">
                          <div className="text-[13px] font-normal leading-none flex items-center">
                            <div className="font-semibold">
                              Save Default Question
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div
                    data-tooltip-id={`buttonAddConcernQuesition${199}`}
                    data-tooltip-place="bottom"
                  >
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-plus"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("add")}
                      buttonHasLink={false}
                      functions={() =>
                        setIsopen({ _id: null, open: true, type: "concern" })
                      }
                    />
                    <Tooltip
                      id={`buttonAddConcernQuesition${199}`}
                      className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                      render={() => (
                        <div className="gap-x-1">
                          <div className="text-[13px] font-normal leading-none flex items-center">
                            <div className="font-semibold">Add New Concern</div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className="relative">
                  {loading && (
                    <div className="px-2 py-2">
                      <ManageDefaultConcernAndPredictorsSkleton
                        rowValue={"!grid-cols-1"}
                        count={20}
                      />
                    </div>
                  )}
                  {!loading && (
                    <div className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-100px)]">
                      {concerns.length > 0 ? (
                        concerns.map((itm, inx) => (
                          <div
                            key={inx}
                            className={`space-y-2 border border-dark-teal-200 rounded-md p-3 ${
                              !itm?.disabled && itm?.isDefault
                                ? "bg-blue-50"
                                : "bg-transparent"
                            }`}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex-shrink">
                                <Checkbox
                                  checkboxLableClass={
                                    "text-teal-600 !font-semibold"
                                  }
                                  checkboxInputClass={""}
                                  checkboxClass={"!rounded-full"}
                                  divClass={"!items-start"}
                                  checkboxName={itm?._id}
                                  isChecked={itm?.isDefault}
                                  checkboxLabel={itm?.name}
                                  checkboxValue={itm?._id}
                                  getCheckboxValue={(val, event) =>
                                    updateHandler(
                                      val,
                                      event.target.checked,
                                      "concern"
                                    )
                                  }
                                  isDisabled={itm?.disabled}
                                />
                              </div>
                              <div className="flex items-center space-x-2 flex-shrink">
                                <div className="relative">
                                  <div
                                    className="flex items-center"
                                    data-tooltip-id={`editButton${itm?._id}`}
                                    data-tooltip-place="bottom"
                                  >
                                    <Button
                                      buttonClasses={
                                        "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                                      }
                                      buttonType={"button"}
                                      buttonIcon={"fa-regular fa-pencil"}
                                      buttonIconPosition={"left"}
                                      buttonHasLink={false}
                                      functions={() => {
                                        setIsopen({
                                          open: true,
                                          _id: itm?._id,
                                          type: "concern",
                                        });
                                      }}
                                    />
                                    <Tooltip
                                      id={`editButton${itm?._id}`}
                                      className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6"
                                      render={() => (
                                        <div className="gap-x-1 gap-y-1">
                                          <div className="text-xs leading-none flex items-center">
                                            <div className="font-medium">{`Click To Edit Concern (${
                                              itm?.heading_name
                                                ? itm?.heading_name
                                                : itm?.name
                                            })`}</div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative">
                              <div className="w-full p-1 rounded-md">
                                <div className="text-[14px] font-normal">
                                  <p className="font-normal text-slate-600">
                                    {itm?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoDataFound label={"No Data Found"} />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="relative p-2 rounded-lg border border-slate-400 shadow-sm">
                <div className="text-xl font-semibold text-slate-500">
                  Manage Quesions(Predictors)
                </div>
                <div className="sm:ml-auto flex justify-end items-center mt-2 gap-4">
                  <div
                    className={
                      weightTotalValue === 100
                        ? "text-xs sm:text-sm bg-teal-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded"
                        : "text-xs sm:text-sm bg-rose-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded"
                    }
                  >
                    {t("weight")}: {weightTotalValue}%
                  </div>
                  {weightTotalValue === 100 && (
                    <div
                      data-tooltip-id={`buttonDefaultQuesition${1236}`}
                      data-tooltip-place="bottom"
                    >
                      <Button
                        buttonType={"button"}
                        buttonClasses={"!bg-slate-500"}
                        buttonIcon={"fa-regular fa-floppy-disk"}
                        buttonIconPosition={"left"}
                        buttonLabel={"Save For Default"}
                        buttonHasLink={false}
                        functions={() => onSubmit("predictors")}
                      />
                      <Tooltip
                        id={`buttonDefaultQuesition${1236}`}
                        className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                        render={() => (
                          <div className="gap-x-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">
                                Save Default Question
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  )}

                  <div
                    data-tooltip-id={`buttonAddQuesition${199}`}
                    data-tooltip-place="bottom"
                  >
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-plus"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("add")}
                      buttonHasLink={false}
                      functions={() =>
                        setIsopen({ _id: null, open: true, type: "predictors" })
                      }
                    />
                    <Tooltip
                      id={`buttonAddQuesition${199}`}
                      className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                      render={() => (
                        <div className="gap-x-1">
                          <div className="text-[13px] font-normal leading-none flex items-center">
                            <div className="font-semibold">
                              Add New Predictor
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="relative">
                  {loading && (
                    <div className="px-2 py-2">
                      <ManageDefaultConcernAndPredictorsSkleton
                        rowValue={"!grid-cols-1"}
                        count={20}
                      />
                    </div>
                  )}
                  {!loading && (
                    <div className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-100px)]">
                      {predictors.length > 0 ? (
                        predictors.map((itm, inx) => (
                          <div
                            key={inx}
                            className={`space-y-2 border border-dark-teal-200 rounded-md p-3 ${
                              !itm?.disabled && itm?.isDefault
                                ? "bg-blue-50"
                                : "bg-transparent"
                            }`}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex-shrink">
                                <Checkbox
                                  checkboxLableClass={
                                    "text-teal-600 !font-semibold"
                                  }
                                  checkboxInputClass={""}
                                  checkboxClass={"!rounded-full"}
                                  divClass={"!items-start"}
                                  checkboxName={itm?._id}
                                  isChecked={itm?.isDefault}
                                  checkboxLabel={itm?.name}
                                  checkboxValue={itm?._id}
                                  getCheckboxValue={(val, event) =>
                                    updateHandler(
                                      val,
                                      event.target.checked,
                                      "predictors"
                                    )
                                  }
                                  isDisabled={itm?.disabled}
                                />
                              </div>
                              <div className="flex items-center space-x-2 flex-shrink">
                                <div className="relative">
                                  <div
                                    className="flex items-center"
                                    data-tooltip-id={`editButton${itm?._id}`}
                                    data-tooltip-place="bottom"
                                  >
                                    <Button
                                      buttonClasses={
                                        "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                                      }
                                      buttonType={"button"}
                                      buttonIcon={"fa-regular fa-pencil"}
                                      buttonIconPosition={"left"}
                                      buttonHasLink={false}
                                      functions={() => {
                                        setIsopen({
                                          open: true,
                                          _id: itm?._id,
                                          type: "predictors",
                                        });
                                      }}
                                    />
                                    <Tooltip
                                      id={`editButton${itm?._id}`}
                                      className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6"
                                      render={() => (
                                        <div className="gap-x-1 gap-y-1">
                                          <div className="text-xs leading-none flex items-center">
                                            <div className="font-medium">{`Click To Edit Concern (${
                                              itm?.heading_name
                                                ? itm?.heading_name
                                                : itm?.name
                                            })`}</div>
                                          </div>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="relative">
                              <div className="w-full p-1 rounded-md">
                                <div className="text-[15px] font-semibold">
                                  <p className="font-semibold text-pink-600">
                                    Weight(%) {itm?.weight}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="relative">
                              <div className="w-full p-1 rounded-md">
                                <div className="text-[14px] font-normal">
                                  <p className="font-normal text-slate-600">
                                    {itm?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoDataFound label={"No Data Found"} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen?.open && (
        <AddEditConcernHeadingsPopup
          isOpen={isOpen?.open}
          id={isOpen?._id}
          setIsOpen={setIsopen}
          loadList={loadList}
          type={isOpen?.type || "concern"}
        />
      )}
    </div>
  );
};

export default GlobalQuestionList;
