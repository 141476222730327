import React from "react";

export const ManageDefaultConcernAndPredictorsSkleton = ({
  rowValue = "grid-cols-3",
  count = 18,
}) => {
  return (
    <div
      className={`grid grid-cols-3 gap-2 overflow-auto scroll-smooth scrollbar ${rowValue}`}
    >
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className="flex items-start justify-between gap-2 border border-teal-500 rounded-md py-3 px-3 shimmer"
        >
          <div className="w-5 h-5 bg-gray-300 rounded-full flex-shrink-0"></div>
          <div className="flex-shrink flex-grow space-y-2">
            <div className="w-full h-3 bg-gray-300 rounded-full"></div>
            <div className="w-full h-1.5 bg-gray-300 rounded-full"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
