import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../../src/logo-succession-now.png";
import Shape from "../../shape.png";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const EmergencyReplacementPlanReport = ({ data, details, user, companyLogo }) => {
  const styles = StyleSheet.create({
    image: {
      width: 360,
      height: 120,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0  auto",
    },
    sectionTitle: {
      fontSize: 11,
      fontFamily: "RobotoBold",
      textDecoration: "underline",
      marginBottom: 12,
    },
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
      backgroundColor: "#f1f5f9",
    },
    subDes: {
      fontSize: 10,
      fontFamily: "RobotoMedium",
      marginBottom: 5,
    },
    flexPart: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 12,
    },
    section: {
      fontSize: 13,
    },
    listSection: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "RobotoItalic",
    },
    listPart: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  });

  return (
    <Page size="A4" orientation="landscape" style={[styles.page, { display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems:'flex-start', position: "relative" }]}>
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: 16,
          alignItems: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <Image style={styles.image} src={companyLogo} />
        <View style={{ position: "absolute", right: 0, top: 0 }}>
          <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginVertical: 20, width:"100%" }}>
        <Text
          style={{
            fontFamily: "RobotoBold",
            fontSize: 12,
            textTransform: "uppercase",
            color: "#14b8a6",
            letterSpacing: 3,
            textAlign: "center",
          }}
        >
          Emergency/Interim Replacement Policy &amp; Plan – President/CEO
        </Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
          An unplanned departure of a President/CEO can include death, catastrophic long-term disability, termination without notice, or the CEO accepting a position with another organization.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
          The Board Chair should be notified immediately of the situation. If the Board Chair is unavailable, notice will be given to the (1) Vice Chair, (2) the Secretary/Treasurer, (3) the
          Supervisory Committee Chair.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
          If the involuntary termination is due to the President/CEO engaging in an egregious act, the Board will contact its attorney regarding questions or concerns related to legal issues
          surrounding termination. The attorney contact information is:
        </Text>
        <Text style={[styles.subDes, { marginBottom: 0 }]}>Email: {details?.email}</Text>
        <Text style={[styles.subDes, { marginBottom: 0 }]}>Business Phone:{details?.business_phone}</Text>
        <Text style={[styles.subDes, { marginBottom: 0 }]}>Cell Phone:{details?.cell_phone}</Text>
      </View>
      <View style={[styles.flexPart]}>
        <Text style={styles.sectionTitle}>2. Voluntary Termination:</Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>If the departure is a voluntary termination (the CEO leaves, accepts a position elsewhere, etc.), The CEO should provide at least 6 months prior notice.</Text>
        <View style={[styles.listSection]}>
          <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
            <Text style={[styles.listPart, { fontFamily: "RobotoBold" }]}>Appointment:</Text>
            In the event of an unplanned departure of the CEO, the Board of Directors authorizes the Board Chair to appoint an interim CEO until the full board can meet and approve the appointment. If
            for any reason the Board Chair is unable or unavailable to appoint the interim President/CEO, the order of responsibility to appoint an interim President/CEO is as follows:
          </Text>
          <View style={{ flexDirection: "column", width: 400, marginBottom: 5 }}>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginHorizontal: 8, fontSize: 10 }}>1.</Text>
              <Text style={{ fontSize: 10 }}>Vice Chair: {details?.vice_chair}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginHorizontal: 8, fontSize: 10 }}>2.</Text>
              <Text style={{ fontSize: 10 }}>Secretary: {details?.secretary}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginHorizontal: 8, fontSize: 10 }}>3.</Text>
              <Text style={{ fontSize: 10 }}>Treasurer: {details?.treasurer}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={{ marginHorizontal: 8, fontSize: 10 }}>4.</Text>
              <Text style={{ fontSize: 10 }}>or the Supervisory Committee of the Board.</Text>
            </View>
          </View>
          <Text style={[styles.subDes, { fontFamily: "RobotoRegular" }]}>
            The Board should act as quickly as possible in naming an internal interim CEO. If there is a named EVP, they typically will assume interim CEO responsibilities
          </Text>
          <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
            <Text style={[styles.listPart, { fontFamily: "RobotoBold" }]}>Roles:</Text>
            The interim CEO will rely heavily on other key managers for guidance on running the day-to-day operations. Until a permanent CEO is assigned or hired, the board chair will be designated to
            provide support and guidance to the interim CEO, meeting regularly (weekly or bi-weekly) until a permanent CEO is selected. The Chair’s role will be as an advisor or counselor, leaving the
            day-to-day management and operations of the organization to the interim CEO.
          </Text>
        </View>
      </View>
      <View style={[{ marginBottom: 20 }, styles.flexPart]}>
        <Text style={styles.sectionTitle}>3. Action Plan:</Text>
        <Text style={{ fontSize: 11, textDecoration: "underline", marginBottom: 5, fontFamily: "RobotoMedium" }}>Within 24 Hours:</Text>
        <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20, backgroundColor:"#ffffff" }}>
          <View style={{ display: "flex", flexDirection: "row", overflow: "hidden", width: "100%", backgroundColor:"#e2e8f0" }}>
            <View style={{ width: "70%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8 }}>
              <Text style={{ fontSize: 10, lineHeight: 1, color:"#64748b", fontFamily: "RobotoBold" }}>Responsibility</Text>
            </View>
            <View style={{ width: "30%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8, whiteSpace: "nowrap" }}>
              <Text style={{ fontSize: 10, lineHeight: 1, color:"#64748b", fontFamily: "RobotoBold" }}>Responsible Party</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>1. Name and contact the Interim CEO</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board Chair</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>
                2. Contact and advise the following as soon as possible informing them of the change/vacancy and the interim CEO.
              </Text>
              <View style={{ flexDirection: "column", marginTop: 10 }}>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Management Team Members</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>
                    Remaining Members – Board of Directors and all committee members.
                    <Text style={{ fontFamily: "RobotoMedium" }}> See Attachment 2 for contact information.</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board Chair</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>3. Hold Supervisory Committee meeting:</Text>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Execute procedures for securing computer network and execute procedure.</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Secure records</Text>
                </View>
                <View style={{ paddingLeft: 12 }}>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Employee files</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Loan documents</Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Change locks and security system if deemed necessary</Text>
                </View>
                <View style={{ paddingLeft: 12 }}>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Disable master key</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Notify base security and security monitoring company.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Revise security codes at all facilities</Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Secure personal property of departing President/CEO</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Block corporate credit cards</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>
                    Delegate staff to contact outside organizations such as vendors, regulators, security companies, police, leagues,etc. to inform them of the change. Stop purchasing authority CEO with appropriate vendors and investors.
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Supervisory Committee</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>4.Convene a Board meeting:</Text>
              <View style={{ flexDirection: "column", width: 400 }}>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Vote to approve the appointment of the interim President/CEO.</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Confirm the Board Chair’s role as advisor to the interim CEO.</Text>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Determine content and approach to communicating the situation to the staff. Content can include and will not be limited to the following:</Text>
                </View>
                <View style={{ paddingLeft: 12 }}>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Former President/CEO is no longer responsible for organization.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Reason for departure – if it can be shared.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Clarification of the role of the Interim President/CEO</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Clarify appropriate responses to members, vendors and others who ask.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Designate the appropriate person(s) responsible for providing information to the media, members, vendors and others.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>Communicate the process for replacement of the CEO with anticipated time frames.</Text>
                  </View>
                  <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                    <Text>When and how additional information will be provided.</Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", fontSize: 9, marginBottom: 4 }}>
                  <Text style={{ marginHorizontal: 8 }}>•</Text>
                  <Text>Determine audit needs (timing & procedures)</Text>
                </View>
              </View>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board Chair</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>
                5. Disseminate information to staff explaining the situation and the anticipated actions, appropriate communication & reasoning.
              </Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Interim CEO</Text>
            </View>
          </View>
        </View>
        <View wrap={false}>
          <Text style={{ fontSize: 11, textDecoration: "underline", marginBottom: 5, fontFamily: "RobotoMedium" }}>Within 10 Business Days:</Text>
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20, backgroundColor: "#ffffff" }}>
            <View style={{ display: "flex", flexDirection: "row", overflow: "hidden", width: "100%", backgroundColor: "#e2e8f0" }} warp={false}>
              <View style={{ width: "70%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8 }}>
                <Text style={{ fontSize: 10, lineHeight: 1, color: "#64748b", fontFamily: "RobotoBold" }}>Responsibility</Text>
              </View>
              <View style={{ width: "30%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8, whiteSpace: "nowrap" }}>
                <Text style={{ fontSize: 10, lineHeight: 1, color: "#64748b", fontFamily: "RobotoBold" }}>Responsible Party</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
              <View style={{ width: "70%", padding: 8 }} warp={false}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>1. Board Chair meeting with the interim CEO to determine appropriate levels of authority.</Text>
              </View>
              <View style={{ width: "30%", padding: 8 }}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board Chair</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
              <View style={{ width: "70%", padding: 8 }} warp={false}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  2. Board Chair in concert with the interim CEO, to determine appropriate course of action in managing the organization.
                </Text>
              </View>
              <View style={{ width: "30%", padding: 8 }}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board Chair</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
              <View style={{ width: "70%", padding: 8 }} warp={false}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>3. Determine appropriate interim compensation for interim CEO</Text>
              </View>
              <View style={{ width: "30%", padding: 8 }}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
              <View style={{ width: "70%", padding: 8 }} warp={false}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>4. Implement recruitment plan</Text>
              </View>
              <View style={{ width: "30%", padding: 8 }}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Board</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
              <View style={{ width: "70%", padding: 8 }} warp={false}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  5. Interim CEO will maintain regular communication with the Chair throughout the period of time he/she is serving as Interim CEO.
                </Text>
              </View>
              <View style={{ width: "30%", padding: 8 }}>
                <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap" }}>Interim CEO</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={{ display: "flex", flexDirection: "column", fontFamily: "RobotoMedium", fontSize: 24 }}>
        <Text style={{ marginBottom: 12, fontSize: 12, fontFamily: "RobotoMedium" }}>FORM: External Recruitment Plan – President/CEO</Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }]}>
          When internal candidates (Potential Successors) are not available or not yet ready to be named the new CEO, the following options and guidelines are recommended:
        </Text>
        <View style={[{ marginBottom: 2 }, styles.flexPart]}>
          <Text style={[{ whiteSpace: "pre-wrap", fontSize: 11, fontFamily: "RobotoMedium" }]}>
            <Text style={[styles.listPart]}>1. </Text>
            Recruit the CEO without the assistance of an external search firm (not recommended)
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginBottom: 2 }}>
          <Text style={{ fontSize: 11, fontFamily: "RobotoMedium" }}>2. </Text>
          <Text style={{ fontSize: 11, fontFamily: "RobotoMedium" }}>Engage a search firm to either: </Text>
        </View>
        <View style={{ marginLeft: 4, marginBottom: 2 }}>
          <View style={{ flexDirection: "row", marginBottom: 2 }}>
            <Text style={{ marginHorizontal: 8, fontSize: 9, fontFamily: "RobotoMedium" }}>•</Text>
            <Text style={{ fontSize: 9, fontFamily: "RobotoRegular" }}>Manage the search process from beginning to end.</Text>
          </View>
          <View style={{ flexDirection: "row", marginBottom: 4 }}>
            <Text style={{ marginHorizontal: 8, fontSize: 9, fontFamily: "RobotoMedium" }}>•</Text>
            <Text style={{ fontSize: 9, fontFamily: "RobotoRegular" }}>Perform agreed upon segments of the search process, or</Text>
          </View>
          <View style={{ flexDirection: "row", marginBottom: 4 }}>
            <Text style={{ marginHorizontal: 8, fontSize: 9, fontFamily: "RobotoMedium" }}>•</Text>
            <Text style={{ fontSize: 9, fontFamily: "RobotoRegular" }}>On an hourly basis to provide assistance throughout the search process on an as-needed basis.</Text>
          </View>
        </View>
        <View style={[{ marginBottom: 20 }, styles.flexPart]}>
          <Text style={[{ whiteSpace: "pre-wrap", fontSize: 11, fontFamily: "RobotoMedium" }]}>
            <Text style={[{}, styles.listPart]}>3. </Text>
            The Process
          </Text>
        </View>
        <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20, backgroundColor: "#ffffff" }}>
          <View style={{ display: "flex", flexDirection: "row", overflow: "hidden", width: "100%", backgroundColor: "#e2e8f0" }} warp={false}>
            <View style={{ width: "70%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8 }}>
              <Text style={{ fontSize: 10, lineHeight: 1, color: "#64748b", fontFamily: "RobotoBold" }}>Responsibility</Text>
            </View>
            <View style={{ width: "30%", fontSize: 12, lineHeight: 2, textAlign: "left", paddingBottom: 10, paddingTop: 10, paddingLeft: 8, whiteSpace: "nowrap" }}>
              <Text style={{ fontSize: 10, lineHeight: 1, color: "#64748b", fontFamily: "RobotoBold" }}>Responsible Party</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>1. Appoint a Search Committee.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Board</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>2. Submit Request for Proposal (RFP) to three search firms*</Text>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>1.</Text>
                <Text>{details?.request_for_proposal}</Text>
              </View>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>2.</Text>
                <Text>{details?.request_for_proposal_two}</Text>
              </View>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>3.</Text>
                <Text>{details?.request_for_proposal_three}</Text>
              </View>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee</Text>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Review guidelines for selecting a search firm (below)</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>3. Interview search firm and:</Text>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>-</Text>
                <Text>Agree on search methodology, salary range and employee benefits for new CEO.</Text>
              </View>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>-</Text>
                <Text>Confirm desired competencies for the new CEO.</Text>
              </View>
              <View style={{ flexDirection: "row", fontSize: 9, fontFamily: "RobotoRegular", marginBottom: 4 }}>
                <Text style={{ marginHorizontal: 8 }}>-</Text>
                <Text>Agree on professional fee to be paid to the search firm</Text>
              </View>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>4. Select search firm based on Search Committee recommendation.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Board</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>5. Search firm will search for and present 3-5 highly qualified candidates.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Firm</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>6. Conduct first interviews of short list (3-5) of highly qualified candidates.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee and CEO, if available</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>7. Conduct second interviews.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee and CEO, if available. Board may also participate in these interviews.</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>8. Make first and second choice selections from among interviewed candidates and submit to Board.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>
                9. Verification of candidates’ credentials and employability: including, but not be limited to: educational transcripts, reference checks, credit bureau reports (subject to strict Fair
                Credit Reporting Act (FCRA) regulations regarding notification, lawful procedures if report is negative, etc.) and bond check
              </Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Firm</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>10. Make final selection and submit to board for approval.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>11. Notify selected candidate</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Board Chair</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>12. Negotiate compensation, benefits and start date</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee, selected candidate (and Search Firm if requested)</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>13. Notify unsuccessful candidates.</Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Search Committee Chair</Text>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0", width: "100%" }} warp={false}>
            <View style={{ width: "70%", padding: 8 }} warp={false}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>
                14. Send out the job announcement /introduce selected candidate to all employees and other appropriate stakeholder, including but not limited to:
              </Text>
            </View>
            <View style={{ width: "30%", padding: 8 }}>
              <Text style={{ fontSize: 9, marginTop: 2, whiteSpace: "pre-wrap", fontFamily: "RobotoRegular" }}>Board</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ display: "flex", flexDirection: "column", fontFamily: "RobotoMedium", fontSize: 24 }} wrap={false}>
        <Text style={{ marginBottom: 14, fontSize: 11, fontFamily: "RobotoMedium" }}>Guidelines for Selecting a Search Firm*</Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>1. </Text>
          Determine how many years the search firm has been in business. The longer they have been in business the more likely they have established a proven track record.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>2. </Text>
          Determine how many searches they have done in the last year that are specific to the industry.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>3. </Text>
          Identify the typical compensation levels received by those who the firm places. That level should be similar to the compensation your organization expects to pay a replacement CEO.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>4. </Text>
          Remember that the search firm is representing your organization so you must be comfortable with its reputation and the professionalism of the people specifically assigned to your search.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>5. </Text>
          Ask to meet the search consultant that will actually be doing the search. (Often times, the consultant who presents the search proposal is different that the consultant actually doing the
          search.)
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>6. </Text>
          Request recent references from the search firms submitting proposals
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>7. </Text>
          Request the search firms to share the process that they use to do the search (from start to finish); assuring consistency with your approach and philosophy.
        </Text>
        <Text style={[styles.subDes, { whiteSpace: "pre-wrap", fontSize:9, fontFamily: "RobotoRegular" }]}>
          <Text style={[styles.listPart]}>8. </Text>
          Ask the firm to explain how they source (acquire) candidates; research is more valuable than advertising in acquiring a pool of candidates.
        </Text>
      </View>
    </Page>
  );
};

export default EmergencyReplacementPlanReport;
