import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { postData } from "../services/api";
import toastr from "toastr";
import { useSelector } from "react-redux";
import SettingTierPopup from "../components/popup/SettingTierPopup";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const user = useSelector((state) => state.auth?.user);
  const [isOpen, setIsOpen] = useState({
    _id: null,
    open: false,
  });
  const [tierType, setTierType] = useState("");

  useEffect(() => {
    if (user?._id) {
      setTierType(user?.tier_type || "");
    }
  }, [user]);

  return (
    <SettingsContext.Provider
      value={{ tierType, setTierType, isOpen, setIsOpen }}
    >
      {children}
      {isOpen?.open && (
        <SettingTierPopup
          isOpen={isOpen?.open}
          setIsOpen={setIsOpen}
          tierType={tierType}
          setTierType={setTierType}
          user={user}
        />
      )}
    </SettingsContext.Provider>
  );
};

export const useSettingsModal = () => {
  return useContext(SettingsContext);
};
