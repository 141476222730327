import { useCallback } from 'react';

const useByteConverter = () => {
  // Convert bytes to kilobytes
  const bytesToKB = useCallback((bytes) => {
    return (bytes / 1024).toFixed(2);
  }, []);

  // Convert bytes to megabytes
  const bytesToMB = useCallback((bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  }, []);

  // Convert bytes to KB or MB based on size
  const convertBytes = useCallback((bytes) => {
    const kb = bytesToKB(bytes);
    if (parseFloat(kb) > 1024) {
      return `${bytesToMB(bytes)} MB`;
    } else {
      return `${kb} KB`;
    }
  }, [bytesToKB, bytesToMB]);

  return {
    convertBytes,
  };
};

export default useByteConverter;
