import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import { allowedImageExtensions, parseDate, formatDate } from "../../helpers";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import OtherPositionPopup from "./OtherPositionPopup";

import { postData } from "../../services/api";
import moment from "moment";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const ManageEmployeePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  setDetails = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  //const timezone =  Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Denver' });
  const role = localStorage.getItem("role");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([]);

  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});

  const closeModal = () => {
    setIsOpen(false);
    setDetails({});
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      let payload = {
        email,
        designation: designation || null,
      };

      let endpoint = "employee/add";

      if (data?._id) {
        payload = {
          ...payload,
          id: data._id,
          type: "edit",
          first_name: firstName,
          last_name: lastName,
        };
        endpoint = "employee/update";
      } else {
        payload = {
          ...payload,
          firstname: firstName,
          lastname: lastName,
        };
      }
      const result = await postData(endpoint, payload);

      if (result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "manage",
          row_id: data?._id || result?.data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setDetails({});
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  useEffect(() => {
    let firstName = "";
    let lastName = "";
    if (!data?.first_name) {
      firstName = data?.name?.split(/\s+/)[0];
      lastName = data?.name?.split(/\s+/)[1];
    } else {
      firstName = data?.first_name;
      lastName = data?.last_name;
    }
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(data?.email);
    setDesignation(data?.position || data?.designation);
  }, [data]);

  useEffect(() => {
    if (Object.keys(actionValue).length > 0) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("manage_information")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen(false);
                        setFirstName(null);
                        setLastName(null);
                        setEmail(null);
                        setDesignation(null);
                        setDetails({});
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-4  mt-4">
                    <div className="relative col-span-3">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Input
                            label={t("first_name")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("enter_first_name")}
                            inputValue={firstName}
                            setInput={setFirstName}
                            requiredDisplay={true}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Input
                            label={t("last_name")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("enter_last_name")}
                            inputValue={lastName}
                            setInput={setLastName}
                            requiredDisplay={true}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Input
                            label={t("email")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("enter_email")}
                            inputValue={email}
                            setInput={setEmail}
                            requiredDisplay={true}
                            //isDisabled={true}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Select
                            label={t("position")}
                            labelClasses={"!text-xs"}
                            xPlacement={"left"}
                            selectedValue={designation}
                            dropdownClass={"!w-60"}
                            search={true}
                            dropdownData={[
                              {
                                _id: "",
                                label: t("select_position"),
                                value: "",
                              },
                              ...position.map((item) => ({
                                _id: item?._id,
                                label: item?.name,
                                value: item?._id,
                              })),
                              {
                                _id: "others",
                                label: "Other",
                                value: "others",
                              },
                            ]}
                            requiredDisplay={true}
                            getSelectedValue={(e) => {
                              if (e._id === "others") {
                                setEnabledOtherPositionPopup(true);
                              }
                              setDesignation(e._id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("confirm")}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        />
      )}
    </>
  );
};

export default ManageEmployeePopup;
