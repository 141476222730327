import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Button from "../components/form/Button";
import Image from "../components/elements/Image";
import Select from "../components/form/Select";
import toastr from "toastr";

import { useTranslation } from "react-i18next";
import { languages } from "../helpers";

const FrontHeader = ({ settings }) => {
  const { i18n, t } = useTranslation();
  const isLogin = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");
  const [currentLang, setCurrentLang] = useState("");
  const menu = [
    //{ _id: 1, name: "Home", link: "/", type: "link" },
    // { _id: 2, name: "Meet the Team", link: "/#teamDiv", type: "link" },
    {
      _id: 3,
      name: t("why_succession_planning"),
      link: "/about-us",
      type: "link",
    },
    { _id: 4, name: t("contact_us"), link: "/contact-us", type: "link" },
    // { _id: 5, name: "FAQ", link: "/frequently-asked-questions", type: "link" },
    settings && settings.enabled_resource
      ? { _id: 5, name: t("articles"), link: "/resources", type: "link" }
      : "",
  ].filter((item) => item);

  const [width, setWidth] = useState(window.screen?.width);
  const [height, setHeight] = useState(window.screen?.height);
  const handleWindowSizeChange = () => {
    setWidth(window.screen?.width);
    setHeight(window.screen?.height);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if ((width <= 990 && width > height) || (width <= 640 && width < height)) {
      toastr.error(t("mobile_device_manage"));
    }
  }, []);

  const handleLanguageChange = (e) => {
    const newLang = e.value;
    i18n.changeLanguage(newLang);
    sessionStorage.setItem("sessionLang", newLang);
  };

  const [isMenu, setIsMenu] = useState(false);
  const handleMenuToggle = () => {
    setIsMenu(!isMenu);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);

  useEffect(() => {
    const langs = languages();
    const filterValue = langs.filter((item) => item?.value === i18n?.language);
    if (filterValue && filterValue.length > 0) {
      setCurrentLang(filterValue[0]?._id);
    }
  }, [i18n?.language]);

  return (
    <>
      <header
        className={
          "fixed top-0 left-0 w-full h-20 flex items-center z-50 drop-shadow-sm transition-all duration-200" +
          (scroll ? " bg-white" : "")
        }
      >
        <div className="w-full max-w-9xl mx-auto flex justify-between items-center px-5">
          <div className="h-10 relative">
            <Link
              to="/"
              className={
                "block w-full h-full cursor-pointer transition-all duration-200" +
                (!scroll ? " brightness-[5] grayscale-0" : "")
              }
            >
              <Image
                src={"/logo-succession-now.svg"}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain object-left"}
                id={"header-logo"}
              />
            </Link>
          </div>
          <div className="block lg:hidden">
            <Button
              buttonClasses={
                "!bg-transparent !w-10 !p-0 text-white !text-3xl" +
                (scroll ? " !text-slate-600" : "")
              }
              buttonType={"button"}
              buttonIcon={"fa-regular fa-bars"}
              buttonIconPosition={"left"}
              buttonLabel={""}
              buttonLabelClasses={""}
              buttonHasLink={false}
              functions={handleMenuToggle}
            />
          </div>
          <div
            className={
              "fixed lg:relative top-0 lg:top-auto left-0 lg:left-auto w-[300px] lg:w-auto h-screen lg:h-auto p-5 lg:p-0 bg-white lg:bg-transparent ml-auto flex -translate-x-full lg:translate-x-0 transition-all duration-200 lg:items-center flex-col lg:flex-row gap-y-8 lg:gap-x-8 lg:gap-y-0" +
              (isMenu ? " !-translate-x-0" : "")
            }
          >
            {menu.map.length > 0 && (
              <div className="relative">
                <ul className="lg:flex items-center gap-x-4">
                  {menu.map((item) => (
                    <li key={item?._id}>
                      <NavLink
                        key={item?._id}
                        to={item.link}
                        className={({ isActive }) =>
                          "block text-lg lg:text-base font-semibold cursor-pointer py-2 lg:px-0 lg:py-0 xl:px-4 transition-all duration-200 lg:text-white" +
                          (scroll
                            ? " xl:text-slate-800 !text-slate-800"
                            : " xl:text-white text-slate-800 underline decoration-2 underline-offset-8 decoration-dark-teal-400") +
                          (item._id === 2
                            ? "!underline-offset-8 !decoration-dark-teal-400"
                            : "") +
                          (isActive && item._id !== 2
                            ? " !decoration-white "
                            : "")
                        }
                        onClick={handleMenuToggle}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="relative flex flex-wrap lg:flex-nowrap gap-4">
              <NavLink
                to={"/request-demo"}
                className="w-1/2 max-w-[calc(50%-8px)] lg:w-auto flex justify-center items-center gap-2 bg-dark-teal-400 hover:bg-dark-teal-600 text-white rounded-full text-base md:text-sm h-10 py-0 px-6 transition-all duration-200 cursor-pointer"
              >
                {t("demo")}
              </NavLink>
              {isLogin ? (
                <NavLink
                  to={
                    role === "master-admin"
                      ? "/dashboard-statistics"
                      : "/profiles"
                  }
                  className="w-1/2 max-w-[calc(50%-8px)] lg:w-auto flex justify-center items-center gap-2 bg-dark-teal-400 hover:bg-dark-teal-600 text-white rounded-full text-base md:text-sm h-10 py-0 px-6 transition-all duration-200 cursor-pointer whitespace-nowrap"
                  duration={500}
                >
                  {t("my_account")}
                </NavLink>
              ) : (
                <NavLink
                  to={"/signin"}
                  className="w-1/2 max-w-[calc(50%-8px)] lg:w-auto flex justify-center items-center gap-2 bg-dark-teal-400 hover:bg-dark-teal-600 text-white rounded-full text-base md:text-sm h-10 py-0 px-6 transition-all duration-200 cursor-pointer"
                  duration={500}
                >
                  {/* {t('login')}
                   */}
                  {t("my_account")}
                </NavLink>
              )}
              {/* <div className="w-full max-w-[calc(100%-8px)] lg:w-auto">
                <Select
                  xPlacement={'right'}
                  transitionClass={'!w-full lg:!w-auto'}
                  dropdownClass={'!w-full lg:!w-24'}
                  dropdownButtonClass={'!bg-dark-teal-400 hover:!bg-dark-teal-600 text-white rounded-full border-none'}
                  dropdownButtonLabelClass={'text-white'}
                  selectedValue={currentLang} 
                  dropdownData={languages()}
                  getSelectedValue={handleLanguageChange}
                />
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={
            "fixed top-0 left-0 z-[-1] bg-slate-900/50 w-full h-screen block lg:hidden" +
            (isMenu ? " opacity-100 visible" : " opacity-0 invisible")
          }
          onClick={handleMenuToggle}
        ></div>
      </header>
    </>
  );
};

export default FrontHeader;
