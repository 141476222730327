import React from "react";
import { sheduleLink } from "../../../constant/Constant";

const Webinars = () => {
  return (
    <section
      className="relative w-full py-10 bg-home-background  h-[548px] rounded-[20px] mt-16"
      id="company-section"
    >
      <div className="w-full mt-8">
        <div className="space-y-5 px-5">
          <div className="font-normal  text-black font-IBM text-[48px] text-center w-full">
            <span className="italic">Succession</span>
            <br />
            <span className="font-light text-black">Success Webinars</span>
          </div>
          <div className="text-center font-medium text-black font-Outfit text-[16px] max-w-[664px] mx-auto">
            These webinars offer a holistic approach to building succession
            strategies that encompass all levels of your organization.
          </div>
          <div className="text-center font-medium text-black font-Outfit text-[16px] max-w-[664px] mx-auto">
            Discover best practices for developing future leaders, engaging your
            board in the succession process, and creating development
            opportunities that motivate your workforce.
          </div>
        </div>
     
      </div>
      <div className="w-48 mx-auto mt-14">
          <a
            target="_blank"
            href={sheduleLink}
            className={
              "relative z-0 overflow-hidden flex justify-center items-center gap-2 rounded-full h-[52px] py-0 px-3 transition-all duration-200 border-black text-black border after:absolute after:h-full after:content-[''] after:-z-[1] after:top-0 after:left-0 after:transition-[width] after:duration-300 after:ease-in-out after:w-full hover:after:w-0 mx-auto after:bg-transparent hover:border-black hover:text-black capitalize font-IBM font-medium text-base mt-5"
            }
          >
            Register Now
          </a>
        </div>
    </section>
  );
};

export default Webinars;
