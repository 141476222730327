import React, { useEffect, useState } from "react";
import Button from "./Button";
import { classNames } from "../../helpers/classNames";
import useByteConverter from "../../helpers/useByteConverter";
import toastr from "toastr";
import ViewFile from "../elements/ViewFile";

const DragDropfile = ({
  handleFile = () => {},
  progress = 0,
  selectedFile,
}) => {
  const { convertBytes } = useByteConverter();
  const [files, setFile] = useState([]);
  const [message, setMessage] = useState();

  const handleFileChange = (e) => {
    setMessage("");
    let fileList = Array.from(e.target.files);
    e.target.value = null;

    const validImageTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "text/csv",
    ];
    let newFiles = [...files];

    fileList.forEach((file) => {
      if (validImageTypes.includes(file.type)) {
        if (!newFiles.some((existingFile) => existingFile.name === file.name)) {
          newFiles.push(file);
        } else {
          toastr.warning(`File "${file.name}" is already selected.`);
        }
      } else {
        setMessage("Only Excel and CSV files are accepted.");
        toastr.warning("Only Excel and CSV files are accepted!");
      }
    });

    setFile(newFiles);
  };

  useEffect(() => {
    if (files?.length) {
      handleFile(files);
    }
  }, [files]);

  function removeHandler(fileIndex) {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFile(updatedFiles);
  }

  useEffect(() => {
    if (!selectedFile) {
      setFile([]);
    }
  }, [selectedFile]);
  return (
    <>
      <div className="relative">
        <div className="w-full flex justify-center relative space-y-1">
          <div className="w-full h-72 relative overflow-hidden items-center rounded-2xl cursor-pointer border-4 border-gray-300 border-dashed py-20 mt-3 bg-gray-100">
            <input
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
              className="h-full w-full bg-green-200 opacity-0 z-10 absolute top-0 left-0 cursor-pointer"
              name="files[]"
            />
            <div className="h-full w-full z-1 flex flex-col justify-center items-center">
              <div className="flex flex-col space-y-1">
                <div className="text-5xl text-center font-bold text-teal-500">
                  <i className="fa-regular fa-folder-open"></i>
                </div>
                <div className="text-2xl font-normal text-slate-600">
                  Drag & Drop Files Here
                </div>
                <div className="text-xs text-slate-500 text-center ">
                  Supported formats: XLSX & CSV
                </div>
              </div>
              <div className="max-w-8 mx-auto my-3 relative after:absolute after:bg-slate-300 after:w-32 after:h-[1px] after:right-0 after:top-4 before:absolute before:bg-slate-300 before:w-32 before:h-[1px] before:left-0 before:top-4 mb-8">
                <div className="border border-slate-400 w-9 h-9 rounded-full text-black text-sm uppercase text-center font-bold pt-2 bg-slate-50 relative z-[2]">
                  or
                </div>
              </div>
              <div className="flex items-center justify-center space-x-0 lg:space-x-4 ">
                <label
                  htmlFor="fileInput"
                  className="cursor-pointer flex gap-2 items-center justify-center w-32 h-10 flex-shrink-0 bg-transparent border border-dashed border-teal-500 text-teal-500 rounded-md hover:bg-teal-600 transition duration-300 text-sm"
                >
                  <i className="fa-regular fa-upload"></i> Select File
                </label>
              </div>
            </div>
          </div>
        </div>

        {message && (
          <div className="flex justify-center items-center text-xs mb-1 text-red-500">
            {message}
          </div>
        )}
        <div className="mt-5 max-h-[calc(100vh-500px)] overflow-y-auto">
          <ul className="space-y-2">
            {files.map((file, i) => (
              <ViewFile
                key={file?.name + i}
                file={file}
                progress={progress || 0}
                removeHandler={() => removeHandler(i)}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DragDropfile;
