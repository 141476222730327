import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/form/Button";
import NotificationDropdown from "./NotificationDropdown";
import UserDropdown from "./UserDropdown";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest, postData } from "../services/api";
import { authLogout } from "../store/authSlice";
import TermsPopup from "../components/popup/TermsPopup";
import ProfilePopup from "../components/popup/ProfilePopup";
import HelpPopup from "../components/popup/HelpPopup";
import { useNavigate } from "react-router-dom";
import Select from "../components/form/Select";
import toastr from "toastr";
import { Tooltip } from "react-tooltip";

import { useTranslation } from "react-i18next";
import { languages } from "../helpers";
import BackConfirmationPopup from "../components/BackConfirmationPopup";
import axios from "axios";
import {
  NINEBOX_API_URL,
  NINEBOX_BASE_URL,
  ORGCHART_API_URL,
  ORGCHART_BASE_URL,
} from "../config/host";
import nineBoxLogo from "../assets/image/9boxlogo.png";
import orgChartLogo from "../assets/image/orgchartLogo.png";
import { useDataSyncModal } from "../Context/SyncDataModalContext";

const DefaultHeader = ({ isMenuOpen, setIsMenuOpen }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const loginAs = localStorage.getItem("loginAs");
  const authToken = localStorage.getItem("auth_token");
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setSyncDataModalOpen,
    syncingLoader,
    setSyncingLoader,
    syncingStep,
    setSyncingStep,
  } = useDataSyncModal();
  const [enabledTermsPopup, setEnabledTermsPopup] = useState(false);
  const [enabledProfilePopup, setEnabledProfilePopup] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [currentLang, setCurrentLang] = useState("");
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g, "");
  const [enabledHelpPopup, setEnabledHelpPopup] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState({
    _id: 1,
    label: "9BoxNow",
    image: nineBoxLogo,
    code: "9BoxNow",
  });
  
  // const handleWindowSizeChange = () => {
  //   setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);

  // useEffect(() => {
  //   if(width <= 768){
  //     toastr.error(t('mobile_device_manage'));
  //   }
  // }, []);

  const checkValueInArrayObject = (arr, value) => {
    if (Array.isArray(arr)) {
      return arr.some((obj) => Object.values(obj).includes(value));
    } else {
      return false; // Return false if arr is not an array
    }
  };

  useEffect(() => {
    if (user?.role_details) {
      if (checkValueInArrayObject(user?.role_details, role)) {
        //console.log('1.1');
      } else {
        //console.log('1.2');
        dispatch(authLogout());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginAs");
        localStorage.removeItem("role");
        localStorage.removeItem("isTrial");

        localStorage.removeItem("reminderId");
        localStorage.removeItem("reminderDate");
        localStorage.removeItem("reminderFiled");
        return navigate("/");
      }
    }
  }, [user, role]);

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") &&
      role !== "master-admin" &&
      role !== "basic-administrator" &&
      (!loginAs || user?.isInvitedEmployee === 2)
    ) {
      dispatch(async () => {
        try {
          const results = await postData("subscription/check", {
            sortQuery: { created_at: -1 },
            status: 3,
          });
          if (results.data && !results.data.trans_details) {
            if (
              role === "customer-admin" &&
              results.data.payment_status &&
              results.data.payment_status === 3
            ) {
              localStorage.setItem("isTrial", 1);
            } else {
              localStorage.removeItem("isTrial");
              const results2 = await postData("check-promo-user", {
                sortQuery: { created_at: -1 },
              });
              if (
                results2.data &&
                results2.data.promo_code &&
                results2.data.isPaid === 1
              ) {
                try {
                  const results3 = await postData("check-promocode", {
                    promo_code: results2.data.promo_code,
                  });
                  if (results3.status && results3.status === 200) {
                    return navigate("/dashboard");
                  } else {
                    return navigate("/buy-plan");
                    //return navigate("/plans");
                  }
                } catch (error) {
                  console.log(error.message);
                }
              } else {
                //return navigate("/plans");
                return navigate("/buy-plan");
              }
            }
          } else if (results.data && results.data.trans_details) {
            if (
              results.data.trans_details.status &&
              results.data.trans_details.status !== 1
            ) {
              dispatch(authLogout());
              return navigate("/signin");
            }
            if (role === "customer-admin") {
              if (
                results.data.payment_status &&
                results.data.payment_status === 3
              ) {
                localStorage.setItem("isTrial", 1);
              } else {
                localStorage.removeItem("isTrial");
              }
              try {
                const result = await postData("check/accecpt-terms", {});
                if (result.status && result.status === 400) {
                  setEnabledTermsPopup(true);
                } else {
                  try {
                    const result2 = await postData("profile/details", {});
                    if (
                      result2.data &&
                      (!result2.data.designation ||
                        !result2.data.retirement_window)
                    ) {
                      setProfileData(result2.data);
                      setEnabledProfilePopup(true);
                    }
                  } catch (err) {
                    console.log(err.message);
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (
              role === "customer-user" ||
              role === "customer-subadmin"
            ) {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data && !result2.data.retirement_window) {
                  setProfileData(result2.data);
                  setEnabledProfilePopup(true);
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (role === "customer-boardmember") {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data) {
                  if (
                    path !== "change-password" &&
                    path !== "perspective-board-member"
                  ) {
                    return navigate("/boardroom");
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (role === "customer-executive") {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data) {
                  if (path !== "change-password") {
                    return navigate("/add-executive");
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            }
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);

  const handleLanguageChange = (e) => {
    const newLang = e.value;
    i18n.changeLanguage(newLang);
    sessionStorage.setItem("sessionLang", newLang);
  };

  useEffect(() => {
    const langs = languages();
    const filterValue = langs.filter((item) => item?.value === i18n?.language);
    if (filterValue && filterValue.length > 0) {
      setCurrentLang(filterValue[0]?._id);
    }
  }, [i18n?.language]);

  const companyCreateVerify = useCallback(
    async (url, headersToken, selectedsite) => {
      const data = {};
      try {
        const result = await apiRequest({
          method: "POST",
          url: url,
          headers: {
            Authorization: `Bearer ${headersToken}`,
            "Content-Type": "application/json",
          },
        });
        // const result = await axios.post(url, data, {
        //   headers: {
        //     Authorization: `Bearer ${headersToken}`,
        //     "Content-Type": "application/json",
        //   },
        // });
        if (result?.data?.status === 200) {
          setSyncingLoader(false);
          setSyncingStep({
            step: "1",
            message: "Comapny syncing successfull",
            status: 200,
            selectedWebsite: selectedsite,
          });
          if (result?.data) {
            setTimeout(() => {
              setSyncingStep({
                step: "2",
                message: null,
                selectedWebsite: selectedsite,
              });
            }, 2000);
          }
        } else if (result?.data?.status === 417) {
          setSyncingLoader(false);
          setSyncingStep({
            step: "2",
            message: "Comapany Already Exists",
            status: 417,
            selectedWebsite: selectedsite,
          });
          if (result?.data?._id && result?.data?.email) {
            setTimeout(() => {
              setSyncingStep({
                step: "2",
                message: null,
                selectedWebsite: selectedsite,
              });
            }, 1000);
          }
        } else {
          setSyncingLoader(false);
          setSyncingStep({
            step: "1",
            message:
              result?.data?.message ||
              "An error occurred while processing the request.",
            status: 400,
            selectedWebsite: selectedsite,
          });
          setTimeout(() => {
            setSyncDataModalOpen(false);
            toastr.error(
              "Please click on the 9box now button to re-syncing the company"
            );
          }, 1000);
        }
      } catch (err) {
        setSyncingLoader(false);
        setSyncingStep({
          step: "1",
          message:
            err?.response?.data?.message ||
            "An error occurred while processing the request.",
          status: 400,
        });
        setTimeout(() => {
          setSyncDataModalOpen(false);
          toastr.error(
            "Please click on the 9box now button to re-syncing the company"
          );
        }, 1000);
      }
    },
    []
  );

  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    setSyncingLoader(true);
    setSyncDataModalOpen(true);
    setSyncingStep({ step: "1", message: null });
    try {
      const res = await postData("create/token", {
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "verify/token";
        const headersToken = res?.data;
        companyCreateVerify(url, headersToken, selectedWebsite);
      } else {
        toastr.error(res?.data?.message || "Something went wrong");
        setSyncDataModalOpen(false);
      }
    } catch (err) {
      console.log(err);
      setSyncingLoader(false);
      toastr.error("Somthing went wrong !!!");
      setSyncDataModalOpen(false);
    }
  };


  return (
    <>
      <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-2 sm:px-6 bg-white shadow gap-1 sm:gap-4">
        {loginAs && (
          <div
            className="relative rounded-md"
            data-tooltip-id={`button${1}`}
            data-tooltip-place="bottom"
          >
            <Button
              buttonType={"button"}
              buttonLabel={"Back To Admin"}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonClasses={
                "rounded-tl-xl shadow-lg rounded-br-xl leading-none hover:!bg-dark-teal-400/80"
              }
              // buttonLink={"/login-as?back=true"}
              buttonHasLink={false}
              functions={() => setIsOpen(true)}
            />
            <Tooltip
              id={`button${1}`}
              className="!text-xs !bg-teal-500 z-50 rounded !py-2 !px-2"
              render={() => (
                <div className="gap-x-1 gap-y-1 max-w-[180px] text-center">
                  <div className="text-[13px] font-normal flex items-center">
                    <div className="font-semibold">
                      Click Here For Easy Access Back To The Admin Panel
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        )}

        <div className="relative flex-shrink-0">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-solid fa-bars"}
            buttonIconPosition={"left"}
            buttonClasses={
              "px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900"
            }
            functions={menuToggle}
          />
        </div>
        <div className="relative ml-auto flex justify-end items-center flex-shrink-0 divide-x divide-slate-200">
          {role === "customer-admin" &&
            user?.email === "sujaymondal9@gmail.com" && (
              <div className="flex items-center gap-1 px-2">
                <div className="relative w-36">
                  <Select
                    xPlacement={"right"}
                    transitionClass={"!w-full lg:!w-auto"}
                    dropdownClass={"!w-full lg:!w-36"}
                    dropdownButtonClass={""}
                    dropdownButtonLabelClass={""}
                    selectedValue={selectedWebsite}
                    getSelectedValue={(e) => {
                      setSelectedWebsite(e);
                    }}
                    dropdownData={[
                      {
                        _id: 1,
                        label: "9BoxNow",
                        image: nineBoxLogo,
                        code: "9BoxNow",
                      },
                      {
                        _id: 2,
                        label: "ORG Chart",
                        image: orgChartLogo,
                        code: "orgChart",
                      },
                    ]}
                  />
                </div>
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-arrows-rotate"}
                  buttonIconPosition={"left"}
                  buttonClasses={
                    "!bg-slate-100 !text-slate-500 !px-0 !aspect-[1/1] hover:!text-slate-800 hover:!bg-slate-200"
                  }
                  functions={() => {
                    if (selectedWebsite?.code === "orgChart") {
                      getGenarateToken(ORGCHART_BASE_URL, ORGCHART_API_URL);
                    } else if (selectedWebsite?.code === "9BoxNow") {
                      getGenarateToken(NINEBOX_BASE_URL, NINEBOX_API_URL);
                    } else {
                      toastr.error("Please Select a Website For Syncing");
                    }
                  }}
                />
              </div>
            )}
          {role && role !== "master-admin" && (
            <div
              className="relative px-2"
              data-tooltip-id={`help`}
              data-tooltip-place="bottom"
            >
              <Button
                //buttonLabel={t("help")}
                buttonIcon={"fa-light fa-circle-info"}
                buttonIconPosition={"right"}
                buttonClasses={
                  "!bg-slate-100 !text-slate-500 !px-0 !aspect-[1/1] hover:!text-slate-800 hover:!bg-slate-200"
                }
                buttonLabelClasses={"!text-white"}
                functions={() => {
                  setEnabledHelpPopup(true);
                }}
              />
              <Tooltip
                id={`help`}
                className="!text-xs !bg-teal-500 z-50 rounded !py-2 !px-2"
                render={() => (
                  <div className="gap-x-1">
                    <div className="text-xs font-normal leading-none flex items-center">
                      <div className="font-semibold">Help</div>
                    </div>
                  </div>
                )}
              />
            </div>
          )}

          {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={'right'} />
          </div> */}
          <div className="relative px-2">
            <Select
              xPlacement={"right"}
              transitionClass={"!w-full lg:!w-auto"}
              dropdownClass={"!w-full lg:!w-24"}
              dropdownButtonClass={"!bg-white border-none"}
              dropdownButtonLabelClass={""}
              selectedValue={currentLang}
              dropdownData={languages()}
              getSelectedValue={handleLanguageChange}
            />
          </div>

          <div className="relative pl-2">
            <UserDropdown xPlacement={"right"} />
          </div>
        </div>
      </header>
      {enabledTermsPopup && (
        <TermsPopup
          isOpen={enabledTermsPopup}
          setIsOpen={(val) => setEnabledTermsPopup(val)}
        ></TermsPopup>
      )}
      {enabledProfilePopup && (
        <ProfilePopup
          isOpen={enabledProfilePopup}
          setIsOpen={(val) => setEnabledProfilePopup(val)}
          data={profileData}
        ></ProfilePopup>
      )}
      {enabledHelpPopup && (
        <HelpPopup
          isOpen={enabledHelpPopup}
          setIsOpen={(val) => setEnabledHelpPopup(val)}
        ></HelpPopup>
      )}

      {isOpen && (
        <BackConfirmationPopup isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
};

export default DefaultHeader;
