import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "animate.css";
import { useTranslation } from "react-i18next";
import CompanyItem from "../../Items/CompanyItem";
import { postData } from "../../../services/api";

const TrustedBy = () => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [clients, setClientsData] = useState([]);

  useEffect(() => {
    const clientsList = async () => {
      setLoading(true);
      try {
        const result = await postData("client/list", {
          status: 1,
          sortQuery: { ordering: 1 },
        });
        if (result.data) {
          setClientsData(result.data);
          // setClientsData(
          //   result.data.map((item,index)=>{
          //     return {
          //       image : item?.image
          //     }
          //   })
          // );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    clientsList();
  }, []);
  const CompanyData = {
    title: t("Trusted by"),
    Company: clients,
  };
  return (
    <section
      className="relative w-full py-10 bg-home-background  h-[548px] rounded-[20px] mt-32"
      id="company-section"
    >
      <div className="font-normal italic text-black font-IBM text-[48px] text-center w-full">
        Trusted by
      </div>
      <div className="relative  py-2 px-2 mt-14">
        <Swiper
          spaceBetween={10}
          slidesPerView={5}
          loop={true}
          // navigation={false}
          modules={[Navigation, Autoplay, EffectFade]}
          speed={5000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          className="w-full"
        >
          {CompanyData.Company.map((item) => {
            return (
              <SwiperSlide key={item?._id}>
                <div className=" w-[270] h-[270px] bg-white rounded-2xl flex flex-col items-center justify-center">
                  <div className="w-[100px] h-[100px] overflow-hidden bg-slate-100 rounded-full relative border border-slate-300">
                    <img
                      className="w-full h-full object-contain"
                      src={item?.image}
                      alt=""
                    />
                  </div>
                  <div className="mt-4 text-[24px] text-center lg:text-base xl:text-lg font-light text-black capitalize !leading-none max-w-[144px] w-full line-clamp-3">
                    {item?.name}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default TrustedBy;
