import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../helper/classNames";
import Button from "../form/Button";
import { useTranslation } from "react-i18next";
import { handleDownload, monthYear } from "../../helpers";
import { Tooltip } from "react-tooltip";
import DragDropfile from "../form/DragDropfile";
import toastr from "toastr";
import { postData } from "../../services/api";
import EmployeeUploadedList from "../section/EmployeeUploaded/EmployeeUploadedList";
import ConfirmationPopup from "./ConfirmationPopup";

const EmployeeUploadPopup = ({
  isOpen,
  setIsOpen = () => {},
  loadList = () => {},
  loadPositionList = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState("");
  const tabLabels = {
    newEmployee: "New Employees",
    currentEmployee: "Current Employees",
    removedEmployee: "Removed Employees",
    excludedEmployee: "Excluded Employees",
  };
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tabType, setTabType] = useState("newEmployee");
  const [uploadedStep, setUploadedStep] = useState({
    step: 1,
    message: "",
    data: null,
  });
  const [message, setMessage] = useState("");
  const [allData, setAllData] = useState({
    rejectedUser: [],
    existingUser: [],
    newUser: [],
    existedUsersonDifferentCompany: [],
  });
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const time = new Date().getTime();

  const downloadSampleFile = async () => {
    try {
      const res = await postData("download/sample-csv", {});
      if (res) {
        const blob = new Blob([res], { type: "text/csv" });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "succession-employee-" + time + ".csv";
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
      } else {
        toastr.error(res.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleFile = (newFile) => {
    let file = newFile[0];
    if (file) {
      if (
        file.type === "application/vnd.ms-excel" || // .xls
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || // .xlsx
        file.type === "text/csv" // .csv
      ) {
        setSelectedFile(file);
        // sendFile(file);
      } else {
        toastr.warn("Invalid file type. Please select an Excel or CSV file.");
      }
    }
  };

  const onSubmit = async (e) => {
    setLoading(true);
    setProgress(0);
    try {
      const payload = new FormData();
      payload.append("xls", selectedFile);

      const path = "/import-data";

      const result = await postData(path, payload, (event) => {
        const percent = Math.round((event.loaded * 100) / event.total);
        setProgress(percent);
      });

      if (result.status && result.status === 200) {
        console.log(result, "result");
        toastr.success(result.message);
        setAllData((prev) => ({
          rejectedUser: result?.rejectedData || [],
          existingUser: result?.existingUsers || [],
          newUser: result?.newUsers || [],
          existedUsersonDifferentCompany: result?.excludedEmployees || [],
        }));
        setUploadedStep({
          step: 2,
          message: "",
          data: {},
        });
      } else {
        toastr.error(result.message);
        setSelectedFile("");
      }
    } catch (error) {
      toastr.error(error.message);
    } finally {
      setLoading(false);
      setProgress(0);
    }
  };

  useEffect(() => {
    if (allData?.rejectedUser?.length > 0) {
      setEnabledConfirmationPopup(true);
      setMessage(
        <div>
          <span className="font-bold text-red-600">Missing Employees</span> :
          The file you are uploading is missing some employees who are already
          in the Successionnow system. These employees are set to be removed. To
          retain these employees, please visit the 'Removed Employees' tab and
          follow the instructions provided.
        </div>
      );
    } else if (allData?.existedUsersonDifferentCompany?.length > 0) {
      setEnabledConfirmationPopup(true);
      setMessage(
        <div>
          <span className="font-bold text-red-600">Excluded Employees</span> :
          Your file was uploaded successfully, but some employees were not added
          because they are associated with a different company in our system.
          Please review the excluded employees list for details.
        </div>
      );
    }
  }, [allData]);

  const handleEmployeeUpload = async (data) => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("xls", selectedFile);

      if (data === "keep" && Array.isArray(allData?.rejectedUser)) {
        allData.rejectedUser.forEach((obj, index) => {
          payload.append(`unremovedEmployees[${index}]`, obj?._id);
        });
      }
      const path = "/confirm-new-employee";
      console.log(payload, "payloadpayload");

      const result = await postData(path, payload);

      if (result.status && result.status === 200) {
        setIsOpen(false);
        loadList();
        loadPositionList();
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      console.log(error, "result");
      toastr.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCSVDownloadClick = (e) => {
    e.preventDefault();
    setIsDownloadingCSV(true);

    setTimeout(() => {
      setIsDownloadingCSV(false);
      const link = document.createElement("a");
      link.href = "/sample_file.xlsx";
      link.download = "sample_file.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 2000);
  };

  return (
    <div className="relative z-50 overflow-hidden w-full">
      <div
        className={classNames(
          "fixed inset-0 bg-black bg-opacity-25 transition-all duration-300",
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        )}
      />
      <div
        className={classNames(
          "fixed inset-0 overflow-y-auto transition-transform duration-300",
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        )}
      >
        <div className="flex h-screen justify-end w-full overflow-hidden">
          <div
            className={classNames(
              "w-full flex flex-col max-w-4xl rounded-l-2xl p-4 bg-white text-left align-middle shadow-xl transition-all duration-300",
              isOpen ? "translate-x-0" : "translate-x-full"
            )}
          >
            <div className="relative flex-shrink-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900 text-center mb-4">
                {uploadedStep?.step === 2
                  ? "Imported Employees"
                  : t("bulk_employee_upload")}
              </h3>
              <div className="absolute -top-4 -right-4 z-10">
                <Button
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-times"}
                  buttonIconPosition={"left"}
                  buttonLabel={""}
                  buttonClasses={
                    "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                  }
                  functions={closeModal}
                  buttonHasLink={false}
                />
              </div>
            </div>

            {uploadedStep?.step === 1 && (
              <>
                <div className="relative mt-2 flex-shrink flex-grow flex flex-col">
                  <div className="flex items-end gap-3">
                    <div className="relative mt-5">
                      <h1 className="capitalize text-slate-600 font-medium text-sm">
                        Bulk Upload
                      </h1>
                      <p className="capitalize text-slate-500 text-xs">
                        Upload employees from an Excel file.
                      </p>
                    </div>
                  </div>
                  <DragDropfile
                    handleFile={(file) => handleFile(file)}
                    progress={progress}
                    selectedFile={selectedFile}
                  />
                  <div className="space-y-3 bg-gray-50 px-4 py-4 rounded-lg mt-2">
                    <div className="flex items-center justify-between w-full">
                      <h4 className="font-medium">CSV Format Guide</h4>

                      <div className="flex items-center gap-4">
                        <div className="bg-teal-50 p-4 rounded-lg border border-teal-200 flex items-center gap-4">
                          <p className="text-sm text-gray-600">
                            Download the sample CSV file to understand the
                            expected format.
                          </p>

                          <a
                            href="#"
                            onClick={handleCSVDownloadClick}
                            className={`text-teal-500 hover:text-teal-800 text-sm underline flex items-center gap-2 ${
                              isDownloadingCSV
                                ? "pointer-events-none text-gray-400"
                                : ""
                            }`}
                          >
                            {isDownloadingCSV ? (
                              <i className="fa-duotone fa-spinner-third animate-spin"></i>
                            ) : (
                              <i className="fa-regular fa-file-arrow-down"></i>
                            )}
                            <span>
                              {isDownloadingCSV
                                ? "Preparing download..."
                                : "Sample File"}
                            </span>
                          </a>
                        </div>

                        <Tooltip
                          id="download-XLSX"
                          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                          render={() => (
                            <div className="text-white">
                              Click to download sample employee upload XLSX
                              file.
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex items-start divide-x">
                      <div className="w-1/2 pr-2">
                        <p className="text-sm text-rose-600 font-semibold">
                          Required Columns:
                        </p>
                        <code className="text-xs block bg-white p-2 rounded border">
                          firstName, lastName, email
                        </code>
                      </div>
                      <div className="w-1/2 pl-2">
                        <p className="text-sm text-gray-600 font-semibold">
                          Optional Columns:
                        </p>
                        <code className="text-xs block bg-white p-2 rounded border">
                          position
                        </code>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-4 mt-2">
                    <Button
                      buttonClasses={
                        "bg-white border border-slate-300 !text-slate-400 ml-2"
                      }
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-regular fa-xmark"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      functions={() => {
                        setIsOpen(false);
                      }}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonClasses=""
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-solid fa-upload"}
                      buttonIconPosition={"left"}
                      buttonLabel={"Import Now"}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                  </div>
                </div>
              </>
            )}
            {uploadedStep?.step === 2 && (
              <>
                <div className="relative mt-2 flex-shrink flex-grow flex flex-col">
                  <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5 flex-shrink-0">
                    {[
                      "newEmployee",
                      "currentEmployee",
                      "removedEmployee",
                      ...(allData?.existedUsersonDifferentCompany?.length > 0
                        ? ["excludedEmployee"]
                        : []),
                    ].map((type) => (
                      <button
                        key={type}
                        type="button"
                        className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform
      ${
        tabType === type
          ? type === "excludedEmployee"
            ? "text-rose-600 border-rose-600 font-semibold"
            : "text-teal-500 border-teal-500 font-semibold"
          : "text-slate-500 border-transparent hover:text-teal-500 hover:border-teal-500"
      }`}
                        onClick={() => setTabType(type)}
                      >
                        {tabLabels[type]}
                        {type === "excludedEmployee" &&
                          tabType !== "excludedEmployee" && (
                            <i className="fa-solid fa-bell text-rose-600 ml-2 animate-[wiggle_1.5s_ease-in-out_infinite]"></i>
                          )}
                      </button>
                    ))}
                  </div>
                  {tabType === "newEmployee" && (
                    <>
                      <EmployeeUploadedList
                        userList={allData?.newUser || []}
                        setUserList={setAllData}
                        onSubmitEmployee={() => {}}
                      />
                      {allData?.newUser?.length > 0 && (
                        <div className="flex justify-end gap-4 mt-2">
                          <Button
                            buttonClasses={
                              "bg-white border border-slate-300 !text-slate-400 ml-2"
                            }
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setUploadedStep({
                                step: 1,
                                message: "",
                              });
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-upload"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Import Employee"}
                            functions={() => handleEmployeeUpload()}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {tabType === "currentEmployee" && (
                    <>
                      <EmployeeUploadedList
                        userList={allData?.existingUser || []}
                        setUserList={""}
                        onSubmitEmployee={() => {}}
                      />
                      {allData?.existingUser?.length > 0 && (
                        <div className="flex justify-end gap-4 mt-2">
                          <Button
                            buttonClasses={
                              "bg-white border border-slate-300 !text-slate-400 ml-2"
                            }
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setUploadedStep({
                                step: 1,
                                message: "",
                              });
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-upload"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Import Employee"}
                            functions={() => handleEmployeeUpload()}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {tabType === "removedEmployee" && (
                    <>
                      <EmployeeUploadedList
                        userList={allData?.rejectedUser || []}
                        setUserList={""}
                        onSubmitEmployee={() => {}}
                      />
                      {allData?.rejectedUser?.length > 0 && (
                        <div className="flex justify-end gap-4 mt-2">
                          <Button
                            buttonClasses={
                              "bg-white border border-slate-300 !text-slate-400 ml-2"
                            }
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setUploadedStep({
                                step: 1,
                                message: "",
                              });
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=" !bg-rose-500"
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-trash"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Remove"}
                            functions={() => handleEmployeeUpload("")}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-rotate"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Keep"}
                            functions={() => handleEmployeeUpload("keep")}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {tabType === "excludedEmployee" && (
                    <>
                      <div className="bg-yellow-100 border-l-4 border-yellow-400 text-yellow-700 p-4 rounded-lg flex items-start">
                        <i className="fa-solid fa-triangle-exclamation text-yellow-600 mr-3 mt-1"></i>
                        <div>
                          <p className="font-semibold">Warning!</p>
                          <p>
                            Your file was uploaded successfully, but some
                            employees were not added because they are associated
                            with a different company in our system. Please
                            review the list below for details.
                          </p>
                        </div>
                      </div>
                      <div className="relative">
                        <EmployeeUploadedList
                          userList={
                            allData?.existedUsersonDifferentCompany || []
                          }
                          setUserList={""}
                          onSubmitEmployee={() => {}}
                        />
                      </div>
                      {/* {allData?.existedUsersonDifferentCompany?.length > 0 && (
                        <div className="flex justify-end gap-4 mt-2">
                          <Button
                            buttonClasses={
                              "bg-white border border-slate-300 !text-slate-400 ml-2"
                            }
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            functions={() => {
                              setUploadedStep({
                                step: 1,
                                message: "",
                              });
                            }}
                            buttonHasLink={false}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-upload"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Import Employee"}
                            functions={() => handleEmployeeUpload()}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                        </div>
                      )} */}
                      <div className="bg-blue-100 border-l-4 border-blue-400 text-blue-700 p-4 rounded-lg flex items-start mt-auto">
                        <i className="fa-solid fa-circle-info text-blue-600 mr-3 mt-1"></i>
                        <div>
                          <p className="font-semibold">Information</p>
                          <p>
                            If you believe these employees should be included,
                            please contact support for assistance.
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={t("Warning")}
          message={message}
          confirmButtonLabel={t("Resolve")}
          cancelButtonLabel={t("cancel")}
          getActionValue={(obj) => {
            if (obj && obj.button_type === "confirm") {
              if (allData?.rejectedUser?.length > 0) {
                setTabType("removedEmployee");
                setEnabledConfirmationPopup(false);
              } else if (allData?.existedUsersonDifferentCompany?.length > 0) {
                setTabType("excludedEmployee");
                setEnabledConfirmationPopup(false);
              }
            } else if (obj && obj.button_type === "close") {
              setEnabledConfirmationPopup(false);
              setTabType("newEmployee");
            }
          }}
        />
      )}
    </div>
  );
};

export default EmployeeUploadPopup;
