import axios from "axios";
import BASE_URL from "../config/host";

export const postData = async (url, data, onUploadProgress) => {
  try {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    const res = await instance.post(url, data, {
      onUploadProgress, // Track progress
    });
    return res.data;
  } catch (error) {
    return { status: 400, message: error?.response?.data?.message || error.message };
  }
};

export const apiRequest = async ({
  method,
  url,
  data = {},
  params = {},
  headers = {},
  useBaseUrl = true,
}) => {
  try {
    const baseURL = useBaseUrl ? BASE_URL : "";
    const accessToken = localStorage.getItem("accessToken");
    const instance = axios.create({
      baseURL,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
        ...headers,
      },
    });

    const response = await instance.request({
      method, // HTTP method (GET, POST, PUT, DELETE, etc.)
      url, // API endpoint
      data, // Body payload (for POST, PUT, PATCH)
      params, // Query parameters
    });

    return response.data;
  } catch (error) {
    return {
      status: error.response?.status || 400,
      message: error.response?.data?.message || error.message,
    };
  }
};
