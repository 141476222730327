import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import toastr from "toastr";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import Input from "../form/Input";
import Button from "../form/Button";
import { useDebounce } from "../../helpers/useDebounce";
import Switch from "../form/Switch";
import { authLogin } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import { PositionTier } from "../../constant/Constant";
import ConfirmationPopup from "./ConfirmationPopup";

const SettingTierPopup = ({
  isOpen,
  setIsOpen = () => {},
  tierType,
  setTierType = () => {},
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] = useState({
    open: false,
    value: "",
  });

  useEffect(() => {
    if (user?._id) {
      setTierType(user?.tier_type);
    }
  }, [user]);

  const handleTierOptionChange = async (value) => {
    setLoading(true);
    try {
      const res = await postData("settings/enabled-data", {
        _id: user?._id,
        filed: "tier_type",
        filedValue: value,
        type: "enabled-tier",
      });
      if (res?.status === 200) {
        setTierType(res?.data?.tier_type);
        setLoading(false);
        dispatch(authLogin({ ...user, tier_type: res?.data?.tier_type }));
        setEnabledConfirmationPopup({
          open: false,
          value: "",
        });
      } else {
        toastr.error(
          res?.message || res?.data?.message || "Something went wrong"
        );
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      toastr.error("Somthing went wrong !!!");
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {"Company Settings"}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen({ _id: null, open: false });
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-6 p-4 bg-white shadow-md rounded-lg border border-gray-200">
                    <div className="bg-rose-100 border-l-4 border-rose-400 text-rose-700 p-4 rounded-lg flex items-start">
                      <i className="fa-solid fa-triangle-exclamation text-rose-600 mr-3 mt-1"></i>
                      <div>
                        <p className="font-semibold">Alert!</p>
                        <p>
                          {`Your company is currently in the ${
                            tierType === PositionTier
                              ? "Position-Wide"
                              : "Company-Wide"
                          } Tier. If
                        you enable the ${
                          tierType === PositionTier
                            ? "Company-Wide"
                            : "Position-Wide"
                        } Tier, the rated successor
                        values in the Succession Plan will be reset.`}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                      <span className="text-lg font-bold text-teal-600">
                        {tierType === PositionTier
                          ? "Disable Position Wide Tier"
                          : "Enable Position Wide Tier"}
                      </span>
                      <Switch
                        onChange={(e) => {
                          if (!loading) {
                            // handleTierOptionChange(
                            //   e.target.checked
                            //     ? "position-wide"
                            //     : "company-wide"
                            // );
                            setEnabledConfirmationPopup({
                              open: true,
                              value: e.target.checked
                                ? "position-wide"
                                : "company-wide",
                            });
                          }
                        }}
                        checked={tierType === PositionTier ? true : false}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledConfirmationPopup?.open && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup?.open}
          title={"Confirmation"}
          message={`"Concern" & "Predictors" all data will reset after "confirm". Are you sure you want to continue?`}
          confirmButtonLabel={"Confirm"}
          cancelButtonLabel={"Cancel"}
          getActionValue={(obj) => {
            if (obj && obj.button_type === "confirm") {
              handleTierOptionChange(enabledConfirmationPopup?.value);
            } else if (obj && obj.button_type === "close") {
              setEnabledConfirmationPopup({
                open: false,
                value: "",
              });
            }
          }}
        />
      )}
    </>
  );
};

export default SettingTierPopup;
