import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { postData } from "../services/api";
import ManagePositionPopup from "../components/popup/ManagePositionPopup";
import toastr from "toastr";
import { useSelector } from "react-redux";
import { PositionTier } from "../constant/Constant";

const PositionManageContext = createContext();

export const PositionManageProvider = ({ children }) => {
  const [selectedPosition, setSelectedPosition] = useState("");
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });
  const user = useSelector((state) => state.auth?.user);

  const [isAssignTierInPosition, setIsAssignTierInPosition] = useState(false);
  const [isCloseModal, setIsCloseModal] = useState(false);
  const [loadPositionList, setLoadPositionList] = useState(null); // 🔹 Function Store করার State

  const onCheckingAssignTier = async (positionId) => {
    if (selectedPosition) {
      try {
        const result = await postData("check-assign-tier", {
          position: positionId || selectedPosition,
        });
        if (result.status && result.status === 200) {
          if (result.data === 1) {
            setIsAssignTierInPosition(true);
            setIsopen({ _id: null, open: false });
          } else {
            setIsopen({ _id: selectedPosition, open: true });
            setIsAssignTierInPosition(false);
            setIsCloseModal(false);
          }
        } else {
          toastr.error(result.message);
          setIsopen({ _id: null, open: false });
          setIsAssignTierInPosition(false);
          setIsCloseModal(true);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsopen({ _id: null, open: false });
        setIsAssignTierInPosition(false);
        setIsCloseModal(true);
      }
    }
  };

  useEffect(() => {
    if (user?.tier_type === PositionTier) {
      if (selectedPosition !== "others") {
        onCheckingAssignTier(selectedPosition);
      }
    }
  }, [selectedPosition, user]);

  return (
    <PositionManageContext.Provider
      value={{
        selectedPosition,
        setSelectedPosition,
        isOpen,
        setIsopen,
        isAssignTierInPosition,
        setIsAssignTierInPosition,
        isCloseModal,
        setIsCloseModal,
        onCheckingAssignTier,
        loadPositionList,
        setLoadPositionList,
      }}
    >
      {children}
      <ManagePositionPopup
        isOpen={isOpen?.open}
        id={isOpen?._id}
        setIsOpen={setIsopen}
        setIsCloseModal={setIsCloseModal}
        loadPositionList={loadPositionList}
      />
    </PositionManageContext.Provider>
  );
};

export const usePositionManageModal = () => {
  return useContext(PositionManageContext);
};
