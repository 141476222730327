import React from "react";
import NoDataFound from "../../NoDataFound";
import Checkbox from "../../form/Checkbox";
import { Tooltip } from "react-tooltip";
import Button from "../../form/Button";
import { useSettingsModal } from "../../../Context/SettingsContext";
import { PositionTier } from "../../../constant/Constant";

const QuestionItems = ({
  data,
  updateHandler = () => {},
  type,
  setEditData = () => {},
  setEnabledPopup = () => {},
}) => {
  const { tierType } = useSettingsModal();
  return (
    <>
      <div className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-100px)]">
        {data.length > 0 ? (
          data.map((itm, inx) => (
            <div
              key={inx}
              className={`flex items-center justify-between gap-2 border border-dark-teal-200 rounded-md p-3 ${
                !itm?.disabled && itm?.isDefault
                  ? "bg-blue-50"
                  : "bg-transparent"
              }`}
            >
              <Checkbox
                checkboxLableClass={"text-slate-800"}
                checkboxInputClass={""}
                checkboxClass={"!rounded-full"}
                divClass={"!items-start"}
                checkboxName={itm?._id}
                isChecked={itm?.isDefault}
                checkboxLabel={itm?.name}
                checkboxValue={itm?._id}
                getCheckboxValue={(val, event) =>
                  updateHandler(val, event.target.checked, type)
                }
                isDisabled={itm?.disabled}
              />

              <div className="flex items-center justify-between gap-2">
                {/* {tierType === PositionTier && (
                  <div className="flex items-center">
                    <div
                      className={`flex items-center text-[12px] font-medium leading-tight rounded py-1 px-2 bg-violet-100 text-violet-600`}
                    >
                      {itm?.tier_details?.name || "N/A"}
                    </div>
                  </div>
                )} */}
                {type === "predictors" && (
                  <div className="flex-shrink-0 p-1 ml-4">
                    <p className="text-[15px] font-semibold text-pink-600">
                      Weight(%) {itm?.weight || 0}
                    </p>
                  </div>
                )}

                <div
                  className="flex-shrink-0"
                  data-tooltip-id={"info-tooltip-" + itm?._id}
                  data-tooltip-place="bottom"
                >
                  {itm?.description && (
                    <>
                      <Tooltip
                        id={"info-tooltip-" + itm?._id}
                        className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                        render={() => (
                          <div className="w-60 p-1 rounded-md">
                            <div className="text-[14px] font-normal">
                              <p className="font-normal text-white">
                                {itm?.description}
                              </p>
                            </div>
                          </div>
                        )}
                      />
                      <i
                        className="fa-light fa-circle-info"
                        style={{ color: "#14b8a6" }}
                      ></i>
                    </>
                  )}
                </div>
                {/* <div
                  className="flex items-center"
                  data-tooltip-id={`editButton${itm?._id}`}
                  data-tooltip-place="bottom"
                >
                  <Button
                    buttonClasses={
                      "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-regular fa-pencil"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledPopup({ open: true, type: type });
                      setEditData(itm);
                    }}
                  />
                  <Tooltip
                    id={`editButton${itm?._id}`}
                    className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6"
                    render={() => (
                      <div className="gap-x-1 gap-y-1">
                        <div className="text-xs leading-none flex items-center">
                          <div className="font-medium">{`Click To Edit Concern (${
                            itm?.heading_name ? itm?.heading_name : itm?.name
                          })`}</div>
                        </div>
                      </div>
                    )}
                  />
                </div> */}
              </div>
            </div>
          ))
        ) : (
          <NoDataFound label={"No Data Found"} />
        )}
      </div>
    </>
  );
};

export default QuestionItems;
