import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Textarea from "../components/form/Textarea";
import Image from "../components/elements/Image";
import ReactPaginate from "react-paginate";
import toastr from "toastr";
import { useSelector } from "react-redux";
import { postData } from "../services/api";
import { NextStepMessage } from "../helpers";
import ScorecardReport from "./pdf/ScorecardReport";
import { API_URL } from "../config/host";
import StepAlert from "../components/elements/alert/StepAlert";

import { useTranslation } from "react-i18next";

import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import html2canvas from "html2canvas";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const Scorecard = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Succession Plan Scorecard"
  );
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState("");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(100000);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [yearWithCompanyWeight, setYearWithCompany] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [isNextStep, setIsNextStep] = useState(false);
  // const predictorWeight = 30;
  // const experienceWeight = 25;
  // const concernsWeight = 20;
  // const performanceWeight = 15;
  // const educationWeight = 10;

  const [names, setNames] = useState([]);
  const [positions, setPositions] = useState([]);
  const [points, setPoints] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [unknown, setUnknown] = useState([]);
  const [chartPdf, setChartPdf] = useState("");

  const heading = [
    {
      name: t("potential_predictor_score"),
      option: t("weight") + ": " + predictorWeight + "%",
      overall_score: predictorWeight,
      ordering: 0,
    },
    {
      name: t("senior_management_experience"),
      option: t("weight") + ": " + experienceWeight + "%",
      overall_score: experienceWeight,
      ordering: 1,
    },
    {
      name: t("concerns"),
      option: t("weight") + ": " + concernsWeight + "%",
      overall_score: concernsWeight,
      ordering: 2,
    },
    {
      name: t("recent_performance_review"),
      option: t("weight") + ": " + performanceWeight + "%",
      overall_score: performanceWeight,
      ordering: 3,
    },
    {
      name: t("high_level_of_education"),
      option: t("weight") + ": " + educationWeight + "%",
      overall_score: educationWeight,
      ordering: 4,
    },
    {
      name: t("years_with_the_company"),
      option: t("weight") + ": " + yearWithCompanyWeight + "%",
      overall_score: yearWithCompanyWeight,
      ordering: 5,
    },
    {
      name: t("desire_to_advance"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 6,
    },
    {
      name: t("loyalty_toward_company"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 7,
    },
    {
      name: t("pending_retirement_window"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 8,
    },
    {
      name: t("overall_weighted_score"),
      option: t("weight") + ": " + totalWeight + "%",
      overall_score: 0,
      ordering: 9,
    },
    {
      name: t("additional_comments"),
      option: "",
      overall_score: 0,
      ordering: 10,
    },
  ].sort((a, b) => b.overall_score - a.overall_score);

  useEffect(() => {
    const loaWeights = async () => {
      setIsUpdate(false);
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              setPredictorWeight(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            if (value.alias === "executive_experience") {
              setExperienceWeight(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            if (value.alias === "concerns") {
              setConcernWeight(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            if (value.alias === "recent_performance_review") {
              setPerformanceWeight(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            if (value.alias === "highest_level_education") {
              setEducationWeight(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            if (value.alias === "years_with_company") {
              setYearWithCompany(
                value?.company_weight ? value?.company_weight : value?.weight
              );
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            setTotalWeight(total);
            setIsUpdate(true);
          });
        }
        setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
        setIsUpdate(false);
      }
    };
    loaWeights();
  }, []);

  useEffect(() => {
    // Scorcard List
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.data) {
          //console.log('data',result.data)
          setSuccessors(
            result.data.map((value, key) => {
              let isEditable = false;
              let desire =
                Array.isArray(value?.desire_details) &&
                value?.desire_details.length > 0
                  ? value?.desire_details[0]?.isPass
                  : "";
              let loyalty =
                Array.isArray(value?.loyalty_details) &&
                value?.loyalty_details.length > 0
                  ? value?.loyalty_details[0]?.isPass
                  : "";
              let retirement =
                Array.isArray(value?.retirement_details) &&
                value?.retirement_details.length > 0
                  ? value?.retirement_details[0]?.isPass
                  : "";
              if (desire === true && loyalty === true && retirement === true) {
                isEditable = true;
              }
              let predictorCalculation =
                (predictorWeight * value?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (value.experience_details
                    ? value?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * value?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (value.performance_details
                    ? value?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (value.education_details
                    ? value?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (value.cu_details ? value?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                value?.desire_details && value?.desire_details.length > 0
                  ? value?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                value?.loyalty_details && value?.loyalty_details.length > 0
                  ? value?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                value?.retirement_details &&
                value?.retirement_details.length > 0
                  ? value?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;

              let desireInfo =
                value?.desire_details && value?.desire_details.length > 0
                  ? value?.desire_details[0]?.isClass === "blue"
                    ? 1
                    : 2
                  : null;
              let loyaltyInfo =
                value?.loyalty_details && value?.loyalty_details.length > 0
                  ? value?.loyalty_details[0]?.isClass === "blue"
                    ? 1
                    : 2
                  : null;
              let RetirementInfo =
                value?.retirement_details &&
                value?.retirement_details.length > 0
                  ? value?.retirement_details[0]?.isClass === "blue"
                    ? 1
                    : 2
                  : null;
              return {
                ...value,
                overallWeightScore:
                  desireResult === 2 ||
                  loyaltyResult === 2 ||
                  RetirementResult === 2
                    ? 0.0
                    : (
                        parseFloat(predictorCalculation) +
                        parseFloat(experienceCalculation) +
                        parseFloat(concernsCalculation) +
                        parseFloat(performanceCalculation) +
                        parseFloat(educationCalculation) +
                        parseFloat(yearWithCompanyCalculation)
                      ).toFixed(2),
                isEditable: isEditable,
                isResult:
                  desireResult === 2 ||
                  loyaltyResult === 2 ||
                  RetirementResult === 2
                    ? 1
                    : 2,
                isUnknown:
                  desireInfo === 1 || loyaltyInfo === 1 || RetirementInfo === 1
                    ? 1
                    : 2,
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();
  }, [keyword, limit, offset, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const handelUpdate = async (id, val, field, index) => {
    try {
      setIsUpdate(false);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              [field]: val,
            };
          } else {
            return it;
          }
        })
      );

      const result = await postData("successor/update", {
        id: id,
        [field]: val,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(true);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  useEffect(() => {
    if (successors && successors.length > 0) {
      let experience = successors.filter((item) => !item.experience);
      let with_credit_union = successors.filter(
        (item) => !item.with_credit_union
      );
      let education = successors.filter((item) => !item.education);
      let performance = successors.filter((item) => !item.performance);
      let loyalty = successors.filter((item) => !item.loyalty);
      let desire_to_advance = successors.filter(
        (item) => !item.desire_to_advance
      );
      let retirement_window = successors.filter(
        (item) => !item.retirement_window
      );
      let concernRecord = successors
        .filter((item) => item.concerns && item.isEditable)
        .map((item) => item.concerns.filter((row) => !row.option_id));
      let concernEmptyRecord = successors.filter(
        (item) => item.concerns.length === 0 && item.isEditable
      );
      let finalConcern = concernRecord.reduce(
        (currentCount, row) => currentCount + row.length,
        0
      );
      let checkConcern = successors.filter(
        (concern) => concern.overall_score > 0 && concern.isEditable
      );
      let predictorRecord = successors
        .filter((item) => item.predictors && item.isEditable)
        .map((item) => item.predictors.filter((row) => !row.option_id));
      let predictorEmptyRecord = successors.filter(
        (item) => item.predictors.length === 0 && item.isEditable
      );
      let finalPredictor = predictorRecord.reduce(
        (currentCount, row) => currentCount + row.length,
        0
      );
      let checkPredictors = successors.filter(
        (predictor) =>
          predictor.overall_weighted_score > 0 && predictor.isEditable
      );
      if (
        experience.length === 0 &&
        with_credit_union.length === 0 &&
        education.length === 0 &&
        performance.length === 0 &&
        loyalty.length === 0 &&
        desire_to_advance.length === 0 &&
        retirement_window.length === 0 &&
        checkConcern &&
        checkConcern.length > 0 &&
        finalConcern === 0 &&
        concernEmptyRecord.length === 0 &&
        finalPredictor === 0 &&
        predictorEmptyRecord.length === 0 &&
        checkPredictors &&
        checkPredictors.length > 0
      ) {
        setIsNextStep(true);
      } else {
        setIsNextStep(false);
      }
    } else {
      setIsNextStep(false);
    }
  }, [successors]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  // Use For plan summary (chart)
  useEffect(() => {
    let successorName = [];
    let successorPoint = [];
    let successorPosition = [];
    let successorResult = [];
    let successorUnknown = [];
    if (successors && successors.length > 0) {
      successors
        .sort((a, b) => b.overallWeightScore - a.overallWeightScore)
        .map((value, key) => {
          successorName[key] = value?.name;
          successorPosition[key] = value?.position_details
            ? value?.position_details[0]?.short_name
            : "";
          successorPoint[key] = parseFloat(
            value?.overallWeightScore
              ? value?.isUnknown === 2
                ? value?.overallWeightScore
                : 0
              : 0
          ).toFixed(2);
          successorUnknown[key] = value?.isUnknown;
          successorResult[key] = value?.isResult;
        });
      setNames(successorName);
      setPoints(successorPoint);
      setPositions(successorPosition);
      setUnknown(successorUnknown);
      setResultData(successorResult);
    }
  }, [successors, isUpdate]);

  const barData = {
    labels: names,
    datasets: [
      {
        label: "",
        borderRadius: 0,
        data: points,
        backgroundColor: "rgba(20, 184, 166, 1)",
        barThickness: 100,
      },
    ],
  };

  const barOptions = {
    plugins: {
      legend: {
        display: false,
        position: "top",
        align: "start",
        maxWidth: 500,
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 12,
            weight: 600,
            lineHeight: 100,
          },
        },
        title: {
          display: false,
        },
      },

      tooltip: {
        displayColors: false,
        filter: function (context) {
          if (context.formattedValue > 0) {
            return true;
          } else {
            return false;
          }
        },
        callbacks: {
          label: function (context) {
            if (context.formattedValue > 0) {
              return [
                `${t("position")}: ${positions[context.dataIndex]}`,
                `${t("point")}: ${context.formattedValue}`,
              ];
            } else {
              return;
            }
          },
        },
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            return this.getLabelForValue(value).split(";")[0];
          },
        },
        grid: {
          display: false,
        },
      },
      x2: {
        ticks: {
          font: {
            size: 13,
            weight: "600",
          },
          callback: function (value, index, values) {
            return positions[index];
          },
        },
        grid: {
          display: false,
        },
      },
      x3: {
        ticks: {
          color: "red",
          beginAtZero: true,
          callback: function (value, index, values) {
            if (resultData[index] === 2) {
            } else {
              return [
                t("eliminated_because_of_questionable"),
                t("loyalty_desire_to_advance"),
                t("or_short_term_retirement_window"),
              ];
            }
          },
        },
        grid: {
          display: false,
        },
      },
      x4: {
        ticks: {
          color: "blue",
          beginAtZero: true,
          callback: function (value, index, values) {
            if (unknown[index] === 2) {
            } else {
              return [
                t("unknown"),
                t("loyalty_desire_to_advance"),
                t("or_short_term_retirement_window"),
              ];
            }
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("succession_plan_scorecard") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {/* <button 
            type={'button'} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
            onClick={(e) => getDownload(e)}
            >
            <i className={"fa-fw fa-light fa-download"}></i> 
            <div className={"text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<ScorecardReport successors={successors} heading={heading} user={user} />} fileName="scorecard.pdf">
                {({ blob, url, loading, error }) => (
                  loading ? 'Loading document...' : 'Download PDF')}
              </PDFDownloadLink>
            </div> 
          </button> */}
          <Button
            buttonClasses={isNextStep ? "" : "!bg-rose-500"}
            buttonType={"button"}
            buttonIcon={"fa-light fa-arrow-right-to-arc"}
            buttonIconPosition={"left"}
            buttonLabel={t("next_step")}
            buttonHasLink={isNextStep ? true : false}
            buttonLink={"/development-plan"}
            functions={() => toastr.error(NextStepMessage)}
          />
        </div>
      </div>
      <StepAlert />

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md"></th>
              {totalDataCount > 0
                ? successors
                    .sort((a, b) => b.overallWeightScore - a.overallWeightScore)
                    .map((item, index0) => {
                      return (
                        <th
                          className="py-4 px-2 max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md"
                          key={index0}
                        >
                          <div className="flex justify-center gap-1">
                            <div className="mx-auto flex justify-center gap-4 items-center">
                              <div className="w-8 h-8 min-w-[32px] min-h-[32px] overflow-hidden rounded-full relative">
                                <Image
                                  src={API_URL + item?.image}
                                  alt={"Admin"}
                                  width={"100%"}
                                  height={"100%"}
                                  effect={"blur"}
                                  classes={"object-cover cursor-pointer"}
                                />
                              </div>
                              <div className="font-semibold text-sm leading-none text-center">
                                {item.name}
                                <span className="block text-[11px] font-medium mt-1">
                                  {item?.position_details
                                    ? item?.position_details[0]?.short_name
                                    : "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </th>
                      );
                    })
                : ""}
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white" key={0}>
            {heading
              .sort((a, b) => b.overall_score - a.overall_score)
              .map((items, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                    {items.name}
                    <span className="block text-xs font-bold leading-tight text-rose-500">
                      {items.option}
                    </span>
                  </td>
                  {totalDataCount > 0
                    ? successors.map((item, index1) => {
                        if (items?.ordering === 0) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.overall_weighted_score
                                  ? item?.overall_weighted_score
                                  : 0}
                              </div>
                            </td>
                          );
                        } else if (items?.ordering === 1) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.experience_details &&
                                item?.experience_details.length > 0
                                  ? item?.experience_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (items?.ordering === 2) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.overall_score ? item?.overall_score : 0}
                              </div>
                            </td>
                          );
                        } else if (items?.ordering === 3) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.performance_details &&
                                item?.performance_details.length > 0
                                  ? (item?.performance_alt_details.length > 0
                                      ? item?.performance_alt_details[0]?.name
                                      : item?.performance_details[0]?.name) +
                                    " " +
                                    (item?.performance_alt_details[0]?.number
                                      ? "(" +
                                        item?.performance_alt_details[0]?.number +
                                        ")"
                                      : "(" +
                                        item?.performance_details[0]?.number +
                                        ")")
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (items?.ordering === 4) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.education_details &&
                                item?.education_details.length > 0
                                  ? item?.education_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (items?.ordering === 5) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                {item?.cu_details && item?.cu_details.length > 0
                                  ? item?.cu_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (index === 6) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div
                                className={
                                  "relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded " +
                                  (item.desire_details.length > 0 &&
                                  item?.desire_details[0].isPass
                                    ? "text-slate-700"
                                    : "text-rose-500")
                                }
                              >
                                {Array.isArray(item?.desire_details) &&
                                item?.desire_details.length > 0
                                  ? item?.desire_details[0]?.isPass
                                    ? "Pass: "
                                    : "Fail: "
                                  : ""}
                                {item?.desire_details &&
                                item?.desire_details.length > 0
                                  ? item?.desire_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (index === 7) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div
                                className={
                                  "relative flex justify-center text-xs font-medium bg-white py-2 px-3 border border-slate-200 rounded " +
                                  (item?.loyalty_details.length > 0 &&
                                  item?.loyalty_details[0].isPass
                                    ? "text-slate-700"
                                    : "text-rose-500")
                                }
                              >
                                {Array.isArray(item?.loyalty_details) &&
                                item?.loyalty_details.length > 0
                                  ? item?.loyalty_details[0]?.isPass
                                    ? "Pass: "
                                    : "Fail: "
                                  : ""}
                                {item?.loyalty_details &&
                                item?.loyalty_details.length > 0
                                  ? item?.loyalty_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (index === 8) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div
                                className={
                                  "relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded " +
                                  (item.retirement_details.length > 0 &&
                                  item?.retirement_details[0].isPass
                                    ? "text-slate-700"
                                    : "text-rose-500")
                                }
                              >
                                {Array.isArray(item?.retirement_details) &&
                                item?.retirement_details.length > 0
                                  ? item?.retirement_details[0]?.isPass
                                    ? "Pass: "
                                    : "Fail: "
                                  : ""}
                                {item?.retirement_details &&
                                item?.retirement_details.length > 0
                                  ? item?.retirement_details[0]?.name
                                  : "N/A"}
                              </div>
                            </td>
                          );
                        } else if (index === 9) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-sm font-semibold text-white bg-dark-teal-400 py-2 px-3 border border-black/10 rounded">
                                {Number(item?.overallWeightScore)
                                  ? Number(item?.overallWeightScore).toFixed(2)
                                  : 0.0}
                              </div>
                            </td>
                          );
                        } else if (index === 10) {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <Textarea
                                inputClasses={""}
                                inputValue={
                                  item.scorecard_comments &&
                                  item.scorecard_comments !== null
                                    ? item.scorecard_comments
                                    : ""
                                }
                                setTextarea={(val) =>
                                  setSuccessors(
                                    successors.map((it, idx) => {
                                      if (idx === index1) {
                                        return {
                                          ...it,
                                          scorecard_comments: val,
                                        };
                                      } else {
                                        return it;
                                      }
                                    })
                                  )
                                }
                                onBlur={(val) =>
                                  handelUpdate(
                                    item._id,
                                    val,
                                    "scorecard_comments",
                                    index
                                  )
                                }
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td className="py-3 px-2 text-left max-w-[240px] align-top">
                              <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded"></div>
                            </td>
                          );
                        }
                      })
                    : ""}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="relative bg-white shadow rounded mb-4 mt-3">
        <div className="py-10 px-3">
          <div className="relative w-full max-w-5xl" id="div2PDF">
            <div className="w-full h-40 sm:h-80 lg:h-[600px]">
              <Chart
                //key={userDetailsData()}
                type="bar"
                data={barData}
                options={barOptions}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex justify-between items-center">
        <div className="ml-auto flex justify-end items-center space-x-2">
          <Button
            buttonClasses={isNextStep ? "" : "!bg-rose-500"}
            buttonType={"button"}
            buttonIcon={"fa-light fa-arrow-right-to-arc"}
            buttonIconPosition={"left"}
            buttonLabel={t("next_step")}
            buttonHasLink={isNextStep ? true : false}
            buttonLink={"/development-plan"}
            functions={() => toastr.error(NextStepMessage)}
          />
        </div>
      </div>
      {noOfPage > 1 && (
        <div className="flex justify-center sm:justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default Scorecard;
