import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const BoardRetirementReportPdf = ({ boardMambers, user, companyLogo }) => {
  const { i18n, t } = useTranslation();
  const profileHeading = [
    {
      name: t("retirement_window"),
    },
  ];
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 360,
      height: 120,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <Image style={styles.image} src={companyLogo} />
            <View style={{ position: "absolute", right: 0, top: 0 }}>
              <Image
                style={{ width: 120, height: 24, objectFit: "contain" }}
                src={logo}
              />
            </View>
          </View>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoBold",
                  fontSize: 20,
                  textTransform: "capitalize",
                },
              ]}
            >
              Company Name: {user?.company_name}
            </Text>
          </View>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              Succession Plan for {user?.title}
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                textAlign: "center",
                justifyContent: "center",
              },
            ]}
          >
            <View
              style={[
                styles.tableOther,
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                    textAlign: "center",
                    fontSize: 10,
                  },
                ]}
              >
                Created by:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {user?.name}({user?.title})
              </Text>
            </View>
            <View
              style={[
                styles.tableOther,
                { display: "flex", flexDirection: "row", marginTop: 5 },
              ]}
            >
              <Text
                style={[
                  styles.tableOtherData,
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 10,
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={[
            styles.tableOther,
            {
              display: "flex",
              flexDirection: "row",
              columnGap: 20,
              marginTop: 5,
              marginBottom: 5,
            },
          ]}
        >
          <Text
            style={[
              styles.tableOtherData,
              {
                display: "flex",
                flexDirection: "row",
                fontFamily: "RobotoMedium",
                fontSize: 12,
              },
            ]}
          >
            Board Retirement Report
          </Text>
        </View>

        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 6,
            overflow: "hidden",
            marginBottom: 30,
          }}
        >
          {/* Header Row */}
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#0f172a",
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Board Retirement Window
            </Text>
            <Text
              style={{
                flex: 8,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Year
            </Text>
          </View>

          {/* Data Rows */}
          {boardMambers &&
            boardMambers.length > 0 &&
            boardMambers.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderBottomColor: "#e2e8f0",
                  paddingVertical: 10,
                  backgroundColor: index % 2 === 0 ? "#f8fafc" : "#ffffff",
                }}
              >
                <Text
                  style={{
                    flex: 3,
                    color: "#333333",
                    textAlign: "left",
                    paddingHorizontal: 8,
                    fontSize: 10,
                    fontWeight: "600",
                  }}
                >
                  {item.association_retirement_information === 1
                    ? `${item.first_name} ${item.last_name}`
                    : "Anonymous"}
                  <Text
                    style={{
                      display: "block",
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item.position_details?.name || "N/A"}
                  </Text>
                </Text>
                <Text
                  style={{
                    flex: 8,
                    color: "#333333",
                    textAlign: "left",
                    paddingHorizontal: 8,
                    fontSize: 10,
                  }}
                >
                  {item.retirement_window > 1
                    ? `${item.retirement_window} Years`
                    : `${item.retirement_window} Year`}
                </Text>
              </View>
            ))}
        </View>
      </Page>

      <Page size="A4" orientation="landscape" style={styles.page}>
      <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 6,
            overflow: "hidden",
            marginBottom: 30,
          }}
        >
          {/* Table Header */}
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#0f172a",
              paddingVertical: 10,
            }}
          >
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Name
            </Text>
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Primary Area of Expertise
            </Text>
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Primary Area of Expertise 2
            </Text>
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Primary Area of Expertise 3
            </Text>
            <Text
              style={{
                flex: 3,
                color: "#ffffff",
                textAlign: "left",
                paddingHorizontal: 8,
                fontFamily: "RobotoMedium",
                fontSize: 10,
                fontWeight: "bold",
              }}
            >
              Primary Area of Expertise 4
            </Text>
          </View>

          {/* Table Rows */}
          {boardMambers &&
            boardMambers.length > 0 &&
            boardMambers.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderBottomColor: "#e2e8f0",
                  paddingVertical: 10,
                  backgroundColor: index % 2 === 0 ? "#f8fafc" : "#ffffff",
                }}
              >
                <Text 
                style={{
                    color: "#333333",
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "600",
                    width:"20%",
                    paddingHorizontal:8,
                  }}
                >
                  {item?.association_retirement_information === 1
                    ? `${item?.first_name} ${item?.last_name}`
                    : "Anonymous"}
                  <Text
                    style={{
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item?.position_details?.name || "N/A"}
                  </Text>
                </Text>
                <Text 
                style={{
                    flex: 3,
                    color: "#333333",
                    textAlign: "left",
                    fontSize: 10,
                    width:"20%",
                    paddingHorizontal:8,
                    fontWeight: "600",
                  }}
                >
                  {item?.primary_expertise_details?.name || "N/A"}
                  <Text 
                    style={{
                      display: "block",
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item?.primary_experience
                      ? `${item?.primary_experience} ${
                          item?.primary_experience > 1 ? "Years" : "Year"
                        }`
                      : ""}
                  </Text>
                </Text>
                <Text 
                style={{
                    flex: 3,
                    color: "#333333",
                    textAlign: "left",
                    fontSize: 10,
                    width:"20%",
                    paddingHorizontal:5,
                    fontWeight: "600",
                  }}
                >
                  {item?.primary_expertise_two_details?.name || "N/A"}
                  <Text
                    style={{
                      display: "block",
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item?.primary_experience_two
                      ? `${item?.primary_experience_two} ${
                          item?.primary_experience_two > 1 ? "Years" : "Year"
                        }`
                      : ""}
                  </Text>
                </Text>
                <Text 
                style={{
                    flex: 3,
                    color: "#333333",
                    textAlign: "left",
                    fontSize: 10,
                    width:"20%",
                    paddingHorizontal:8,
                    fontWeight: "600",
                  }}
                >
                  {item?.primary_expertise_three_details?.name || "N/A"}
                  <Text
                    style={{
                      display: "block",
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item?.primary_experience_three
                      ? `${item?.primary_experience_three} ${
                          item?.primary_experience_three > 1 ? "Years" : "Year"
                        }`
                      : ""}
                  </Text>
                </Text>
                <Text 
                style={{
                    flex: 3,
                    color: "#333333",
                    textAlign: "left",
                    fontSize: 10,
                    width:"20%",
                    paddingHorizontal:8,
                    fontWeight: "600",
                  }}
                >
                  {item?.primary_expertise_four_details?.name || "N/A"}
                  <Text
                    style={{
                      display: "block",
                      fontSize: 8,
                      fontWeight: "bold",
                      color: "#14b8a6",
                    }}
                  >
                    {" "}
                    {item?.primary_experience_four
                      ? `${item?.primary_experience_four} ${
                          item?.primary_experience_four > 1 ? "Years" : "Year"
                        }`
                      : ""}
                  </Text>
                </Text>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
};

export default BoardRetirementReportPdf;
