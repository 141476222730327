import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import SelectedQuestionsItem from "./SelectedQuestionsItem";
import Select from "../../form/Select";
import { PositionTier } from "../../../constant/Constant";
import TierSelectDropdown from "../../elements/TierSelectDropdown";

const PositionWideQuestionLibrary = ({
  selectedConcern,
  selectedPredictors,
  tierList,
  selectedTier,
  setSelectedTier = () => {},
  tierType,
  setEditData = () => {},
  setEnabledPopup = () => {},
}) => {
  const { t } = useTranslation();
  const [tabType, setTabType] = useState("concern");

  return (
    <>
      <div className="relative mt-6 p-4 bg-white shadow-md rounded-lg border border-gray-200">
        <div className="text-xl font-semibold text-teal-500">
          Selected Questions
        </div>
        {tierType === PositionTier && (
          <TierSelectDropdown
            selectedTier={selectedTier}
            setSelectedTier={setSelectedTier}
            isLable={false}
            isSelect={false}
          />
        )}
        {/* <div className="relative flex flex-col gap-4 mb-3">
          <div className="grid grid-cols-1 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
            <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5">
              {tabHeading?.map((type) => (
                <button
                  key={type}
                  type="button"
                  className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform font-semibold ${
                    tabType === type?.value
                      ? "text-teal-500 border-teal-500 "
                      : "text-slate-500 border-transparent hover:text-teal-500 hover:border-teal-500"
                  }`}
                  onClick={() => setTabType(type?.value)}
                >
                  {type?.title}
                </button>
              ))}
            </div>

            <div className="grid grid-cols-3 gap-6">
              {questionData &&
                Array.isArray(questionData) &&
                questionData.length > 0 &&
                questionData.map((category, index) => (
                  <div
                    key={index}
                    className="rounded-lg bg-slate-50 border border-slate-200"
                  >
                    <div className="cursor-pointer py-2 px-4 bg-teal-50 flex justify-between items-center border-t rounded-t-lg border-b border-slate-200">
                      <h2 className="flex gap-2 text-lg font-bold text-slate-800">
                        {category.name}
                        <div className="text-lg font-bold text-slate-800">{`(${category?.question?.length})`}</div>
                      </h2>

                      <div
                        className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer ${
                          category?.setDefault === 1
                            ? "bg-blue-100 text-blue-600"
                            : "bg-amber-100 text-amber-600"
                        }`}
                      >
                        {category?.setDefault === 1 ? "Default" : "Non-default"}
                      </div>
                    </div>
                    {category?.question?.length > 0 ? (
                      <div className="list-disc py-3 px-4 space-y-2">
                        {category?.question?.map((q, index) => (
                          <div
                            key={index}
                            className="flex text-gray-800 bg-white py-3 px-4 rounded-md border border-slate-300 shadow-sm font-semibold gap-4"
                          >
                            <div className="text-sm font-medium !leading-none flex items-center flex-shrink flex-grow">
                              {q.name}
                            </div>
                            <div
                              className="flex-shrink-0 ml-auto"
                              data-tooltip-id={"info-tooltip-" + q?._id}
                              data-tooltip-place="left"
                            >
                              {q?.description && (
                                <>
                                  <Tooltip
                                    id={"info-tooltip-" + q?._id}
                                    className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                                    render={() => (
                                      <div className="w-60 p-1 rounded-md">
                                        <div className="text-[13px] font-normal">
                                          <p className="font-normal text-white">
                                            {q?.description}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  />
                                  <i
                                    className="fa-light fa-circle-info"
                                    style={{ color: "#14b8a6" }}
                                  ></i>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="list-disc pl-6 p-4 space-y-1">
                        <div className="flex justify-center items-center">
                          <i className="fas fa-exclamation-circle mr-2 text-slate-500 text-[25px]"></i>
                        </div>
                        <div className="flex justify-center items-center text-xl font-normal text-slate-700">
                          No questions are available in this group at the
                          moment.
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div> */}
        <div className="relative flex flex-col gap-4 mb-3">
          <div className="grid grid-cols-2 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
            <div className="relative">
              <SelectedQuestionsItem
                data={selectedConcern}
                type="concern"
                setEditData={setEditData}
                setEnabledPopup={setEnabledPopup}
              />
            </div>
            <div className="relative">
              <SelectedQuestionsItem
                data={selectedPredictors}
                type="predictors"
                setEditData={setEditData}
                setEnabledPopup={setEnabledPopup}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PositionWideQuestionLibrary;
