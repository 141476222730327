import React from "react";
import { sheduleLink } from "../../../constant/Constant";

const EvaluationTools = () => {
  const cards = [
    {
      title: "Board Strength",
      description:
        "Evaluate your current overall board strength and identify future potential board leaders.",
      descriptionTwo:
        "Our tool facilitates enhanced governance and strengthens organizational resilience.",
      bgColor: "bg-[#FAF3FF]",
      textColor: "text-purple-700",
    },
    {
      title: "9 Box Now",
      description:
        "Elevate employee performance and potential, streamline succession planning, and make data-driven HR decisions using 9Box.",
      descriptionTwo:
        "Our platform goes beyond simple categorization, offering a nuanced approach to employee evaluations to mitagate bias and customization to adapt to your organization's evolving needs.",
      bgColor: "bg-[#FFF6F6]",
      textColor: "text-red-700",
    },
    {
      title: "Executive Audit 360",
      description:
        "Review your Excutive Team to identify successes, strengths, and opportunities for growth.",
      descriptionTwo:
        "Use the audits to improve communication and understanding and align your Executive Team with organizational goals.",
      bgColor: "bg-[#E7FAFC]",
      textColor: "text-blue-700",
    },
  ];
  return (
    <section className="w-full my-12 md:my-28">
      <div className="w-full">
        <div className="space-y-8 px-5">
          <div className="font-normal  text-black font-IBM text-[48px] text-center w-full">
            <span className="italic">Integrated Evaluation Tools</span>
            <br />
            <span className="font-light text-black">
              for better succession planning
            </span>
          </div>
          <div className="text-center font-medium text-black font-Outfit text-[16px] max-w-[545px] mx-auto">
            Enhance your succession strategy with our powerful evaluation tools:
            Board Strength, 9Box Now, and Executive Audit 360. These integrated
            tools provide data-driven insights that support informed
            decision-making, reduc- ing subjectivity and enhancing the overall
            effectiveness of your succession planning process.
          </div>
        </div>
      </div>
      <div className="w-48 mx-auto mt-8">
        <a
          target="_blank"
          href={sheduleLink}
          className={
            "relative z-0 overflow-hidden flex justify-center items-center gap-2 rounded-full h-[52px] py-0 px-3 transition-all duration-200 text-white border bg-black after:absolute after:h-full after:content-[''] after:-z-[1] after:top-0 after:left-0 after:transition-[width] after:duration-300 after:ease-in-out after:w-full hover:after:w-0 mx-auto after:bg-[#000000] border-transparent hover:border-white hover:text-white capitalize font-IBM font-medium text-base mt-5"
          }
        >
          Book Your Demo
        </a>
      </div>
      <div className="mt-5 w-full">
        <div className="flex flex-wrap justify-center gap-6 px-4 py-10">
          {cards.map((card, index) => (
            <div
              key={index}
              className="w-[405px] h-[563px] bg-white shadow-xl rounded-[24px] px-6 py-16 border border-gray-200"
            >
              <div className="flex justify-center items-center">
                <div
                  className={`flex justify-center items-center w-[205px] h-[68px] rounded-md  text-[20px] font-IBM font-semibold text-black ${card.bgColor}`}
                >
                  {card.title}
                </div>
              </div>
              <div className="flex justify-center items-center w-full mt-12">
                <div className="max-w-[282px] mx-auto text-black font-Outfit text-[16px] font-medium text-center">
                  {card.description}
                </div>
              </div>
              <div className="flex justify-center items-center w-full mt-8">
                <div className="max-w-[282px] mx-auto text-black font-Outfit text-[16px] font-medium text-center">
                  {card.descriptionTwo}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EvaluationTools;
