import React from 'react';
import Desktop from "../../../../assets/image/desktop.png"
import Button from "../../../form/Button";
const SuccessionWebinars = () => {
  return (
    <>
      <section className="relative w-full py-10 bg-dark-teal-500">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
          <div className=" w-full mb-10 lg:mb-20 space-y-5">
            <h2 className="w-full border-b border-white mx-auto text-center capitalize text-white text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl font-extrabold font-PlusJakartaSans py-4" >Succession Success Webinars</h2>
            <div className="max-w-4xl mx-auto  text-sm xs:text-base text-slate-700 font-extrabold font-PlusJakartaSans"></div>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-10 md:gap-5 lg:gap-10">
              <div className="w-full md:w-1/2">
                  <div className="text-white capitalize text-sm md:text-base lg:text-lg xl:text-xl font-bold font-PlusJakartaSans space-y-5">
                      <p>These webinars offer a holistic approach to building succession strategies that encompass all levels of your organization.</p>
                      <p>Discover best practices for developing future leaders, engaging your board in the succession process, and creating development opportunities that motivate your workforce.</p>
                  </div>
              </div>
              <div className="w-full md:w-1/2 space-y-10">
                <div className="w-full h-40 lg:h-48 xl:h-52">
                    <img src={Desktop} alt="Icon" className="w-full h-full object-contain"/>
                </div>
                <div className="relative max-w-full md:max-w-[280px] lg:max-w-[320px] xl:max-w-[380px] m-auto">
                    <Button
                      buttonLabel={"register now"}
                      buttonClasses={"bg-white w-full"}
                      buttonLabelClasses={"text-dark-teal-500 !font-bold capitalize !text-lg md:!text-xl"}
                      buttonHasLink={true}
                      buttonLink={'//calendly.com/cameronbrockbank/succession-now-demo'}
                      buttonTarget={"_blank"}
                    />
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessionWebinars