import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const OptionRatingHistoryReport = ({ history, reatingOptions, title, headingTitle, user, companyLogo, type }) => {
  const { t } = useTranslation();

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };

  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={[
          {
            padding: 20,
            fontFamily: "RobotoRegular",
            lineHeight: 1.2,
            fontSize: 9,
            color: "#334155",
            backgroundColor: "#f1f5f9"
          },
        ]}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.1
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            left: 0,
            bottom: -10,
            right:0,
            height: 160,
            zIndex: -1,
            opacity:0.075
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
        <View
          style={{
            position: "absolute",
            right: 30,
            top: 30,
            width: 120,
            height: 24,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <View
            style={{
              paddingBottom: 20,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,
              }}
            >
              <Image
                style={
                  {
                    width: "360",
                    height: "120",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                }
                src={companyLogo}
              />
            </View>
            <View style={[{ display: "flex", flexDirection: "row", marginTop: 25 }]}>
              <Text
                style={[
                  {
                    fontFamily: "RobotoBold",
                    fontSize: 28,
                    textTransform: "capitalize",
                    color: "#334155",
                  },
                ]}
              >
                {user?.company_name}
              </Text>
            </View>
            <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text
                style={[
                  {
                    fontFamily: "RobotoBold",
                    fontSize: 12,
                    textTransform: "uppercase",
                    color: "#14b8a6",
                    letterSpacing:3
                  },
                ]}
              >
                {title}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                position: "absolute",
                bottom: 80,
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "auto",
              },
            ]}
          >
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontFamily: "RobotoMedium",
                    fontSize: 12,
                    textAlign: "center",
                    color: "#334155",
                  },
                ]}
              >
                Created by :
              </Text>
              <Text style={{ fontFamily: "RobotoBold", fontSize: 12, marginLeft: 2, color: "#334155", }}>{history && history.length > 0 ? history[0].creator : ""}</Text>
            </View>
            <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 12,
                    color: "#334155"
                  },
                ]}
              >
                Date:
              </Text>
              <Text style={{ fontFamily: "RobotoBold", fontSize: 12, marginLeft: 2, color: "#334155" }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page
        size="A4"
        orientation="landscape"
        style={{
          padding: 16,
          fontFamily: "RobotoRegular",
          lineHeight: 1.2,
          fontSize: 9,
          color: "#334155",
          backgroundColor: "#f1f5f9"
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          fixed
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: 160,
              zIndex: -1,
              opacity: 0.1,
            }}
          >
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -10,
              right: 0,
              height: 160,
              zIndex: -1,
              opacity: 0.075,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View
          style={{
            border: "1px solid #e2e8f0",
            borderRadius: 16,
            overflow: "hidden",
            marginBottom: 10,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#0f172a",
              color: "#ffffff",
              overflow: "hidden",
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
          >
            <View
              style={[
                {
                  fontSize: 12,
                  maxWidth: "100%",
                  paddingHorizontal: 12,
                  paddingVertical: 12,
                  backgroundColor: "#0f172a",
                  color: "#ffffff",
                  width: "25%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={{
                  width: "100%",
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  color: "#ffffff",
                }}
              >
                {type && type === "boardmember" ? t("board_effectiveness") : t("executive_audit_360")}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              paddingHorizontal: 12,
              paddingVertical: 12,
              backgroundColor: "white",
            }}
          >
            {reatingOptions &&
              reatingOptions.length > 0 &&
              reatingOptions.map((item, index) => {
                const ratingRowAnswers = item?.responses[0]?.answers;
                const ratedBoardMember = history.filter((it) => it?.data?.ratingOptions).map((itm) => itm?.data?.ratingOptions.filter((row) => row.questionId === item?._id));              
                let totalSum = 0;
                let _comments = [];
                let _ans = [];
                if (ratedBoardMember && ratedBoardMember.length > 0) {
                  ratedBoardMember.forEach((obj) => {
                    obj.forEach((ob) => {
                      totalSum += ob?.rating ? ob?.rating : 0;
                      if (ob?.comments) {
                        _comments.push(ob?.comments);
                      }
                      if (ob?.ids && ob?.ids?.length > 0) {
                        _ans.push(ob?.ids);
                      }
                    });
                  });
                }
                if (ratingRowAnswers && ratingRowAnswers.length > 0) {
                  ratingRowAnswers.forEach((obj) => {
                    if (obj?.company_id === user?._id) {
                      _ans.push(obj?._id);
                    }
                  });
                }
                let totalBoardMember = ratedBoardMember?.length;
                let avgRating = Number(totalSum / totalBoardMember) > 0 ? Number(totalSum / totalBoardMember).toFixed(2) : 0;
                let _score = "";
                if (avgRating && avgRating >= 9 && avgRating <= 10) {
                  _score = "Highly Effective";
                } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                  _score = "Very Effective";
                } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                  _score = "Effective";
                } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                  _score = "Somewhat Effective";
                } else if (avgRating && avgRating < 5) {
                  _score = "Effectiveness Improvement Needed";
                }
                let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
                return (
                  <View key={index} wrap={false}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: 16,
                      }}
                    >
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "75%",
                            columnGap: 4,
                            flexDirection: 'row',
                          },
                        ]}
                      >
                        <View style={{ width:16,  flexShrink: 0,}}>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                lineHeight: 1,
                                whiteSpace: "nowrap",
                                color: "#334155",
                                textAlign: "right",
                                fontFamily: "RobotoMedium",
                              },
                            ]}
                          >
                            {Number(index + 1) + "."}
                          </Text>
                        </View>
                        <View style={{flexShrink:1, flexGrow:1, paddingRight:16,}}>
                          <Text
                            style={[
                              {
                                fontFamily: "RobotoRegular",
                                fontSize: 10,
                                lineHeight: 1,
                                color: "#334155",
                                whiteSpace: 'normal',
                              },
                            ]}
                          >
                            {item?.name}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "8%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent:"center"
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              textAlign: 'center',
                              fontFamily: "RobotoBold",
                            },
                          ]}
                        >
                          {item?.averageRating}
                        </Text>
                      </View>
                      <View
                        style={[
                          {
                            maxWidth: "100%",
                            paddingVertical: 12,
                            width: "17%",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                      >
                        <Text
                          style={[
                            {
                              width: "100%",
                              fontSize: 10,
                              lineHeight: 1,
                              color: "#334155",
                              fontFamily: "RobotoRegular",
                            },
                          ]}
                        >
                          {_score}
                        </Text>
                      </View>
                    </View>
                    {_uniqueArrayWithCount && _uniqueArrayWithCount.length > 0 && (
                      <View className="relative">
                        {_uniqueArrayWithCount.map((an, i) => {                          
                          let rowData = ratingRowAnswers?.filter((row) => row._id === an.id);
                          let filterMatch = history?.filter((user) => user?.user_id === rowData[0]?.created_by);
                          if (rowData && rowData.length > 0 && (filterMatch.length > 0 || rowData[0]?.created_by === null)) {
                            return (
                              <View
                                style={{
                                  paddingRight: 16,
                                  paddingLeft:20,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginBottom: 4,
                                }}
                                key={i}
                              >
                                <View
                                  style={{
                                    backgroundColor: "#000000",
                                    width: 4,
                                    height: 4,
                                    borderRadius: 9999,
                                    overflow: "hidden",
                                    flexShrink: 0,
                                  }}
                                ></View>
                                <View style={{
                                  flexShrink: 1,
                                  flexGrow: 1,
                                  marginHorizontal: 8,
                                }}>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      color: "#334155",
                                    }}
                                  >
                                    {rowData[0]?.description}
                                  </Text>
                                </View>
                                <View style={{
                                  marginLeft: "auto",
                                  flexShrink: 0,
                                }}>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      color: "#334155",
                                      fontFamily: "RobotoMedium",
                                    }}
                                  >
                                    {an.count}
                                  </Text>
                                </View>
                              </View>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </View>
                    )}
                    {_comments?.length > 0 && (
                      <View
                        style={{
                          paddingHorizontal: 12,
                          paddingVertical: 8,
                          width: "100%",
                          borderTopWidth: 1,
                          borderColor: "#e2e8f0",
                        }}
                      >
                        <Text
                          style={{
                            color: "#000",
                            fontSize: 10,
                            textTransform: "capitalize",
                            fontFamily: "RobotoBold",
                            marginBottom: 5,
                          }}
                        >
                          comments
                        </Text>
                        {_comments?.map((itm, idx) => (
                          <Text
                            style={{
                              fontSize: 10,
                              color: "#334155",
                              paddingHorizontal: 8,
                              textTransform: "capitalize",
                              paddingVertical: 8,
                              fontFamily: "RobotoRegular",
                            }}
                            key={idx}
                          >
                            {itm}
                          </Text>
                        ))}
                      </View>
                    )}
                  </View>
                );
              })}
          </View>
        </View>
      </Page>
    </>
  );
};

export default OptionRatingHistoryReport;
