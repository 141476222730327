import React from "react";
import Indentification from "../../elements/Indentification";
import { initialsValue } from "../../../helpers";
import NoDataFound from "../../NoDataFound";

const EmployeeUploadedList = ({ userList, setUserList = () => {} }) => {
  return (
    <div>
      <div className="flex-shrink flex-grow">
        <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2"></div>
        <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-250px)] -mx-4 px-4">
          {userList &&
            Array.isArray(userList) &&
            userList?.length > 0 &&
            userList?.map((user, index) => {
              return (
                <>
                  <div className="bg-white rounded-md border border-slate-200 py-4 px-4 flex  gap-2 w-full">
                    {/* <div className="flex justify-start items-start py-2 flex-shrink-0">
                      <div
                        className="flex items-center justify-between"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          id={`${user?._id}`}
                          checked={selectedMember.some(
                            (member) => member?._id === user?._id
                          )}
                          onChange={() => handleSelectedMember(user)}
                          className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-teal-500 checked:hover:bg-teal-500 checked:bg-teal-500 "
                        />
                      </div>
                    </div> */}
                    <Indentification
                      initial={initialsValue(
                        user?.name
                      )}
                      image={null}
                      alt={user?.name}
                      fill={true}
                      className={"flex-shrink-0"}
                      size={"sm"}
                      initialClass={" !text-sm"}
                    />
                    <div className="space-y-px flex-shrink flex-grow">
                      <h3 className="text-sm font-semibold text-teal-500 capitalize !leading-none">
                        <span>
                          {user?.name || "N/A"}
                        </span>
                        <small className="text-slate-600 capitalize text-xs font-medium block">
                          {user?.designation || "N/A"}
                        </small>
                      </h3>
                      <div className="text-xs text-gray-500 flex items-center gap-2">
                        <span>{user?.email || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        {userList && Array.isArray(userList) && userList?.length <= 0 && (
          <NoDataFound />
        )}
      </div>
    </div>
  );
};

export default EmployeeUploadedList;
