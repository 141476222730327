import React from "react";

const OfferItem = ({ title, color, description, image }) => {
  return (
    <div className="relative mt-8 md:mt-20 rounded-3xl overflow-hidden h-[19rem] xl:h-[22rem]">
      <div className="absolute top-0 left-0 w-full h-full z-[1] p-9 px-9 flex items-center">
        <div className="space-y-3 bg-white max-w-[400px] xl:max-w-[490px] 2xl:max-w-[600px] p-8 rounded-3xl">
          <h3
            className={`inline font-Outfit z-0 font-medium text-[32px] text-black relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-${color} after:-z-[1] leading-[1.2]`}
          >
            {title}
          </h3>
          <div className="w-full text-[24px] font-Outfit font-normal text-black">
            {description}
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full z-0">
        <img
          src={image}
          alt="banner"
          loading="lazy"
          className="w-full h-full object-center object-cover"
        />
      </div>
    </div>
  );
};

export default OfferItem;
