import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import BoardStrengthReport from "./pdf/BoardStrengthReport";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";

const BoardStrength = () => {
  setTitle("Succession Now | Board Member Strength");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [boardMambers, setBoardMambers] = useState([]);
  const [areaOfExpertise, setAreaOfExpertise] = useState([]);
  const [studies, setStudies] = useState([]);
  const [education, setEducation] = useState([]);
  const [position, setPosition] = useState([]);
  const [certifacates, setCertifacates] = useState([]);

  const [reatingOptions, setReatingOptions] = useState([]);
  const [totalEffectivenessAvg, setTotalEffectivenessAvg] = useState(0);
  const [retirementWindow, setRetirementWindow] = useState([]);
  const [totalAvgYearOfBoard, setTotalAvgYearofBoard] = useState(0);

  useEffect(() => {
    if (role && (role !== "customer-admin" && role !== "customer-subadmin")) {
      return navigate("/dashboard");
    }
  }, [role]);

  const heading = [
    {
      name: t("management"),
      option: "",
    },
    {
      name: t("human_resources"),
      option: "",
    },
    {
      name: t("finance_banking_credit_union_finance_companies"),
      option: "",
    },
    {
      name: t("it"),
      option: "",
    },
    {
      name: t("legal"),
      option: "",
    },
    {
      name: t("accounting"),
      option: "",
    },
    {
      name: t("marketing"),
      option: "",
    },
    {
      name: t("complance_and_regulation"),
      option: "",
    },
    {
      name: t("prior_board_experience"),
      option: "",
    },
  ];

  useEffect(() => {
    async function areaExpertiseList() {
      try {
        const result = await postData("area-of-expertise/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setAreaOfExpertise(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    areaExpertiseList();
    // Board Member List
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("board-member/list", {
          sortQuery: { created_at: 1 },
          outside_candidate: 2,
        });
        if (result.data) {
          setBoardMambers(result.data);
          let cert = [];
          result.data.length > 0 &&
            result.data.map((item) => {
              if (item.additional_certifications && item.additional_certifications.length > 0) {
                item.additional_certifications.forEach((obj) => {
                  cert.push(obj);
                });
              }
            });
          let _uniqueCertificateArrayWithCount = uniqueArrayWithCount(cert);
          setCertifacates(_uniqueCertificateArrayWithCount);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();

    async function studyList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "study",
        });
        if (result.data) {
          setStudies(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    studyList();

    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember: 1,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          isBoardMember: 1,
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function employeeRatingOptionList() {
      setLoading2(true);
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "boardmember",
        });
        if (result.data) {
          setReatingOptions(result.data);
          setLoading2(false);
        } else {
          console.log("Employee rating message", result.message);
          setLoading2(false);
        }
      } catch (error) {
        console.log("Error employee rating catch", error.message);
        setLoading2(false);
      }
    }
    employeeRatingOptionList();
  }, []);

  useEffect(() => {
    if (reatingOptions && reatingOptions.length > 0) {
      let checkRatedCount = reatingOptions.filter((it) => it?.question?.fieldType !== "checkbox");
      const totalQuestion = Number(checkRatedCount?.length);
      let totalAvg = 0;
      reatingOptions.map((item, index) => {
        const ratedBoardMember = boardMambers.filter((it) => it?.ratingOptions).map((itm) => itm.ratingOptions.filter((row) => row.questionId === item?.question?._id));
        let totalSum = 0;
        if (ratedBoardMember && ratedBoardMember.length > 0) {
          ratedBoardMember.forEach((obj) => {
            obj.forEach((ob) => {
              totalSum += ob?.rating ? ob?.rating : 0;
            });
          });
        }
        let totalBoardMember = ratedBoardMember?.length;
        let avgRating = Number(totalSum / totalBoardMember).toFixed(2);
        totalAvg += Number(avgRating ? avgRating : 0);
      });
      let avg = Number(totalAvg / totalQuestion) ? Number(totalAvg / totalQuestion).toFixed(2) : 0;
      setTotalEffectivenessAvg(avg);
    }
    if (boardMambers && boardMambers.length > 0) {
      let totalBoardMember = boardMambers.length;
      let totalYear = 0;
      boardMambers.map((item) => {
        totalYear += Number(item?.board_year);
      });
      let boardYearAvg = Number(totalYear / totalBoardMember) ? Number(totalYear / totalBoardMember) : 0;
      let roundAvg = Math.round(boardYearAvg);
      setTotalAvgYearofBoard(roundAvg);
    }
  }, [reatingOptions, boardMambers]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const overallScoreDescription = (avgRating) => {
    if (avgRating && avgRating >= 9 && avgRating <= 10) {
      return "Highly Effective";
    } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
      return "Very Effective";
    } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
      return "Effective";
    } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
      return "Somewhat Effective";
    } else if (avgRating && avgRating < 5) {
      return "Effectiveness Improvement Needed";
    }
  };

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };

  useEffect(() => {
    async function retirementList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "retirement-window",
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }

    retirementList();
  }, [boardMambers]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("board_strength_report") + " - " + user?.company_name}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <BoardStrengthReport
                      areaOfExpertise={areaOfExpertise}
                      studies={studies}
                      educations={education}
                      positions={position}
                      boardMambers={boardMambers}
                      reatingOptions={reatingOptions}
                      totalEffectivenessAvg={totalEffectivenessAvg}
                      certifacates={certifacates}
                      user={user}
                      companyLogo={API_URL + user?.image}
                      retirementWindow={retirementWindow}
                      totalAvgYearOfBoard={totalAvgYearOfBoard}
                    />
                  </Document>
                }
                fileName="profile_report.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_strength_report"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative mt-2">
        <div className="flex justify-center items-center gap-2">
          <div className="relative">
            <div className="w-full max-w-xs bg-white rounded shadow mt-3 m-auto">
              <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("overall_board_effectiveness_score")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-bold text-slate-600 text-center">{totalEffectivenessAvg}</div>
                      <div className="text-sm font-medium text-slate-500 text-center">{overallScoreDescription(totalEffectivenessAvg)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="w-full max-w-xs bg-white rounded shadow mt-3 m-auto">
              <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("average_years_of_board_service")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-bold text-slate-600 text-center">{totalAvgYearOfBoard}</div>
                      <div className="text-sm font-medium text-slate-500 text-center">{totalAvgYearOfBoard > 1 ? "Years" : "Year"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white rounded shadow mt-3">
        <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
          <table className="min-w-max w-full table-auto rounded overflow-hidden">
            <thead>
              <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                <th className="py-4 px-2 text-left w-full max-w-[50%] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">{t("strength_factors")}</th>
                <th className="py-4 px-2 text-left w-full max-w-[50%] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">{t("combined_year_of_experience")}</th>
                {/* <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                  {t("strength_rating")}
                </th>
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                  {t("overall_board_strength_score")}
                </th> */}
              </tr>
            </thead>
            <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
              {areaOfExpertise && areaOfExpertise.length > 0 ? (
                areaOfExpertise.map((item, index) => {
                  let count = 0;
                  let member = 0;
                  let rating = 0;
                  let score = "Limited";
                  let primary = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.primary_expertise === item?._id).map((it) => it?.primary_experience);
                  if (primary && primary.length > 0) {
                    count = count + primary.reduce((total, num) => total + num, 0);
                    member = member + primary?.length;
                  }
                  let secondary = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.secondary_expertise === item?._id).map((it) => it?.secondary_experience);
                  if (secondary && secondary.length > 0) {
                    count = count + secondary.reduce((total, num) => total + num, 0);
                    member = member + secondary?.length;
                  }
                  //count = (data && data?.length>0)?data?.length:0;
                  rating = count && count > 0 ? Number(count) / Number(boardMambers.length) : 0;
                  if (rating && rating < 2) {
                    score = "Limited";
                  } else if (rating && rating >= 2 && rating <= 4.9) {
                    score = "Well Represented";
                  } else if (rating && rating >= 5 && rating <= 9.9) {
                    score = "Strong";
                  } else if (rating && rating >= 10) {
                    score = "Very Strong";
                  }
                  if (Number(count) > 0) {
                    return (
                      <tr>
                        <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {score === "Weak" ? <span className="text-rose-600">*</span> : ""}
                              {item?.name}
                            </div>
                            <div className="text-xs text-slate-400">{item?.option}</div>
                          </div>
                        </td>
                        <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">{count}</div>
                          </div>
                        </td>
                        {/* <td className={"py-3 px-2 text-left max-w-[240px]"}>
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {Number(rating).toFixed(2)}
                            </div>
                          </div>
                        </td>
                        <td className={"py-3 px-2 text-left max-w-[240px]"}>
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {score}
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    );
                  }
                })
              ) : (
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Board Education */}
      <div className="relative mt-5">
        <div className="grid grid-cols-4 gap-2">
          <div className="flex flex-col">
            <div className="text-lg text-slate-800 font-bold flex-shrink-0">{t("board_education")}</div>
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%]  z-10 bg-slate-900 rounded-tl-md">{t("field_of_study")}</th>
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tr-md">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {studies && studies.length > 0 ? (
                      studies
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => {
                          let count = 0;
                          let studyCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.study === item?._id).map((it) => it?.study);
                          if (studyCount && studyCount.length > 0) {
                            count = count + studyCount?.length;
                          }
                          if (count > 0)
                            return (
                              <tr>
                                <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                                    <div className="text-xs text-slate-400">{item?.option}</div>
                                  </div>
                                </td>
                                <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{count}</div>
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-lg text-slate-800 font-bold flex-shrink-0">&nbsp;</div>
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%]  z-10 bg-slate-900 rounded-tl-md">{t("level_of_education")}</th>
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tr-md">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {education && education.length > 0 ? (
                      education
                        ?.sort((a, b) => b.ordering - a.ordering)
                        ?.map((item, index) => {
                          let count = 0;
                          let educationCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.education === item?._id).map((it) => it?.education);
                          if (educationCount && educationCount.length > 0) {
                            count = count + educationCount?.length;
                          }
                          if (count > 0)
                            return (
                              <tr>
                                <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                                    <div className="text-xs text-slate-400">{item?.option}</div>
                                  </div>
                                </td>
                                <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{count}</div>
                                  </div>
                                </td>
                              </tr>
                            );
                        })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            {/* <div className="mb-4">
              <div className="text-xl text-slate-800 font-bold">&nbsp;</div>
              <div className="w-full bg-white rounded shadow mt-3">
                <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                  <table className="w-full table-auto">
                    <thead>
                      <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                        <th className="py-4 px-2 text-left max-w-[240px]  z-10 bg-slate-900 rounded-tl-md">
                          {t("certifications_&_licenses")}
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tr-md">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-slate-700 text-sm font-light bg-white">
                      {certifacates && certifacates.length > 0 ? (
                        certifacates?.map((item) => {
                          return (
                            <tr>
                              <td
                                className={"py-3 px-2 text-left max-w-[240px]"}
                              >
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">
                                    {item?.id}
                                  </div>
                                </div>
                              </td>
                              <td
                                className={"py-3 px-2 text-right max-w-[240px]"}
                              >
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">
                                    {item?.count}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="border-b border-slate-200">
                          <td className="py-3 px-2 text-left" colSpan={20}>
                            {loading ? (
                              <Searching label={"Searching"} />
                            ) : (
                              <NoDataFound label={"No Data Found"} />
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            <div className="text-lg text-slate-800 font-bold flex-shrink-0">{t("board_retirement_profile")}</div>
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tl-md">{t("board_retirement_window")}</th>
                      <th className="py-4 px-2 text-right w-full max-w-[50%] z-10 bg-slate-900 rounded-tr-md">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {retirementWindow && retirementWindow.length > 0 ? (
                      retirementWindow.map((item, index) => {
                        const countMap =
                          boardMambers &&
                          Array?.isArray(boardMambers) &&
                          boardMambers?.length > 0 &&
                          boardMambers?.reduce((acc, member) => {
                            const windowId = member?.retirement_window;
                            acc[windowId] = (acc[windowId] || 0) + 1;
                            return acc;
                          }, {});
                        const count = countMap[item._id] || 0;
                        if (countMap && count > 0) {
                          return (
                            <tr>
                              <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                                </div>
                              </td>
                              <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{count}</div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-[15px] text-slate-800 font-bold flex-shrink-0">{t("board_committee_experience")}</div>
            <div className="w-full bg-white rounded shadow mt-3 flex-shrink flex-grow overflow-hidden">
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left w-full max-w-[50%] z-10 bg-slate-900 rounded-tl-md">{t("committee_assignment")}</th>
                      <th className="py-4 px-2 text-right w-full max-w-[50%] text-xs z-10 bg-slate-900 rounded-tr-md">{t("board_member_board_experience_summary")}</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
                    {position && position.length > 0 ? (
                      position
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => {
                          let count = 0;
                          let positionCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.position === item?._id).map((it) => it?.position);
                          let prevPositionCount = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position === item?._id).map((it) => it?.previous_position);
                          let prevPositionCount2 = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position_two === item?._id).map((it) => it?.previous_position_two);
                          let prevPositionCount3 = boardMambers.length > 0 && boardMambers.filter((itm) => itm?.previous_position_three === item?._id).map((it) => it?.previous_position_three);
                          if (positionCount && positionCount.length > 0) {
                            count = count + positionCount?.length;
                          }
                          if (prevPositionCount && prevPositionCount.length > 0) {
                            count = count + prevPositionCount?.length;
                          }
                          if (prevPositionCount2 && prevPositionCount2.length > 0) {
                            count = count + prevPositionCount2?.length;
                          }
                          if (prevPositionCount3 && prevPositionCount3.length > 0) {
                            count = count + prevPositionCount3?.length;
                          }
                          if (count > 0) {
                            return (
                              <tr>
                                <td className={"py-3 px-2 text-left w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                                    <div className="text-xs text-slate-400">{item?.option}</div>
                                  </div>
                                </td>
                                <td className={"py-3 px-2 text-right w-full max-w-[50%]"}>
                                  <div className="block">
                                    <div className="text-sm font-medium text-slate-600">{count}</div>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-slate-50 rounded-md  mt-3 overflow-hidden shadow">
        <div className="w-full px-2 py-4 bg-black rounded-t-md sticky">
          <div className="text-white font-bold text-sm leading-none text-center">{t("board_effectiveness")}</div>
        </div>
        <div className="w-full relative divide-y divide-slate-200 overflow-hidden rounded-b-md">
          {reatingOptions && reatingOptions.length > 0 ? (
            reatingOptions.map((item, index) => {
              let ratingRowAnswers = item?.question?.answers;
              const ratedBoardMember = boardMambers.filter((it) => it?.ratingOptions).map((itm) => itm.ratingOptions.filter((row) => row.questionId === item?.question?._id));
              let totalSum = 0;
              let _comments = [];
              let _ans = [];
              if (ratedBoardMember && ratedBoardMember.length > 0) {
                ratedBoardMember.forEach((obj) => {
                  obj.forEach((ob) => {
                    totalSum += ob?.rating ? ob?.rating : 0;
                    if (ob?.comments) {
                      _comments.push(ob?.comments);
                    }
                    if (ob?.ids && ob?.ids?.length > 0) {
                      _ans.push(ob?.ids);
                    }
                  });
                });
              }
              if (ratingRowAnswers && ratingRowAnswers.length > 0) {
                ratingRowAnswers.forEach((obj) => {
                  if (obj?.company_id === user?._id) {
                    _ans.push(obj?._id);
                  }
                });
              }
              let totalBoardMember = ratedBoardMember?.length;
              let avgRating = Number(totalSum / totalBoardMember) > 0 ? Number(totalSum / totalBoardMember).toFixed(2) : 0;
              let _score = "";
              if (avgRating && avgRating >= 9 && avgRating <= 10) {
                _score = "Highly Effective";
              } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                _score = "Very Effective";
              } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                _score = "Effective";
              } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                _score = "Somewhat Effective";
              } else if (avgRating && avgRating < 5) {
                _score = "Effectiveness Improvement Needed";
              }
              let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
              return (
                <>
                  <div className="w-full px-3 py-3 bg-white">
                    <div className="flex w-full">
                      <div className={"py-3 px-2 text-left w-[60%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">
                            {Number(index + 1)}.{" " + item?.question?.name}
                          </div>
                          <div className="text-xs text-slate-400">{item?.option}</div>
                        </div>
                      </div>
                      <div className={"py-3 px-2 text-left w-[10%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">{avgRating > 0 ? avgRating : ""}</div>
                        </div>
                      </div>
                      <div className={"py-3 px-2 text-left w-[30%]"}>
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">{_score}</div>
                        </div>
                      </div>
                    </div>
                    {_uniqueArrayWithCount && _uniqueArrayWithCount.length > 0 && (
                      <div className="relative">
                        {_uniqueArrayWithCount.map((an, i) => {
                          let rowData = ratingRowAnswers.filter((row) => row._id === an.id);
                          if (rowData && rowData.length > 0) {
                            return (
                              <div className="px-4 flex items-center gap-2">
                                <div className="w-2 h-2 rounded-full overflow-hidden bg-slate-600"></div>
                                <div className="text-slate-600 text-sm">{rowData[0]?.description}</div>
                                <div className="text-slate-600 text-sm font-semibold ml-auto">{an.count}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}
                    {_comments.length > 0 && (
                      <div className="py-3 px-2 w-full divide-y divide-slate-200 ">
                        <div className="flex items-center gap-1">
                          <i className="fa-solid fa-comment mr-1"></i>
                          <div className="text-black text-sm capitalize">comments</div>
                        </div>
                        {_comments.map((itm, idx) => (
                          <div className="text-xs text-slate-500 whitespace-pre-line py-2 capitalize px-2 font-semibold">{itm}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <div className="border-b border-slate-200">
              <div className="py-3 px-2 text-left">{loading2 ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}</div>
            </div>
          )}
        </div>
      </div>

      <div className="relative mt-2">
        <div className="flex justify-center items-center gap-2">
          <div className="relative">
            <div className="w-full max-w-xs bg-white rounded overflow-hidden shadow mt-3 m-auto">
              <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("overall_board_effectiveness_score")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-bold text-slate-600 text-center">{totalEffectivenessAvg}</div>
                      <div className="text-sm font-medium text-slate-500 text-center">{overallScoreDescription(totalEffectivenessAvg)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="w-full max-w-xs bg-white rounded overflow-hidden shadow mt-3 m-auto">
              <div className="w-full px-2 py-4 bg-black rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("average_years_of_board_service")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-bold text-slate-600 text-center">{totalAvgYearOfBoard}</div>
                      <div className="text-sm font-medium text-slate-500 text-center">{totalAvgYearOfBoard > 1 ? "Years" : "Year"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardStrength;
