import React, { useRef, useEffect, useState, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Textarea from "../components/form/Textarea";
import Input from "../components/form/Input";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import toastr from "toastr";
import SignaturePad from "react-signature-canvas";
import { useSelector } from "react-redux";

import { postData } from "../services/api";
import { API_URL } from "../config/host";

import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import ConclusionReport from "./pdf/ConclusionReport";
import SuccessionPlanReport from "./pdf/SuccessionPlanReport";
import OtherEmployeePopup from "../components/popup/OtherEmployeePopup";
import StepAlert from "../components/elements/alert/StepAlert";
import EmergencyReplacementPlanReport from "./pdf/EmergencyReplacementPlanReport";

import { useTranslation } from "react-i18next";
import { useSettingsModal } from "../Context/SettingsContext";
import { PositionTier } from "../constant/Constant";

const Conclusion = () => {
  setTitle("Succession Now | Replacement Conclusion");
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const user = useSelector((state) => state.auth?.user);
  const { tierType } = useSettingsModal();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successors, setSuccessors] = useState([]);
  const [conclusionDetails, setConclusionDetails] = useState({});
  const [state, setState] = useState({ trimmedDataURL: null });
  const [sigPad, setSignpad] = useState({});
  const [isClear, setIsClear] = useState(false);
  const [isTrim, setIsTrim] = useState(false);
  const [conclusion, setConclusion] = useState([]);
  const [conclusionPdf, setConclusionPdf] = useState([]);
  const [tempData1, setTempData1] = useState([]);
  const [tempData2, setTempData2] = useState([]);
  const [tempData3, setTempData3] = useState([]);
  const [tempData4, setTempData4] = useState([]);

  const [enabledOtherEmpPopup, setEnabledOtherEmpPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});

  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [yearWithCompanyWeight, setYearWithCompany] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const [totalDataCount, setTotalDataCount] = useState(0);
  const [positionDetails, setPositionDetails] = useState({});
  const [concernHeadings, setConcernHeadings] = useState([]);
  const [predictorsHeading, setPredictorsHeading] = useState([]);
  const [ownSuccessor, setOwnSuccessors] = useState([]);
  const [load, setLoad] = useState(false);

  const [field, setField] = useState("");
  const [choise, setChoise] = useState("");
  const [step, setStep] = useState(0);
  const [isChange, setIsChange] = useState(false);

  const [callingFun, setCallingFun] = useState(false);
  const [details, setDetails] = useState({});

  const [tierDetails, setTierDetails] = useState([]);

  const loadTierList = useCallback(async () => {
    if (tierType === PositionTier) {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { ["created_at"]: -1 },
          type: "position-group",
        });
        if (result.status === 200) {
          setTierDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [tierType]);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  // const heading = [
  //   {
  //     name: t('senior_management_experience'),
  //     option: t('weight')+': ' + experienceWeight + '%',
  //     ordering : 0
  //   },
  //   {
  //     name: t('education'),
  //     option: t('weight')+': ' + educationWeight + '%'
  //   },
  //   {
  //     name: t('performance_rating'),
  //     option: t('weight')+': ' + performanceWeight + '%'
  //   },
  //   {
  //     name: t('loyalty'),
  //     option: t('pass')+'/'+t('fail')
  //   },
  //   {
  //     name: t('desire_to_advance'),
  //     option: t('pass')+'/'+t('fail')
  //   },
  //   {
  //     name: t('retirement_window'),
  //     option: t('pass')+'/'+t('fail')
  //   },
  //   {
  //     name: t('area_of_concern_score'),
  //     option: t('weight')+': ' + concernsWeight + '%'
  //   },
  //   {
  //     name: t('potential_predictor_score'),
  //     option: t('weight')+': ' + predictorWeight + '%'
  //   },
  //   {
  //     name: t('final_succession_score'),
  //     option: t('weight')+': ' + totalWeight + '%'
  //   },
  //   {
  //     name: t('advancement_readiness_projection'),
  //     option: ''
  //   }
  // ]

  const heading = [
    {
      name: t("potential_predictor_score"),
      option: t("weight") + ": " + predictorWeight + "%",
      overall_score: predictorWeight,
      ordering: 0,
    },
    {
      name: t("senior_management_experience"),
      option: t("weight") + ": " + experienceWeight + "%",
      overall_score: experienceWeight,
      ordering: 1,
    },
    {
      name: t("concerns"),
      option: t("weight") + ": " + concernsWeight + "%",
      overall_score: concernsWeight,
      ordering: 2,
    },
    {
      name: t("recent_performance_review"),
      option: t("weight") + ": " + performanceWeight + "%",
      overall_score: performanceWeight,
      ordering: 3,
    },
    {
      name: t("high_level_of_education"),
      option: t("weight") + ": " + educationWeight + "%",
      overall_score: educationWeight,
      ordering: 4,
    },
    {
      name: t("years_with_the_company"),
      option: t("weight") + ": " + yearWithCompanyWeight + "%",
      overall_score: yearWithCompanyWeight,
      ordering: 5,
    },
    {
      name: t("desire_to_advance"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 6,
    },
    {
      name: t("loyalty_toward_company"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 7,
    },
    {
      name: t("pending_retirement_window"),
      option: t("pass") + "/" + t("fail"),
      overall_score: 0,
      ordering: 8,
    },
    {
      name: t("overall_weighted_score"),
      option: t("weight") + ": " + totalWeight + "%",
      overall_score: 0,
      ordering: 9,
    },
    {
      name: t("additional_comments"),
      option: "",
      overall_score: 0,
      ordering: 10,
    },
  ].sort((a, b) => b.overall_score - a.overall_score);

  const readinessHeading = [
    {
      name: t("readiness_timeframe"),
      option: "",
    },
    {
      name: t("competency_fit"),
      option: "",
    },
    {
      name: t("cultural_fit"),
      option: "",
    },
    {
      name: t("additional_comments"),
      option: "",
    },
    {
      name: t("eliminated_candidate_due_to"),
      option: "",
    },
  ];

  const clear = () => {
    setIsClear(true);
    sigPad.clear();
    setState({ trimmedDataURL: null });
    setIsClear(false);
  };

  const trim = () => {
    setIsTrim(true);
    if (sigPad.isEmpty()) {
      sigPad.clear();
      setState({ trimmedDataURL: null });
    } else {
      setState({
        trimmedDataURL: sigPad.getTrimmedCanvas().toDataURL("image/png"),
      });
      setCallingFun(true);
    }
    setIsTrim(false);
  };

  let { trimmedDataURL } = state;
  const [planApprove, setPlanApprove] = useState("");
  const [name, setName] = useState();
  const [date, setDate] = useState("");
  const [step1, setStep1] = useState([]);

  let conclusionArray = [
    {
      _id: 1,
      name: t("first_choice"),
      time_horizon_zero_to_one: "",
      time_horizon_zero_to_one_text: "",
      time_horizon_one_to_three: "",
      time_horizon_one_to_three_text: "",
      time_horizon_three_to_five: "",
      time_horizon_three_to_five_text: "",
      replacement: "",
      replacement_text: "",
      isComment: false,
    },
    {
      _id: 2,
      name: t("second_choice"),
      time_horizon_zero_to_one: "",
      time_horizon_zero_to_one_text: "",
      time_horizon_one_to_three: "",
      time_horizon_one_to_three_text: "",
      time_horizon_three_to_five: "",
      time_horizon_three_to_five_text: "",
      replacement: "",
      replacement_text: "",
      isComment: false,
    },
    {
      _id: 3,
      name: t("third_choice"),
      time_horizon_zero_to_one: "",
      time_horizon_zero_to_one_text: "",
      time_horizon_one_to_three: "",
      time_horizon_one_to_three_text: "",
      time_horizon_three_to_five: "",
      time_horizon_three_to_five_text: "",
      replacement: "",
      replacement_text: "",
      isComment: false,
    },
    {
      _id: 4,
      name: t("fourth_choice"),
      time_horizon_zero_to_one: "",
      time_horizon_zero_to_one_text: "",
      time_horizon_one_to_three: "",
      time_horizon_one_to_three_text: "",
      time_horizon_three_to_five: "",
      time_horizon_three_to_five_text: "",
      replacement: "",
      replacement_text: "",
      isComment: false,
    },
    {
      _id: 5,
      name: t("additional_comments"),
      comment_zero_to_one: "",
      comment_one_to_three: "",
      comment_three_to_five: "",
      comment_replacement: "",
      isComment: true,
    },
  ];

  useEffect(() => {
    const loaWeights = async () => {
      setLoad(false);
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          let pWeight = 0;
          let exWeight = 0;
          let con = 0;
          let pref = 0;
          let edu = 0;
          let ywc = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              pWeight = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            } else if (value.alias === "executive_experience") {
              exWeight = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            } else if (value.alias === "concerns") {
              con = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            } else if (value.alias === "recent_performance_review") {
              pref = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            } else if (value.alias === "highest_level_education") {
              edu = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            } else if (value.alias === "years_with_company") {
              ywc = value?.company_weight
                ? value?.company_weight
                : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }
            setPredictorWeight(pWeight);
            setExperienceWeight(exWeight);
            setConcernWeight(con);
            setPerformanceWeight(pref);
            setEducationWeight(edu);
            setYearWithCompany(ywc);
            setTotalWeight(total);
            setLoad(true);
          });
        }
        setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
        setLoad(false);
      }
    };
    loaWeights();

    const detailsFun = async () => {
      setLoading(true);
      try {
        const result = await postData("emergency-replacement/details", {});
        if (result.data) {
          setDetails(result.data);
          setLoading(false);
        } else {
          setDetails({});
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    detailsFun();
  }, []);

  useEffect(() => {
    const loadSuccessors = async () => {
      try {
        const result = await postData("successor/list", {
          sortQuery: { created_at: 1 },
          //is_interim:2,
        });
        if (result.data) {
          setOwnSuccessors(
            result.data
              .map((value, key) => {
                let predictorCalculation =
                  (predictorWeight * value?.overall_weighted_score) / 100;
                let experienceCalculation =
                  (experienceWeight *
                    (value.experience_details
                      ? value?.experience_details[0]?.point
                      : 0)) /
                  100;
                let concernsCalculation =
                  (concernsWeight * value?.overall_score) / 100;
                let performanceCalculation =
                  (performanceWeight *
                    (value.performance_details
                      ? value?.performance_details[0]?.point
                      : 0)) /
                  100;
                let educationCalculation =
                  (educationWeight *
                    (value.education_details
                      ? value?.education_details[0]?.point
                      : 0)) /
                  100;
                let yearWithCompanyCalculation =
                  (yearWithCompanyWeight *
                    (value.cu_details ? value?.cu_details[0]?.point : 0)) /
                  100;
                let desireResult =
                  value?.desire_details && value?.desire_details.length > 0
                    ? value?.desire_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
                let loyaltyResult =
                  value?.loyalty_details && value?.loyalty_details.length > 0
                    ? value?.loyalty_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
                let RetirementResult =
                  value?.retirement_details &&
                  value?.retirement_details.length > 0
                    ? value?.retirement_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
                return {
                  ...value,
                  overallWeightScore:
                    desireResult === 2 ||
                    loyaltyResult === 2 ||
                    RetirementResult === 2
                      ? 0.0
                      : (
                          parseFloat(predictorCalculation) +
                          parseFloat(experienceCalculation) +
                          parseFloat(concernsCalculation) +
                          parseFloat(performanceCalculation) +
                          parseFloat(educationCalculation) +
                          parseFloat(yearWithCompanyCalculation)
                        ).toFixed(2),
                  desireResult: desireResult,
                  loyaltyResult: loyaltyResult,
                  RetirementResult: RetirementResult,
                };
              })
              .filter((item) => item.is_interim === 2)
          );
          setSuccessors(result.data);
          setStep1(
            result.data.map((value, key) => {
              let predictorCalculation =
                (predictorWeight * value?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (value.experience_details
                    ? value?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * value?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (value.performance_details
                    ? value?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (value.education_details
                    ? value?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (value.cu_details ? value?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                value?.desire_details && value?.desire_details.length > 0
                  ? value?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                value?.loyalty_details && value?.loyalty_details.length > 0
                  ? value?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                value?.retirement_details &&
                value?.retirement_details.length > 0
                  ? value?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              return {
                ...value,
                overallWeightScore:
                  desireResult === 2 ||
                  loyaltyResult === 2 ||
                  RetirementResult === 2
                    ? 0.0
                    : (
                        parseFloat(predictorCalculation) +
                        parseFloat(experienceCalculation) +
                        parseFloat(concernsCalculation) +
                        parseFloat(performanceCalculation) +
                        parseFloat(educationCalculation) +
                        parseFloat(yearWithCompanyCalculation)
                      ).toFixed(2),
                desireResult: desireResult,
                loyaltyResult: loyaltyResult,
                RetirementResult: RetirementResult,
              };
            })
          );
          setTempData1([
            {
              _id: "external-hire",
              label: t("external_hire"),
              value: "external-hire",
            },
            ...result.data.map((row) => {
              let predictorCalculation =
                (predictorWeight * row?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (row.experience_details
                    ? row?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * row?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (row.performance_details
                    ? row?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (row.education_details
                    ? row?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (row.cu_details ? row?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                row?.desire_details && row?.desire_details.length > 0
                  ? row?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                row?.loyalty_details && row?.loyalty_details.length > 0
                  ? row?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                row?.retirement_details && row?.retirement_details.length > 0
                  ? row?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let score =
                desireResult === 2 ||
                loyaltyResult === 2 ||
                RetirementResult === 2
                  ? 0.0
                  : (
                      parseFloat(predictorCalculation) +
                      parseFloat(experienceCalculation) +
                      parseFloat(concernsCalculation) +
                      parseFloat(performanceCalculation) +
                      parseFloat(educationCalculation) +
                      parseFloat(yearWithCompanyCalculation)
                    ).toFixed(2);
              return {
                _id: row?._id,
                label:
                  row?.name +
                  (row?.is_interim === 1
                    ? " (" + t("no_rating") + ")"
                    : " (" + (score > 0 ? score : 0) + ")"),
                value: row?._id,
                disabled: row?.disabled,
              };
            }),
            {
              _id: "other-employee",
              label: t("other_employee"),
              value: "other-employee",
            },
          ]);
          setTempData2([
            {
              _id: "external-hire",
              label: t("external_hire"),
              value: "external-hire",
            },
            ...result.data.map((row) => {
              let predictorCalculation =
                (predictorWeight * row?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (row.experience_details
                    ? row?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * row?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (row.performance_details
                    ? row?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (row.education_details
                    ? row?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (row.cu_details ? row?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                row?.desire_details && row?.desire_details.length > 0
                  ? row?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                row?.loyalty_details && row?.loyalty_details.length > 0
                  ? row?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                row?.retirement_details && row?.retirement_details.length > 0
                  ? row?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let score =
                desireResult === 2 ||
                loyaltyResult === 2 ||
                RetirementResult === 2
                  ? 0.0
                  : (
                      parseFloat(predictorCalculation) +
                      parseFloat(experienceCalculation) +
                      parseFloat(concernsCalculation) +
                      parseFloat(performanceCalculation) +
                      parseFloat(educationCalculation) +
                      parseFloat(yearWithCompanyCalculation)
                    ).toFixed(2);
              return {
                _id: row?._id,
                label:
                  row?.name +
                  (row?.is_interim === 1
                    ? " (" + t("no_rating") + ")"
                    : " (" + (score > 0 ? score : 0) + ")"),
                value: row?._id,
                disabled: row?.disabled,
              };
            }),
            {
              _id: "other-employee",
              label: t("other_employee"),
              value: "other-employee",
            },
          ]);
          setTempData3([
            {
              _id: "external-hire",
              label: t("external_hire"),
              value: "external-hire",
            },
            ...result.data.map((row) => {
              let predictorCalculation =
                (predictorWeight * row?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (row.experience_details
                    ? row?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * row?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (row.performance_details
                    ? row?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (row.education_details
                    ? row?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (row.cu_details ? row?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                row?.desire_details && row?.desire_details.length > 0
                  ? row?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                row?.loyalty_details && row?.loyalty_details.length > 0
                  ? row?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                row?.retirement_details && row?.retirement_details.length > 0
                  ? row?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let score =
                desireResult === 2 ||
                loyaltyResult === 2 ||
                RetirementResult === 2
                  ? 0.0
                  : (
                      parseFloat(predictorCalculation) +
                      parseFloat(experienceCalculation) +
                      parseFloat(concernsCalculation) +
                      parseFloat(performanceCalculation) +
                      parseFloat(educationCalculation) +
                      parseFloat(yearWithCompanyCalculation)
                    ).toFixed(2);
              return {
                _id: row?._id,
                label:
                  row?.name +
                  (row?.is_interim === 1
                    ? " (" + t("no_rating") + ")"
                    : " (" + (score > 0 ? score : 0) + ")"),
                value: row?._id,
                disabled: row?.disabled,
              };
            }),
            {
              _id: "other-employee",
              label: t("other_employee"),
              value: "other-employee",
            },
          ]);
          setTempData4([
            {
              _id: "external-hire",
              label: t("external_hire"),
              value: "external-hire",
            },
            ...result.data.map((row) => {
              let predictorCalculation =
                (predictorWeight * row?.overall_weighted_score) / 100;
              let experienceCalculation =
                (experienceWeight *
                  (row.experience_details
                    ? row?.experience_details[0]?.point
                    : 0)) /
                100;
              let concernsCalculation =
                (concernsWeight * row?.overall_score) / 100;
              let performanceCalculation =
                (performanceWeight *
                  (row.performance_details
                    ? row?.performance_details[0]?.point
                    : 0)) /
                100;
              let educationCalculation =
                (educationWeight *
                  (row.education_details
                    ? row?.education_details[0]?.point
                    : 0)) /
                100;
              let yearWithCompanyCalculation =
                (yearWithCompanyWeight *
                  (row.cu_details ? row?.cu_details[0]?.point : 0)) /
                100;
              let desireResult =
                row?.desire_details && row?.desire_details.length > 0
                  ? row?.desire_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let loyaltyResult =
                row?.loyalty_details && row?.loyalty_details.length > 0
                  ? row?.loyalty_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let RetirementResult =
                row?.retirement_details && row?.retirement_details.length > 0
                  ? row?.retirement_details[0]?.isPass
                    ? 1
                    : 2
                  : null;
              let score =
                desireResult === 2 ||
                loyaltyResult === 2 ||
                RetirementResult === 2
                  ? 0.0
                  : (
                      parseFloat(predictorCalculation) +
                      parseFloat(experienceCalculation) +
                      parseFloat(concernsCalculation) +
                      parseFloat(performanceCalculation) +
                      parseFloat(educationCalculation) +
                      parseFloat(yearWithCompanyCalculation)
                    ).toFixed(2);
              return {
                _id: row?._id,
                label:
                  row?.name +
                  (row?.is_interim === 1
                    ? " (" + t("no_rating") + ")"
                    : " (" + (score > 0 ? score : 0) + ")"),
                value: row?._id,
                disabled: row?.disabled,
              };
            }),
            {
              _id: "other-employee",
              label: t("other_employee"),
              value: "other-employee",
            },
          ]);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSuccessors();

    async function concernHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "concern",
        });
        if (result.data) {
          setConcernHeadings(result.data);
        } else {
          console.log("Concern heading list message", result.message);
        }
      } catch (error) {
        console.log("Error concern heading list catch", error.message);
      }
    }
    concernHeadingData();

    async function predictorHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "predictors",
        });
        if (result.data) {
          setPredictorsHeading(result.data);
        } else {
          console.log("Predictors heading list message", result.message);
        }
      } catch (error) {
        console.log("Error predictors heading list catch", error.message);
      }
    }
    predictorHeadingData();

    async function positionDetails() {
      try {
        const result = await postData("competency/details", {
          id: user.designation,
        });
        if (result.data) {
          setPositionDetails(result.data);
        } else {
          console.log("Competency details message", result.message);
        }
      } catch (error) {
        console.log("Error competency details catch", error.message);
      }
    }
    positionDetails();

    // Conclusion Details
    const loadConclusionDetails = async () => {
      setLoading(true);
      try {
        const result = await postData("conclusion/details", {});
        if (result.data) {
          setConclusionDetails(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadConclusionDetails();
  }, [load || ""]);

  useEffect(() => {
    const onUpdate = async (e) => {
      setIsUpdate(true);
      try {
        const result = await postData("conclusion/update", {
          conclusion: conclusion,
          name: name,
          date: date,
          plan_approve: planApprove,
          signature: trimmedDataURL,
          company_id: user?._id,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsUpdate(false);
          setCallingFun(false);
          await postData("download-succession-plan/close-approval", {});
        } else {
          toastr.error(result.message);
          setIsUpdate(false);
          setCallingFun(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsUpdate(false);
        setCallingFun(false);
      }
    };
    if (callingFun) {
      onUpdate();
    }
  }, [callingFun]);

  const onUpdatePlanHistory = async (e) => {
    setIsUpdate(true);
    try {
      const result = await postData("create/plan-history", {});
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  useEffect(() => {
    let unsetArray_thz1 = conclusion
      .filter((item) => item.time_horizon_zero_to_one !== undefined)
      .map((item) => item.time_horizon_zero_to_one);
    let uniqueArray_thz1 = successors.filter((item) =>
      unsetArray_thz1.includes(item._id)
    );
    setTempData1(
      tempData1.map((temp1) => {
        let record = uniqueArray_thz1.filter((item) => item._id === temp1._id);
        if (record && record.length > 0) {
          return {
            ...temp1,
            disabled: true,
          };
        } else {
          return {
            ...temp1,
            disabled: false,
          };
        }
      })
    );

    let unsetArray_thz2 = conclusion
      .filter((item) => item.time_horizon_one_to_three !== undefined)
      .map((item) => item.time_horizon_one_to_three);
    let uniqueArray_thz2 = successors.filter((item) =>
      unsetArray_thz2.includes(item._id)
    );
    setTempData2(
      tempData2.map((temp2) => {
        let record2 = uniqueArray_thz2.filter((item) => item._id === temp2._id);
        if (record2 && record2.length > 0) {
          return {
            ...temp2,
            disabled: true,
          };
        } else {
          return {
            ...temp2,
            disabled: false,
          };
        }
      })
    );

    let unsetArray_thz3 = conclusion
      .filter((item) => item.time_horizon_three_to_five !== undefined)
      .map((item) => item.time_horizon_three_to_five);
    let uniqueArray_thz3 = successors.filter((item) =>
      unsetArray_thz3.includes(item._id)
    );
    setTempData3(
      tempData3.map((temp3) => {
        let record3 = uniqueArray_thz3.filter((item) => item._id === temp3._id);
        if (record3 && record3.length > 0) {
          return {
            ...temp3,
            disabled: true,
          };
        } else {
          return {
            ...temp3,
            disabled: false,
          };
        }
      })
    );

    let unsetArray_thz4 = conclusion
      .filter((item) => item.replacement !== undefined)
      .map((item) => item.replacement);
    let uniqueArray_thz4 = successors.filter((item) =>
      unsetArray_thz4.includes(item._id)
    );
    setTempData4(
      tempData4.map((temp4) => {
        let record4 = uniqueArray_thz4.filter((item) => item._id === temp4._id);
        if (record4 && record4.length > 0) {
          return {
            ...temp4,
            disabled: true,
          };
        } else {
          return {
            ...temp4,
            disabled: false,
          };
        }
      })
    );
  }, [conclusion]);

  useEffect(() => {
    if (conclusionDetails?._id) {
      setConclusion(
        conclusionDetails?.conclusion.map((item) => {
          if (item?._id === 1) {
            return {
              ...item,
              name: t("first_choice"),
            };
          } else if (item?._id === 2) {
            return {
              ...item,
              name: t("second_choice"),
            };
          } else if (item?._id === 3) {
            return {
              ...item,
              name: t("third_choice"),
            };
          } else if (item?._id === 4) {
            return {
              ...item,
              name: t("fourth_choice"),
            };
          } else if (item?._id === 5) {
            return {
              ...item,
              name: t("additional_comments"),
            };
          }
        })
      );
      setPlanApprove(conclusionDetails?.plan_approve);
      setName(conclusionDetails?.name);
      setDate(conclusionDetails?.date ? conclusionDetails?.date : "");
      setState({ trimmedDataURL: API_URL + conclusionDetails?.signature });
      let unsetArray_thz1 = conclusionDetails?.conclusion
        .filter((item) => item.time_horizon_zero_to_one !== undefined)
        .map((item) => item.time_horizon_zero_to_one);
      let uniqueArray_thz1 = successors.filter((item) =>
        unsetArray_thz1.includes(item._id)
      );
      setTempData1(
        tempData1.map((temp1) => {
          let record = uniqueArray_thz1.filter(
            (item) => item._id === temp1._id
          );
          if (record && record.length > 0) {
            return {
              ...temp1,
              disabled: true,
            };
          } else {
            return {
              ...temp1,
              disabled: false,
            };
          }
        })
      );

      let unsetArray_thz2 = conclusionDetails?.conclusion
        .filter((item) => item.time_horizon_one_to_three !== undefined)
        .map((item) => item.time_horizon_one_to_three);
      let uniqueArray_thz2 = successors.filter((item) =>
        unsetArray_thz2.includes(item._id)
      );
      setTempData2(
        tempData2.map((temp2) => {
          let record2 = uniqueArray_thz2.filter(
            (item) => item._id === temp2._id
          );
          if (record2 && record2.length > 0) {
            return {
              ...temp2,
              disabled: true,
            };
          } else {
            return {
              ...temp2,
              disabled: false,
            };
          }
        })
      );

      let unsetArray_thz3 = conclusionDetails?.conclusion
        .filter((item) => item.time_horizon_three_to_five !== undefined)
        .map((item) => item.time_horizon_three_to_five);
      let uniqueArray_thz3 = successors.filter((item) =>
        unsetArray_thz3.includes(item._id)
      );
      setTempData3(
        tempData3.map((temp3) => {
          let record3 = uniqueArray_thz3.filter(
            (item) => item._id === temp3._id
          );
          if (record3 && record3.length > 0) {
            return {
              ...temp3,
              disabled: true,
            };
          } else {
            return {
              ...temp3,
              disabled: false,
            };
          }
        })
      );

      let unsetArray_thz4 = conclusionDetails?.conclusion
        .filter((item) => item.replacement !== undefined)
        .map((item) => item.replacement);
      let uniqueArray_thz4 = successors.filter((item) =>
        unsetArray_thz4.includes(item._id)
      );
      setTempData4(
        tempData4.map((temp4) => {
          let record4 = uniqueArray_thz4.filter(
            (item) => item._id === temp4._id
          );
          if (record4 && record4.length > 0) {
            return {
              ...temp4,
              disabled: true,
            };
          } else {
            return {
              ...temp4,
              disabled: false,
            };
          }
        })
      );
      // let unsetArray_replacement =
    } else {
      setConclusion(conclusionArray);
      setName(user?.name);
      setDate(new Date());
    }
  }, [conclusionDetails, i18n?.language, user]);

  // useEffect(() => {
  //   let data = conclusion;
  //   for (const item of data) {
  //     //if(item.time_horizon_zero_to_one){
  //       let time_horizon_zero_to_one_index = tempData1.findIndex((t) => t._id === item.time_horizon_zero_to_one);
  //       if(time_horizon_zero_to_one_index !== -1){
  //         item.time_horizon_zero_to_one_text = tempData1[time_horizon_zero_to_one_index].label;
  //       } else {
  //         item.time_horizon_zero_to_one_text = '';
  //       }

  //       let time_horizon_one_to_three_index = tempData2.findIndex((t) => t._id === item.time_horizon_one_to_three);
  //       if(time_horizon_one_to_three_index !== -1){
  //         item.time_horizon_one_to_three_text = tempData2[time_horizon_one_to_three_index].label;
  //       } else {
  //         item.time_horizon_one_to_three_text = '';
  //       }

  //       let time_horizon_three_to_five_index = tempData3.findIndex((t) => t._id === item.time_horizon_three_to_five);
  //       if(time_horizon_three_to_five_index !== -1){
  //         item.time_horizon_three_to_five_text = tempData3[time_horizon_three_to_five_index].label;
  //       } else {
  //         item.time_horizon_three_to_five_text = '';
  //       }

  //       let replacement_index = tempData4.findIndex((t) => t._id === item.replacement);
  //       if(replacement_index !== -1){
  //         item.replacement_text = tempData4[replacement_index].label;
  //       } else {
  //         item.replacement_text = '';
  //       }
  //     //}
  //   }
  //   setConclusionPdf(data)
  // }, [conclusion, tempData1, tempData2, tempData3, tempData4]);

  const handelUpdate = async (id, index, value, field, label, isCalling) => {
    setCallingFun(false);
    setConclusion(
      conclusion.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            [field]: value,
            [field + "_text"]: label,
          };
        } else {
          //return it;
          return {
            ...it,
            //[field+'_text']:''
          };
        }
      })
    );
    setIsChange(true);
    if (value === "other-employee") {
      setEnabledOtherEmpPopup(true);
      setChoise(id);
      setField(field);
    }
    if (isCalling && isCalling === 1) {
      setCallingFun(true);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  useEffect(() => {
    if (actionValue) {
      setTempData1([
        ...tempData1,
        {
          _id: actionValue?._id,
          label: actionValue?.name + " (" + t("no_rating") + ")",
          value: actionValue?._id,
          disabled: false,
        },
      ]);
      setTempData2([
        ...tempData2,
        {
          _id: actionValue?._id,
          label: actionValue?.name + " (" + t("no_rating") + ")",
          value: actionValue?._id,
          disabled: false,
        },
      ]);
      setTempData3([
        ...tempData3,
        {
          _id: actionValue?._id,
          label: actionValue?.name + " (" + t("no_rating") + ")",
          value: actionValue?._id,
          disabled: false,
        },
      ]);
      setTempData4([
        ...tempData4,
        {
          _id: actionValue?._id,
          label: actionValue?.name + " (" + t("no_rating") + ")",
          value: actionValue?._id,
          disabled: false,
        },
      ]);
      setConclusion(
        conclusion.map((it) => {
          if (it?._id === choise) {
            return {
              ...it,
              [field]: actionValue?._id,
              [field + "_text"]:
                actionValue?.name + " (" + t("no_rating") + ")",
            };
          } else {
            return it;
          }
        })
      );
    }
    if (Object.keys(actionValue).length > 0) {
      setCallingFun(true);
    }
    setField("");
    setChoise("");
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("replacement_conclusion") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <button
            type={"button"}
            className={
              "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "
            }
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <SuccessionPlanReport
                      successors={[
                        {
                          position: user?.position_details
                            ? user?.position_details?.short_name
                            : "",
                          name: user?.name,
                          pos_details: [user?.position_details],
                          retirement: "",
                          successors_details: ownSuccessor,
                          position_competencies:
                            positionDetails.position_competencies,
                        },
                      ]}
                      heading={heading}
                      readinessHeading={readinessHeading}
                      concernHeadings={concernHeadings}
                      predictorsHeading={predictorsHeading}
                      user={user}
                      step1={step1}
                      conclusion={conclusion}
                      planApprove={planApprove}
                      name={name}
                      date={date}
                      trimmedDataURL={trimmedDataURL}
                      companyLogo={API_URL + user?.image}
                      creatorPosition={
                        user?.position_details
                          ? user?.position_details?.name
                          : ""
                      }
                      creator={user?.name}
                      tierType={tierType}
                      tierDetails={tierDetails}
                      //is_approved ={ownSuccessor[0]?.is_approved}
                    />
                    {user?.position_details &&
                      user?.position_details?.code === "ceo" && (
                        <EmergencyReplacementPlanReport
                          data={details}
                          companyLogo={API_URL + user?.image}
                        />
                      )}
                  </Document>
                }
                fileName="conclusion.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading
                    ? t("loading_document")
                    : t("download_succession_plan")
                }
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <StepAlert />
      <div className="w-full bg-white rounded shadow mt-3">
        <div className="w-full scroll-smooth scrollbar min-h-[480px]">
          <table className="min-w-max w-full table-auto">
            <thead>
              <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                  {t("choices")}
                </th>
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                  {t("time_horizon")}
                  <span className="block text-[11px] font-medium mt-1">
                    ({t("if_0_1_year")})
                  </span>
                </th>
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                  {t("time_horizon")}
                  <span className="block text-[11px] font-medium mt-1">
                    ({t("if_1_3_years")})
                  </span>
                </th>
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                  {t("time_horizon")}
                  <span className="block text-[11px] font-medium mt-1">
                    ({t("if_3_5_years")})
                  </span>
                </th>
                <th className="py-4 px-2 text-left max-w-[240px] bg-red-500 text-white sticky -top-6 z-10 rounded-tr-md">
                  {t("emergency_or_interim_replacement")}
                  {/* <span className="block text-[11px] font-medium mt-1">(Rate successors if an emergency occurred)</span> */}
                </th>
              </tr>
            </thead>
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {conclusion && conclusion.length > 0 ? (
                conclusion.map((item, index) => {
                  let previous_index = index - 1;
                  //console.log('conclusion[previous_index].time_horizon_one_to_three',conclusion[previous_index]?.time_horizon_one_to_three)
                  return (
                    <tr className={"border-b border-slate-200"} key={item._id}>
                      <td
                        className={
                          "py-3 px-2 text-left max-w-[240px] " +
                          (item.isComment ? "align-top" : "")
                        }
                      >
                        <div className="block">
                          <div className="text-sm font-medium text-slate-600">
                            {item.name}
                          </div>
                          <div className="text-xs text-slate-400">
                            {item.designation}
                          </div>
                        </div>
                      </td>
                      <td
                        className={
                          "py-3 px-2 text-left max-w-[240px] " +
                          (item.isComment ? "align-top" : "") +
                          (index !== 0 &&
                          (!conclusion[previous_index]
                            ?.time_horizon_zero_to_one ||
                            conclusion[previous_index]
                              ?.time_horizon_zero_to_one === -1) &&
                          !item.time_horizon_zero_to_one &&
                          !item.isComment
                            ? "pointer-events-none opacity-40"
                            : "")
                        }
                      >
                        {item.isComment ? (
                          <Textarea
                            inputClasses={"!h-32"}
                            inputValue={item?.comment_zero_to_one}
                            setTextarea={(val) =>
                              handelUpdate(
                                item._id,
                                index,
                                val,
                                "comment_zero_to_one",
                                "",
                                2
                              )
                            }
                            onBlur={(val) => {
                              setCallingFun(true);
                            }}
                          />
                        ) : (
                          <Select
                            xPlacement={"left"}
                            selectedValue={item.time_horizon_zero_to_one}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: "", label: t("select"), value: "" },
                              ...tempData1,
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => {
                              handelUpdate(
                                item._id,
                                index,
                                e._id,
                                "time_horizon_zero_to_one",
                                e.label,
                                1
                              );
                              // if(e._id !== 'other-employee'){
                              //   setCallingFun(true)
                              // }
                            }}
                          />
                        )}
                      </td>
                      <td
                        className={
                          "py-3 px-2 text-left max-w-[240px] " +
                          (item.isComment ? "align-top" : "") +
                          (index !== 0 &&
                          (!conclusion[previous_index]
                            ?.time_horizon_one_to_three ||
                            conclusion[previous_index]
                              ?.time_horizon_one_to_three === -1) &&
                          !item.time_horizon_one_to_three &&
                          !item.isComment
                            ? "pointer-events-none opacity-40"
                            : "")
                        }
                      >
                        {item.isComment ? (
                          <Textarea
                            inputClasses={"!h-32"}
                            inputValue={item?.comment_one_to_three}
                            setTextarea={(val) =>
                              handelUpdate(
                                item._id,
                                index,
                                val,
                                "comment_one_to_three",
                                "",
                                2
                              )
                            }
                            onBlur={(val) => {
                              setCallingFun(true);
                            }}
                          />
                        ) : (
                          <Select
                            xPlacement={"left"}
                            selectedValue={item.time_horizon_one_to_three}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: -1, label: t("select"), value: 0 },
                              ...tempData2,
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => {
                              handelUpdate(
                                item._id,
                                index,
                                e._id,
                                "time_horizon_one_to_three",
                                e.label,
                                1
                              );
                              // if(e._id !== 'other-employee'){
                              //   setCallingFun(true)
                              // }
                            }}
                          />
                        )}
                      </td>
                      <td
                        className={
                          "py-3 px-2 text-left max-w-[240px] " +
                          (item.isComment ? "align-top" : "") +
                          (index !== 0 &&
                          (!conclusion[previous_index]
                            ?.time_horizon_three_to_five ||
                            conclusion[previous_index]
                              ?.time_horizon_three_to_five === -1) &&
                          !item.time_horizon_three_to_five &&
                          !item.isComment
                            ? "pointer-events-none opacity-40"
                            : "")
                        }
                      >
                        {item.isComment ? (
                          <Textarea
                            inputClasses={"!h-32"}
                            inputValue={item?.comment_three_to_five}
                            setTextarea={(val) =>
                              handelUpdate(
                                item._id,
                                index,
                                val,
                                "comment_three_to_five",
                                "",
                                2
                              )
                            }
                            onBlur={(val) => {
                              setCallingFun(true);
                            }}
                          />
                        ) : (
                          <Select
                            xPlacement={"left"}
                            selectedValue={item.time_horizon_three_to_five}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: -1, label: t("select"), value: 0 },
                              ...tempData3,
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => {
                              handelUpdate(
                                item._id,
                                index,
                                e._id,
                                "time_horizon_three_to_five",
                                e.label,
                                1
                              );
                              // if(e._id !== 'other-employee'){
                              //   setCallingFun(true)
                              // }
                            }}
                          />
                        )}
                      </td>
                      <td
                        className={
                          "py-3 px-2 text-left max-w-[240px] " +
                          (item.isComment ? "align-top" : "") +
                          (index !== 0 &&
                          (!conclusion[previous_index].replacement ||
                            conclusion[previous_index].replacement === -1) &&
                          !item.isComment
                            ? "pointer-events-none opacity-40"
                            : "")
                        }
                      >
                        {item.isComment ? (
                          <Textarea
                            inputClasses={"!h-32"}
                            inputValue={item?.comment_replacement}
                            setTextarea={(val) =>
                              handelUpdate(
                                item._id,
                                index,
                                val,
                                "comment_replacement",
                                "",
                                2
                              )
                            }
                            onBlur={(val) => {
                              setCallingFun(true);
                            }}
                          />
                        ) : (
                          <Select
                            xPlacement={"left"}
                            selectedValue={item.replacement}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: -1, label: t("select"), value: 0 },
                              ...tempData4,
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => {
                              handelUpdate(
                                item._id,
                                index,
                                e._id,
                                "replacement",
                                e.label,
                                1
                              );
                              // if(e._id !== 'other-employee'){
                              //   setCallingFun(true)
                              // }
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {loading ? (
                      <Searching label={t("searching")} />
                    ) : (
                      <NoDataFound label={t("no_data_found")} />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="py-10 px-2 space-y-4">
          {/* <div className="flex items-center w-full gap-4">
            <div className="w-1/6">
              <div className="text-sm font-medium text-slate-500">Plan Approved (Yes/No)</div>
            </div>
            <div className="w-full max-w-[400px]">
              <Select 
              xPlacement={'left'} 
              dropdownClass={'!w-full'}
              selectedValue={planApprove}  
              dropdownData={
                [
                  { _id: '', label: 'Select', value: '' },
                  ...[{ _id: 1, label: 'Yes', value: 1 },
                      { _id: 2, label: 'No', value: 2 }]
                ]}
              getSelectedValue={(e) => setPlanApprove(e.value)}
              />
            </div>
          </div>*/}
          <div className="flex items-center w-full gap-4">
            <div className="w-1/6">
              <div className="text-sm font-medium text-slate-600">
                {t("completed_by")}:
              </div>
            </div>
            <div className="w-full max-w-[400px]">
              <Input inputType={"text"} inputValue={name} setInput={setName} />
            </div>
          </div>
          <div className="flex items-center w-full gap-4">
            <div className="w-1/6">
              <div className="text-sm font-medium text-slate-600">
                {t("completion_date")}
              </div>
            </div>
            <div className="w-full max-w-[400px]">
              <Flatpickr
                ref={flatPicker}
                className="w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200"
                placeholder={""}
                value={date}
                options={{ dateFormat: "m/d/Y" }}
                onChange={(selectedDates, dateStr, instance) => {
                  setDate(dateStr);
                }}
                onClose={() => {
                  setCallingFun(true);
                }}
              />
            </div>
          </div>
          {/* <div className="flex w-full gap-4">
            <div className="w-1/6">
              <div className="text-sm font-medium text-slate-600">Signature:
                <span className="text-[11px] text-slate-400 block leading-none font-normal">(Sign Printed Copy, If Required)</span>
              </div>
            </div>
            <div className="w-full max-w-[400px]">
              <div className="w-full border border-slate-200 bg-slate-50 rounded">
                <SignaturePad
                  //penColor="green"
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas w-full",
                  }}
                  ref={(ref) => { setSignpad(ref);}}
                />
                <div className='flex gap-2 w-full px-3 py-2 bg-slate-200 border-t border-slate-200'>
                  <Button 
                  buttonType={'button'} 
                  buttonIcon={(isTrim)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                  buttonIconPosition={'left'} 
                  buttonClasses={'!h-6 !text-sm !py-0 !px-1 !gap-1'}
                  functions={trim}
                  buttonHasLink={false}
                  buttonDisabled={isTrim}
                  buttonLabel={'Save Signature'}
                  buttonLabelClasses={'!text-xs'}
                  />
                  <Button 
                  buttonType={'button'} 
                  buttonIcon={(isClear)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                  buttonIconPosition={'left'}
                  buttonClasses={'!h-6 !text-sm !py-0 !px-1 !gap-1 !bg-rose-500'} 
                  functions={clear}
                  buttonHasLink={false}
                  buttonDisabled={isClear}
                  buttonLabel={'Clear'}
                  buttonLabelClasses={'!text-xs'}
                  />
                </div>
                {
                  trimmedDataURL?(
                    <>
                    <div className="w-full px-3 py-2 bg-white border-t border-slate-200">
                      <div className="text-sm font-medium text-slate-600">Signature Preview</div>
                      <div className="w-full mt-4 h-32">
                        <img className="w-full h-full object-contain" src={trimmedDataURL} alt='' />
                      </div>
                    </div>
                    </>
                  ):null
                }
              </div>
            </div>
          </div> */}
        </div>
        <div className="py-3 px-3 border-t border-slate-200">
          <div className="flex items-center justify-center gap-4">
            {/* <Button 
            buttonType={'button'} 
            buttonIcon={'fa-light fa-times'} 
            buttonIconPosition={'left'} 
            buttonLabel={'Cancel'} 
            buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
            /> */}
            <Button
              buttonType={"button"}
              buttonIcon={
                isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
              }
              buttonIconPosition={"left"}
              buttonLabel={t("submit")}
              functions={(e) => onUpdatePlanHistory(e)}
              buttonHasLink={false}
              buttonDisabled={isUpdate}
            />
          </div>
        </div>
      </div>
      {enabledOtherEmpPopup && (
        <OtherEmployeePopup
          isOpen={enabledOtherEmpPopup}
          setIsOpen={(val) => setEnabledOtherEmpPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherEmployeePopup>
      )}
    </>
  );
};

export default Conclusion;
