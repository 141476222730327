import React from "react";
import NoDataFound from "../../NoDataFound";
import Checkbox from "../../form/Checkbox";
import { Tooltip } from "react-tooltip";
import Button from "../../form/Button";

const SelectedQuestionsItem = ({
  data,
  type = "concern",
  setEditData = () => {},
  setEnabledPopup = () => {},
}) => {
  return (
    <>
      <div className="rounded-lg bg-slate-50 border border-slate-200">
        <div className="cursor-pointer py-2 px-4 bg-teal-50 flex justify-between items-center border-t rounded-t-lg border-b border-slate-200">
          <h2 className="flex gap-2 text-lg font-bold text-slate-800">
            {type === "concern" ? "Concerns" : "Predictors"}
            <div className="text-lg font-bold text-slate-800">{`(${data?.length})`}</div>
          </h2>
        </div>
        {data?.length > 0 ? (
          <div className="list-disc py-3 px-4 space-y-2">
            {data?.map((itm, inx) => (
              <div
                key={inx}
                className="flex text-gray-800 bg-white py-3 px-4 rounded-md border border-slate-300 shadow-sm font-semibold gap-4"
              >
                <div className="text-sm font-medium !leading-none flex items-center flex-shrink flex-grow">
                  {itm?.name || "N/A"}
                  {type === "predictors" && (
                    <span className="flex-shrink-0 p-1 ml-4">
                      <p className="text-[15px] font-semibold text-pink-600">
                        Weight(%) {itm?.weight || 0}
                      </p>
                    </span>
                  )}
                </div>
                {/* <div
                  className="flex items-center"
                  data-tooltip-id={`editButton${itm?._id}`}
                  data-tooltip-place="bottom"
                >
                  <Button
                    buttonClasses={
                      "!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"
                    }
                    buttonType={"button"}
                    buttonIcon={"fa-regular fa-pencil"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledPopup({ open: true, type: type });
                      setEditData(itm);
                    }}
                  />
                  <Tooltip
                    id={`editButton${itm?._id}`}
                    className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6"
                    render={() => (
                      <div className="gap-x-1 gap-y-1">
                        <div className="text-xs leading-none flex items-center">
                          <div className="font-medium">{`Click To Edit Concern (${
                            itm?.heading_name ? itm?.heading_name : itm?.name
                          })`}</div>
                        </div>
                      </div>
                    )}
                  />
                </div> */}
                <div
                  className="flex-shrink-0 ml-auto"
                  data-tooltip-id={"info-tooltip-selected-" + itm?._id}
                  data-tooltip-place="left"
                >
                  {itm?.description && (
                    <>
                      <Tooltip
                        id={"info-tooltip-selected-" + itm?._id}
                        className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                        render={() => (
                          <div className="w-60 p-1 rounded-md">
                            <div className="text-[13px] font-normal">
                              <p className="font-normal text-white">
                                {itm?.description}
                              </p>
                            </div>
                          </div>
                        )}
                      />
                      <i
                        className="fa-light fa-circle-info"
                        style={{ color: "#14b8a6" }}
                      ></i>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound label={"No Data Found"} />
        )}
      </div>
    </>
  );
};

export default SelectedQuestionsItem;
