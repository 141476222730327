import React from "react";
import { classNames } from "../../../helper/classNames";
import ClientRelationships from "../../../assets/image/offerItemIcon/ClientRelationships.png";
import OpportunityCosts from "../../../assets/image/offerItemIcon/OpportunityCosts.png";
import MultiColorTab from "../../elements/MultiColorTab";
import RecruitmentFees from "../../../assets/image/offerItemIcon/RecruitmentFees.png";
import NewHireMistakes from "../../../assets/image/offerItemIcon/NewHireMistakes.png";
import LostKnowledge from "../../../assets/image/offerItemIcon/LostKnowledge.png";
import ManagementTime from "../../../assets/image/offerItemIcon/ManagementTime.png";
import ClientRelationshipsBanner from "../../../assets/image/offerItemBanner/ClientRelationships.png";
import OpportunityCostsBanner from "../../../assets/image/offerItemBanner/OpportunityCosts.png";
import RecruitmentFeesBanner from "../../../assets/image/offerItemBanner/RecruitmentFeesBanner.png";
import NewHireMistakesBanner from "../../../assets/image/offerItemBanner/NewHireMistakesBanner.png";
import LostKnowledgeBanner from "../../../assets/image/offerItemBanner/LostKnowledgeBanner.png";
import ManagementTimeBanner from "../../../assets/image/offerItemBanner/ManagementTimeBanner.png";


import OfferItem from "./OfferItem/OfferItem";

const Offer = () => {
  const items = [
    {
      key: "1",
      label: "Client Relationships",
      buttonIcon: ClientRelationships,
      buttonColor: "",
      children: (
        <OfferItem
          title={"Client Relationships"}
          image={ClientRelationshipsBanner}
          color={"offerItem-light-pink"}
          description={
            "Having a succession plan in place that include key client introductions can keep a relationship with those clients strong when a transition is required."
          }
        />
      ),
      className: "bg-offerItem-light-pink",
    },
    {
      key: "2",
      label: "Opportunity Costs",
      buttonIcon: OpportunityCosts,
      buttonColor: "",
      children: (
        <OfferItem
          title={"Opportunity Costs"}
          image={OpportunityCostsBanner}
          color={"offerItem-light-green"}
          description={
            "Missed opportunities take their toll as time and focus have to be turned to hiring,onboarding and training a new hire rather than business development."
          }
        />
      ),
      className: "bg-offerItem-light-green",
    },
    {
      key: "3",
      label: "Recruitment Fees",
      buttonIcon: RecruitmentFees,
      buttonColor: "",
      children: (
        <OfferItem
          title={"Recruitment Fees"}
          image={RecruitmentFeesBanner}
          color={"offerItem-light-yellow"}
          description={
            "Agency fees and hiring incentives can add up to a significant amount. SuccessionNow enables you to efficiently manage your hiring process in-house, saving on external recruitment costs."
          }
        />
      ),
      className: "bg-offerItem-light-yellow",
    },
    {
      key: "4",
      label: "New Hire Mistakes",
      buttonIcon: NewHireMistakes,
      buttonColor: "",
      children: (
        <OfferItem
          title={"New Hire Mistakes"}
          image={NewHireMistakesBanner}
          color={"offerItem-light-cream"}
          description={
            "New hires are much more likely to make mistakes that can lead to major consequences as they learn their new position. Avoid these costly errors with a leadership development plan using SuccessionNow."
          }
        />
      ),
      className: "bg-offerItem-light-cream",
    },
    {
      key: "5",
      label: "Lost Knowledge",
      buttonIcon: LostKnowledge,
      buttonColor: "",
      children: (
        <OfferItem
          title={"Lost Knowledge"}
          image={LostKnowledgeBanner}
          color={"offerItem-light-lime"}
          description={
            "Departing employees can take valuable company-specific knowledge and experience that can be lost for good. Having a succession plan builds in a backup so that knowledge is passed on."
          }
        />
      ),
      className: "bg-offerItem-light-lime",
    },
    {
      key: "6",
      label: "Management Time",
      buttonIcon: ManagementTime,
      buttonColor: "",
      children: (
        <OfferItem
          title={"Management Time"}
          image={ManagementTimeBanner}
          color={"offerItem-light-blue"}
          description={
            "Minimizing the time spent on recruitment, interviews, and transition challenges, SuccessionNow saves your management team valuable time to focus on their strategic initiatives."
          }
        />
      ),
      className: "bg-offerItem-light-blue",
    },
  ];
  return (
    <section className="w-full my-12 md:my-28">
      <div className="w-full">
        <div className="space-y-8 px-5">
          <div className="font-normal  text-black font-IBM text-[48px] text-center w-full">
            <span className="italic">Leadership Changes</span>
            <br />
            <span className="font-light text-black">cost more than money</span>
          </div>
          <div className="text-center font-medium text-black font-Outfit text-[16px] max-w-[545px] mx-auto">
            We offer smart succession planning software for proactive HR
            leaders, so organizations can effectively grow their own leaders and
            avoid the significant costs and disruption associated with external
            hiring.
          </div>
        </div>
      </div>
      <div className="mt-5 w-full">
        <MultiColorTab
          tabs={items}
          bodyClass={"border-none w-full"}
          headerClass={
            "border-none grid grid-cols-3 gap-4 max-w-[1000px] mx-auto !space-x-0"
          }
          buttonClass={(activeKey, tab) =>
            classNames(
              "!text-black whitespace-nowrap h-[50px] md:h-[60px] lg:h-[87px] px-6 md:!px-8 rounded-lg",
              activeKey === tab?.key
                ? "border border-black text-black"
                : "!text-black"
            )
          }
        />
      </div>
      {/* <a
        href="https://calendly.com/cameronbrockbank/9box-now-demo"
        target="_blank"
        className={
          "relative z-0 overflow-hidden flex justify-center items-center gap-2 rounded-full h-[52px] py-0 px-3 transition-all duration-200 text-white border bg-transparent after:absolute after:h-full after:content-[''] after:-z-[1] after:top-0 after:left-0 after:transition-[width] after:duration-300 after:ease-in-out after:w-full hover:after:w-0 mx-auto after:bg-[#76ABD6] border-transparent hover:border-white hover:text-white capitalize font-IBM font-medium text-base mt-14"
        }
      >
        Shedule your demo
      </a> */}
    </section>
  );
};

export default Offer;
