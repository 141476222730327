import React, { useState } from "react";
import { classNames } from "../../helper/classNames";

const MultiColorTab = ({
  tabs = [],
  bodyClass,
  contentClass,
  buttonClass,
  headerClass,
}) => {
  const [activeKey, setActiveKey] = useState(tabs[0]?.key);

  return (
    <div
      className={classNames(
        "w-full mx-auto mt-8 p-4 border border-gray-300 rounded-lg",
        bodyClass
      )}
    >
      <div
        className={classNames(
          "flex space-x-4 border-b border-gray-300 pb-2",
          headerClass
        )}
      >
        {(tabs || []).map((item, i) => (
          <button
            type="button"
            key={item?.key || i?.toString()}
            onClick={() => setActiveKey(item?.key)}
            className={classNames(
              `px-4 py-2 text-sm font-medium rounded`,
              activeKey === item?.key
                ? "text-white bg-blue-500"
                : "text-gray-600 bg-gray-100 hover:bg-gray-200",
              typeof buttonClass === "function"
                ? buttonClass(activeKey, item)
                : buttonClass,
              item?.className
            )}
          >
            <div className="flex items-center gap-x-3">
              <div className="w-6 aspect-[1 / 1] overflow-hidden flex-shrink-0">
                <img
                  src={item?.buttonIcon}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="line-clamp-1 flex-shrink text-base lg:text-lg xl:text-xl">
                {item?.label}
              </div>
            </div>
          </button>
        ))}
      </div>
      <div className={classNames("mt-4 text-gray-700", contentClass)}>
        {(tabs || [])?.find((item) => item?.key === activeKey)?.children}
      </div>
    </div>
  );
};

export default MultiColorTab;
