import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";
import { sumTotal } from "../../helpers";

import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CompanySNReport = ({
  user,
  companyLogo,
  creator,
  creatorPosition,
  list,
}) => {
  const { i18n, t } = useTranslation();

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "Helvetica",
      fontSize: 9,
      color: "#334155",
      backgroundColor: "#f1f5f9",
    },

    row: {
      flexDirection: "row",
    },
    table: {
      width: "100%",
      flexDirection: "column",
      borderWidth: 1,
      borderColor: "#1e293b",
      borderStyle: "solid",
      backgroundColor: "#fff",
      borderRadius: 20,
      overflow: "hidden",
    },
    thead: {
      width: "100%",
      flexDirection: "row",
      backgroundColor: "#1e293b",
      color: "#ffffff",
      fontWeight: "bold",
    },
    th: {
      borderWidth: 1,
      borderColor: "#1e293b",
      padding: 5,
      width: "12.5%",
      flexDirection: "row",
      alignItems: "center",
    },
    tbody: {
      flexDirection: "column",
      width: "100%",
    },
    tr: {
      flexDirection: "row",
      width: "100%",
    },
    td: {
      borderWidth: 1,
      borderColor: "#1e293b",
      padding: 5,
      width: "12.5%",
      flexDirection: "row",
      alignItems: "center",
    },
    tdSpan: {
      borderWidth: 0,
      padding: 0,
      width: "75%",
    },
    trInner: {
      flexDirection: "row",
      width: "100%",
    },
    tdInner: {
      borderWidth: 1,
      borderColor: "#1e293b",
      padding: 5,
      width: "16.66%",
      flexDirection: "row",
      alignItems: "center",
    },
    thData: { fontFamily: "RobotoBold", fontSize: 9, color: "#ffffff" },
    tdData: { fontFamily: "RobotoRegular", fontSize: 8, color: "#334155" },
    emergencyCell: {
      backgroundColor: "#dc2626",
      color: "#ffffff",
    },
    gapRow: {
      height: 4, 
      backgroundColor: "#4fd1c5",
    },
  });
  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={{
          padding: 20,
          fontFamily: "RobotoRegular",
          lineHeight: 1.2,
          fontSize: 9,
          color: "#334155",
          backgroundColor: "#f1f5f9",
          position: "relative",
          zIndex: 0,
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          fixed
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: 160,
              zIndex: -1,
              opacity: 0.1,
            }}
          >
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -10,
              right: 0,
              height: 160,
              zIndex: -1,
              opacity: 0.075,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View style={{ position: "absolute", right: 30, top: 30 }}>
          <Image
            style={{ width: 120, height: 24, objectFit: "contain" }}
            src={logo}
          />
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <View
            style={{
              paddingBottom: 20,
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,
                alignItems: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <Image
                style={{
                  width: 360,
                  height: 120,
                  objectFit: "contain",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                src={companyLogo}
              />
            </View>
            <View
              style={[
                {
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 15,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontFamily: "RobotoBold",
                    fontSize: 20,
                    textTransform: "capitalize",
                    textAlign: "center",
                  },
                ]}
              >
                Company Name: {user?.company_name}
              </Text>
            </View>
            <View
              style={[
                {
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 15,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  textTransform: "uppercase",
                  color: "#14b8a6",
                  letterSpacing: 3,
                  textAlign: "center",
                }}
              >
                Company Succession Summary
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                display: "flex",
                width: "100%",
                flexDirection: "row",
                marginTop: 5,
                textAlign: "center",
                justifyContent: "center",
              },
            ]}
          >
            <View
              style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}
            >
              <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                Date:
              </Text>
              <Text
                style={{
                  fontFamily: "RobotoRegular",
                  fontSize: 10,
                  marginLeft: 2,
                }}
              >
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.thead}>
            <View style={styles.th}>
              <Text style={styles.thData}>Position</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>Current Position Holder</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>Candidates</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>Readiness Timeframe</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>Plan Selections</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>0-12 Month Plan</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>1-3 Year Plan</Text>
            </View>
            <View style={styles.th}>
              <Text style={styles.thData}>3-5 Year Plan</Text>
            </View>
            <View style={[styles.th, styles.emergencyCell]}>
              <Text style={styles.thData}>Emergency or Interim Plan</Text>
            </View>
          </View>

          <View style={styles.tbody}>
            {list &&
              list.map((position, index) => {
                const successors = position?.successors_details || [];
                const conclusions =
                  position?.conclusion?.filter((c) => !c?.isComment) || [];
                const totalRows = Math.max(
                  successors.length,
                  conclusions.length,
                  4
                );
                const selectionLabels = [
                  "Selection 1",
                  "Selection 2",
                  "Selection 3",
                  "Selection 4",
                ];

                return (
                  <>
                    <View style={styles.tr} key={index} wrap={false}>
                      <View style={styles.td}>
                        <Text
                          style={[
                            styles.tdData,
                            { fontWeight: "extrabold", textAlign: "center" },
                          ]}
                        >
                          {position?.position || "-"}
                        </Text>
                      </View>
                      <View style={styles.td}>
                        <Text
                          style={[
                            styles.tdData,
                            {
                              color: "#0d9488",
                              fontWeight: "extrabold",
                              textAlign: "center",
                            },
                          ]}
                        >
                          {position?.name || "-"}
                        </Text>
                      </View>
                      <View style={styles.tdSpan}>
                        {Array.from({ length: totalRows }).map((_, rowIdx) => {
                          const candidate = successors[rowIdx] || {};
                          const conclusion = conclusions[rowIdx] || {};

                          return (
                            <View
                              style={styles.trInner}
                              key={`row-${index}-${rowIdx}`}
                            >
                              <View style={styles.tdInner}>
                                <Text style={styles.tdData}>
                                  {candidate?.name || "-"}
                                </Text>
                              </View>
                              <View style={styles.tdInner}>
                                <Text style={styles.tdData}>
                                  {candidate["timeframe_details"]?.length > 0
                                    ? candidate["timeframe_details"]
                                        .map((item) => item?.name || "-")
                                        .join(", ")
                                    : "-"}
                                </Text>
                              </View>
                              <View style={styles.tdInner}>
                                <Text
                                  style={[
                                    styles.tdData,
                                    {
                                      color: "#E30B5C",
                                      fontWeight: "extrabold",
                                      textAlign: "center",
                                    },
                                  ]}
                                >
                                  {selectionLabels[rowIdx] || "-"}
                                </Text>
                              </View>
                              <View style={styles.tdInner}>
                                <Text style={styles.tdData}>
                                  {conclusion?.time_horizon_zero_to_one_text ||
                                    "-"}
                                </Text>
                              </View>
                              <View style={styles.tdInner}>
                                <Text style={styles.tdData}>
                                  {conclusion?.time_horizon_one_to_three_text ||
                                    "-"}
                                </Text>
                              </View>
                              <View style={styles.tdInner}>
                                <Text style={styles.tdData}>
                                  {conclusion?.time_horizon_three_to_five_text ||
                                    "-"}
                                </Text>
                              </View>
                              <View style={[styles.tdInner]}>
                                <Text style={styles.tdData}>
                                  {conclusion?.replacement_text || "-"}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                    <View style={styles.gapRow}></View>
                  </>
                );
              })}
          </View>
        </View>
      </Page>
    </>
  );
};

export default CompanySNReport;
