import React, { useCallback, useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Search from "../components/form/Search";
import Select from "../components/form/Select";
import Textarea from "../components/form/Textarea";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import ReactPaginate from "react-paginate";
import Image from "../components/elements/Image";
import toastr from "toastr";
import { postData } from "../services/api";
import { sumTotal } from "../helpers";
import { SuccessorLimitMessage, NextStepMessage } from "../helpers";
import { API_URL } from "../config/host";
import StepAlert from "../components/elements/alert/StepAlert";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSettingsModal } from "../Context/SettingsContext";
import { PositionTier } from "../constant/Constant";

const Concern = ({ getIsChange = () => {} }) => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Conditions of Concern"
  );
  const { i18n, t } = useTranslation();
  const { tierType } = useSettingsModal();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [lacks, setLacks] = useState([]);
  const [headings, setHeadings] = useState([]);

  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10000);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [isChange, setIsChage] = useState(false);
  const [tierDetails, setTierDetails] = useState([]);
  const [isHeadingsInTier, setIsHeadingInTier] = useState(false);
  const [tierListData, setTierListData] = useState([]);

  useEffect(() => {
    if (tierType === "company-wide") {
      async function headingData() {
        try {
          const result = await postData("heading/list", {
            sortQuery: { created_at: 1 },
            status: 1,
            type: "concern",
            //isGlobal : true
          });
          if (result.data) {
            setHeadings(result.data);
          } else {
            console.log("Concern heading list message", result.message);
          }
        } catch (error) {
          console.log("Error concern heading list catch", error.message);
        }
      }
      headingData();
    }
  }, [tierType]);

  const loadTierList = useCallback(async () => {
    if (tierType === PositionTier) {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { ["created_at"]: -1 },
          type: "position-group",
        });
        if (result.status === 200) {
          setTierDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [tierType]);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  useEffect(() => {
    // Concern List
    const loadProfiles = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.data) {
          setSuccessors(
            result.data.map((it, idx) => {
              let header = [];
              let isEditable = false;
              let desire =
                Array.isArray(it?.desire_details) &&
                it?.desire_details.length > 0
                  ? it?.desire_details[0]?.isPass
                  : "";
              let loyalty =
                Array.isArray(it?.loyalty_details) &&
                it?.loyalty_details.length > 0
                  ? it?.loyalty_details[0]?.isPass
                  : "";
              let retirement =
                Array.isArray(it?.retirement_details) &&
                it?.retirement_details.length > 0
                  ? it?.retirement_details[0]?.isPass
                  : "";
              if (desire === true && loyalty === true && retirement === true) {
                isEditable = true;
              }

              it?.concerns.length > 0 &&
                it?.concerns.map((itm) => {
                  let heading = headings
                    .filter((item) => item._id === itm.heading_id)
                    .map((item) =>
                      item.options.filter((row) => row._id === itm.option_id)
                    );
                  if (
                    heading.length > 0 &&
                    heading[0][0]?._id === itm?.option_id &&
                    !heading[0][0]?.isPass
                  ) {
                    let head = headings.filter(
                      (item) => item._id === itm.heading_id
                    );
                    header.push(head[0].name);
                  }
                });
              return {
                ...it,
                header: header,
                isEditable: isEditable,
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadProfiles();
  }, [keyword, limit, offset, isUpdate, headings]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
  };

  const handelCommentUpdate = async (id, val, field, index, point = "") => {
    try {
      setIsUpdate(false);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              [field]: val,
            };
          } else {
            return it;
          }
        })
      );
      const result = await postData("successor/update", {
        id: id,
        [field]: val,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(true);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  const handelUpdate = async (
    id,
    option_id,
    heading_id,
    index,
    point = "",
    tierId
  ) => {
    if (tierType === PositionTier) {
      try {
        setIsChage(false);
        setIsUpdate(false);
        getIsChange(false);
        let concernArray = [];
        const headingsData = tierDetails?.find(
          (it) => it?._id === tierId
        )?.company_heading_details;
        const heading =
          Array?.isArray(headingsData) &&
          headingsData?.filter((data) => data?.type === "concern");
        if (
          successors[index]?.concerns &&
          successors[index]?.concerns.length > 0
        ) {
          let oldArray = successors[index]?.concerns;
          heading.map((itm) => {
            let isExist = oldArray.findIndex(
              (item) => item.heading_id === itm?._id
            );
            if (isExist !== -1) {
              concernArray.push(oldArray[isExist]);
            } else {
              concernArray.push({
                heading_id: itm?._id,
                option_id: null,
                point: 0,
              });
            }
          });
        } else {
          heading.map((itm) =>
            concernArray.push({
              heading_id: itm?._id,
              option_id: null,
              point: 0,
            })
          );
        }
        concernArray = concernArray.map((concern) =>
          concern.heading_id !== heading_id
            ? concern
            : {
                ...concern,
                option_id: option_id,
                tier_id: tierId,
                point,
              }
        );
        let scors = 0;
        setSuccessors(
          successors.map((it, idx) => {
            let header = [];
            it?.concerns.length > 0 &&
              it?.concerns.map((itm) => {
                let heading = headings
                  .filter((item) => item._id === itm.heading_id)
                  .map((item) =>
                    item.options.filter((row) => row._id === itm.option_id)
                  );
                if (
                  heading.length > 0 &&
                  heading[0][0]?._id === itm?.option_id &&
                  !heading[0][0]?.isPass
                ) {
                  let head = headings.filter(
                    (item) => item._id === itm.heading_id
                  );
                  header.push(head[0].name);
                }
              });
            if (idx === index) {
              scors = parseFloat(
                sumTotal(concernArray, "point") / parseInt(concernArray.length)
              ).toFixed(2);
              return {
                ...it,
                concerns: concernArray,
                overall_score: scors,
                header: header,
              };
            } else {
              return {
                ...it,
                header: header,
              };
            }
          })
        );

        const result = await postData("successor/update", {
          id: id,
          concerns: concernArray,
          overall_score: scors,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsUpdate(true);
          setIsChage(true);
          getIsChange(true);
          await postData("download-succession-plan/close-approval", {});
        } else {
          toastr.error(result.message);
          setIsUpdate(false);
          setIsChage(true);
          getIsChange(true);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsUpdate(false);
        setIsChage(true);
        getIsChange(true);
      }
    } else {
      try {
        setIsChage(false);
        setIsUpdate(false);
        getIsChange(false);
        let concernArray = [];
        if (
          successors[index]?.concerns &&
          successors[index]?.concerns.length > 0
        ) {
          let oldArray = successors[index]?.concerns;
          headings.map((itm) => {
            let isExist = oldArray.findIndex(
              (item) => item.heading_id === itm?._id
            );
            if (isExist !== -1) {
              concernArray.push(oldArray[isExist]);
            } else {
              concernArray.push({
                heading_id: itm?._id,
                option_id: null,
                point: 0,
              });
            }
          });
        } else {
          headings.map((itm) =>
            concernArray.push({
              heading_id: itm?._id,
              option_id: null,
              point: 0,
            })
          );
        }
        concernArray = concernArray.map((concern) =>
          concern.heading_id !== heading_id
            ? concern
            : {
                ...concern,
                option_id: option_id,
                point,
              }
        );
        let scors = 0;
        setSuccessors(
          successors.map((it, idx) => {
            let header = [];
            it?.concerns.length > 0 &&
              it?.concerns.map((itm) => {
                let heading = headings
                  .filter((item) => item._id === itm.heading_id)
                  .map((item) =>
                    item.options.filter((row) => row._id === itm.option_id)
                  );
                if (
                  heading.length > 0 &&
                  heading[0][0]?._id === itm?.option_id &&
                  !heading[0][0]?.isPass
                ) {
                  let head = headings.filter(
                    (item) => item._id === itm.heading_id
                  );
                  header.push(head[0].name);
                }
              });
            if (idx === index) {
              scors = parseFloat(
                sumTotal(concernArray, "point") / parseInt(concernArray.length)
              ).toFixed(2);
              return {
                ...it,
                concerns: concernArray,
                overall_score: scors,
                header: header,
              };
            } else {
              return {
                ...it,
                header: header,
              };
            }
          })
        );

        const result = await postData("successor/update", {
          id: id,
          concerns: concernArray,
          overall_score: scors,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsUpdate(true);
          setIsChage(true);
          getIsChange(true);
          await postData("download-succession-plan/close-approval", {});
        } else {
          toastr.error(result.message);
          setIsUpdate(false);
          setIsChage(true);
          getIsChange(true);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsUpdate(false);
        setIsChage(true);
        getIsChange(true);
      }
    }
  };

  useEffect(() => {
    if (successors && successors.length > 0) {
      let concernRecord = successors
        .filter((item) => item.concerns && item.isEditable)
        .map((item) => item.concerns.filter((row) => !row.option_id));

      console.log(concernRecord, "concernRecord");
      let concernEmptyRecord = successors.filter(
        (item) => item.concerns.length === 0 && item.isEditable
      );
      let finalConcern = concernRecord.reduce(
        (currentCount, row) => currentCount + row.length,
        0
      );
      let checkConcern = successors.filter(
        (concern) => concern.overall_score > 0 && concern.isEditable
      );
      console.log({ checkConcern, finalConcern, concernEmptyRecord });

      if (
        checkConcern &&
        checkConcern.length > 0 &&
        finalConcern === 0 &&
        concernEmptyRecord.length === 0
      ) {
        setIsNextStep(true);
      } else {
        setIsNextStep(false);
      }
    }
  }, [isChange, successors]);

  useEffect(() => {
    if (tierDetails.length > 0 || headings?.length > 0) {
      const isInTier = headings.some((heading) =>
        tierDetails.some((tier) => tier.tire_id === heading.tierId)
      );
      setIsHeadingInTier(isInTier);
    } else {
      setIsHeadingInTier(false);
    }
  }, [tierDetails, headings]);

  useEffect(() => {
    if (!tierDetails || tierDetails.length === 0) return;

    const filteredTiers = tierDetails.filter((tierItem) =>
      successors.some((item) => item?.tier_id === tierItem?._id)
    );
    setTierListData(filteredTiers);
  }, [tierDetails, successors]);


  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("conditions_of_concern") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {isNextStep ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t("next_step")}
              buttonHasLink={true}
              buttonLink={"/predictors"}
            />
          ) : (
            <Button
              buttonClasses={"!bg-rose-500"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t("next_step")}
              buttonHasLink={false}
              functions={() => toastr.error(NextStepMessage)}
            />
          )}
        </div>
      </div>
      <StepAlert />
      <div className="relative flex justify-between items-center mb-4">
        {/* <div className="relative">
          <Select
            dropdownButtonClass={"!bg-white"}
            xPlacement={"left"}
            dropdownData={[
              { _id: 1000000, label: "All Items", value: 1000000 },
              ...[
                { _id: 10, label: "10 Items", value: 10 },
                { _id: 20, label: "20 Items", value: 20 },
                { _id: 30, label: "30 Items", value: 30 },
                { _id: 50, label: "50 Items", value: 50 },
                { _id: 100, label: "100 Items", value: 100 },
              ],
            ]}
            selectedValue={limit}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div> */}
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
          <div className="relative">
            <Search 
            placeholder={"Search"} 
            setInput={setKeyword} 
            />
          </div>
        </div> */}
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar  min-h-[480px] mb-4">
        {tierType && tierType === PositionTier ? (
          <>
            {tierListData && tierListData.length > 0 ? (
              <>
                {tierListData.map((tierItem, tireIndex) => {
                  return (
                    <>
                      <div key={tireIndex} className="rounded-tl-md">
                        <div className="flex items-center justify-center py-3 px-2 bg-violet-100 rounded-tl-md rounded-tr-md">
                          <div className="text-center text-[18px] align-top text-violet-700 font-bold">
                            {tierItem?.name || ""}
                          </div>
                        </div>
                      </div>
                      <table className="min-w-max w-full table-auto">
                        <thead>
                          <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                            <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                              {t("current_potential_concerns")}
                            </th>
                            {totalDataCount > 0
                              ? successors.map((item, index) => {
                                  if (tierItem?._id === item?.tier_id) {
                                    return (
                                      <th className="py-4 px-2 max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">
                                        <div className="flex justify-center gap-1">
                                          <div className="mx-auto flex justify-center gap-4 items-center">
                                            <div className="w-8 h-8 min-w-[32px] min-h-[32px] overflow-hidden rounded-full relative">
                                              <Image
                                                src={API_URL + item?.image}
                                                alt={"Admin"}
                                                width={"100%"}
                                                height={"100%"}
                                                effect={"blur"}
                                                classes={
                                                  "object-cover cursor-pointer"
                                                }
                                              />
                                            </div>
                                            <div className="font-semibold text-sm leading-none text-center">
                                              {item.name}
                                              <span className="block text-[11px] font-medium mt-1">
                                                {item?.position_details
                                                  ? item?.position_details[0]
                                                      ?.short_name
                                                  : "N/A"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                    );
                                  } else {
                                    <div className="">NO Successor</div>;
                                  }
                                })
                              : ""}
                          </tr>
                        </thead>
                        <tbody className="text-slate-700 text-sm font-light bg-white">
                          {tierItem?.company_heading_details &&
                            tierItem?.company_heading_details.length > 0 &&
                            tierItem?.company_heading_details
                              ?.filter((i) => i?.type === "concern")
                              ?.map((ph_item, index) => {
                                return (
                                  <tr
                                    className="border-b border-slate-200"
                                    key={tireIndex + "-" + index}
                                  >
                                    <td className="py-3 px-2 font-bold text-sm text-left max-w-[240px] align-top text-teal-600">
                                      {ph_item?.heading_name
                                        ? ph_item?.heading_name
                                        : ph_item?.name}
                                      <div className="block text-xs font-normal leading-tight text-slate-500 my-1">
                                        {ph_item.heading_description
                                          ? ph_item?.heading_description
                                          : ph_item?.description}
                                      </div>
                                    </td>
                                    {totalDataCount > 0 ? (
                                      successors.map((item, index1) => {
                                        let h_idx = -1;
                                        let opt_id = "";
                                        let op_idx = -1;
                                        if (item?.concerns) {
                                          h_idx = item?.concerns?.findIndex(
                                            (p) => p.heading_id === ph_item?._id
                                          );
                                          opt_id = item?.concerns[h_idx]
                                            ?.option_id
                                            ? item?.concerns[h_idx]?.option_id
                                            : "";
                                          op_idx = ph_item?.options?.findIndex(
                                            (op) => op._id === opt_id
                                          );
                                        }
                                        if (tierItem?._id === item?.tier_id) {
                                          return (
                                            <td
                                              className={
                                                "py-3 px-2 text-left max-w-[240px] align-top " +
                                                (!item?.isEditable
                                                  ? "pointer-events-none opacity-40"
                                                  : "")
                                              }
                                            >
                                              <Select
                                                xPlacement={"bottomLeft"}
                                                selectedValue={
                                                  index === h_idx &&
                                                  ph_item?.options[op_idx]
                                                    ?._id === opt_id
                                                    ? ph_item?.options[op_idx]
                                                        ?._id
                                                    : ""
                                                }
                                                dropdownClass={"!w-60"}
                                                dropdownButtonLabelClass={
                                                  !ph_item?.options[op_idx]
                                                    ?.isPass
                                                    ? "!text-rose-500 !font-bold"
                                                    : "!font-bold"
                                                }
                                                dropdownData={[
                                                  {
                                                    _id: "",
                                                    label: t("select"),
                                                    value: 0,
                                                    note: "",
                                                  },
                                                  ...ph_item?.options?.map(
                                                    (option_item) => ({
                                                      _id: option_item?._id,
                                                      label:
                                                        Number(
                                                          option_item?.point
                                                        ).toFixed(1) +
                                                        " " +
                                                        option_item?.name,
                                                      value: option_item?.point,
                                                      note: option_item?.note,
                                                      isPass:
                                                        option_item?.isPass,
                                                    })
                                                  ),
                                                ]}
                                                dropdownOptionBold={true}
                                                getSelectedValue={(e) =>
                                                  handelUpdate(
                                                    item._id,
                                                    e._id,
                                                    ph_item?._id,
                                                    index1,
                                                    e.value,
                                                    tierItem?._id
                                                  )
                                                }
                                              />
                                            </td>
                                          );
                                        }
                                      })
                                    ) : (
                                      <tr className="border-b border-slate-200">
                                        <td
                                          className="py-3 px-2 text-left"
                                          colSpan={
                                            parseInt(
                                              tierItem?.company_heading_details
                                                .length
                                            ) + parseInt(3)
                                          }
                                        >
                                          {loading ? (
                                            <Searching label={t("searching")} />
                                          ) : (
                                            <NoDataFound
                                              label={t("no_data_found")}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </tr>
                                );
                              })}

                          {tierItem?.company_heading_details.length > 0 && (
                            <>
                              <tr className="border-b border-slate-200">
                                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                                  {t("overall_score")}
                                  <span className="block text-[10px] opacity-75">
                                    ({t("1_5_scale")})
                                  </span>
                                </td>
                                {totalDataCount > 0
                                  ? successors.map((item) => {
                                      if (tierItem?._id === item?.tier_id) {
                                        return (
                                          <td className="py-3 px-2 text-left max-w-[240px] align-top">
                                            <div className="relative flex justify-center text-sm font-semibold text-white bg-dark-teal-400 py-2 px-3 border border-black/10 rounded ">
                                              {item?.overall_score
                                                ? Number(
                                                    item?.overall_score
                                                  ).toFixed(2)
                                                : 0.0}
                                            </div>
                                          </td>
                                        );
                                      }
                                    })
                                  : ""}
                              </tr>
                              <tr className="border-b border-slate-200">
                                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                                  {t("additional_comments")}
                                </td>
                                {totalDataCount > 0
                                  ? successors.map((item, index) => {
                                      if (tierItem?._id === item?.tier_id) {
                                        return (
                                          <td className="py-3 px-2 text-left max-w-[240px]">
                                            <Textarea
                                              inputClasses={"!h-32"}
                                              inputValue={
                                                item?.concern_additional_comments
                                              }
                                              setTextarea={(val) =>
                                                setSuccessors(
                                                  successors.map((it, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...it,
                                                        concern_additional_comments:
                                                          val,
                                                      };
                                                    } else {
                                                      return it;
                                                    }
                                                  })
                                                )
                                              }
                                              onBlur={(val) =>
                                                handelCommentUpdate(
                                                  item._id,
                                                  val,
                                                  "concern_additional_comments",
                                                  index
                                                )
                                              }
                                            />
                                          </td>
                                        );
                                      }
                                    })
                                  : ""}
                              </tr>
                              <tr className="border-b border-slate-200">
                                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                                  {t("major_concerns")}
                                </td>
                                {totalDataCount > 0
                                  ? successors.map((item, index) => {
                                      if (tierItem?._id === item?.tier_id) {
                                        return (
                                          <td className="py-3 px-2 text-left max-w-[240px]">
                                            {item.header &&
                                              item.header.length > 0 &&
                                              item.header.map((itm) => (
                                                <div className="block text-xs font-semibold leading-tight text-rose-500 my-1">
                                                  {itm}
                                                </div>
                                              ))}
                                          </td>
                                        );
                                      }
                                    })
                                  : ""}
                              </tr>
                            </>
                          )}

                          {tierItem?.company_heading_details.length <= 0 && (
                            <tr className="border-b border-slate-200">
                              <td
                                colSpan={
                                  Array?.isArray(successors) &&
                                  successors?.length + 1
                                }
                                className="py-3  px-2  align-top"
                              >
                                {(role && role === "customer-admin") ||
                                (role && role === "customer-subadmin") ? (
                                  <div className="flex items-center justify-center min-h-[200px] bg-gray-50">
                                    <div className="bg-white shadow-lg rounded-2xl p-6 max-w-md w-full border border-gray-200">
                                      <div className="flex items-center space-x-4">
                                        <div className="bg-red-100 text-red-500 p-3 rounded-full">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 1010 10A10 10 0 0012 2z"
                                            />
                                          </svg>
                                        </div>
                                        <div>
                                          <h2 className="text-lg font-semibold text-gray-800">
                                            No Assigned Questions
                                          </h2>
                                          <p className="text-gray-600 text-sm">
                                            There are currently no assigned
                                            questions for this position group.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="mt-4 flex justify-end">
                                        <Button
                                          buttonClasses={
                                            "!px-4 !py-2 !bg-teal-600 text-white text-sm font-medium rounded-lg shadow-md hover:bg-blue-700 transition"
                                          }
                                          buttonType={"button"}
                                          buttonLabel={"Assign Questions"}
                                          buttonHasLink={true}
                                          buttonLink={"/question-library"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-center min-h-[200px] bg-gray-50">
                                    <div className="bg-white shadow-lg rounded-2xl p-6 max-w-md w-full border border-gray-200">
                                      <div className="flex items-center space-x-4">
                                        <div className="bg-red-100 text-red-500 p-3 rounded-full">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 1010 10A10 10 0 0012 2z"
                                            />
                                          </svg>
                                        </div>
                                        <div>
                                          <h2 className="text-lg font-semibold text-gray-800">
                                            No Assigned Questions
                                          </h2>
                                          <p className="text-gray-600 text-sm">
                                            No questions are assigned to this
                                            group at the moment. Please consult
                                            the company administrator for help
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  );
                })}
              </>
            ) : (
              <div className="">No tier Data</div>
            )}
          </>
        ) : (
          <table className="min-w-max w-full table-auto">
            <thead>
              <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                  {t("current_potential_concerns")}
                </th>
                {totalDataCount > 0
                  ? successors.map((item, index) => {
                      return (
                        <th className="py-4 px-2 max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">
                          <div className="flex justify-center gap-1">
                            <div className="mx-auto flex justify-center gap-4 items-center">
                              <div className="w-8 h-8 min-w-[32px] min-h-[32px] overflow-hidden rounded-full relative">
                                <Image
                                  src={API_URL + item?.image}
                                  alt={"Admin"}
                                  width={"100%"}
                                  height={"100%"}
                                  effect={"blur"}
                                  classes={"object-cover cursor-pointer"}
                                />
                              </div>
                              <div className="font-semibold text-sm leading-none text-center">
                                {item.name}
                                <span className="block text-[11px] font-medium mt-1">
                                  {item?.position_details
                                    ? item?.position_details[0]?.short_name
                                    : "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </th>
                      );
                    })
                  : ""}
              </tr>
            </thead>
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {headings &&
                headings.length > 0 &&
                headings.map((ph_item, index) => (
                  <tr className="border-b border-slate-200" key={index}>
                    <td className="py-3 px-2 font-bold text-sm text-left max-w-[240px] align-top text-teal-600">
                      {ph_item?.heading_name
                        ? ph_item?.heading_name
                        : ph_item?.name}
                      <div className="block text-xs font-normal leading-tight text-slate-500 my-1">
                        {ph_item.heading_description
                          ? ph_item?.heading_description
                          : ph_item?.description}
                      </div>
                    </td>
                    {totalDataCount > 0 ? (
                      successors.map((item, index1) => {
                        let h_idx = -1;
                        let opt_id = "";
                        let op_idx = -1;
                        //let heading = [];
                        if (item?.concerns) {
                          h_idx = item?.concerns.findIndex(
                            (p) => p.heading_id === ph_item?._id
                          );
                          opt_id = item?.concerns[h_idx]?.option_id
                            ? item?.concerns[h_idx]?.option_id
                            : "";
                          op_idx = ph_item?.options.findIndex(
                            (op) => op._id === opt_id
                          );
                          // if(!ph_item?.options[op_idx]?.isPass && ph_item?.alias !== 'major-concerns'){
                          //   heading.push((ph_item?.heading_name)?ph_item?.heading_name:ph_item?.name)
                          // }
                        }
                        // if(ph_item?.alias === 'major-concerns'){
                        //   ph_item.options = heading;
                        // }
                        return (
                          <td
                            className={
                              "py-3 px-2 text-left max-w-[240px] align-top " +
                              (!item?.isEditable
                                ? "pointer-events-none opacity-40"
                                : "")
                            }
                          >
                            <Select
                              xPlacement={"left"}
                              selectedValue={
                                index === h_idx &&
                                ph_item?.options[op_idx]?._id === opt_id
                                  ? ph_item?.options[op_idx]?._id
                                  : ""
                              }
                              dropdownClass={"!w-60"}
                              dropdownButtonLabelClass={
                                !ph_item?.options[op_idx]?.isPass
                                  ? "!text-rose-500 !font-bold"
                                  : "!font-bold"
                              }
                              dropdownData={[
                                {
                                  _id: "",
                                  label: t("select"),
                                  value: 0,
                                  note: "",
                                },
                                ...ph_item?.options.map((option_item) => ({
                                  _id: option_item?._id,
                                  label:
                                    Number(option_item?.point).toFixed(1) +
                                    " " +
                                    option_item?.name,
                                  value: option_item?.point,
                                  note: option_item?.note,
                                  isPass: option_item?.isPass,
                                })),
                              ]}
                              dropdownOptionBold={true}
                              getSelectedValue={(e) =>
                                handelUpdate(
                                  item._id,
                                  e._id,
                                  ph_item?._id,
                                  index1,
                                  e.value
                                )
                              }
                            />
                          </td>
                        );
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td
                          className="py-3 px-2 text-left"
                          colSpan={parseInt(headings.length) + parseInt(3)}
                        >
                          {loading ? (
                            <Searching label={t("searching")} />
                          ) : (
                            <NoDataFound label={t("no_data_found")} />
                          )}
                        </td>
                      </tr>
                    )}
                  </tr>
                ))}

              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                  {t("overall_score")}
                  <span className="block text-[10px] opacity-75">
                    ({t("1_5_scale")})
                  </span>
                </td>
                {totalDataCount > 0
                  ? successors.map((item) => (
                      <td className="py-3 px-2 text-left max-w-[240px] align-top">
                        <div className="relative flex justify-center text-sm font-semibold text-white bg-dark-teal-400 py-2 px-3 border border-black/10 rounded ">
                          {item?.overall_score
                            ? Number(item?.overall_score).toFixed(2)
                            : 0.0}
                        </div>
                      </td>
                    ))
                  : ""}
              </tr>
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                  {t("additional_comments")}
                </td>
                {totalDataCount > 0
                  ? successors.map((item, index) => (
                      <td className="py-3 px-2 text-left max-w-[240px]">
                        <Textarea
                          inputClasses={"!h-32"}
                          inputValue={item?.concern_additional_comments}
                          setTextarea={(val) =>
                            setSuccessors(
                              successors.map((it, idx) => {
                                if (idx === index) {
                                  return {
                                    ...it,
                                    concern_additional_comments: val,
                                  };
                                } else {
                                  return it;
                                }
                              })
                            )
                          }
                          onBlur={(val) =>
                            handelCommentUpdate(
                              item._id,
                              val,
                              "concern_additional_comments",
                              index
                            )
                          }
                        />
                      </td>
                    ))
                  : ""}
              </tr>
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                  {t("major_concerns")}
                </td>
                {totalDataCount > 0
                  ? successors.map((item, index) => {
                      return (
                        <td className="py-3 px-2 text-left max-w-[240px]">
                          {item.header &&
                            item.header.length > 0 &&
                            item.header.map((itm) => (
                              <div className="block text-xs font-semibold leading-tight text-rose-500 my-1">
                                {itm}
                              </div>
                            ))}
                        </td>
                      );
                    })
                  : ""}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div className="relative flex justify-between items-center">
        <div className="ml-auto flex justify-end items-center space-x-2">
          {isNextStep ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t("next_step")}
              buttonHasLink={true}
              buttonLink={"/predictors"}
            />
          ) : (
            <Button
              buttonClasses={"!bg-rose-500"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-arrow-right-to-arc"}
              buttonIconPosition={"left"}
              buttonLabel={t("next_step")}
              buttonHasLink={false}
              functions={() => toastr.error(NextStepMessage)}
            />
          )}
        </div>
      </div>
      {/* {noOfPage > 1 && (
        <div className="flex justify-end my-4">
        <ReactPaginate
          breakLabel="..."
          breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
          nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
          nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={noOfPage}
          containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
          activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
          pageLinkClassName="flex justify-center items-center w-full h-full"
          previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
          pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
          previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
          renderOnZeroPageCount={null}
        />
        </div>
      )} */}
    </>
  );
};

export default Concern;
