import React, { useState, useEffect, useCallback } from "react";
import { postData } from "../../services/api";
import { setTitle } from "../../helpers/MetaTag";
import { useTranslation } from "react-i18next";
import GlobalQuestionList from "../../components/section/questionGroup/GlobalQuestionList";
import Select from "../../components/form/Select";

const QuestionGroup = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Question group"
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tierList, setTierList] = useState("");
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedType, setSelectedType] = useState("company-wide");

  const loadTierList = useCallback(async () => {
    if (selectedType === "position-wide") {
      try {
        const result = await postData("filter-item/list", {
          limit: 999999,
          type: "position-group",
        });
        if (result.status === 200) {
          setTierList(result.data);
          const tierData =
            result?.data &&
            Array?.isArray(result.data) &&
            result.data.length > 0 &&
            result.data[0]?._id;
          setSelectedTier(tierData);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setTierList([]);
    }
  }, [selectedType]);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex justify-between items-center mb-3">
          <div className="text-2xl font-bold">{t("Question Groups")}</div>
        </div>
        <div className="relative mb-3">
          <div className="flex justify-start items-start gap-4">
            <div className={`relative w-60`}>
              <Select
                xPlacement={"left"}
                selectedValue={selectedType}
                dropdownClass={"!w-full"}
                dropdownData={[
                  {
                    _id: "company-wide",
                    label: "Company Wide",
                    value: "company-wide",
                  },
                  {
                    _id: "position-wide",
                    label: "Position Wide",
                    value: "position-wide",
                  },
                ]}
                getSelectedValue={(e) => {
                  setSelectedType(e._id);
                }}
              />
            </div>
            {selectedType === "position-wide" && (
              <div className={`relative w-60`}>
                <Select
                  xPlacement={"left"}
                  selectedValue={selectedTier}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    ...(Array.isArray(tierList)
                      ? tierList.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        }))
                      : []),
                  ]}
                  getSelectedValue={(e) => {
                    setSelectedTier(e._id);
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <div className="space-y-8">
              <GlobalQuestionList
                selectedTierType={selectedType}
                selectedTier={selectedTier}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionGroup;
