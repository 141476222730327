import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSettingsModal } from "../Context/SettingsContext";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";
import Button from "../components/form/Button";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import NoDataFound from "../components/NoDataFound";
import Checkbox from "../components/form/Checkbox";
import QuestionItems from "../components/section/questionLibrary/QuestionItems";
import SelectedQuestionsItem from "../components/section/questionLibrary/SelectedQuestionsItem";
import { ManageDefaultConcernAndPredictorsSkleton } from "../components/loader/ManageDefaultConcernAndPredictorsSkleton";
import HeadingManagePopup from "../components/popup/HeadingManagePopup";
import ConfirmationPopup from "../components/popup/ConfirmationPopup";
import PositionWideQuestionLibrary from "../components/section/questionLibrary/PositionWideQuestionLibrary";
import Select from "../components/form/Select";
import { PositionTier } from "../constant/Constant";
import TierSelectDropdown from "../components/elements/TierSelectDropdown";
import toastr from "toastr";
import { sumTotal } from "../helpers";

const QuestionsLibrary = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Questions Library"
  );
  const { i18n, t } = useTranslation();
  const { tierType, setTierType, isOpen, setIsOpen } = useSettingsModal();
  const user = useSelector((state) => state.auth?.user);
  const [selectedConcern, setSelectedConcern] = useState([]);
  const [selectedPredictors, setSelectedPredictors] = useState([]);
  const [concernLoading, setConcernLoading] = useState([]);
  const [predictorLoading, setPredictorLoading] = useState([]);
  const [concerns, setConcerns] = useState([]);
  const [predictors, setPredictors] = useState([]);
  const [actionValue, setActionValue] = useState({});
  const [enabledPopup, setEnabledPopup] = useState({ open: false, type: "" });
  const [editData, setEditData] = useState({});
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isUpdateConcern, setIsUpdateConcern] = useState(false);
  const [isUpdatePredictor, setIsUpdatePredictor] = useState(false);
  const [weightTotalValue, setWeightTotalValue] = useState(0);

  //Concern List Api Call
  const loadConcernList = useCallback(async () => {
    setConcernLoading(true);
    try {
      const result = await postData("heading/list", {
        sortQuery: { created_at: 1 },
        status: 1,
        type: "concern",
        isGlobal: true,
        ...(tierType === PositionTier && { tierId: selectedTier }),
        tierType: tierType,
        limit: 9999,
      });

      if (result.data) {
        let _concerns = [];
        let globalConcern = [];
        result.data.map((item) => {
          globalConcern.push({
            ...item,
            isDefault: item?.company_headings.length > 0 ? true : false,
            disabled: false,
          });
          if (item?.company_headings.length > 0) {
            _concerns.push({
              _id: item?.company_headings[0]?._id,
              name: item?.company_headings[0]?.name,
              description: item?.company_headings[0]?.description,
              alias: item?.company_headings[0]?.alias,
              weight: item?.company_headings[0]?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
              tierId: item?.tierId,
            });
          }
        });
        setConcerns(globalConcern);
        setSelectedConcern(_concerns);
        setIsUpdateConcern(true);
      }
      setConcernLoading(false);
    } catch (err) {
      setConcernLoading(false);
      console.log(err.message);
    } finally {
      setConcernLoading(false);
    }
  }, [tierType, selectedTier]);

  useEffect(() => {
    loadConcernList();
  }, [loadConcernList]);

  //Predictors List Api Call
  const loadPredictorsList = useCallback(async () => {
    setPredictorLoading(true);
    try {
      const result = await postData("heading/list", {
        sortQuery: { created_at: 1 },
        status: 1,
        type: "predictors",
        isGlobal: true,
        tierType: tierType,
        ...(tierType === PositionTier && { tierId: selectedTier }),
        limit: 9999,
      });

      if (result.data) {
        let _predictors = [];
        let globalPredictors = [];
        result.data.map((item) => {
          globalPredictors.push({
            ...item,
            isDefault: item?.company_headings.length > 0 ? true : false,
            disabled: false,
          });
          if (item?.company_headings.length > 0) {
            _predictors.push({
              _id: item?.company_headings[0]?._id,
              name: item?.company_headings[0]?.name,
              description: item?.company_headings[0]?.description,
              alias: item?.company_headings[0]?.alias,
              weight: item?.company_headings[0]?.weight,
              created_at: new Date(),
              isDefault: item?.isDefault ? item?.isDefault : false,
              disabled: false,
              tierId: item?.tierId,
            });
          }
        });
        const getPredictorValue = result.data?.filter(
          (item) => item?.isDefault && item?.type === "predictors"
        );
        setWeightTotalValue(sumTotal(getPredictorValue, "weight"));
        setPredictors(globalPredictors);
        setSelectedPredictors(_predictors);
        setIsUpdatePredictor(true);
      }
      setPredictorLoading(false);
    } catch (err) {
      setPredictorLoading(false);
      console.log(err.message);
    } finally {
      setPredictorLoading(false);
    }
  }, [tierType, selectedTier]);

  useEffect(() => {
    loadPredictorsList();
  }, [loadPredictorsList]);

  const updateHandler = (heading_id, event, type) => {
    if (event) {
      if (type === "concern") {
        let enabledConcerns =
          concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;
        if (Number(enabledConcerns + 1) === 8) {
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
        } else {
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                return item;
              }
            })
          );
        }
        setIsUpdateConcern(true);
      } else if (type === "predictors") {
        let enabledPredictors =
          predictors.length > 0 &&
          predictors.filter((it) => it?.isDefault)?.length;
        if (Number(enabledPredictors + 1) === 8) {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
        } else {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                return item;
              }
            })
          );
        }
        setIsUpdatePredictor(true);
      }
    } else {
      if (type === "concern") {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdateConcern(true);
      } else if (type === "predictors") {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdatePredictor(true);
      }
    }
  };

  useEffect(() => {
    let enabledConcerns =
      concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;
    if (enabledConcerns > 0) {
      if (enabledConcerns === 8) {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      } else {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      }
    }
  }, [isUpdateConcern]);

  useEffect(() => {
    let enabledPredictors =
      predictors.length > 0 && predictors.filter((it) => it?.isDefault)?.length;
    if (enabledPredictors > 0) {
      if (enabledPredictors === 8) {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      } else {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      }
    }
  }, [isUpdatePredictor]);

  const onSubmit = async () => {
    if (weightTotalValue !== 100) {
      toastr.error("The total weight of selected predictors must be 100%.");
      return;
    }
    try {
      setLoading(true);
      let concernsData;
      let predictorsData;
      concernsData =
        concerns &&
        Array?.isArray(concerns) &&
        concerns?.length > 0 &&
        concerns?.map((item) => ({
          ...item,
        }));
      predictorsData =
        predictors &&
        Array?.isArray(predictors) &&
        predictors?.length > 0 &&
        predictors?.map((item) => ({
          ...item,
        }));
      const res = await postData("heading/checking-user-tire", {
        concerns: concernsData,
        predictors: predictorsData,
        tierType: tierType,
        ...(tierType === PositionTier && { tierId: selectedTier }),
      });
      if (res.status === 200) {
        const result = await postData("heading/update-user-tire", {
          concerns: concernsData,
          predictors: predictorsData,
          tierType: tierType,
          ...(tierType === PositionTier && { tierId: selectedTier }),
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setLoading(false);
          loadConcernList();
          loadPredictorsList();
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else {
        if (res.type === 1) {
          toastr.error(res.message);
        } else if (res.type === 2) {
          setMessage(res.message);
          setEnabledConfirmationPopup(true);
        } else {
          toastr.error(res.message);
        }
        setLoading(false);
      }
    } catch (error) {
      // toastr.error(error.message);
      setLoading(false);
    }
  };

  const onUpdate = async () => {
    let concernsData;
    let predictorsData;
    concernsData =
      concerns &&
      Array?.isArray(concerns) &&
      concerns?.length > 0 &&
      concerns?.map((item) => ({
        ...item,
      }));
    predictorsData =
      predictors &&
      Array?.isArray(predictors) &&
      predictors?.length > 0 &&
      predictors?.map((item) => ({
        ...item,
      }));
    try {
      setLoading(true);
      const result = await postData("heading/update-user-tire", {
        concerns: concernsData,
        predictors: predictorsData,
        tierType: tierType,
        ...(tierType === PositionTier && { tierId: selectedTier }),
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setEnabledConfirmationPopup(false);
        setLoading(false);
        loadConcernList();
        loadPredictorsList();
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Array.isArray(predictors)) {
      const getPredictorValue = predictors.filter((item) => item?.isDefault);
      setWeightTotalValue(sumTotal(getPredictorValue, "weight"));
    }
  }, [predictors]);

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex justify-between items-center mb-3 gap-4">
          <div className="text-2xl font-bold">{"Questions Library"}</div>
          <div
            className="sm:ml-auto flex justify-end items-center"
            data-tooltip-id={`buttonSettings${1}`}
            data-tooltip-place="right"
          >
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-gear"}
              buttonIconPosition={"left"}
              buttonLabel={"Setting Question Group"}
              buttonHasLink={false}
              functions={() => {
                setIsOpen({ _id: null, open: true });
              }}
            />
            <Tooltip
              id={`buttonSettings${1}`}
              className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
              render={() => (
                <div className="gap-x-1">
                  <div className="text-[13px] font-normal leading-none flex items-center">
                    <div className="font-semibold">Setting Question Group</div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        {tierType === PositionTier ? (
          <>
            <PositionWideQuestionLibrary
              selectedConcern={selectedConcern}
              selectedPredictors={selectedPredictors}
              selectedTier={selectedTier}
              setSelectedTier={setSelectedTier}
              tierType={tierType}
              setEditData={setEditData}
              setEnabledPopup={setEnabledPopup}
            />
          </>
        ) : (
          <>
            <div className="relative mt-6 p-4 bg-white shadow-md rounded-lg border border-gray-200">
              <div className="text-xl font-semibold text-teal-500">
                Selected Questions
              </div>
              <div className="relative flex flex-col gap-4 mb-3">
                <div className="grid grid-cols-2 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
                  <div className="relative">
                    {!concernLoading && (
                      <SelectedQuestionsItem
                        data={selectedConcern}
                        type="concern"
                        setEditData={setEditData}
                        setEnabledPopup={setEnabledPopup}
                      />
                    )}
                  </div>
                  <div className="relative">
                    {!predictorLoading && (
                      <SelectedQuestionsItem
                        data={selectedPredictors}
                        type="predictors"
                        setEditData={setEditData}
                        setEnabledPopup={setEnabledPopup}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="relative mt-6 p-4 bg-white shadow-md rounded-lg border border-gray-200">
          <div className="text-xl font-semibold text-teal-500">
            Select Questions
          </div>
          {tierType === PositionTier && (
            <TierSelectDropdown
              selectedTier={selectedTier}
              setSelectedTier={setSelectedTier}
              isLable={false}
              isDefaultTier={true}
              isSelect={false}
            />
          )}

          <div className="relative mt-6 p-4 rounded-lg">
            <div className="relative flex flex-col gap-4 mb-3">
              <div className="grid grid-cols-2 gap-4 p-2 overflow-auto scroll-smooth scrollbar">
                <div className="relative p-2 rounded-lg border border-slate-400 shadow-sm">
                  <div className="text-xl font-semibold text-slate-500">
                    Concerns
                  </div>
                  <div className="bg-orange-100 border-l-4 border-orange-400 text-orange-700 p-4 rounded-lg flex items-start mt-4">
                    <i className="fa-solid fa-triangle-exclamation text-orange-600 mr-3 mt-1"></i>
                    <div>
                      <p className="font-semibold">Alert!</p>
                      <p>
                        {` Your company is in the ${
                          tierType === PositionTier
                            ? "Position Wide"
                            : "Company Wide"
                        } Tier. If you modify
                        the default questions, the rated successor values in the
                        Succession Plan will be lost.`}
                      </p>
                    </div>
                  </div>
                  {/* <div className="sm:ml-auto flex justify-end items-center mt-2">
                    <div
                      data-tooltip-id={`buttonAddconcern${1}`}
                      data-tooltip-place="bottom"
                    >
                      <Button
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-plus"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("add")}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledPopup({ open: true, type: "concern" });
                          setEditData({});
                        }}
                      />
                      <Tooltip
                        id={`buttonAddconcern${1}`}
                        className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                        render={() => (
                          <div className="gap-x-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">
                                Add New Concern
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div> */}
                  <div className="relative">
                    {concernLoading && (
                      <div className="px-2 py-2">
                        <ManageDefaultConcernAndPredictorsSkleton
                          rowValue={"!grid-cols-1"}
                          count={10}
                        />
                      </div>
                    )}
                    {!concernLoading && (
                      <QuestionItems
                        data={concerns}
                        updateHandler={updateHandler}
                        type="concern"
                        setEditData={setEditData}
                        setEnabledPopup={setEnabledPopup}
                      />
                    )}
                  </div>
                </div>
                <div className="relative p-2 rounded-lg border border-slate-400 shadow-sm">
                  <div className="text-xl font-semibold text-slate-500">
                    Predictors
                  </div>
                  <div className="bg-orange-100 border-l-4 border-orange-400 text-orange-700 p-4 rounded-lg flex items-start mt-4">
                    <i className="fa-solid fa-triangle-exclamation text-orange-600 mr-3 mt-1"></i>
                    <div>
                      <p className="font-semibold">Alert!</p>
                      <p>
                        {` Your company is in the ${
                          tierType === PositionTier
                            ? "Position Wide"
                            : "Company Wide"
                        } Tier. If you modify
                        the default questions, the rated successor values in the
                        Succession Plan will be lost.`}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex mt-2 w-full">
                    <div
                      className={
                        weightTotalValue === 100
                          ? "text-xs sm:text-sm bg-teal-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded w-full"
                          : "text-xs sm:text-sm bg-rose-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded w-full"
                      }
                    >
                      {t("weight")}: {weightTotalValue}%
                    </div>
                  </div>

                  {/* <div className="sm:ml-auto flex justify-end items-center mt-2">
                    <div
                      data-tooltip-id={`buttonAddpredictors${1}`}
                      data-tooltip-place="bottom"
                    >
                      <Button
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-plus"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("add")}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledPopup({ open: true, type: "predictors" });
                          setEditData({});
                        }}
                      />
                      <Tooltip
                        id={`buttonAddpredictors${1}`}
                        className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6 !opacity-100"
                        render={() => (
                          <div className="gap-x-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">
                                Add New Predictors
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div> */}

                  <div className="relative">
                    {predictorLoading && (
                      <div className="px-2 py-2">
                        <ManageDefaultConcernAndPredictorsSkleton
                          rowValue={"!grid-cols-1"}
                          count={10}
                        />
                      </div>
                    )}
                    {!predictorLoading && (
                      <QuestionItems
                        data={predictors}
                        updateHandler={updateHandler}
                        type="predictors"
                        setEditData={setEditData}
                        setEnabledPopup={setEnabledPopup}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-5">
              <div className="flex items-end justify-end gap-4">
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    loading
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("save")}
                  functions={() => {
                    onSubmit();
                  }}
                  buttonHasLink={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {enabledPopup?.open && (
        <HeadingManagePopup
          isOpen={enabledPopup?.open}
          setIsOpen={(val) => setEnabledPopup(val)}
          data={editData}
          setData={setEditData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={enabledPopup?.type}
          loadConcernList={loadConcernList}
          loadPredictorsList={loadPredictorsList}
        />
      )}
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={t("Warning")}
          message={
            message ||
            "If you modify the default questions, the rated successor values in the Succession Plan will be lost. If you still want to proceed, click the Confirm button."
          }
          confirmButtonLabel={t("Confirm")}
          cancelButtonLabel={t("cancel")}
          getActionValue={(obj) => {
            if (obj && obj.button_type === "confirm") {
              onUpdate();
            } else if (obj && obj.button_type === "close") {
              setEnabledConfirmationPopup(false);
            }
          }}
        />
      )}
    </>
  );
};

export default QuestionsLibrary;
