import React from "react";

const Switch = ({ checked = false, onChange = () => {} }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div
        className="w-12 h-6 bg-gray-300 rounded-full peer-checked:bg-teal-500
        relative transition-all before:absolute before:top-1/2 before:left-1 
        before:-translate-y-1/2 before:bg-white before:w-5 before:h-5 
        before:rounded-full before:shadow-md before:transition-all 
        peer-checked:before:left-7"
        role="switch"
        aria-checked={checked}
      ></div>
    </label>
  );
};

export default Switch;
