import React, { useEffect, useState } from "react";
// import Progress from "../Progress";

const ViewFile = ({ file, removeHandler = () => {}, progress }) => {
  const [uploadError, setUploadError] = useState(null);

  const formatBytes = (bytes) => {
    if (!bytes) return;
    const units = ["B", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (bytes >= 1024 && index < units.length - 1) {
      bytes /= 1024;
      index++;
    }
    return `${bytes?.toFixed(2)} ${units[index]}`;
  };
  return (
    <li className="p-2 flex items-center gap-3 rounded-md bg-primary/10 hover:bg-primary/15 transition-all duration-200">
      <img
        src={"https://cdn-icons-png.flaticon.com/512/8242/8242984.png"}
        alt="csv-icon"
        className="w-8 h-8"
      />
      <div className="flex-grow space-y-1">
        <div className="text-sm font-semibold">
          {file?.name}
          <span className="text-muted-foreground font-normal ml-2">
            {formatBytes(file?.size)}
          </span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-full bg-gray-200 rounded-lg h-2 mt-2">
            <div
              className="h-4 bg-blue-500 rounded-lg transition-all"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <span className="text-xs">{progress}%</span>
        </div>
        {uploadError && <p className="text-xs text-rose-500">{uploadError}</p>}
      </div>
      {progress < 1 && (
        <button
          type="button"
          className="text-sm hover:text-destructive/80 transition-all duration-300 text-rose-600"
          onClick={removeHandler}
        >
          <i className="fa-fw fa-regular fa-trash-can"></i>
        </button>
      )}
    </li>
  );
};

export default ViewFile;
