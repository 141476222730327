import React, { useEffect, useState } from "react";
//import SuccessionPlanAllReport from "./SuccessionPlanAllReport.js-not-used";
//import ChartViewReport from "./ChartViewReport";
import { Document } from "@react-pdf/renderer";
import EmergencyReplacementPlanReport from "./EmergencyReplacementPlanReport";
import SuccessionPlanReport from "./SuccessionPlanReport";

const SuccessionPlanAllReportMain = ({
  successors,
  heading,
  readinessHeading,
  concernHeadings,
  predictorsHeading,
  user,
  details,
  companyLogo,
  tierType,
  tierDetails,
}) => {
  return (
    <Document>
      {successors &&
        successors.length > 0 &&
        successors.map((item, index) => {
          return item.successors_details.length > 0 ? (
            <>
              <SuccessionPlanReport
                key={index}
                successors={[item]}
                heading={heading}
                readinessHeading={readinessHeading}
                concernHeadings={concernHeadings}
                predictorsHeading={predictorsHeading}
                conclusion={item.conclusion ? item?.conclusion : []}
                planApprove={item?.plan_approve}
                name={item?.conclusion_name}
                date={item?.date}
                trimmedDataURL={item?.signature}
                user={user}
                companyLogo={companyLogo}
                creatorPosition={
                  item?.pos_details && item?.pos_details?.length > 0
                    ? item?.pos_details[0]?.name
                    : ""
                }
                creator={item?.name}
                tierType={tierType}
                tierDetails={tierDetails}
              />
              {/* <SuccessionPlanAllReport
              key={index}
              successors={[item]}
              heading={heading}
              readinessHeading={readinessHeading}
              concernHeadings={concernHeadings}
              predictorsHeading={predictorsHeading}
              conclusion={item.conclusion ? item?.conclusion : []}
              planApprove={item?.plan_approve}
              name={item?.conclusion_name}
              date={item?.date}
              trimmedDataURL={item?.signature}
              user={user}
              companyLogo={companyLogo}
            /> */}
              {item?.pos_details && item?.pos_details[0]?.code === "ceo" && (
                <EmergencyReplacementPlanReport
                  key={index}
                  data={details}
                  details={item?.emergency_replacement_details}
                  companyLogo={companyLogo}
                />
              )}
            </>
          ) : null;
        })}
    </Document>
  );
};

export default SuccessionPlanAllReportMain;
