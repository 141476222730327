import nineBoxLogo from "../assets/image/9boxlogo.png";
import successionnowLogo from "../assets/image/successionnowlogo.png";
import gametimeHrLogo from "../assets/image/gameTimeHr.png";
import orgChartLogo from "../assets/image/orgchartLogo.png";

export const getStatusFilter = (t) => [
  { value: 1, name: t("active"), type: "checkbox" },
  { value: 2, name: t("deactivated"), type: "checkbox" },
];
export const featured_filter = [
  { value: 1, name: "Featured", type: "checkbox" },
  { value: 2, name: "Non-featured", type: "checkbox" },
];
export const getEmailFilter = (t) => [
  { value: 1, name: t("verified"), type: "checkbox" },
  { value: 2, name: t("not_verified"), type: "checkbox" },
];

export const ALL_SITE_DATA = [
  {
    _id: 1,
    name: "SuccessionNow",
    image: successionnowLogo,
    code: "successionNow",
    value: "successionNow",
    color: "teal-500",
    isDisable: false,
    isDefault: true,
  },
  {
    _id: 4,
    name: "Org Chart",
    image: orgChartLogo,
    code: "orgChart",
    value: "orgChart",
    color: "orange-500",
    isDisable: false,
    isDefault: false,
  },
  {
    _id: 2,
    name: "9BoxNow",
    image: nineBoxLogo,
    code: "nineBoxNow",
    value: "nineBoxNow",
    color: "blue-500",
    isDisable: false,
    isDefault: false,
  },

  {
    _id: 3,
    name: "GameTime Hr",
    image: gametimeHrLogo,
    code: "gametimeHr",
    value: "gametimeHr",
    color: "green-500",
    isDisable: true,
    isDefault: false,
  },

  {
    _id: 5,
    name: "Bonus Now",
    image: null,
    code: "bonusNow",
    value: "bonusNow",
    color: "slate-600",
    isDisable: true,
    isDefault: false,
  },
];

export const TIER_TYPE = [
  {
    _id: "company-wide",
    name: "Company Wide",
    code: "company-wide",
    value: "company-wide",
  },
  {
    _id: "position-wide",
    name: "Position Wide",
    code: "position-wide",
    value: "position-wide",
  },
];

export const sheduleLink =
  "https://calendly.com/cameronbrockbank/succession-now-demo?month=2025-03";

export const PositionTier = "position-wide";
