import React from 'react';
import Graph from "../../../../assets/image/graph-style.svg"
import Button from "../../../form/Button";

import { useTranslation } from "react-i18next";

const SecureBusinessSection= ({description}) => {
  const { i18n,t } = useTranslation();
  const BuildSuccessionData = {
    title:t('secure_your_business_future'),
    subtitle:t('second_thoughts_dont_worry'),
    description:"<p>"+description+"</p>",
    image: Graph,
  }
  return (
    <section className="py-10 relative  w-full" id="securebusiness-section">
        <div className="w-full max-w-[90%] xs:max-w-lg lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto bg-black rounded-xl px-5 md:px-12 py-10">
            <div className="block space-y-5 lg:space-y-0 lg:flex items-center justify-between">
                
                <div className="w-full lg:w-7/12 xl:w-1/2 relative">
                      <div className="flex flex-col space-y-5 lg:space-y-10">
                          <div className="font-PlusJakartaSans font-extrabold text-2xl xs:text-3xl lg:text-4xl xl:text-5xl text-white max-w-lg w-full text-center lg:text-left mx-auto lg:mx-0" dangerouslySetInnerHTML={{__html:BuildSuccessionData.title}}></div>
                          <div className=" text-[#E4E4E4] text-sm xs:text-base xl:text-lg font-Inter font-medium text-center lg:text-left" dangerouslySetInnerHTML={{__html:BuildSuccessionData.description}}></div>
                          <div className="relative flex mx-auto lg:mx-0">
                              <Button
                                  buttonLabel={t('schedule_your_demo_now')}
                                  buttonLabelClasses="capitalize !text-dark-teal-500 font-PlusJakartaSans !font-bold tracking-wider lg:!text-base xl:!text-lg"
                                  buttonClasses="lg:h-12 xl:h-14 rounded !bg-white !px-5 xs:!px-10 !text-dark-teal-500"
                                  buttonIconPosition="right"
                                  buttonIcon="fa-solid fa-arrow-right"
                                  buttonHasLink={true}
                                  buttonTarget="_blank"
                                  buttonLink="//calendly.com/cameronbrockbank/succession-now-demo"
                              />
                          </div>
                          
                      </div>
                </div>
                <div className="w-full lg:w-5/12 xl:w-1/2 relative">
                    <div className="relative flex justify-center lg:justify-end">
                        <div className="max-w-md w-full aspect-auto xl:aspect-[3/2]">
                            <img src={BuildSuccessionData.image} alt="Graph" className="object-contain "/>
                        </div>
                    </div>
                </div>
            </div>
             
        </div>
    </section>
  );
};

export default SecureBusinessSection