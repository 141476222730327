import React, { useState, useRef, useEffect } from "react";

import { allowedImageExtensions, capitalizeString } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import ButtonFile from "../../components/form/ButtonFile";
import Image from "../../components/elements/Image";
import noLogo from "../../assets/image/images.jpeg";

import { useTranslation } from "react-i18next";

const AccountCreation = () => {
  const { id } = useParams();
  const location = useLocation();
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Company"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | New Account Creation"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [staff, setStaff] = useState([]);
  const [sales, setSales] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [appointmentSetter, setAppointmentSetter] = useState([]);
  const [salesAdmin, setalesAdmin] = useState([]);
  const [isManage, setIsManage] = useState(false);
  const [name, setName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [number_of_employees, setNumberOfEmployees] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [promoCode, setPrromoCode] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [price, setPrice] = useState("");
  //const [staffId,setStaffId] = useState('');
  const [salesId, setSalesId] = useState(null);
  const [consultantId, setConsultantId] = useState(null);
  const [appointmentSetterId, setAppointmentSetterId] = useState(null);
  const [salesAdminId, setSalesAdminId] = useState(null);
  const [salesCommission, setSalesCommission] = useState(0);
  const [consultantCommission, setConsultantCommission] = useState(0);
  const [appointmentSetterCommission, setAppointmentSetterCommission] =
    useState(0);
  const [salesAdminCommission, setSalesAdminCommission] = useState(0);
  const [showLogo, setShowLogo] = useState(null);
  const [image, setImage] = useState(null);
  const [accountType, setAccountType] = useState("");

  const staffArray = [
    {
      code: "sales-staff",
      role_id: null,
      staff_id: salesId ? salesId : null,
      commission: salesCommission,
    },
    {
      code: "consultant",
      role_id: null,
      staff_id: consultantId ? consultantId : null,
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      role_id: null,
      staff_id: appointmentSetterId ? appointmentSetterId : null,
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      role_id: null,
      staff_id: salesAdminId ? salesAdminId : null,
      commission: salesAdminCommission,
    },
  ];

  useEffect(() => {
    // Plan list
    const loadPlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
        });
        if (result.data) {
          setPlans(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlans();

    const loadStaffes = async () => {
      try {
        const result = await postData("usertype/list", {
          sortQuery: { ordering: 1 },
          isStaff: 1,
          status: 1,
        });
        if (result.status === 200) {
          let sales = [];
          let consultants = [];
          let appointmentSetter = [];
          let salesAdmin = [];
          result.data.map((value) => {
            sales.push(value);
            consultants.push(value);
            appointmentSetter.push(value);
            salesAdmin.push(value);
          });
          setSales(sales);
          setConsultants(consultants);
          setAppointmentSetter(appointmentSetter);
          setalesAdmin(salesAdmin);
          setStaff(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStaffes();
  }, []);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("company_name", company_name);
    formData.append("number_of_employees", number_of_employees);
    formData.append("code", "customer-admin");
    formData.append("payment_status", paymentStatus);
    formData.append("account_type", accountType);
    staffArray.forEach((obj, index) => {
      const objString = JSON.stringify(obj);
      formData.append(`staffs[${index}]`, objString);
    });
    formData.append("price", price);
    formData.append("sales_id", salesId ? salesId : null);
    formData.append("consultant_id", consultantId ? consultantId : null);
    formData.append(
      "appointment_setter_id",
      appointmentSetterId ? appointmentSetterId : null
    );
    formData.append("image", image);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "usertype/update";
        formData.append("id", id);
        payload = formData;
        // {
        //   id: id,
        //   name: name,
        //   email: email,
        //   company_name: company_name,
        //   number_of_employees: number_of_employees,
        //   code: 'customer-admin',
        //   payment_status: paymentStatus,
        //   staffs:staffArray,
        //   price:price,
        //   sales_id : (salesId)?salesId:null,
        //   consultant_id: (consultantId)?consultantId:null,
        //   appointment_setter_id: (appointmentSetterId)?appointmentSetterId:null,
        //   image : image
        // }
      } else {
        path = "usertype/create";
        formData.append("phone", phone);
        formData.append("promo_code", promoCode);
        payload = formData;
        // {
        //   name: name,
        //   email: email,
        //   phone:phone,
        //   company_name: company_name,
        //   number_of_employees: number_of_employees,
        //   code: 'customer-admin',
        //   promo_code : promoCode,
        //   payment_status: paymentStatus,
        //   staffs:staffArray,
        //   price:price,
        //   sales_id : (salesId)?salesId:null,
        //   consultant_id: (consultantId)?consultantId:null,
        //   appointment_setter_id: (appointmentSetterId)?appointmentSetterId:null,
        //   image : image
        // }
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        navigate("/subscriptions");
        setIsManage(false);
      } else {
        toastr.error(res.message);
        setIsManage(false);
      }
    } catch (err) {
      console.log(err.messege);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        {/* <div className="flex overflow-auto w-full mb-5">
          <div className="flex space-x-2 w-full border-b-2 border-slate-300">
            <button
              type="button"
              className={
                "relative px-3 py-2 transition-all duration-200 font-bold text-sm rounded-none -mb-[2px] border-b-2  bg-transparent " +
                (location && location.pathname === "/new-account-v2"
                  ? "text-teal-500  border-teal-500"
                  : "text-slate-800 border-transparent")
              }
              onClick={() => {
                navigate("/new-account-v2");
              }}
            >
              Latest V2
            </button>
            <button
              type="button"
              className={
                "relative px-3 py-2 transition-all duration-200 font-bold text-sm rounded-none -mb-[2px] border-b-2  bg-transparent " +
                (location && location.pathname === "/new-account"
                  ? "text-teal-500  border-teal-500"
                  : "text-slate-800 border-transparent")
              }
              onClick={() => {
                navigate("/new-account");
              }}
            >
              Previous V1
            </button>
          </div>
        </div> */}
        <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{t("new_account_creation")}</div>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-3 border-r border-slate-200">
            <div className="p-2 2xl:p-4">
              <div className="py-6 px-6 flex flex-col space-y-2 relative">
                <div className="relative">
                  <div className="relative group w-full aspect-1 border-2 mx-auto overflow-hidden rounded-full border-dark-teal-500 z-[1]">
                    <ButtonFile
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-image"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("upload_image")}
                      buttonClasses={
                        "!h-64 !w-64 !absolute !opacity-0 !z-10 !top-0 !left-0"
                      }
                      functions={onImgChange}
                      accepts={"image/*"}
                    />
                    <Image
                      src={image}
                      alt={"Admin"}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"object-cover"}
                      id={"image1"}
                      isLogo={true}
                    />
                  </div>
                  {/* {image && (
                  <div className="absolute right-1 top-6 z-[2] ">
                    <Button
                      buttonIconPosition={"left"}
                      buttonIcon={"fa-solid fa-x"}
                      buttonClasses={
                        "!text-rose-500 !border !border-dark-teal-500 !bg-white !h-8 !w-8 !rounded-full"
                      }
                      functions={() => setImage(null)}
                    />
                  </div>
                )} */}
                </div>
              </div>

              <div className="divide-x divide-slate-200 flex items-center justify-center rounded-b border-t border-slate-200 pt-5">
                <ButtonFile
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-image"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("Upload Logo")}
                  functions={onImgChange}
                  accepts={"image/*"}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-9 flex flex-col">
            <div className="space-y-4 ">
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="relative w-full">
                  <Input
                    label={t("company_name")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    inputType={"input"}
                    inputPlaceholder={t("enter_company_name")}
                    inputValue={company_name}
                    setInput={setCompanyName}
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    label={t("company_administrator_name")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    inputType={"input"}
                    inputPlaceholder={t("enter_company_administrator_name")}
                    inputValue={name}
                    setInput={setName}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="relative w-full">
                  <Input
                    label={t("administrator_email")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    inputType={"email"}
                    inputPlaceholder={t("enter_administrator_email")}
                    inputValue={email}
                    setInput={setEmail}
                  />
                </div>
                <div className="relative w-full">
                  <Input
                    label={t("administrator_phone_number") + ":"}
                    labelClasses={"!text-xs"}
                    inputType={"input"}
                    inputPlaceholder={
                      t("enter_administrator_phone_number") + ":"
                    }
                    inputValue={phone}
                    setInput={setPhone}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="relative w-full">
                  <Select
                    label={t("number_of_employees")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={number_of_employees}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: " ", label: t("select_employees"), value: "" },
                      ...plans.map((item) => ({
                        _id: item?._id,
                        label: item?.name,
                        value: item?.price,
                      })),
                    ]}
                    getSelectedValue={(e) => {
                      setNumberOfEmployees(e._id);
                      setPrice(e.value);
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Select
                    label={t("account_type")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={accountType}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: "", label: t("select_account_type"), value: "" },
                      {
                        _id: "business",
                        label: capitalizeString("business"),
                        value: "business",
                      },
                      {
                        _id: "demonstration",
                        label: capitalizeString("demonstration"),
                        value: "demonstration",
                      },
                      {
                        _id: "consultant",
                        label: capitalizeString("consultant"),
                        value: "consultant",
                      },
                      {
                        _id: "developer",
                        label: capitalizeString("developer"),
                        value: "developer",
                      },
                      {
                        _id: "training",
                        label: capitalizeString("training"),
                        value: "training",
                      },
                    ]}
                    dropdownOptionBold={true}
                    getSelectedValue={(e) => setAccountType(e._id)}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="relative w-full">
                  <Input
                    label={t("price")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    inputType={"number"}
                    inputPlaceholder={t("enter_price")}
                    inputValue={price}
                    setInput={setPrice}
                    //isDisabled={true}
                  />
                </div>
                <div className="relative w-full">
                  <Select
                    label={t("payment_status")}
                    requiredDisplay={true}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={paymentStatus}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      {
                        _id: " ",
                        label: t("select_payment_status"),
                        value: "",
                      },
                      { _id: 1, label: t("paid"), value: 1 },
                      { _id: 2, label: t("unpaid"), value: 2 },
                      { _id: 3, label: t("trial"), value: 3 },
                      // { _id: 4, label: t('inactive'),value:4},
                      // { _id: 5, label: t('sales_demo'),value:5}
                    ]}
                    getSelectedValue={(e) => setPaymentStatus(e._id)}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="relative w-full">
                  <Select
                    label={t("sales")}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={salesId}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: null, label: t("select_sales"), value: null },
                      ...sales.map((item) => {
                        let sales = item?.commission.filter(
                          (itm) => itm.code === "sales-staff"
                        );
                        return {
                          _id: item?._id,
                          label:
                            item?.last_name +
                            "," +
                            item?.first_name +
                            ", " +
                            t("salesperson") +
                            " " +
                            (sales[0].commission ? sales[0].commission : 0) +
                            "%",
                          value: sales[0].commission ? sales[0].commission : 0,
                        };
                      }),
                      { _id: null, label: t("none"), value: null },
                    ]}
                    getSelectedValue={(e) => {
                      setSalesId(e._id);
                      setSalesCommission(e.value);
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Select
                    label={t("consultant")}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={consultantId}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      { _id: null, label: t("select_consultant"), value: null },
                      ...consultants.map((item) => {
                        let consultant = item?.commission.filter(
                          (itm) => itm.code === "consultant"
                        );
                        return {
                          _id: item?._id,
                          label:
                            item?.last_name +
                            "," +
                            item?.first_name +
                            ", " +
                            t("consultant") +
                            " " +
                            (consultant[0].commission
                              ? consultant[0].commission
                              : 0) +
                            "%",
                          value: consultant[0].commission
                            ? consultant[0].commission
                            : 0,
                        };
                      }),
                      { _id: null, label: t("none"), value: null },
                    ]}
                    getSelectedValue={(e) => {
                      setConsultantId(e._id);
                      setConsultantCommission(e.value);
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Select
                    label={t("appointment_setter")}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={appointmentSetterId}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      {
                        _id: null,
                        label: t("select_appointment_setter"),
                        value: null,
                      },
                      ...appointmentSetter.map((item) => {
                        let appointmentSetter = item?.commission.filter(
                          (itm) => itm.code === "appointment-setter"
                        );
                        return {
                          _id: item?._id,
                          label:
                            item?.last_name +
                            "," +
                            item?.first_name +
                            ", " +
                            t("appt_setter") +
                            " " +
                            (appointmentSetter[0].commission
                              ? appointmentSetter[0].commission
                              : 0) +
                            "%",
                          value: appointmentSetter[0].commission
                            ? appointmentSetter[0].commission
                            : 0,
                        };
                      }),
                      { _id: null, label: t("none"), value: null },
                    ]}
                    getSelectedValue={(e) => {
                      setAppointmentSetterId(e._id);
                      setAppointmentSetterCommission(e.value);
                    }}
                  />
                </div>
                <div className="relative w-full">
                  <Select
                    label={t("sales_admin")}
                    labelClasses={"!text-xs"}
                    xPlacement={"left"}
                    selectedValue={salesAdminId}
                    dropdownClass={"!w-60"}
                    dropdownData={[
                      {
                        _id: null,
                        label: t("select_sales_admin"),
                        value: null,
                      },
                      ...salesAdmin.map((item) => {
                        let salesAdmin = item?.commission.filter(
                          (itm) => itm.code === "sales-admin"
                        );
                        return {
                          _id: item?._id,
                          label:
                            item?.last_name +
                            "," +
                            item?.first_name +
                            ", " +
                            t("sales_admin") +
                            " " +
                            (salesAdmin[0].commission
                              ? salesAdmin[0].commission
                              : 0) +
                            "%",
                          value: salesAdmin[0].commission
                            ? salesAdmin[0].commission
                            : 0,
                        };
                      }),
                      { _id: null, label: t("none"), value: null },
                    ]}
                    getSelectedValue={(e) => {
                      setSalesAdminId(e._id);
                      setSalesAdminCommission(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-5">
              <Button
                buttonClasses=""
                buttonLabelClasses=""
                buttonType={"button"}
                buttonIcon={
                  isManage
                    ? "fa-light fa-spinner fa-spin"
                    : "fa-light fa-floppy-disk"
                }
                buttonIconPosition={"left"}
                buttonLabel={t("send")}
                functions={onSubmit}
                buttonHasLink={false}
                buttonDisabled={isManage}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="border border-gray-200 bg-white p-4 shadow-md rounded-xl mt-6 transition duration-300">
        <div className="flex items-start w-full gap-6">
          <div className="w-full">
            <Link to="/new-account-v2">
              <div className="text-base font-semibold text-teal-500 hover:text-teal-600 underline transition duration-200 ease-in-out">
                New Account Creation v2
              </div>
            </Link>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AccountCreation;
